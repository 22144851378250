import { Tooltip } from "@mantine/core";
import React from "react";

const AnimatedRefreshIcon = ({
  isLoading = false,
  size = 24,
  color = "#748FFC",
  tip = "",
}) => {
  const spinAnimation = {
    animation: isLoading ? "spin 1s linear infinite" : "none",
    opacity: isLoading ? 1 : 0.5,
    transition: "opacity 0.3s ease-in-out",
  };

  return (
    <Tooltip
      label={tip}
      position="bottom"
      color="indigo"
      transitionProps={{ transition: "slide-down", duration: 300 }}
    >
      <div style={spinAnimation}>
        <style>
          {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
        </style>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 24 24"
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="icon-tabler icon-tabler-refresh"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
          <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
        </svg>
      </div>
    </Tooltip>
  );
};

export default AnimatedRefreshIcon;
