import React, { ReactNode, useState, useEffect } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Box, Center, Loader, useMantineColorScheme } from "@mantine/core";
import {
  useAppStore,
  useAppStoreActions,
  useAppStoreState,
} from "@src/stores/appstore";
import { authenticationService } from "@services/auth.service";
import apiClient from "@src/lib/api_client";
import { Toaster } from "react-hot-toast";
import { authHeader } from "../lib/requestHeaders";

interface AuthRouteProps {
  children: ReactNode;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<{ workspaceId?: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const updateUserMetaData = useAppStoreActions()?.updateUserMetaData;
  const { currentWorkspaceId } = useAppStore.use.userMetaData();
  const savedSubdoman = useAppStore.use.subdomain();

  const checkAuth = async (): Promise<boolean> => {
    const getAuthHeaders = authHeader();

    try {
      const response = await apiClient.get("/authentication/check", {
        withCredentials: true,
        headers: getAuthHeaders,
      });

      const currentSubdomain = window.location.hostname.split(".")[0];

      if (savedSubdoman !== currentSubdomain) {
        useAppStoreActions()?.setSubdomain(currentSubdomain);
      }

      if (response.data.authenticated) {
        authenticationService.updateCurrentSession({
          isAuthenticated: true,
          user: response.data.user,
        });
        useAppStoreActions()?.updateUserMetaData({
          currentWorkspaceId: response.data.user["currentWorkspaceId"],
          defaultWorkspaceId: response.data.user["defaultWorkspaceId"],
          email: response.data.user["email"],
          firstName: response.data.user["firstName"],
          lastName: response.data.user["lastName"],
        });
      }

      return response.data.authenticated;
    } catch (error) {
      console.error("Error checking authentication status:", error);
      return false;
    }
  };

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const isAuthenticated = await checkAuth();
        if (isAuthenticated) {
          setIsLoading(false);
        } else {
          throw new Error("Authentication failed");
        }
      } catch (error) {
        console.error("Authentication error:", error);
        await authenticationService.logout();
        navigate("/login", { replace: true });
      }
    };

    initializeAuth();
  }, [navigate, updateUserMetaData]);

  useEffect(() => {
    function saveAuthToken() {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("t_id");

      if (token) {
        sessionStorage.setItem("sessions_id", token);
        // Remove token from URL
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      }
    }
    saveAuthToken();
    if (!isLoading && currentWorkspaceId) {
      if (location.pathname === "/") {
        navigate(`/${currentWorkspaceId}/dashboard`, { replace: true });
      } else {
        const urlWorkspaceId = params.workspaceId;
        if (
          urlWorkspaceId === ":workspaceId" ||
          urlWorkspaceId !== currentWorkspaceId
        ) {
          const newPath = location.pathname.replace(
            `/${urlWorkspaceId}`,
            `/${currentWorkspaceId}`
          );
          navigate(newPath, { replace: true });
        }
      }
    }
  }, [
    isLoading,
    currentWorkspaceId,
    params.workspaceId,
    location.pathname,
    navigate,
  ]);

  if (isLoading) {
    return (
      <Box className="h-100">
        <Center className="h-100">
          <Loader color="dark" />
        </Center>
      </Box>
    );
  }

  if (!authenticationService.currentSessionValue()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const currentTheme = useMantineColorScheme();

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={true}
        toastOptions={{
          style: {
            background: currentTheme.colorScheme === "dark" ? "#333" : "#fff",
            color: currentTheme.colorScheme === "dark" ? "#fff" : "#333",
          },
        }}
      />
      {children}
    </>
  );
};

export default AuthRoute;
