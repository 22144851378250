import React from "react";
import { UnstyledButton, Group, Avatar, Text, rem } from "@mantine/core";

import { Image } from "@src/components/BaseUI";
import VerifiedIcon from "@assets/icons/verified.svg";
import { WidgetProps } from "../widgets/types";

interface Composition {
  RenderTitle: React.FC<{ title: string; color: string }>;
  RenderSubtitle: React.FC<{ subtitle: string; show: boolean }>;
}

const SocialButton: React.FC<WidgetProps> & Composition = ({
  id,
  inheritedStyles,
  properties,
  ref,
}) => {
  const {
    title,
    subTitle,
    titleColor,
    displayPicture,
    addSubtitle,
    showVerifiedIcon,
  } = properties.props;

  return (
    <UnstyledButton
      ref={ref}
      className={`user-profile widget-${id} draggable-widget p-0`}
      style={{
        ...inheritedStyles,
      }}
    >
      <Group>
        <Avatar src={displayPicture.value} radius="xl" />

        <div>
          <SocialButton.RenderTitle
            title={title.value}
            color={titleColor.value}
          />

          <SocialButton.RenderSubtitle
            subtitle={subTitle.value}
            show={addSubtitle.value}
          />
        </div>

        {showVerifiedIcon?.value && (
          <Image
            src={VerifiedIcon}
            width={20}
            height={20}
            alt={title.value}
            handleDarkMode={false}
          />
        )}
      </Group>
    </UnstyledButton>
  );
};

const RenderTitle: React.FC<{ title: string; color: string }> = ({
  title,
  color,
}) => {
  return (
    <Text size="sm" fw={500} color={color}>
      {title}
    </Text>
  );
};

const RenderSubtitle = ({
  subtitle,
  show,
}: {
  subtitle: string;
  show: boolean;
}) => {
  if (!show) return null;

  return (
    <Text c="dimmed" size="xs">
      {subtitle}
    </Text>
  );
};

SocialButton.RenderTitle = RenderTitle;
SocialButton.RenderSubtitle = RenderSubtitle;

export default SocialButton;
