import { Button } from "@mantine/core";
import { IconWorldUp } from "@tabler/icons-react";

import React from "react";
import {
  useEditorStore,
  useEditorStoreActions,
} from "@/src/stores/editorstore";
import { modals } from "@mantine/modals";
import toast from "react-hot-toast";

import { useDisclosure } from "@mantine/hooks";
import { ConfimPublishingModal } from "./ConfirmPublishingApp";

const Publish: React.FC<{}> = () => {
  const isPublished = useEditorStore.use.metadata().published;
  const isSaving = useEditorStore.use.isSaving();

  const { publishApp, setEditorState } = useEditorStoreActions();

  const [opened, { open, close }] = useDisclosure(false);

  const publish = () => {
    return new Promise((resolve) => {
      return setTimeout(() => {
        publishApp(true);
        resolve("App published successfully");
      }, 2000);
    });
  };

  const handleConfirm = () => {
    try {
      return toast
        .promise(
          publish(),
          {
            loading: "Publishing app...",
            success: "App published successfully",
            error: "Failed to publish app",
          },
          {
            position: "top-center",
          }
        )
        .then(() => {
          setEditorState({ isSaving: false });
          close();
        });
    } catch (error) {
      toast.error("Failed to publish app");
      setEditorState({ isSaving: false });
    }
  };

  const handlePublish = () => {
    const isPublishedContext = useEditorStore.getState().metadata.published;
    if (isPublishedContext) {
      open();
    } else {
      setEditorState({ isSaving: true });
      toast.loading("Publishing app...", {
        duration: 2000,
      });
      setTimeout(() => {
        publishApp(true)
          .then(() => {
            setEditorState({ isSaving: false });
            toast.success("App published successfully");
          })
          .catch((error) => {
            toast.error("Failed to publish app");
            setEditorState({ isSaving: false });
          });
      }, 2000);
    }
  };
  return (
    <>
      <ConfimPublishingModal
        opened={opened}
        onClose={close}
        onConfirm={handleConfirm}
      />
      <Button
        disabled={isSaving}
        variant="filled"
        size="xs"
        color="indigo"
        radius={"sm"}
        leftIcon={<IconWorldUp size="1rem" />}
        onClick={handlePublish}
        sx={(theme) => ({
          backgroundColor: theme.colors.indigo[6],
          "&:hover": {
            backgroundColor: theme.colors.indigo[8],
          },
        })}
      >
        {isPublished ? "Republish" : "Publish"}
      </Button>
    </>
  );
};

export default Publish;
