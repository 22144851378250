import { Widget } from "@src/modules/BlinkInBio/Editor/widgets/types";
import { CreateComponentDto } from "@src/services/types";

export function sanitizePayload(payload: any): any {
  if (payload === null || payload === undefined) {
    return payload;
  }

  if (Array.isArray(payload)) {
    return payload.map((item) => sanitizePayload(item));
  }

  if (typeof payload === "object") {
    const newObj: { [key: string]: any } = {};
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const value = payload[key];
        if (value instanceof Date) {
          newObj[key] = value.toISOString();
        } else if (typeof value === "object") {
          newObj[key] = sanitizePayload(value);
        } else {
          newObj[key] = value;
        }
      }
    }
    return newObj;
  }

  return payload;
}

export function santizeWidget(
  widget: Widget,
  pageId: string
): CreateComponentDto {
  return {
    id: widget.id,
    name: widget.name,
    category: widget.meta.category,
    type: widget.meta.type,
    baseClassName: widget.baseClassName || "",
    properties: widget.properties,
    control: widget.control,
    pageId: pageId,
    layouts: [],
  };
}
