import { useDisclosure } from "@mantine/hooks";
import {
  Dialog,
  Group,
  Button,
  TextInput,
  Text,
  Highlight,
  Box,
} from "@mantine/core";
import React from "react";
import { AtSign } from "lucide-react";
import { instanceService } from "../services/instance.service";
import toast from "react-hot-toast";

export const CollectWaitlist: React.FC = () => {
  const [opened, { toggle, close }] = useDisclosure(false);

  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleEmailSubmit = async (email: string) => {
    setLoading(true);
    try {
      await instanceService.addToWaitlist(email);
      toast("🚀 You're on the waitlist!");
    } catch (error) {
      console.error("Error storing data in Airtable:", error);
      toast.error("Failed to add to waitlist");
    } finally {
      setEmail("");
    }

    setLoading(false);
  };

  return (
    <>
      <Group position="center">
        <Button
          onClick={toggle}
          className=""
          variant={"white"}
          color="dark"
          size="xs"
          leftIcon={<AtSign size={"1rem"} />}
        >
          Sign up for the waitlist
        </Button>
      </Group>

      <Dialog
        opened={opened}
        withCloseButton
        onClose={close}
        size="lg"
        radius="md"
        className="waitlist-dialog"
      >
        <Box className="mb-2">
          <Text size="sm" mb="xs" weight={600}>
            🎉 Be the First to Access Powerful New Features!
          </Text>
          <Highlight
            size={"xs"}
            align="start"
            highlight={[
              "no-code UI builder",
              "detailed analytics",
              "automations",
              "Blink",
              "RBAC",
            ]}
            highlightStyles={(theme) => ({
              backgroundImage: theme.fn.linearGradient(
                45,
                theme.colors.cyan[5],
                theme.colors.indigo[5]
              ),
              fontWeight: 700,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            })}
          >
            Get early access to Blink – the ultimate link management platform
            with a no-code UI builder, detailed analytics, automations,
            workspaces, RBAC and more!
          </Highlight>
        </Box>

        <Group align="apart" className="w-100">
          <TextInput
            placeholder="Enter your email here"
            size="xs"
            sx={{ width: "60%" }}
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
          />

          <Button
            onClick={() => {
              handleEmailSubmit(email);
            }}
            color="indigo"
            size="xs"
            loading={loading}
          >
            Sign up! 🚀
          </Button>
        </Group>
      </Dialog>
    </>
  );
};
