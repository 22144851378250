import { Widget } from "../../types";

export const Link: Widget = {
  id: "",
  name: "Link",
  description: "Link component to display links with theme styles.",
  meta: {
    type: "link",
    category: "components",
  },
  variant: "filled",
  baseClassName: "",
  properties: {
    styles: {},
    props: {
      text: {
        section: "customization",
        value: "Some text here",
        type: "text",
        key: "text",
        name: "Text",
      },
      target: {
        section: "customization",
        value: "_blank",
        type: "segmented-control",
        options: ["_blank", "_self"],
        icons: {
          _blank: "blank",
          _self: "self",
        },
        key: "target",
        name: "Target",
      },
      color: {
        section: "customization",
        value: "#25262b",
        type: "color",
        key: "color",
        name: "Color",
      },
      cursor: {
        section: "customization",
        value: "pointer",
        type: "segmented-control",
        options: ["pointer", "default", "auto", "none"],
        key: "cursor",
        name: "Cursor",
      },
      textAlign: {
        section: "customization",
        value: "center",
        type: "segmented-control",
        options: ["left", "right", "center", "justify"],
        icons: {
          left: "left-align",
          right: "right-align",
          center: "center-align",
          justify: "justify",
        },
        onlyIcons: true,
        key: "textAlign",
        name: "Text align",
      },
      fontSize: {
        section: "customization",
        value: "xl",
        type: "segmented-control",
        options: ["xs", "sm", "md", "lg", "xl"],
        key: "fontSize",
        name: "Font size",
      },
      fontWeight: {
        section: "customization",
        value: 700,
        type: "number",
        key: "fontWeight",
        name: "Font weight",
      },
      truncate: {
        section: "customization",
        value: true,
        type: "toggle",
        key: "truncate",
        name: "Truncate",
      },
      underline: {
        section: "customization",
        value: true,
        type: "toggle",
        key: "underline",
        name: "Underline",
      },
    },
  },
  control: {
    redirectTo: "",
    type: "blink",
  },
  layout: {
    currentLayout: "mobile",
    mobile: {
      top: 0,
      left: 0,
      width: 390,
      height: 40,
    },
  },
};
