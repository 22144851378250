import React from "react";
import Header from "./Header";
import PanelBody from "./PanelBody";
import { Stack } from "@mantine/core";

type Props = {};
interface Composition {
  Header: React.FC;
  Body: React.FC;
}

const Panel: React.FC<Props> & Composition = () => {
  return (
    <Stack>
      <Panel.Header />
      <Panel.Body />
    </Stack>
  );
};

Panel.Header = Header;
Panel.Body = PanelBody;

export default Panel;
