import React, { useEffect, useState } from "react";
import { WidgetProps } from "../widgets/types";
import { useEditorStore } from "@/src/stores/editorstore";
import { XEmbed } from "react-social-media-embed";
import { Center, Loader } from "@mantine/core";

const TwitterCard: React.FC<WidgetProps> = ({
  id,
  inheritedStyles,
  properties,
  control,
  ref,
}) => {
  const { postUrl } = properties.props;
  const [url, setUrl] = useState<string>("");
  const isEditMode = useEditorStore.use.mode() === "edit";

  useEffect(() => {
    setUrl("");
    setTimeout(() => {
      setUrl(postUrl.value);
    }, 300);
  }, [postUrl.value]);

  const handleEditModeEvents = (e: React.MouseEvent | React.TouchEvent) => {
    if (isEditMode) {
      e.preventDefault();
      // e.stopPropagation();
    }
  };

  const RenderEmbed = ({ embedUrl }: { embedUrl: string }) => {
    return <XEmbed url={embedUrl} placeholderSpinner={true} />;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        ...inheritedStyles,
      }}
      onClick={handleEditModeEvents}
      onMouseDown={handleEditModeEvents}
      onTouchStart={handleEditModeEvents}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          pointerEvents: isEditMode ? "none" : "auto",
        }}
      >
        {url.length === 0 ? (
          <Center
            style={{
              minHeight: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Loader color="indigo" />
          </Center>
        ) : (
          <RenderEmbed embedUrl={url} />
        )}
      </div>
    </div>
  );
};

export default TwitterCard;
