import React, { useEffect } from "react";
import { ClicksChart } from "./ClicksChart";
import DeviceRadialChart from "@/src/modules/Dashboard/RadialChart";
import BarChart from "@/src/modules/Dashboard/Barchart";
import { Box, Button, LoadingOverlay, Stack } from "@mantine/core";
import { useEditorStore } from "@/src/stores/editorstore";
import { blinkService } from "@/src/services/blinks.services";
import { Blink, DeviceBrowserInfo } from "@/src/types/modules/blinks";
import { TChartData } from "@/src/modules/Dashboard";
import { useWorkspaceId } from "@/src/hooks";

type Props = {};

type TBarChartData = {
  browser: string;
  visitors: number;
  fill: string;
}[];

type TGeoData = {
  location: string;
  visitors: number;
  fill: string;
}[];

function transformDataForDeviceAnalyticsGraph(analyticsData: {
  count: number;
  deviceBrowserInfo: Record<string, DeviceBrowserInfo>;
}): TChartData[] {
  const { deviceBrowserInfo } = analyticsData ?? {};

  const data: TChartData[] = [];

  Object.entries(deviceBrowserInfo).forEach(([_, entry]) => {
    const isSameDate = data.find((item) => item.date === entry.date);

    if (isSameDate) {
      isSameDate.mobile +=
        entry.deviceType.toLocaleLowerCase() === "mobile" ? entry.count : 0;
      isSameDate.desktop +=
        entry.deviceType.toLocaleLowerCase() === "desktop" ? entry.count : 0;
      return;
    }

    data.push({
      date: entry.date,
      mobile:
        entry.deviceType.toLocaleLowerCase() === "mobile" ? entry.count : 0,
      desktop:
        entry.deviceType.toLocaleLowerCase() === "desktop" ? entry.count : 0,
    });
  });

  return data;
}

function transformDataForBrowserAnalyticsGraph(analyticsData: {
  count: number;
  deviceBrowserInfo: Record<string, DeviceBrowserInfo>;
}): TBarChartData {
  const { deviceBrowserInfo } = analyticsData;

  const data = [
    { browser: "chrome", visitors: 0, fill: "var(--color-chrome)" },
    { browser: "safari", visitors: 0, fill: "var(--color-safari)" },
    { browser: "firefox", visitors: 0, fill: "var(--color-firefox)" },
    { browser: "edge", visitors: 0, fill: "var(--color-edge)" },
    { browser: "other", visitors: 0, fill: "var(--color-other)" },
  ];

  Object.entries(deviceBrowserInfo).forEach(([_, entry]) => {
    const isSameDate = data.find(
      (item) =>
        item.browser.toLocaleLowerCase() ===
        entry.browserName.toLocaleLowerCase()
    );

    if (isSameDate) {
      isSameDate.visitors += entry.count;
      return;
    }
  });

  return data;
}

function transformDataForGeoAnalyticsGraph(analyticsData: {
  count: number;
  deviceBrowserInfo: Record<string, DeviceBrowserInfo>;
}): TGeoData {
  const { deviceBrowserInfo } = analyticsData;

  const data: TGeoData = [];

  Object.values(deviceBrowserInfo).forEach((entry, index) => {
    const { geoLocation } = entry;
    const isSameLocation = data.find((item) => {
      return item.location.includes(geoLocation.cityName);
    });

    if (isSameLocation) {
      isSameLocation.visitors += entry.count;
      return;
    }

    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

    data.push({
      location: geoLocation.cityName,
      visitors: entry.count,
      fill: randomColor,
    });
  });

  return data;
}

type BlinkAnalytics = {
  count: number;
  deviceBrowserInfo: Record<string, DeviceBrowserInfo>;
  dateWiseCount: { date: string; count: number }[];
};

interface BlinkDetail extends Blink {
  analytics: BlinkAnalytics;
}

const index = (props: Props) => {
  const blinkId = useEditorStore.use.associatedBlinkId();

  const [analyticsData, set] = React.useState<BlinkAnalytics | null>(null);
  const [isLoading, setLoading] = React.useState<boolean>(true);

  const [radialChartData, setRadialChartData] = React.useState<TChartData[]>(
    []
  );
  const [barCharData, setBarChartData] = React.useState<TBarChartData>([]);
  const [geographicalData, setGeographicalData] = React.useState<TGeoData>([]);

  useEffect(() => {
    blinkService
      .getOne(blinkId)
      .then((res: BlinkDetail) => {
        set(res.analytics);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!isLoading && analyticsData) {
      const count = analyticsData.count;
      const deviceBrowserInfo = analyticsData.deviceBrowserInfo ?? {};

      const transformedData = transformDataForDeviceAnalyticsGraph({
        count,
        deviceBrowserInfo,
      });

      const transformedBarData = transformDataForBrowserAnalyticsGraph({
        count,
        deviceBrowserInfo,
      });

      const transformedGeoData = transformDataForGeoAnalyticsGraph({
        count,
        deviceBrowserInfo,
      });

      setRadialChartData(transformedData);
      setBarChartData(transformedBarData);
      setGeographicalData(transformedGeoData);
    }
  }, [isLoading]);

  const workspaceId = useWorkspaceId();

  const origin = window.location.origin;

  const navigateTo = `${origin}/${workspaceId}/blinks/${blinkId}`;

  return (
    <Stack>
      <Box pos="relative">
        <LoadingOverlay
          visible={isLoading}
          overlayBlur={2}
          loaderProps={{
            color: "indigo",
          }}
        />
        <DeviceRadialChart chartData={radialChartData} />
      </Box>
      <Box pos="relative">
        <LoadingOverlay
          visible={isLoading}
          overlayBlur={2}
          loaderProps={{
            color: "indigo",
          }}
        />
        <BarChart chartData={barCharData} />
      </Box>
      <Box pos="relative">
        <LoadingOverlay
          visible={isLoading}
          overlayBlur={2}
          loaderProps={{
            color: "indigo",
          }}
        />
        <ClicksChart
          chartData={geographicalData}
          totalClicks={analyticsData?.count ?? 0}
        />
      </Box>

      <Button
        color="indigo"
        size="xs"
        onClick={() => {
          window.open(navigateTo, "_blank");
        }}
      >
        Open
      </Button>
    </Stack>
  );
};

export default index;
