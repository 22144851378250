import { appConfig } from "@src/app.config";
import { handleResponseWithoutValidation } from "@src/lib/handleResponses";
import { authHeader } from "@src/lib/requestHeaders";

export const instanceService = {
  addToWaitlist,
};

async function addToWaitlist(email: string) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ email }),
  };

  const response = await fetch(
    `${appConfig.apiURL}/instance/waitlist`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}
