import { appConfig } from "@src/app.config";
import { handleResponseWithoutValidation } from "@src/lib/handleResponses";
import { authHeader } from "@src/lib/requestHeaders";

export const templateService = {
  getTemplatePages,
  createFromPage,
};

function getTemplatePages() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  return fetch(`${appConfig.apiURL}/templates/pages`, requestOptions).then(
    handleResponseWithoutValidation
  );
}

function createFromPage(templatePageId: string, currentPageId: string) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ templatePageId, pageId: currentPageId }),
    credentials: "include" as RequestCredentials,
  };

  return fetch(
    `${appConfig.apiURL}/pages/template/create`,
    requestOptions
  ).then(handleResponseWithoutValidation);
}
