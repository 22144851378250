import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Button,
  Group,
  Text,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { useClipboard, useViewportSize } from "@mantine/hooks";
import { humanReadableCount, timeSince } from "@src/utils";
import { IconDownload, IconExternalLink, IconLink } from "@tabler/icons-react";

import React from "react";
import toast from "react-hot-toast";

type Props = {
  name: string;
  blinkURL: string;
  redirectURL: string;
  clickCount: number;
  created: string;
};

const Header: React.FC<Props> = ({
  name,
  blinkURL,
  redirectURL,
  clickCount,
  created,
}) => {
  const clipboard = useClipboard({ timeout: 500 });

  function openBlink(blinkURL: string) {
    // Ensure the URL has a protocol
    if (!/^https?:\/\//i.test(blinkURL)) {
      blinkURL = "http://" + blinkURL;
    }
    window.open(blinkURL, "_blank");
  }
  const screenWidth = useViewportSize().width;

  return (
    <Box sx={{ padding: screenWidth > 450 ? "0" : "1rem" }}>
      <Group position="apart">
        <UnstyledButton
          sx={(theme) => ({
            display: "block",
            width: screenWidth > 768 ? "60%" : "100%",

            padding: screenWidth > 768 ? theme.spacing.md : 0,
            color:
              theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
            cursor: "pointer",
          })}
        >
          <Group>
            <ThemeIcon
              radius={"lg"}
              color={"dark"}
              style={{
                cursor: "default",
              }}
            >
              <IconLink size={"1rem"} />
            </ThemeIcon>
            <div style={{ flex: 1 }}>
              <Text size="sm" weight={500}>
                {name}
              </Text>

              <div
                onClick={() => {
                  clipboard.copy(blinkURL);
                  toast.success("Blink URL copied to clipboard");
                }}
              >
                <Tooltip
                  label={`Click to copy : ${blinkURL}`}
                  withArrow
                  transitionProps={{ duration: 200 }}
                  multiline
                  color="indigo"
                  width={320}
                  sx={{ overflowWrap: "break-word" }}
                >
                  <Text fw={500} color="indigo" size="xs">
                    {blinkURL.length > 30
                      ? blinkURL.substring(0, 30) + "..."
                      : blinkURL}
                  </Text>
                </Tooltip>
              </div>
              <Tooltip
                label={`Redirect URL: ${redirectURL}`}
                width={320}
                withArrow
                transitionProps={{ duration: 200 }}
                multiline
                color="indigo"
                sx={{ overflowWrap: "break-word" }}
              >
                <Text color="dimmed" size="xs">
                  {redirectURL.length > 30
                    ? redirectURL.substring(0, 30) + "..."
                    : redirectURL}
                </Text>
              </Tooltip>
              <Button
                compact
                radius={"sm"}
                className="mt-2"
                size="xs"
                leftIcon={<IconExternalLink size={"1rem"} />}
                variant="light"
                color="indigo"
                fullWidth
                onClick={() => {
                  openBlink(blinkURL);
                }}
              >
                Preview b-link
              </Button>
            </div>
            <div style={{ flex: 1, alignItems: "flex-end" }}>
              <Group position="apart">
                <Badge
                  variant="filled"
                  radius={"sm"}
                  sx={{
                    backgroundColor: "#10B981",
                  }}
                >
                  {humanReadableCount(clickCount)} Clicks
                </Badge>
              </Group>
              {created && (
                <Text className="mt-1" size={"xs"} color="dimmed" weight={500}>
                  {timeSince(created)}
                </Text>
              )}
            </div>
          </Group>
        </UnstyledButton>

        <Group position="apart">
          <Tooltip
            withArrow
            transitionProps={{ duration: 200 }}
            label="Export options for blink analytics."
          >
            <ActionIcon variant="light" disabled>
              <IconDownload size="1rem" />
            </ActionIcon>
          </Tooltip>

          <Tooltip
            multiline
            width={220}
            withArrow
            transitionProps={{ duration: 200 }}
            label="Compare this blink with other blinks."
          >
            <Button compact color="dark" variant="filled" disabled>
              Compare
            </Button>
          </Tooltip>
        </Group>
      </Group>
    </Box>
  );
};

export default Header;
