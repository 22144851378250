import React from "react";
import {
  Box,
  Button,
  CopyButton,
  Group,
  Modal,
  Notification,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import {
  IconBrandSnapchat,
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandX,
  IconBrandWhatsapp,
  IconBrandMessenger,
  IconMail,
  IconDotsCircleHorizontal,
} from "@tabler/icons-react";
import { IconShare } from "@tabler/icons-react";
import { useEditorStore } from "@/src/stores/editorstore";
import Lottie from "lottie-react";
import sharejson from "@assets/lottie/share.json";
import { ShareLinksGenerator } from "@/src/helpers/share";

const shareLinks = new ShareLinksGenerator();

const Share: React.FC<{ url: string }> = ({ url }) => {
  const isPublished = useEditorStore.use.metadata().published;

  const [isShareModalOpen, setShareModalOpen] = React.useState(false);

  return (
    <>
      <Button
        // disabled={true}
        variant="outline"
        size="xs"
        color="indigo"
        radius={"sm"}
        leftIcon={<IconShare size="1rem" />}
        onClick={() => setShareModalOpen(true)}
        sx={(theme) => ({
          "&:hover": {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[9]
                : theme.colors.indigo[9],
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors.gray[1],
          },
        })}
      >
        Share
      </Button>
      <ShareLinkModal
        opened={isShareModalOpen}
        onClose={() => setShareModalOpen(false)}
        urlToShare={url}
      />
    </>
  );
};

interface ShareOption {
  icon: React.ReactNode;
  label: string;
  color: string;
  filled?: boolean;
  source: string;
}

const shareOptions: ShareOption[] = [
  {
    icon: <IconBrandFacebook size="1.5rem" />,
    label: "Share on Facebook",
    color: "blue",
    filled: true,
    source: "facebook",
  },
  {
    icon: <IconBrandLinkedin size="1.5rem" />,
    label: "Share on LinkedIn",
    color: "blue",
    source: "linkedin",
  },
  {
    icon: <IconBrandX size="1.5rem" />,
    label: "Share on X",
    color: "dark",
    filled: true,
    source: "twitter",
  },
  {
    icon: <IconBrandWhatsapp size="1.5rem" />,
    label: "Share via WhatsApp",
    color: "green",
    source: "whatsapp",
  },
  {
    icon: <IconMail size="1.5rem" />,
    label: "Share via Email",
    color: "gray",
    source: "email",
  },
];

const ShareLinkModal: React.FC<{
  opened: boolean;
  onClose: () => void;
  urlToShare: string;
}> = ({ opened, onClose, urlToShare }) => {
  const textMessage = "Check this out!";
  const emailSubject = "Interesting Link";

  const getShareLink = (source: string, url: string) => {
    switch (source) {
      case "twitter":
        return shareLinks.getTwitterShareLink(url);

      case "linkedin":
        return shareLinks.getLinkedInShareLink(url);

      case "whatsapp":
        return shareLinks.getWhatsAppShareLink(url, textMessage);

      case "email":
        return shareLinks.getEmailShareLink(url, emailSubject, textMessage);

      default:
        break;
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size="md"
      title={
        <Title
          styles={{
            root: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          }}
          order={5}
        >
          Share this link with your friends and followers
        </Title>
      }
    >
      <Notification
        withBorder
        icon={
          <Lottie
            animationData={require("@/src/assets/lottie/alert.json")}
            loop={false}
          />
        }
        title="The shareable link is a shortlink of this page. It's easier to share and remember!"
        withCloseButton={false}
        styles={{
          icon: {
            backgroundColor: "transparent",
            height: "50px",
            width: "50px",
          },
        }}
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      />

      <Stack className="mt-3">
        {shareOptions.map((option, index) => {
          const shareLink = getShareLink(option.source, urlToShare);

          return (
            <Button
              key={index}
              leftIcon={option.icon}
              variant={option?.filled ? "filled" : "light"}
              color={option.color}
              fullWidth
              styles={{ inner: { justifyContent: "flex-start" } }}
              radius={"sm"}
              onClick={() => {
                window.open(shareLink, "_blank");
              }}
            >
              {option.label}
            </Button>
          );
        })}

        <Box p="md" style={{ backgroundColor: "#f1f3f5", borderRadius: "8px" }}>
          <Text size="sm" mb="xs">
            {urlToShare}
          </Text>
          <div className="copy-to-btn">
            <CopyButton value={urlToShare}>
              {({ copied, copy }) => (
                <Button
                  color={copied ? "teal" : "blue"}
                  onClick={copy}
                  fullWidth
                >
                  {copied ? "Copied" : "Copy"}
                </Button>
              )}
            </CopyButton>
          </div>
        </Box>
      </Stack>
    </Modal>
  );
};

{
  /* <Title order={4}>Join example on Linktree</Title>
<Text size="sm" color="dimmed">
  Get your own free Linktree. The only link in bio trusted by 50M+
  people.
</Text>
<Button fullWidth>Sign up free</Button>
<Button variant="outline" fullWidth>
  Find out more
</Button> */
}

{
  /* <Button
          leftIcon={<IconDotsCircleHorizontal size="1.5rem" />}
          variant="light"
          color="gray"
          fullWidth
          styles={{ inner: { justifyContent: "flex-start" } }}
        >
          More options
        </Button> */
}

export default Share;
