import React from "react";
import { Text } from "@mantine/core";
import { Widget, WidgetProps } from "../widgets/types";
import {
  useEditorStore,
  useEditorStoreActions,
} from "@/src/stores/editorstore";

const TextComponent: React.FC<WidgetProps> = ({
  id,
  inheritedStyles,
  properties,
  ref,
}) => {
  const {
    text,
    textAlign,
    fontSize,
    fontWeight,
    fontFamily,
    gradient,
    colorVariant,
    color,
  } = properties.props;

  const [editedText, setEditedText] = React.useState(text.value);

  const handleInputChange = (e: any) => {
    const newText = e.currentTarget.textContent;
    // Update your global state with newText
    setEditedText(newText);
  };

  const updateWidgetDefinition = (widgetId: string) => {
    const currentDefintion = useEditorStore
      .getState()
      .definitions.widgets.find((widget) => widget.id === widgetId) as
      | Widget
      | Record<string, any>;

    const newDefinition = {
      ...currentDefintion,
      properties: {
        ...currentDefintion.properties,
        props: {
          ...currentDefintion.properties.props,
          text: {
            ...currentDefintion.properties.props.text,
            value: editedText,
          },
        },
      },
    };

    useEditorStoreActions()?.updateWidgetDefinition(
      widgetId,
      newDefinition,
      "widgetDefinition"
    );
  };
  const isContentEditable = useEditorStore.use.mode() === "edit" || false;

  return (
    <Text
      autoFocus={true}
      onInput={handleInputChange}
      contentEditable={isContentEditable && true}
      ref={ref}
      onBlur={() => {
        updateWidgetDefinition(id);
      }}
      variant={colorVariant.value === "gradient" ? "gradient" : "text"}
      gradient={{ from: gradient.value.from, to: gradient.value.to, deg: 45 }}
      sx={{ fontFamily: fontFamily.value, cursor: "pointer" }}
      align={textAlign.value}
      fz={fontSize.value}
      fw={fontWeight.value}
      className={`widget-${id} draggable-widget`}
      style={{
        ...inheritedStyles,
      }}
      color={color.value}
    >
      {text.value}
    </Text>
  );
};

export default TextComponent;
