import {
  CheckIcon,
  ColorSwatch,
  Group,
  Paper,
  rem,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React, { useEffect, useState } from "react";

export const MantineColorSelector: React.FC<{
  callBack: (val: string) => void;
  value: string;
}> = ({ value, callBack }) => {
  const theme = useMantineTheme();

  const [checked, setChecked] = useState(value);

  useEffect(() => {
    if (checked) {
      callBack(checked);
    }
  }, [checked]);

  const swatches = Object.keys(theme.colors).map((color) => (
    <ColorSwatch
      key={color}
      color={theme.fn.rgba(theme.colors[color][6], 0.7)}
      onClick={() => setChecked(color)}
      sx={{ color: "#fff", cursor: "pointer" }}
    >
      {checked === color && <CheckIcon width={rem(10)} />}
    </ColorSwatch>
  ));

  return (
    <Paper shadow="xs" className="p-2 my-3" withBorder>
      <Text align="center" size="sm" weight={500}>
        Select color
      </Text>
      <Group position="center" spacing="xs" className="mt-2">
        {swatches}
      </Group>
    </Paper>
  );
};
