import React, { useRef } from "react";
import { Carousel } from "@mantine/carousel";
import { WidgetProps } from "../../widgets/types";
import { Image, rem } from "@mantine/core";

const Basic: React.FC<WidgetProps> = ({
  id,
  inheritedStyles,
  properties,
  control,
}) => {
  const {
    align,
    withControls,
    orientation,
    withIndicators,
    data,
    height,
    width,
    length,
  } = properties.props;

  const renderLimit = length?.value ?? 3;

  return (
    <Carousel
      slideSize="70%"
      slideGap="sm"
      controlSize={25}
      withControls={withControls?.value ?? true}
      dragFree
      draggable={true}
      align={align.value}
      orientation={orientation?.value ?? "horizontal"}
      withIndicators={withIndicators?.value ?? true}
      styles={{
        ...inheritedStyles,
        indicator: {
          width: rem(12),
          height: rem(4),
          transition: "width 250ms ease",

          "&[data-active]": {
            width: rem(40),
          },
        },
      }}
    >
      {data?.value.map((item: any, index: number) => {
        if (index >= renderLimit) return null;
        return (
          <Carousel.Slide
            style={{
              ...inheritedStyles,
            }}
          >
            <Image
              key={item.id}
              height={height?.value}
              width={width?.value}
              mx="auto"
              radius="md"
              src={item.src}
              alt={item.alt}
              style={{
                ...inheritedStyles,
              }}
            />
          </Carousel.Slide>
        );
      })}
    </Carousel>
  );
};

export default Basic;
