import React, { useState, useRef } from "react";
import { Modal, Button, TextInput, Box, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { SocialIcon, getNetworks } from "react-social-icons";
import { VirtuosoGrid } from "react-virtuoso";

interface Props {
  callback: (
    property: string,
    value: string | boolean | number | Record<string, any>
  ) => void;
  itemKey: string;
  item: Record<string, any>;
}

// List of available social networks supported by react-social-icons
const socialIcons: string[] = getNetworks();

export const SocialIconSelector: React.FC<Props> = ({
  callback,
  itemKey,
  item,
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [searchText, setSearchText] = useState<string>("");
  const iconList = useRef<string[]>(socialIcons);

  const searchIcon = (text: string) => {
    if (searchText === text) return;
    setSearchText(text);
  };

  const filteredIcons =
    searchText === ""
      ? iconList.current
      : iconList.current.filter((icon) =>
          icon.toLowerCase().includes(searchText.toLowerCase())
        );

  const onIconSelect = (icon: string) => {
    callback(itemKey, icon);
    close();
  };

  return (
    <div onMouseDown={(e) => e.stopPropagation()}>
      <Modal
        opened={opened}
        onClose={close}
        zIndex={3001}
        title="Select Social Icon"
      >
        <Modal.Header>
          <TextInput
            value={searchText}
            onChange={(event) => searchIcon(event.currentTarget.value)}
            placeholder="Search social icons"
            sx={{ width: "100%" }}
          />
        </Modal.Header>
        <Modal.Body>
          <VirtuosoGrid
            style={{ height: 300 }}
            totalCount={filteredIcons.length}
            listClassName="icon-list-wrapper"
            itemClassName="icon-list"
            itemContent={(index) => {
              const iconName = filteredIcons[index];
              return (
                <div
                  className="icon-element p-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => onIconSelect(iconName)}
                >
                  <SocialIcon
                    network={iconName}
                    style={{ width: 40, height: 40 }}
                  />
                </div>
              );
            }}
          />
        </Modal.Body>
      </Modal>
      <Box>
        <Text size="xs" fw={500}>
          Social Icon
        </Text>
        <Button
          sx={(theme) => ({
            "&:hover": {
              backgroundColor: "transparent",
            },
          })}
          leftIcon={
            <SocialIcon
              network={item?.value as string}
              style={{ width: 24, height: 24 }}
            />
          }
          onClick={open}
          variant="subtle"
          color="dark"
          size="sm"
          className="mt-1"
          radius="sm"
        >
          {item?.value || "Select Icon"}
        </Button>
      </Box>
    </div>
  );
};
