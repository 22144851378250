import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { TChartData } from "../Dashboard";

interface AnalyticsGraphProps {
  data: TChartData[];
}

const chartConfig: ChartConfig = {
  mobile: {
    label: "Mobile",
    color: "#8884d8",
  },
  desktop: {
    label: "Desktop",
    color: "#82ca9d",
  },
};

export function AnalyticsGraph({ data }: AnalyticsGraphProps) {
  return (
    <ChartContainer config={chartConfig} className="min-h-[400px] w-full">
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="date"
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            tickFormatter={(value) =>
              new Date(value).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
              })
            }
          />
          <YAxis tickLine={false} tickMargin={10} axisLine={false} />
          <ChartTooltip content={<ChartTooltipContent />} />
          <ChartLegend content={<ChartLegendContent />} />
          <Bar dataKey="mobile" fill="#5C7CFA" />
          <Bar dataKey="desktop" fill="#10B981" />
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}

export default AnalyticsGraph;
