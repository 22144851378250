import React from "react";
import { Image } from "@UIComponents/BaseUI";
import LogoImage from "@assets/logo.svg";
import { useNavigate } from "react-router-dom";
import { useWorkspaceId } from "@/src/hooks";
import { RouteMenu } from "@/src/components";
import { ActionIcon, Box } from "@mantine/core";

interface Props {}

const Logo: React.FC<Props> = (props) => {
  return (
    <Box
      sx={{
        maxWidth: "20%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <RouteMenu>
        <ActionIcon size="xl">
          <Image
            className="bridge-logo"
            src={LogoImage}
            width={40}
            height={40}
            alt="Logo"
            style={{
              cursor: "pointer",
            }}
          />
        </ActionIcon>
      </RouteMenu>
    </Box>
  );
};

export default Logo;
