import React from "react";
import { Avatar, Text, Button, Paper } from "@mantine/core";
import { IControl, Widget, WidgetProps } from "../../widgets/types";
import { handleRedirection } from "@/src/helpers/widgets.helpers";
import {
  useEditorStore,
  useEditorStoreActions,
} from "@/src/stores/editorstore";

const UserInfoBasic: React.FC<WidgetProps> = ({
  id,
  inheritedStyles,
  properties,
  control,
  ref,
}) => {
  const {
    profilePicture,
    profileName,
    showBio,
    bio,
    showActionButton,
    profileNameTextColorSelector,
    profileNameColor,
    gradient,
    actionButtonVariant,
    actionButtonBg,
    showBorder,
    borderColor,
    borderThickness,
    borderRadius,
    ctaText,
  } = properties.props;

  const [editedProfileName, setEditedProfileName] = React.useState(
    profileName.value
  );
  const [editedBio, setEditedBio] = React.useState(bio.value);

  const [editedActionButtonText, setEditedActionButtonText] = React.useState(
    ctaText.value
  );

  const handleInputChange = (e: any, fieldName: string) => {
    const newText = e.currentTarget.textContent;
    if (fieldName === "profileName") {
      setEditedProfileName(newText);
    } else if (fieldName === "bio") {
      setEditedBio(newText);
    } else if (fieldName === "ctaText") {
      setEditedActionButtonText(newText);
    }
  };

  const updateWidgetDefinition = (widgetId: string, field: string) => {
    const currentDefintion = useEditorStore
      .getState()
      .definitions.widgets.find((widget) => widget.id === widgetId) as
      | Widget
      | Record<string, any>;

    const valueToUpdate = (field: string) => {
      if (field === "profileName") {
        return editedProfileName;
      } else if (field === "bio") {
        return editedBio;
      } else if (field === "ctaText") {
        return editedActionButtonText;
      }
    };

    const newDefinition = {
      ...currentDefintion,
      properties: {
        ...currentDefintion.properties,
        props: {
          ...currentDefintion.properties.props,
          [field]: {
            ...currentDefintion.properties.props[field],
            value: valueToUpdate(field),
          },
        },
      },
    };

    useEditorStoreActions()?.updateWidgetDefinition(
      widgetId,
      newDefinition,
      "widgetDefinition"
    );
  };
  const isContentEditable = useEditorStore.use.mode() === "edit" || false;

  return (
    <Paper
      ref={ref}
      className={`draggable-widget widget-${id}`}
      style={{
        ...inheritedStyles,
        borderWidth: showBorder?.value ? borderThickness?.value : 0,
        borderColor: showBorder?.value ? borderColor?.value : "inherit",
      }}
      radius={borderRadius?.value || "md"}
      withBorder
      p="lg"
      bg="var(--mantine-color-body)"
    >
      <Avatar src={profilePicture.value} size={120} radius={120} mx="auto" />
      <Text
        ta="center"
        fz="lg"
        fw={500}
        mt="md"
        gradient={{ from: gradient.value.from, to: gradient.value.to, deg: 45 }}
        color={profileNameColor.value}
        variant={
          profileNameTextColorSelector.value === "gradient"
            ? "gradient"
            : "text"
        }
        onInput={(e) => handleInputChange(e, "profileName")}
        contentEditable={isContentEditable && true}
        autoFocus={true}
        onBlur={() => {
          updateWidgetDefinition(id, "profileName");
        }}
      >
        {profileName.value}
      </Text>
      {showBio.value && (
        <Text
          ta="center"
          c="dimmed"
          fz="sm"
          onInput={(e) => handleInputChange(e, "bio")}
          contentEditable={isContentEditable && true}
          autoFocus={true}
          onBlur={() => {
            updateWidgetDefinition(id, "bio");
          }}
        >
          {bio.value}
        </Text>
      )}

      {showActionButton.value && (
        <Button
          fullWidth
          mt="md"
          variant={actionButtonVariant.value}
          color={actionButtonBg?.value}
          onClick={handleRedirection.bind(null, control as IControl)}
          styles={(theme) => ({
            label: {
              width: "100%",
              display: "flex",
              justifyContent: "center",
            },
          })}
        >
          <Text
            onInput={(e) => handleInputChange(e, "ctaText")}
            contentEditable={isContentEditable && true}
            autoFocus={true}
            onBlur={() => {
              updateWidgetDefinition(id, "ctaText");
            }}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {ctaText.value}
          </Text>
        </Button>
      )}
    </Paper>
  );
};

export default UserInfoBasic;
