import React from "react";
import { Image, Card, Text, Group, Button, MantineTheme } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { IControl, Widget, WidgetProps } from "../../widgets/types";
import { handleRedirection } from "@/src/helpers/widgets.helpers";
import {
  useEditorStore,
  useEditorStoreActions,
} from "@/src/stores/editorstore";

const ProductCard: React.FC<WidgetProps> = ({
  id,
  inheritedStyles,
  properties,
  ref,
  control,
}) => {
  const {
    withControls,
    withIndicators,
    data,
    length,
    showTitle,
    title,
    showSummary,
    summary,
    showCallout,
    callout,
    cardType,
    showCta,
    ctaBg,
    ctaColor,
    ctaHoverBg,
    variant,
    variantColor,
    ctaText,
  } = properties.props;

  const [editedTitle, setEditedTitle] = React.useState(title.value);
  const [editedSummary, setEditedSummary] = React.useState(summary.value);
  const [editedCallout, setEditedCallout] = React.useState(callout.value);
  const [editedCtaText, setEditedCtaText] = React.useState(ctaText.value);

  const handleInputChange = (e: any, fieldName: string) => {
    const newText = e.currentTarget.textContent;
    if (fieldName === "title") {
      setEditedTitle(newText);
    } else if (fieldName === "summary") {
      setEditedSummary(newText);
    } else if (fieldName === "callout") {
      setEditedCallout(newText);
    } else if (fieldName === "ctaText") {
      setEditedCtaText(newText);
    }
  };

  const updateWidgetDefinition = (widgetId: string, field: string) => {
    const currentDefintion = useEditorStore
      .getState()
      .definitions.widgets.find((widget) => widget.id === widgetId) as
      | Widget
      | Record<string, any>;

    const valueToUpdate = (field: string) => {
      if (field === "title") {
        return editedTitle;
      } else if (field === "summary") {
        return editedSummary;
      } else if (field === "ctaText") {
        return editedCtaText;
      } else if (field === "callout") {
        return editedCallout;
      }
    };

    const newDefinition = {
      ...currentDefintion,
      properties: {
        ...currentDefintion.properties,
        props: {
          ...currentDefintion.properties.props,
          [field]: {
            ...currentDefintion.properties.props[field],
            value: valueToUpdate(field),
          },
        },
      },
    };

    useEditorStoreActions()?.updateWidgetDefinition(
      widgetId,
      newDefinition,
      "widgetDefinition"
    );
  };

  const renderLimit = cardType?.value === "Image" ? 1 : length?.value ?? 3;

  const slides = data?.value?.map((image: any, index: number) => {
    if (index >= renderLimit) return null;
    return (
      <Carousel.Slide key={image.scr}>
        <Image
          style={{
            ...inheritedStyles,
          }}
          src={image.src}
          height={220}
          width={"100%"}
          alt={image.alt}
        />
      </Carousel.Slide>
    );
  });
  const showControls = renderLimit > 1 ? withControls?.value ?? true : false;
  const showIndicators =
    renderLimit > 1 ? withIndicators?.value ?? true : false;

  const customBgStyles = (theme: MantineTheme) => {
    if (variant.value === "filled") {
      return {
        backgroundColor: ctaBg?.value || "inherit",
        "&:not([data-disabled])": theme.fn.hover({
          backgroundColor: ctaHoverBg?.value || "inherit",
          color: theme.fn.darken(ctaColor?.value, 0.2) || "inherit",
          borderColor: ctaHoverBg?.value
            ? "none"
            : theme.fn.darken(ctaBg?.value, 0.8),
        }),
      };
    }

    return {};
  };

  const isContentEditable = useEditorStore.use.mode() === "edit" || false;

  return (
    <Card>
      <Card.Section>
        <Carousel
          loop
          controlSize={25}
          withControls={showControls}
          withIndicators={showIndicators}
          slideSize="100%" // Ensure each slide takes full width
          align="start" // Center align slides
          dragFree
          draggable
        >
          {slides}
        </Carousel>
      </Card.Section>

      {showTitle.value && (
        <Group position="apart" mt="lg" noWrap={false}>
          <Text
            weight={500}
            size="lg"
            onInput={(e) => handleInputChange(e, "title")}
            contentEditable={isContentEditable && true}
            autoFocus={true}
            onBlur={() => {
              updateWidgetDefinition(id, "title");
            }}
          >
            {title.value}
          </Text>
        </Group>
      )}

      {showSummary?.value && (
        <Text
          size="sm"
          color="dimmed"
          mt="sm"
          onInput={(e) => handleInputChange(e, "summary")}
          contentEditable={isContentEditable && true}
          autoFocus={true}
          onBlur={() => {
            updateWidgetDefinition(id, "summary");
          }}
        >
          {summary?.value}
        </Text>
      )}

      <Group position={showCallout?.value ? "apart" : "right"} mt="md">
        {showCallout?.value && (
          <Text
            size="xl"
            weight={500}
            onInput={(e) => handleInputChange(e, "callout")}
            contentEditable={isContentEditable && true}
            autoFocus={true}
            onBlur={() => {
              updateWidgetDefinition(id, "callout");
            }}
          >
            {callout?.value}
          </Text>
        )}
        {showCta?.value && (
          <Button
            radius="md"
            onClick={handleRedirection.bind(null, control as IControl)}
            color={variantColor?.value}
            styles={(theme) => ({
              root: {
                ...customBgStyles(theme),
                color: ctaColor?.value || "inherit",
              },
              label: {
                width: "100%",
                display: "flex",
                justifyContent: "center",
              },
            })}
            variant={variant?.value}
          >
            <Text
              onInput={(e) => handleInputChange(e, "ctaText")}
              contentEditable={isContentEditable && true}
              autoFocus={true}
              onBlur={() => {
                updateWidgetDefinition(id, "ctaText");
              }}
            >
              {ctaText?.value ?? "Book now"}
            </Text>
          </Button>
        )}
      </Group>
    </Card>
  );
};

export default ProductCard;
