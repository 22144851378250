import {
  BaseWidget,
  IControl,
  Layout,
} from "@src/modules/BlinkInBio/Editor/widgets/types";
// import { widgetIcons } from "@src/modules/BlinkInBio/Editor/widgets/widgetIcons";
import { widgetIcons } from "@src/modules/Pages/widgets/widgetIcons";

import { useEditorStore } from "../stores/editorstore";

export type TFilteredWidget = {
  name: string;
  description: string;
  icon: React.FunctionComponent<React.SVGAttributes<SVGElement>> | null;
};

export const getWidgetMetaData = (widgets: BaseWidget[]) => {
  const metaData: Record<string, TFilteredWidget[]> = {
    components: [],
    connectors: [],
    social: [],
  };

  widgets.forEach((widget) => {
    const key = widget.meta.category;
    const type = widget.meta.type;
    const widgetMeta = widgetIcons.find((icon) => icon.type === type) ?? {
      icon: null,
    };

    const obj: TFilteredWidget = {
      name: widget.name,
      description: widget.description,
      icon: widgetMeta.icon,
    };

    metaData[key].push(obj);
  });

  return metaData;
};

export const handleRedirection = (control: IControl) => {
  const isEditModeActive = useEditorStore.getState().mode === "edit";

  const shouldRedirect = control.redirectTo.length > 0;
  if (isEditModeActive || !shouldRedirect) return;

  window && window.open(control.redirectTo, "_blank");
};
