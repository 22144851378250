import React, { useEffect, useState } from "react";
import { Box, Card, Center, Container, Paper, Stack } from "@mantine/core";
import { AppShellContainer } from "@src/containers";
import { blinkService } from "@src/services/blinks.services";
import { Blink, DeviceBrowserInfo } from "@src/types/modules/blinks";
import Header from "./Header";
import MainBody from "./MainBody";
import { convertToISO8601 } from "@/src/utils/blinks.utils";
import { defaultDateRange } from "@/src/utils/constants";
import { useViewportSize } from "@mantine/hooks";

type Props = {};

export interface BlinkDetail extends Blink {
  analytics: {
    count: number;
    deviceBrowserInfo: Record<string, DeviceBrowserInfo>;
    dateWiseCount: { date: string; count: number }[];
    sourceCount: Record<string, any>[];
  };
}

export const BlinkDetails = (props: Props) => {
  const [blinkData, setBlinkData] = useState<BlinkDetail | null>();

  const [uniqueVisitorsCount, setUniqueVisitorsCount] = useState<number>(0);

  const initiateWithBlinkData = async (filters: Record<string, string>) => {
    const slug = window.location.pathname.split("/").pop();

    if (slug) {
      const response: BlinkDetail = await blinkService.getOne(slug, filters);

      setBlinkData({
        ...response,
        analytics: {
          count: response.analytics.count,
          deviceBrowserInfo: response.analytics.deviceBrowserInfo,
          dateWiseCount: response.analytics.dateWiseCount,
          sourceCount: response.analytics.sourceCount,
        },
      });

      const uniqueVisitorsCount = await blinkService.getUniqueVisitorsCount(
        slug,
        filters.startDate,
        filters.endDate
      );

      setUniqueVisitorsCount(uniqueVisitorsCount["count"]);
    }
  };

  useEffect(() => {
    initiateWithBlinkData(defaultDateRange);
  }, []);

  if (!blinkData) {
    <div></div>;
  }

  const screenWidth = useViewportSize().width;
  const isMobileView = screenWidth && screenWidth < 768;

  return (
    <AppShellContainer showLoader={!blinkData}>
      <Container
        className="h-100 w-100"
        sx={{
          paddingLeft: screenWidth > 450 ? "1rem" : 0,
          paddingRight: screenWidth > 450 ? "1rem" : 0,
        }}
      >
        <Center className={isMobileView ? "" : `m-4 p-2`}>
          <Paper
            shadow="xs"
            p="md"
            className="w-100"
            style={{
              padding: screenWidth > 450 ? "1rem" : 0,
            }}
          >
            <Stack spacing="lg">
              <Header
                name={blinkData?.name as string}
                blinkURL={`${blinkData?.blinkDomain}/${blinkData?.blinkSignature}`}
                redirectURL={blinkData?.redirectUrl as string}
                clickCount={blinkData?.analytics.count as number}
                created={blinkData?.createdAt as string}
              />
              <Card>
                <MainBody
                  {...(blinkData as BlinkDetail)}
                  fetchBlinkDataWithFilters={initiateWithBlinkData}
                  uniqueVisitorsCount={uniqueVisitorsCount}
                />
              </Card>
            </Stack>
          </Paper>
        </Center>
      </Container>
    </AppShellContainer>
  );
};
