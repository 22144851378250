import React from "react";
import { BaseProps } from "../types/common.types";
import { Menu } from "@mantine/core";
import { Image } from "./BaseUI";
import BlinkIcon from "@assets/icons/blink.svg";
import PagesIcon from "@assets/icons/pages.svg";
import DashboardIcon from "@assets/icons/dash.svg";
import { useWorkspaceId } from "../hooks";
import { useNavigate } from "react-router-dom";

interface Props extends BaseProps {}

const RouteMenu: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const workspaceId = useWorkspaceId();

  const handleNavigation = {
    navigateToBlinks: () => navigate(`/${workspaceId}/blinks`),
    navigateToDashboard: () => navigate(`/${workspaceId}/dashboard`),
    navigateToPages: () => navigate(`/${workspaceId}/pages`),
  };

  return (
    <Menu shadow="md" width={200} position="right-start">
      <Menu.Target>{children}</Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Go back to</Menu.Label>
        <Menu.Item
          icon={<Image src={DashboardIcon} height={14} width={14} />}
          onClick={handleNavigation.navigateToDashboard}
        >
          Dashboard
        </Menu.Item>
        <Menu.Item
          icon={<Image src={BlinkIcon} height={14} width={14} />}
          onClick={handleNavigation.navigateToBlinks}
        >
          Blinks
        </Menu.Item>
        <Menu.Item
          icon={<Image src={PagesIcon} height={14} width={14} />}
          onClick={handleNavigation.navigateToPages}
        >
          Pages
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default RouteMenu;
