import React, { forwardRef, useEffect, useState } from "react";
import {
  Group,
  Avatar,
  Text,
  Select,
  Paper,
  Button,
  Stack,
} from "@mantine/core";
import { useWorkspace } from "../stores/workspace";
import { extractFileName } from "../lib/utils";
import { useEditorStoreActions } from "../stores/editorstore";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  value: string;
  group: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={image} />
        <div>
          <Text size="sm">{label}</Text>
        </div>
      </Group>
    </div>
  )
);

const ImageDropDownSelector: React.FC<{
  callback: (
    property: string,
    value: string | boolean | number | Record<string, any>
  ) => void;
  itemKey: string;
  itemValue: string;
}> = ({ callback, itemKey, itemValue }) => {
  const workspaceBrandAssets = useWorkspace.use.brandAssets();
  const [data, setData] = useState<ItemProps[]>([]);

  useEffect(() => {
    const options: ItemProps[] = [];

    workspaceBrandAssets.forEach((brandAsset) => {
      if (Array.isArray(brandAsset.images)) {
        brandAsset.images.forEach((image) => {
          const imageName = extractFileName(image);
          options.push({
            image: image,
            label: imageName,
            value: image,
            group: brandAsset.name,
          });
        });
      }
    });

    setData(options);

    return () => {
      setData([]);
    };
  }, [workspaceBrandAssets]);

  return (
    <Select
      defaultValue={itemValue}
      size="xs"
      label="Choose image"
      placeholder="Select image"
      itemComponent={SelectItem}
      data={data}
      searchable
      maxDropdownHeight={400}
      nothingFound={<NoImagesFound />}
      onChange={(value) => value && callback(itemKey, value)}
      styles={(theme) => ({
        item: {
          // applies styles to selected item
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[9]
                  : theme.colors.gray[3],
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors.dark[9],
            },
          },
        },
      })}
    />
  );
};

export const NoImagesFound = () => {
  const { setCurrentSegment, setShowFileUploader } = useEditorStoreActions();

  return (
    <Paper
      sx={{
        padding: "10px",
        margin: "10px",
        textAlign: "center",
      }}
    >
      <Stack>
        <Text size={"xs"} fw={500} c="dimgray">
          No images found
        </Text>

        <Button
          size="xs"
          color="indigo"
          variant="light"
          onClick={() => {
            setCurrentSegment("brand_assets");
            setShowFileUploader(true);
          }}
        >
          Upload Image
        </Button>
      </Stack>
    </Paper>
  );
};

export default ImageDropDownSelector;
