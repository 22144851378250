import React from "react";
import { Text } from "@mantine/core";
import { WidgetProps } from "../widgets/types";

const Headline: React.FC<WidgetProps> = ({
  id,
  inheritedStyles,
  properties,
  ref,
}) => {
  const {
    text,
    textAlign,
    fontWeight,
    fontFamily,
    fontSize,
    gradient,
    colorVariant,
    color,
  } = properties.props;

  return (
    <Text
      ref={ref}
      variant={colorVariant.value === "gradient" ? "gradient" : "text"}
      gradient={{ from: gradient.value.from, to: gradient.value.to, deg: 45 }}
      sx={{ fontFamily: fontFamily.value, cursor: "pointer" }}
      align={textAlign.value}
      fz={fontSize?.value}
      fw={fontWeight.value}
      className={`widget-${id} draggable-widget`}
      style={{
        ...inheritedStyles,
      }}
      color={color.value}
    >
      {text.value}
    </Text>
  );
};

export default Headline;
