import React from "react";
import { ActionIcon, MantineColor } from "@mantine/core";
import { SocialIcon } from "react-social-icons";
import { IControl, WidgetProps } from "../widgets/types";
import { handleRedirection } from "@/src/helpers/widgets.helpers";

interface IconProperties {
  variant?: { value: "filled" | "outline" | "light" };
  color?: { value: string };
  gradient?: { value: { from: string; to: string; deg?: number } };
  borderRadius?: { value: string | number };
  size?: { value: "xs" | "sm" | "md" | "lg" | "xl" };
  icon?: { value: string }; // Update to accept a string that matches the social network name
  withBorder?: { value: boolean };
}

interface SafeIconRendererProps {
  networkName: string;
  iconSize: string;
  bgColor?: MantineColor;
}

const SafeIconRenderer: React.FC<SafeIconRendererProps> = ({
  networkName,
  iconSize,
  bgColor,
}) => {
  // Use SocialIcon component from react-social-icons
  return (
    <SocialIcon
      network={networkName}
      style={{ height: iconSize, width: iconSize }}
      bgColor={bgColor}
    />
  );
};

const Icons: React.FC<WidgetProps> = ({ properties, control, id }) => {
  const { variant, color, gradient, borderRadius, size, withBorder } =
    properties.props as IconProperties;
  const icon = properties?.props?.icon?.value ?? "instagram"; // Default to Instagram

  const Size = {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1.125rem",
    lg: "1.625rem",
    xl: "2.125rem",
  } as const;

  return (
    <ActionIcon
      variant={"subtle"}
      gradient={{
        from: gradient?.value?.from || "",
        to: gradient?.value?.to || "",
        deg: gradient?.value?.deg ?? 45,
      }}
      size={size?.value}
      onClick={handleRedirection.bind(null, control as IControl)}
      sx={(theme) => ({
        border: "none",
        "&:hover": {
          backgroundColor: "transparent",
        },
      })}
    >
      <SafeIconRenderer
        networkName={icon} // Pass the selected network name to the renderer
        iconSize={Size[size?.value as keyof typeof Size]} // Convert size to the appropriate rem value
        // bgColor={color?.value}
      />
    </ActionIcon>
  );
};

export default Icons;
