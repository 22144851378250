import React from "react";
import { Bar, BarChart, XAxis, YAxis } from "recharts";

import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Paper, ThemeIcon, Tooltip } from "@mantine/core";
import { IconBrowserCheck } from "@tabler/icons-react";

const chartConfig = {
  visitors: {
    label: "Visitors",
  },
  chrome: {
    label: "Chrome",
    color: "#12b886",
  },
  safari: {
    label: "Safari",
    color: "#4c6ef5",
  },
  firefox: {
    label: "Firefox",
    color: "#51cf6",
  },
  edge: {
    label: "Edge",
    color: "#ff922b",
  },
  other: {
    label: "Other",
    color: "#2C2E33",
  },
} satisfies ChartConfig;

type TBarChartData = {
  browser: string;
  visitors: number;
  fill: string;
}[];

const BarChartComponent: React.FC<{ chartData: TBarChartData }> = ({
  chartData,
}) => {
  return (
    <Paper
      p="md"
      radius="md"
      shadow="md"
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === "dark" ? theme.colors.dark[9] : "white",
      })}
    >
      <Tooltip label="Browser data" color="indigo">
        <ThemeIcon color="grape" variant="light">
          <IconBrowserCheck size={20} />
        </ThemeIcon>
      </Tooltip>

      <ChartContainer config={chartConfig}>
        <BarChart
          accessibilityLayer
          data={chartData}
          layout="vertical"
          margin={{
            left: 0,
          }}
        >
          <YAxis
            dataKey="browser"
            type="category"
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            tickFormatter={(value) =>
              chartConfig[value as keyof typeof chartConfig]?.label
            }
          />
          <XAxis dataKey="visitors" type="number" hide />
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent hideLabel />}
          />
          <Bar dataKey="visitors" layout="vertical" radius={5} />
        </BarChart>
      </ChartContainer>
    </Paper>
  );
};

export default BarChartComponent;
