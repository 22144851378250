import { useEditorStore } from "@/src/stores/editorstore";
import { timeAgo } from "@/src/utils";
import {
  ActionIcon,
  Box,
  Button,
  Code,
  Container,
  CopyButton,
  Group,
  Modal,
  Notification,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";

import Lottie from "lottie-react";
import React from "react";

import { IconCheck, IconCopy } from "@tabler/icons-react";

type Props = {
  opened: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfimPublishingModal: React.FC<Props> = ({
  opened,
  onClose,
  onConfirm,
}) => {
  const appBlinkURL = useEditorStore.use.appBlinkURL();
  const lastPubslishedAt = useEditorStore.use.lastPublishedAt();

  const timeEditedSince = lastPubslishedAt ? timeAgo(lastPubslishedAt) : "";

  return (
    <Modal opened={opened} onClose={close} size="sm" withCloseButton={false}>
      <Notification
        icon={
          <Lottie
            animationData={require("@/src/assets/lottie/alert.json")}
            loop={false}
          />
        }
        title="Are you sure you want to publish this app? This may take a few minutes."
        withCloseButton={false}
        styles={{
          icon: {
            backgroundColor: "transparent",
            height: "50px",
            width: "50px",
          },
        }}
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      ></Notification>

      {timeEditedSince && (
        <Container>
          <Group position="right">
            <Code>Last published: {timeEditedSince}</Code>
            <CopyButton value={appBlinkURL} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? "Copied" : "Copy"}
                  withArrow
                  position="right"
                >
                  <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                    {copied ? (
                      <IconCheck size="1rem" />
                    ) : (
                      <IconCopy size="1rem" />
                    )}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Group>
        </Container>
      )}

      <Group position="right" className="mt-4">
        <Button
          color="red"
          variant="light"
          size="xs"
          sx={(theme) => ({
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.red[9]
                  : theme.colors.red[1],
            },
          })}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          w={"125px"}
          size="xs"
          variant="filled"
          onClick={onConfirm}
          color="indigo"
        >
          Confirm
        </Button>
      </Group>
    </Modal>
  );
};
