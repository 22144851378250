import React, { Suspense, useState } from "react";
import { Loader } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "./";

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const [theme, setTheme] = useState<"light" | "dark" | "auto">("light");
  const toggleTheme = () => setTheme(theme === "dark" ? "light" : "dark");

  return (
    <ThemeProvider theme={theme} toggleTheme={toggleTheme}>
      <Notifications position="top-center" />
      <Suspense
        fallback={
          <div className="flex items-center justify-center w-screen h-screen">
            <Loader />
          </div>
        }
      >
        <Router>{children}</Router>
      </Suspense>
    </ThemeProvider>
  );
};
