import { appConfig } from "@src/app.config";
import { handleResponseWithoutValidation } from "@src/lib/handleResponses";
import { authHeader, fileAuthHeader } from "@src/lib/requestHeaders";

export const brandAssetsService = {
  getAllBrandAssetsManagersOfWorkspace,
  uploadFile,
  addAssetPublicUrls,
  getImageDataUri,
};

async function getAllBrandAssetsManagersOfWorkspace(
  workspaceId: string
): Promise<any> {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/workspaces/${workspaceId}/brand-assets`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function uploadFile(workspaceId: string, file: File): Promise<any> {
  const formData = new FormData();
  formData.append("file", file);

  const requestOptions = {
    method: "POST",
    headers: fileAuthHeader(),
    body: formData,
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/upload/storage/create/${workspaceId}`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function addAssetPublicUrls(
  workspaceId: string,
  assetId: string,
  type: string,
  assetUrl: string
): Promise<any> {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
    body: JSON.stringify({ assetId, type, payload: assetUrl }),
  };

  const response = await fetch(
    `${appConfig.apiURL}/workspaces/${workspaceId}/brand-assets/upload`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function getImageDataUri(
  workspaceId: string,
  fileName: string
): Promise<string> {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${
      appConfig.apiURL
    }/upload/data-uri/${workspaceId}?fileName=${encodeURIComponent(
      fileName
    )}  `,
    requestOptions
  );

  if (!response.ok) {
    throw new Error("Failed to fetch image data");
  }
  const data = await response.json();
  return data.dataUri;
}
