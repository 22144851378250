export enum TModules {
  BLINKS = "blinks",
  BLINKINBIO = "pages",
  ACTIONS = "actions",
  ANALYTICS = "analytics",
  USERS = "users",
  SETTINGS = "settings",
}

export const RouteMap = {
  dashboard: {
    path: "dashboard",
    name: "Workspace dashboard",
  },
  blinks: {
    path: "blinks",
    name: "b-links",
  },
  blinkinbio: {
    path: "pages",
    name: "b-link Pages",
  },

  actions: {
    path: "actions",
    name: "Actions",
  },
  analytics: {
    path: "analytics",
    name: "Analytics",
  },
  connectors: {
    path: "connectors",
    name: "connectors",
  },
  settings: {
    path: "settings",
    name: "Settings",
  },
};

export const defaultDateRange = {
  // start date should be 1 day before the current date
  startDate: new Date(
    new Date().setDate(new Date().getDate() - 1)
  ).toISOString(),
  endDate: new Date().toISOString(),
};
