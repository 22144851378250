import React from "react";
import PanelBody from "../../PanelBody";
import BackgroundSelector from "./BackgroundSelector";
import FontSelector from "./FontSelector";
import { useEditorStoreActions } from "@src/stores/editorstore";

type Props = {};

const index = (props: Props) => {
  const { setSelectedWidget } = useEditorStoreActions();

  const handleCloseInspector = () => {
    setSelectedWidget(null);
  };

  return (
    <PanelBody.Container>
      <BackgroundSelector />
      <FontSelector />
    </PanelBody.Container>
  );
};

export default index;
