import React from "react";
import { IconRefresh } from "@tabler/icons-react";
import { ActionIcon, Box, ColorInput, Paper, Text } from "@mantine/core";

type Props = {
  from: string;
  to: string;
  callback: (from: string, to: string) => void;
};
const randomColor = () =>
  `#${Math.floor(Math.random() * 16777215).toString(16)}`;

const GradientColorpicker = (props: Props) => {
  return (
    <Box>
      <Text fw={500} size={"xs"}>
        Gradient color picker
      </Text>
      <Paper withBorder className="p-2">
        <ColorInput
          size="xs"
          label="From"
          value={props.from}
          onChange={(value) => {
            props.callback(value, props.to);
          }}
          rightSection={
            <ActionIcon
              onClick={() => {
                const newColor = randomColor();
                props.callback(newColor, props.to);
              }}
            >
              <IconRefresh size="1rem" />
            </ActionIcon>
          }
          withinPortal={false}
        />
        <ColorInput
          size="xs"
          label="To"
          value={props.to}
          onChange={(value) => {
            props.callback(props.from, value);
          }}
          rightSection={
            <ActionIcon
              onClick={() => {
                const newColor = randomColor();
                props.callback(props.from, newColor);
              }}
            >
              <IconRefresh size="1rem" />
            </ActionIcon>
          }
          withinPortal={false}
        />
      </Paper>
    </Box>
  );
};

export default GradientColorpicker;
