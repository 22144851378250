import { Box, useMantineTheme } from "@mantine/core";
import React from "react";
import TopNavbar from "./TopNavbar";
import BottomNavbar from "./BottomNavbar";

const Index: React.FC<{}> = () => {
  const theme = useMantineTheme;
  return (
    <Box
      sx={(theme) => {
        return {
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : "#F9FAFB",
          height: "calc(100vh - 60px)",
          width: "4rem",
          // position: "fixed",
          zIndex: 1,
          top: 60,
        };
      }}
    >
      <div
        className="d-flex flex-column justify-content-between h-100"
        style={{ overflow: "scroll", padding: "10px 0" }}
      >
        <TopNavbar />
        <BottomNavbar />
      </div>
    </Box>
  );
};

export default Index;
