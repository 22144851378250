import { Widget } from "../../types";

export const Icons: Widget = {
  id: "",
  name: "Icons",
  description: "Render Icons",
  meta: {
    type: "icons",
    category: "components",
  },
  variant: "filled",
  baseClassName: "",
  properties: {
    styles: {},
    props: {
      icon: {
        section: "customization",
        value: "IconBrandInstagram",
        type: "icon-selector",
        name: "Icon",
        key: "icon",
      },
      variant: {
        section: "customization",
        value: "filled",
        type: "segmented-control",
        name: "Variant",
        key: "variant",
        options: ["filled", "light", "outline", "default"],
      },
      color: {
        section: "customization",
        value: "#25262b",
        type: "mantine-color",
        name: "Color",
        key: "color",
        dependencies: ["variant"],
        not: ["default"],
      },
      withBorder: {
        section: "customization",
        value: false,
        type: "toggle",
        name: "With border",
        key: "withBorder",
      },
      borderRadius: {
        section: "customization",
        value: "sm",
        type: "segmented-control",
        name: "Border radius",
        key: "borderRadius",
        options: ["sm", "md", "lg"],
        dependencies: ["withBorder"],
      },
      size: {
        section: "customization",
        value: "xl",
        type: "segmented-control",
        name: "Size",
        key: "size",
        options: ["xs", "sm", "md", "lg", "xl"],
      },
    },
  },
  control: {
    redirectTo: "",
    type: "blink",
  },
  layout: {
    currentLayout: "mobile",
    mobile: {
      top: 0,
      left: 0,
      width: 44,
      height: 44,
    },
  },
};
