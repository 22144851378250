import React from "react";
import { Switch, useMantineTheme } from "@mantine/core";

type Props = {
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: "xs" | "sm" | "md" | "lg";
  styles?: object;
};

export const Toggle: React.FC<Props> = ({
  checked,
  onChange,
  label,
  size = "xs",
  styles = {},
}) => {
  const theme = useMantineTheme();

  return (
    <Switch
      size={size}
      radius={"sm"}
      color="dark"
      checked={checked}
      onChange={(event) => onChange(event)}
      label={label}
      labelPosition="left"
      styles={{
        ...styles,
      }}
    />
  );
};
