// LayoutGrid.tsx
import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  BackgroundImage,
  Box,
  Button,
  Center,
  Drawer,
  Grid,
  Group,
  Image,
  Input,
  LoadingOverlay,
  Modal,
  ScrollArea,
  Select,
  Text,
} from "@mantine/core";
import { useWorkspace, useWorkspaceActions } from "@/src/stores/workspace";
import { IconSearch, IconUpload } from "@tabler/icons-react";
import { FileUpload } from "@/components/ui/file-upload";
import { useClickOutside, useDisclosure } from "@mantine/hooks";
import { brandAssetsService } from "@/src/services/brand_asset.service";
import toast from "react-hot-toast";
import { useWorkspaceId } from "@/src/hooks";
import ImageList from "./ImageList";
import {
  useEditorStore,
  useEditorStoreActions,
} from "@/src/stores/editorstore";

const PanelBody = () => {
  const allBrandAssets = useWorkspace.use.brandAssets();
  const showFileUploader = useEditorStore.use.showFileUploader();
  const { setShowFileUploader } = useEditorStoreActions();
  const workspaceId = useWorkspaceId();

  const [options, setOptions] = useState(() => {
    return allBrandAssets.map((brandAsset) => ({
      value: brandAsset.id,
      label: brandAsset.name,
    }));
  });

  const [selectedBrandAsset, setSelectedBrandAsset] = useState(
    () => allBrandAssets[0]?.id
  );

  const [images, setImages] = useState<string[]>(
    () =>
      allBrandAssets.find((asset) => asset.id === selectedBrandAsset)?.images ||
      []
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [loadingAssets, setLoadingAssets] = useState(false);

  const [uploadFile, setFiles] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const handleFileUpload = (file: File) => {
    setFiles(file);
  };

  const uploadFileToServer = async () => {
    try {
      setIsUploading(true);
      if (uploadFile && workspaceId) {
        const assetURL = await brandAssetsService.uploadFile(
          workspaceId,
          uploadFile
        );

        const resp = await brandAssetsService.addAssetPublicUrls(
          workspaceId,
          selectedBrandAsset,
          "images",
          assetURL
        );

        if (resp) {
          setIsUploading(false);
          toast.success("File uploaded successfully");
          setShowFileUploader(false);
          setLoadingAssets(true);

          useWorkspaceActions()
            ?.getBrandAssets(workspaceId)
            .then(() => {
              setLoadingAssets(false);
            })
            .catch((error) => {
              console.error("Error fetching brand assets", error);
              setLoadingAssets(false);
              toast.error("Error fetching brand assets");
            });
        }
      }
    } catch (error) {
      console.error("Error uploading file", error);
      setIsUploading(false);
      toast.error("Error uploading file");
    }
  };

  const handleSearching = () => {
    const query = searchTerm.toLowerCase();
    const selectedAsset = allBrandAssets.find(
      (asset) => asset.id === selectedBrandAsset
    );

    if (selectedAsset && Array.isArray(selectedAsset.images)) {
      const filteredImages = selectedAsset?.images.filter((image: string) =>
        image.toLowerCase().includes(query)
      );
      setImages(filteredImages);
    }
  };

  useEffect(() => {
    if (loadingAssets) {
      const selectedAsset = allBrandAssets.find(
        (asset) => asset.id === selectedBrandAsset
      );
      setImages(selectedAsset?.images || []);
      setLoadingAssets(false);

      const newOptions = allBrandAssets.map((brandAsset) => ({
        value: brandAsset.id,
        label: brandAsset.name,
      }));

      setOptions(newOptions);
    }
  }, [JSON.stringify([selectedBrandAsset, allBrandAssets])]);

  useEffect(() => {
    handleSearching();
  }, [searchTerm]);

  const ref = useClickOutside(() => setShowFileUploader(false));

  return (
    <Box>
      <LoadingOverlay
        visible={isUploading}
        overlayBlur={2}
        loaderProps={{ color: "indigo" }}
      />

      <Select
        onChange={(value) => value && setSelectedBrandAsset(value)}
        value={selectedBrandAsset}
        data={options}
        label="Select Brand Asset"
        placeholder="Select Brand Asset"
        searchable
        nothingFound="No brand assets found"
      />

      {!showFileUploader ? (
        <Grid>
          <Grid.Col span={10}>
            <SearchComponent
              value={searchTerm}
              callBack={(val) => setSearchTerm(val)}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <ActionIcon
              className="my-2"
              onClick={() => {
                setShowFileUploader(true);
              }}
            >
              <IconUpload size={18} />
            </ActionIcon>
          </Grid.Col>
        </Grid>
      ) : (
        <div ref={ref}>
          <FileUpload
            onChange={handleFileUpload}
            onClear={() => setShowFileUploader(false)}
            limit={1}
            fileIsUploading={isUploading}
            upload={uploadFileToServer}
          />
        </div>
      )}

      {!showFileUploader && (
        <ScrollArea
          style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto" }}
        >
          <ImageList images={images} />
        </ScrollArea>
      )}
    </Box>
  );
};

const SearchComponent: React.FC<{
  value: string;
  callBack: (val: string) => void;
}> = ({ value, callBack }) => {
  return (
    <Box className="my-2 w-100">
      <Input
        icon={<IconSearch size={18} />}
        placeholder={"Search"}
        value={value}
        onChange={(e) => callBack(e.currentTarget.value)}
        size="xs"
      />
    </Box>
  );
};

export default PanelBody;
