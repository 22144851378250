import { useEditorStore, useEditorStoreState } from "./editorstore";
import { State } from "./editorstore/types";

export const createSnapshotForPublishedApp = (appState: State) => {
  const snapShot = {
    id: appState.id,
    currentPageId: appState.currentPageId,
    metadata: { ...appState.metadata, published: true, draftMode: false },
    definitions: appState.definitions,
    mode: "published",
  };

  return snapShot;
};

export const getUniqueWidgetname = (name: string) => {
  const allWidgets = useEditorStore.getState().definitions.widgets;

  const widgetNames = allWidgets
    .filter((widget) => widget.name === name)
    .map((widget) => widget.name);

  let widgetName = name;

  if (widgetNames.length) {
    let index = 1;
    while (widgetNames.includes(widgetName)) {
      widgetName = `${name} ${index}`;
      index++;
    }
  }

  return widgetName;
};

export const validateWidgetName = (name: string) => {
  if (name === "") return false;

  const allWidgets = useEditorStore.getState().definitions.widgets;

  const widgetNames = allWidgets.map((widget) => widget.name);

  return !widgetNames.includes(name);
};
