import ButtonWidget from "./Button";
import TextComponent from "./Text";
import SocialProfileComponent from "./SocialProfile";
import ImageActionBanner from "./ImageActionBanner";
import UserInfoBasic from "./UserInfoBasic";
import LinkIndicator from "./LinkIndicator";
import InstagramCard from "./InstagramPostCard";
import TwitterCard from "./TwitterPostCard";
import YoutubeCard from "./YoutubeCard";
import LinkComponent from "./Link";
import { BasicCarousel } from "./carousels";
import Icons from "./Icons";
import { WidgetProps } from "../widgets/types";
import ProductCard from "./ProductCard/index";
import OverlayComponent from "./Overlay";
import Headline from "./Headline";
import SocialIcons from "./SocialIcons";

type WidgetLib = {
  [key: string]: React.FC<any>;
};

const widgetLib: WidgetLib = {
  button: ButtonWidget as React.FC<WidgetProps>,
  text: TextComponent as React.FC<WidgetProps>,
  social_profile: SocialProfileComponent as React.FC<WidgetProps>,
  image_action_banner: ImageActionBanner as React.FC<WidgetProps>,
  user_info_basic: UserInfoBasic as React.FC<WidgetProps>,
  link_indicator: LinkIndicator as React.FC<WidgetProps>,
  instagram_card: InstagramCard as React.FC<WidgetProps>,
  twitter_card: TwitterCard as React.FC<WidgetProps>,
  youtube_card: YoutubeCard as React.FC<WidgetProps>,
  link: LinkComponent as React.FC<WidgetProps>,
  carousel_basic: BasicCarousel as React.FC<WidgetProps>,
  icons: Icons as React.FC<WidgetProps>,
  product_card: ProductCard as React.FC<WidgetProps>,
  overlay: OverlayComponent as React.FC<WidgetProps>,
  headline: Headline as React.FC<WidgetProps>,
  social_icons: SocialIcons as React.FC<WidgetProps>,
};

export default widgetLib;
