import { useAppStoreActions } from "@/src/stores/appstore";
import {
  useEditorStore,
  useEditorStoreActions,
} from "@/src/stores/editorstore";
import { useMantineTheme } from "@mantine/core";
import React from "react";
import NavigationIcon from "../NavigationIcon";

const Index: React.FC<{}> = () => {
  const { setCurrentSegment } = useEditorStoreActions();
  const activeSegment = useEditorStore.use.activeSegment();

  return (
    <div
      className="d-flex flex-column"
      style={{ gap: "20px", justifyContent: "center", alignItems: "center" }}
    >
      <NavigationIcon
        icon="textResize"
        label="Widget library"
        onClick={() => setCurrentSegment("widget_library")}
        {...(activeSegment === "widget_library" && { color: "green" })}
        transitionProps={{ transition: "slide-down", duration: 300 }}
      />

      <NavigationIcon
        icon="colorSwatch"
        label="Your Brand assets"
        onClick={() => setCurrentSegment("brand_assets")}
        {...(activeSegment === "brand_assets" && { color: "green" })}
        transitionProps={{ transition: "slide-down", duration: 300 }}
      />

      <NavigationIcon
        icon="stackFilled"
        label="Layers"
        onClick={() => {
          //When layers will be available , will set  setCurrentSegment("layers")
          useAppStoreActions()?.toggleUnreleasedFeatureModal();
        }}
        transitionProps={{ transition: "slide-down", duration: 300 }}
        // disabled={true}
      />

      <NavigationIcon
        icon="typography"
        label="Font family and typography settings"
        onClick={() => useAppStoreActions()?.toggleUnreleasedFeatureModal()}
        transitionProps={{ transition: "slide-down", duration: 300 }}
        // disabled={true}
      />
      <NavigationIcon
        icon="network"
        label="Actions"
        onClick={() => useAppStoreActions()?.toggleUnreleasedFeatureModal()}
        transitionProps={{ transition: "slide-down", duration: 300 }}
        // disabled={true}
      />
      <NavigationIcon
        icon="connector"
        label="Connectors"
        onClick={() => useAppStoreActions()?.toggleUnreleasedFeatureModal()}
        transitionProps={{ transition: "slide-down", duration: 300 }}
        // disabled={true}
      />
    </div>
  );
};

export default Index;
