import React from "react";
import SegmentControlForDevice from "./SegmentControlForDevice";
import AnimatedSaving from "./AnimatedSaving";
import { Box, Group } from "@mantine/core";
const Index: React.FC<{}> = () => {
  return (
    <Box>
      <Group position="center" spacing="lg">
        <AnimatedSaving />
        <SegmentControlForDevice />
      </Group>
    </Box>
  );
};

export default Index;
