import { appConfig } from "@src/app.config";
import { handleResponseWithoutValidation } from "@src/lib/handleResponses";
import { AuthUserDetails } from "@src/types/modules/auth";
import { AuthenticationPayload } from "./types";
import { authHeader } from "@src/lib/requestHeaders";
import { getCookie } from "@src/lib/cookie.lib";
import { BehaviorSubject, Observable } from "rxjs";

// Retrieve initial state from cookies
const userFromCookie = getCookie("user");
const initialState = {
  isAuthenticated: !!getCookie("Authentication"),
  user: userFromCookie ? JSON.parse(userFromCookie) : null,
};

// Create a BehaviorSubject to hold the current session state
const currentSessionSubject = new BehaviorSubject(initialState);
const authTokenSession = new BehaviorSubject({ token: "" });

export const authTokenSessionService = {
  session: authTokenSession.asObservable(),
  sessionValue() {
    return authTokenSession.value;
  },
  update(token: string) {
    authTokenSession.next({ token: token });
  },
};

// Define the authentication service
export const authenticationService = {
  registerNewUser,
  login,
  logout,
  currentSession: currentSessionSubject.asObservable(),
  currentSessionValue() {
    return currentSessionSubject.value;
  },
  updateCurrentSession(data: Partial<typeof initialState>) {
    const updatedState = { ...currentSessionSubject.value, ...data };
    currentSessionSubject.next(updatedState);
    // Persist the updated state in cookies
  },

  setAuthStatus(isAuthenticated: boolean) {
    this.updateCurrentSession({ isAuthenticated });
  },
};

// Function to register a new user
function registerNewUser(
  userData: AuthUserDetails
): Promise<AuthenticationPayload> {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(userData),
  };

  return fetch(`${appConfig.apiURL}/authentication/register`, requestOptions)
    .then(handleResponseWithoutValidation)
    .then((response) => {
      return response;
    });
}

function login(email: string, password: string) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ email, password }),
    credentials: "include" as RequestCredentials,
  };

  return fetch(`${appConfig.apiURL}/authentication/login`, requestOptions)
    .then((response) => {
      // Extract the token from the response header
      const authToken =
        response.headers.get("blink-auth-token") ||
        response.headers.get("Blink-Auth-Token") ||
        "";

      // If token exists, store it in localStorage
      if (authToken) {
        authTokenSessionService.update(authToken);
      }

      // Return the response for further processing
      return response;
    })
    .then(handleResponseWithoutValidation);
}

function logout() {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  return fetch(`${appConfig.apiURL}/authentication/logout`, requestOptions)
    .then(handleResponseWithoutValidation)
    .then(() => {
      // Clear the current session
      authenticationService.updateCurrentSession({
        isAuthenticated: false,
        user: null,
      });
    })
    .catch((error) => {
      console.error("Error logging out:", error);
    });
}
