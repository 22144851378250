import { Widget } from "../../types";

export const ProductCard: Widget = {
  id: "",
  name: "Product card",
  description: "Product card",
  meta: {
    type: "product_card",
    category: "components",
  },
  variant: "filled",
  baseClassName: "",
  properties: {
    styles: {},
    props: {
      cardType: {
        section: "customization",
        value: "Image",
        type: "segmented-control",
        key: "cardType",
        name: "Card type",
        options: ["Image", "Carousel"],
        icons: {
          Image: "image",
          Carousel: "horizontal-carousel",
        },
      },
      length: {
        section: "customization",
        value: 3,
        type: "number",
        key: "length",
        name: "Number of images",
        dependencies: ["cardType"],
        not: ["Image"],
      },
      data: {
        section: "customization",
        value: [
          {
            src: "https://images.unsplash.com/photo-1598928506311-c55ded91a20c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80",
            alt: "Image 1",
          },
          {
            src: "https://images.unsplash.com/photo-1567767292278-a4f21aa2d36e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80",
            alt: "Image 2",
          },
          {
            src: "https://images.unsplash.com/photo-1605774337664-7a846e9cdf17?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80",
            alt: "Image 3",
          },
        ],
        type: "array",
        key: "data",
        name: "Images",
      },
      showTitle: {
        section: "flexibility",
        value: true,
        type: "toggle",
        key: "showTitle",
        name: "Show title",
      },
      title: {
        section: "customization",
        value: "Forde, Norway",
        type: "text",
        key: "title",
        name: "Title",
        dependencies: ["showTitle"],
      },
      showSummary: {
        section: "flexibility",
        value: true,
        type: "toggle",
        key: "showSummary",
        name: "Show summary",
      },
      summary: {
        section: "customization",
        value: `Relax, rejuvenate and unplug in this unique contemporary Birdbox. Feel close to nature in ultimate comfort. Enjoy the view of the epic mountain range of Blegja and the Førdefjord.`,
        type: "textarea",
        key: "summary",
        name: "Summary",
        dependencies: ["showSummary"],
      },
      showCallout: {
        section: "flexibility",
        value: true,
        type: "toggle",
        key: "showCallout",
        name: "Show callout",
      },
      callout: {
        section: "customization",
        value: "399$ / night",
        type: "text",
        key: "callout",
        name: "Callout",
        dependencies: ["showCallout"],
      },
      withControls: {
        section: "flexibility",
        value: true,
        type: "toggle",
        key: "withControls",
        name: "Show Navigation Controls",
        dependencies: ["cardType"],
        not: ["Image"],
      },
      withIndicators: {
        section: "flexibility",
        value: true,
        type: "toggle",
        key: "withIndicators",
        name: "Show Image Position",
        dependencies: ["cardType"],
        not: ["Image"],
      },
      showCta: {
        section: "flexibility",
        value: true,
        type: "toggle",
        key: "showCta",
        name: "Show Call to action",
      },
      ctaText: {
        section: "customization",
        value: "Book now",
        type: "text",
        key: "ctaText",
        name: "Call to action text",
      },
      ctaColor: {
        section: "customization",
        value: "#ffffff",
        type: "color",
        key: "ctaColor",
        name: "Call to action color",
      },
      variant: {
        section: "customization",
        value: "filled",
        type: "segmented-control",
        name: "Call to action variant",
        key: "variant",
        options: ["filled", "light", "outline"],
      },
      ctaBg: {
        section: "customization",
        value: "#5C7CFA",
        type: "color",
        key: "ctaBg",
        name: "Call to action background",
        dependencies: ["variant"],
        dependencyValue: "filled",
      },
      ctaHoverBg: {
        section: "customization",
        value: "#7491FF",
        type: "color",
        key: "ctaHoverBg",
        name: "Call to action hover background",
        dependencies: ["variant"],
        dependencyValue: "filled",
      },
      variantColor: {
        section: "customization",
        value: "dark",
        type: "mantine-color",
        name: "Call to action varint color",
        key: "variantColor",
        dependencies: ["variant"],
        not: ["filled", "gradient"],
      },
    },
  },
  control: {
    redirectTo: "",
    type: "blink",
  },
  layout: {
    currentLayout: "mobile",
    mobile: {
      top: 0,
      left: 0,
      width: 390,
      height: 440,
    },
  },
};
