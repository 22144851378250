import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authenticationService } from "../services/auth.service";
import { useAppStore } from "../stores/appstore";
import { useWorkspaceId } from "./use-workspaceId";
import { appConfig } from "../app.config";

export const useSubdomainRedirect = (allowedSubdomains = ["app"]) => {
  const defaultDomain =
    appConfig.environment === "production" ? "b-link.co" : "localhost:8080";
  const protocol = appConfig.environment === "production" ? "https" : "http";

  const navigate = useNavigate();
  const { isAuthenticated } = authenticationService.currentSessionValue();
  const userSubdomain = useAppStore.use.subdomain();
  const workspaceId = useWorkspaceId();

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split(".")[0];

    if (isAuthenticated) {
      if (subdomain !== userSubdomain) {
        window.location.href = `${protocol}://${userSubdomain}.${defaultDomain}/${workspaceId}/dashboard`;
      }
    } else if (!allowedSubdomains.includes(subdomain)) {
      window.location.href = `${protocol}://app.${defaultDomain}/login`;
    }
  }, [isAuthenticated, navigate, allowedSubdomains]);
};
