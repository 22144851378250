import React from "react";
import { Stack } from "@mantine/core";
import {
  EditorManager,
  AnalyticsManager,
  SettingsManager,
  BrandAssetsManager,
} from "./Manager";
import WidgetManager from "./Manager/EditorManager/WidgetSelector/WidgetManager";

type Props = {
  render:
    | "widget_library"
    | "analytics"
    | "settings"
    | "layers"
    | "brand_assets";
};

interface Composition {
  EditorPane: React.FC;
  AnalyticsPane: React.FC;
  SettingsPane: React.FC;
  BrandAssetsPane: React.FC;
  Container: React.FC<{ children: React.ReactNode }>;
}

const PanelBody: React.FC<Props> & Composition = ({ render }) => {
  return (
    <div>
      {render === "widget_library" && <WidgetManager />}
      {render === "analytics" && <PanelBody.AnalyticsPane />}
      {render === "settings" && <PanelBody.SettingsPane />}
      {render === "brand_assets" && <PanelBody.BrandAssetsPane />}
    </div>
  );
};

const Container: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <Stack>{children}</Stack>;
};

const EditorPane = () => {
  return (
    <div className="editor-panel-container">
      <EditorManager />
    </div>
  );
};

const AnalyticsPane = () => {
  return (
    <div className="editor-panel-container">
      <AnalyticsManager />
    </div>
  );
};

const SettingsPane = () => {
  return (
    <div className="editor-panel-container">
      <SettingsManager />
    </div>
  );
};

const BrandAssetsPane = () => {
  return (
    <div className="editor-panel-container">
      <BrandAssetsManager />
    </div>
  );
};

PanelBody.EditorPane = EditorPane;
PanelBody.AnalyticsPane = AnalyticsPane;
PanelBody.SettingsPane = SettingsPane;
PanelBody.BrandAssetsPane = BrandAssetsPane;
PanelBody.Container = Container;
export default PanelBody;
