import { Image } from "@/src/components/BaseUI";
import {
  Box,
  Button,
  Card,
  Group,
  List,
  Tabs,
  Text,
  ThemeIcon,
  Badge,
  Tooltip,
  Center,
  Stack,
  ActionIcon,
} from "@mantine/core";
import {
  IconChevronsRight,
  IconCrown,
  IconLink,
  IconPageBreak,
  IconTextResize,
  IconTrashFilled,
} from "@tabler/icons-react";
import { BookmarkPlusIcon } from "lucide-react";

import React from "react";
import WidgetEmptyState from "@assets/empty-states/widgets.svg";
import { useWorkspace, useWorkspaceActions } from "@/src/stores/workspace";
import { useWorkspaceId } from "@/src/hooks";
import toast from "react-hot-toast";

type Props = {};

const QuickLinks = (props: Props) => {
  const { pages, blinks } = useWorkspace.use.quickAccess();
  const workspaceId = useWorkspaceId() as string;

  return (
    <Box
      style={{
        height: "inherit",
      }}
    >
      <div className="h-100">
        <Group position="apart">
          <Group align="flex-end">
            <ThemeIcon variant="light" color="indigo">
              <IconCrown size="1rem" className={"icon"} stroke={2} />
            </ThemeIcon>
            <Text fz="sm" fw={500}>
              Quick access
            </Text>
          </Group>
          <Button
            compact
            size="xs"
            variant="filled"
            color="indigo"
            disabled
            leftIcon={<BookmarkPlusIcon size="0.75rem" />}
          >
            Add type
          </Button>
        </Group>

        <Tabs
          defaultValue="blinks"
          color="dark"
          className="mt-1"
          style={{
            maxHeight: "calc(100% - 40px)",
            height: "calc(100% - 40px)",
          }}
        >
          <Tabs.List>
            <Tabs.Tab value="blinks" icon={<IconLink size={"0.9rem"} />}>
              <Text size={"xs"}>Blinks</Text>
            </Tabs.Tab>
            <Tabs.Tab value="pages" icon={<IconTextResize size={"0.9rem"} />}>
              <Text size={"xs"}>Pages</Text>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel
            value="blinks"
            style={{
              height: "calc(100% - 40px)",
              maxHeight: "calc(100% - 40px)",
            }}
          >
            <Box
              className="mt-2"
              style={{
                width: "100%",
                height: "100%",
                overflowY: blinks.length > 0 ? "auto" : "hidden",
              }}
            >
              {blinks.length > 0 ? (
                <List
                  className="w-100"
                  styles={{
                    itemWrapper: {
                      width: "100%",
                    },
                  }}
                >
                  {blinks.map((blink) => (
                    <List.Item key={blink.id} className="my-2">
                      <RenderQuickLink
                        name={blink.name}
                        id={blink.id}
                        type={"blinks"}
                        workspaceId={workspaceId}
                      />
                    </List.Item>
                  ))}
                </List>
              ) : (
                <EmptyState />
              )}
            </Box>
          </Tabs.Panel>
          <Tabs.Panel
            value="pages"
            style={{
              height: "calc(100% - 40px)",
            }}
          >
            <Box
              className="mt-2"
              style={{
                width: "100%",
                height: "100%",
                overflowY: pages.length > 0 ? "auto" : "hidden",
              }}
            >
              {pages.length > 0 ? (
                <List
                  className="w-100"
                  styles={{
                    itemWrapper: {
                      width: "100%",
                    },
                  }}
                >
                  {pages.map((page) => (
                    <List.Item key={page.id} className="my-2">
                      <RenderQuickLink
                        name={page.name}
                        id={page.id}
                        type={"pages"}
                        workspaceId={workspaceId}
                      />
                    </List.Item>
                  ))}
                </List>
              ) : (
                <EmptyState />
              )}
            </Box>
          </Tabs.Panel>
        </Tabs>
      </div>
    </Box>
  );
};

type TQuickLink = {
  name: string;
  id: string;
  type: "blinks" | "pages";
  workspaceId: string;
};

export const RenderQuickLink: React.FC<TQuickLink> = ({
  name,
  id,
  type,
  workspaceId,
}) => {
  const navigateTo = `${type}/${id}` + (type === "blinks" ? "" : "/edit");

  const deleteQuickAccess = (type: "blinks" | "pages", id: string) => {
    useWorkspaceActions()
      ?.removeFromQuickAccess(workspaceId, type, id)
      .then(() => {
        toast.success("Removed from quick access");
      })
      .catch(() => {
        toast.error("Error removing from quick access");
      });
  };

  return (
    <Card
      className="w-100"
      radius={"sm"}
      p="xs"
      withBorder
      sx={(theme) => ({
        cursor: "pointer",
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[7]
            : theme.colors.gray[0],
        borderColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[8]
            : theme.colors.gray[1],
        // hover styles
        "&:hover": {
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[8] : "whitesmoke",
          borderColor: theme.colors.indigo[3],
        },
      })}
      onClick={() => {
        window.open(navigateTo, "_blank");
      }}
    >
      <Group position="apart">
        <Tooltip
          label={`Open ${type}`}
          position="top"
          withArrow
          color="indigo"
          withinPortal
        >
          <Group position="left">
            <ThemeIcon color="indigo" size={24} radius="xl">
              {type === "blinks" ? (
                <IconLink size={16} />
              ) : (
                <IconPageBreak size={16} />
              )}
            </ThemeIcon>
            <Text size={"xs"} fw={500}>
              {name}
            </Text>
          </Group>
        </Tooltip>
        <Group>
          <ActionIcon>
            <IconTrashFilled
              size="1rem"
              color="red"
              onClick={(e) => {
                e.stopPropagation();
                deleteQuickAccess(type, id);
              }}
            />
          </ActionIcon>
          <IconChevronsRight size="1rem" />
        </Group>
      </Group>
    </Card>
  );
};

const EmptyState = () => {
  return (
    <Center
      style={{
        height: "calc(100% - 20px)",
        border: "1px dashed",
      }}
    >
      <Stack>
        <Center>
          <Image src={WidgetEmptyState} height={200} width={200} />
        </Center>
        <div>
          <Text size={"xs"} c="dimmed" style={{ textAlign: "center" }}>
            Start by adding your first blink to create quick access links!
          </Text>
        </div>
      </Stack>
    </Center>
  );
};

export default QuickLinks;
