import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Button,
  Container,
  useMantineTheme,
  MantineTheme,
  Code,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import { useWorkspaceId } from "../hooks"; // Adjust the import path as needed
import { useAppStore } from "../stores/appstore"; // Adjust the import path as needed

const DebugNotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const currentWorkspaceId = useWorkspaceId();
  const user = useAppStore.use.userMetaData();
  const [localStorageData, setLocalStorageData] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    // Capture localStorage data
    const data: Record<string, string> = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        data[key] = localStorage.getItem(key) || "";
      }
    }
    setLocalStorageData(data);
  }, []);

  const handleNavigateHome = () => {
    if (currentWorkspaceId) {
      navigate(`/${currentWorkspaceId}/dashboard`);
    } else {
      navigate("/");
    }
  };

  const getSvgColor = (theme: MantineTheme) => {
    return theme.colorScheme === "dark"
      ? theme.colors.dark[4]
      : theme.colors.gray[8];
  };

  return (
    <Container
      size="xl"
      style={{
        height: "100vh",
        position: "relative",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          maxWidth: "400px",
          width: "100%",
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          padding: "16px",
        }}
      >
        <Text
          size="xl"
          weight={700}
          align="center"
          style={{ marginBottom: theme.spacing.md, width: "100%" }}
        >
          Nothing to see here
        </Text>
        <Text
          color="dimmed"
          size="sm"
          align="center"
          style={{ marginBottom: theme.spacing.xl }}
        >
          The page you are trying to open does not exist. You may have mistyped
          the address, or the page has been moved to another URL. If you think
          this is an error, contact support.
        </Text>
        <Button
          color="dark"
          size="sm"
          fullWidth
          onClick={handleNavigateHome}
          variant="filled"
          leftIcon={<IconArrowNarrowLeft size="1rem" />}
        >
          Take me back to the home page
        </Button>
      </Box>
      <svg
        viewBox="0 0 1000 1000"
        preserveAspectRatio="xMinYMid meet"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "100%",
          height: "auto",
          maxWidth: "1000px",
          zIndex: 0,
          opacity: 0.1,
        }}
      >
        <text
          x="10%"
          y="50%"
          dy=".35em"
          textAnchor="start"
          fontSize="420"
          fontWeight="bold"
          fill={getSvgColor(theme)}
        >
          404
        </text>
      </svg>
    </Container>
  );
};

export default DebugNotFoundPage;
