import React, { useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { AppProvider } from "./providers";
import BlinkModule from "./modules/Blink";
import BlinkInBioModule from "./modules/BlinkInBio";
import { BlinkDetails } from "./modules/Blink/Details";
import {
  ForgotPassword,
  LoginPage,
  RegisteredUserVerification,
  RegisterPage,
} from "./modules/Auth";
import AuthRoute from "./routes/AuthRoute";
import Viewer from "./modules/Viewer";
import Dashboard from "./modules/Dashboard";
import { useAppStoreActions } from "./stores/appstore";
import WaitlistPage from "./modules/Waitlist";
import ErrorBoundaryModal from "./ErrorBoundary/ErrorBoundary503";
import { ErrorBoundary } from "react-error-boundary";
import NotFoundPage from "./ErrorBoundary/NotFoundPage";
import { useWorkspaceId } from "./hooks";
import { useWorkspace } from "./stores/workspace";
import SettingsPage from "./modules/Settings";
import Pages from "./modules/Pages";
import {
  ExpiredUI,
  PassProtectedBlink,
  ScheduledUI,
} from "./modules/BlinkDirectives";
import { appConfig } from "./app.config";
import BlinkInBioEditor from "./modules/BlinkInBio/Editor";

const RootRedirect = () => {
  const defaultDomain =
    appConfig.environment === "production" ? "b-link.co" : "localhost:8080";
  const protocol = appConfig.environment === "production" ? "https" : "http";

  const navigate = useNavigate();
  const currentWorkspaceId = useWorkspaceId();
  const currentWorkspaceName = useWorkspace.use.workspaceName();

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  useEffect(() => {
    if (currentWorkspaceId && currentWorkspaceName) {
      const isDefaultSubdomain = subdomain === "app";
      const userSubdomain = currentWorkspaceName.toLowerCase();

      if (!isDefaultSubdomain) {
        useAppStoreActions()?.setSubdomain(subdomain);
      }

      if (isDefaultSubdomain && userSubdomain !== "app") {
        // Redirect to user's subdomain if accessed via app subdomain
        window.location.href = `${protocol}://${userSubdomain}.${defaultDomain}/${currentWorkspaceId}/dashboard`;
      } else if (subdomain !== userSubdomain && subdomain !== "app") {
        // Handle cases where user tries to access a subdomain they don't own
        redirectToAppLogin();
      } else {
        navigate(`/${currentWorkspaceId}/dashboard`, { replace: true });
      }
    } else {
      // If no workspace ID or name is found, redirect to login on app subdomain
      redirectToAppLogin();
    }
  }, [currentWorkspaceId, currentWorkspaceName, navigate, subdomain]);

  const redirectToAppLogin = () => {
    if (subdomain !== "app") {
      const redirectTo = `${protocol}://app.${defaultDomain}`;

      window.location.href = `${redirectTo}/login`;
    } else {
      navigate("/login", { replace: true });
    }
  };

  return null;
};

const App = () => {
  return (
    <ErrorBoundary
      FallbackComponent={(props) => {
        console.log("Error caught by ErrorBoundary:", props.error);
        return <ErrorBoundaryModal />;
      }}
    >
      <AppProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route
            path="/verify-email"
            element={<RegisteredUserVerification />}
          />

          <Route path="/" element={<RootRedirect />} />
          <Route path="/wait-list" element={<WaitlistPage />} />
          <Route path="/password-protected" element={<PassProtectedBlink />} />
          <Route path="/scheduled-redirect" element={<ScheduledUI />} />
          <Route path="/expired-link" element={<ExpiredUI />} />
          <Route path="/:workspaceId">
            <Route
              path="dashboard"
              element={
                <AuthRoute>
                  <Dashboard />
                </AuthRoute>
              }
            />
            <Route
              path="blinks"
              element={
                <AuthRoute>
                  <BlinkModule />
                </AuthRoute>
              }
            />
            <Route
              path="blinks/:slug"
              element={
                <AuthRoute>
                  <BlinkDetails />
                </AuthRoute>
              }
            />
            <Route
              path="pages"
              element={
                <AuthRoute>
                  <BlinkInBioModule />
                </AuthRoute>
              }
            />
            <Route path="pages/:slug" element={<Viewer />} />

            <Route
              path="pages/:slug/edit"
              element={
                <AuthRoute>
                  <Pages />
                </AuthRoute>
              }
            />

            <Route
              path="settings"
              element={
                <AuthRoute>
                  <SettingsPage />
                </AuthRoute>
              }
            />
          </Route>
          <Route
            path="*"
            element={
              <AuthRoute>
                <NotFoundPage />
              </AuthRoute>
            }
          />
        </Routes>
      </AppProvider>
    </ErrorBoundary>
  );
};

export default App;
