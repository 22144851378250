import React from "react";
import { Box, Button, Center, Modal } from "@mantine/core";
import Canvas from "../Canvas";
import { IconLockFilled } from "@tabler/icons-react";
import { useEditorStore } from "@/src/stores/editorstore";

type Props = {
  show: boolean;
};

const Preview: React.FC<Props> = ({ show }) => {
  return (
    <Modal.Root
      opened={show}
      onClose={() => null}
      fullScreen
      transitionProps={{ transition: "fade", duration: 200 }}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Body>
          <Box className="h-100">
            <Canvas.Header />
            <Center className="mt-4">
              <IPhoneFrame>
                <div className="">{/* <Canvas /> */}</div>
              </IPhoneFrame>
            </Center>
          </Box>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export const IPhoneFrame: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const isPreviewMode = useEditorStore.use.mode() === "preview";

  if (!isPreviewMode) return children;

  return (
    <div className="iphone-frame">
      <div className="iphone-frame-inner">
        <div className="iphone-notch">
          <div className="iphone-speaker"></div>
          <div className="iphone-camera"></div>
        </div>
        <div className="screen-header">
          <Button
            compact
            variant="subtle"
            // disabled
            color="dark"
            fullWidth
            radius={"lg"}
            leftIcon={<IconLockFilled size="1rem" />}
          >
            https://blinkinbio.com
          </Button>
        </div>
        <div className="iphone-screen">{children}</div>
        <div className="iphone-button"></div>
      </div>
    </div>
  );
};

export default Preview;
