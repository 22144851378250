import React from "react";
import { Card, Overlay, Button, Text, Box } from "@mantine/core";
import "./index.scss";
import { IControl, Widget, WidgetProps } from "../../widgets/types";
import { handleRedirection } from "@/src/helpers/widgets.helpers";
import {
  useEditorStore,
  useEditorStoreActions,
} from "@/src/stores/editorstore";

const ImageActionBanner: React.FC<WidgetProps> = ({
  id,
  inheritedStyles,
  properties,
  control,
  ref,
}) => {
  const {
    imageSrc,
    actionLabel,
    description,
    title,
    actionButtonColor,
    actionButtonVariant,
  } = properties.props;

  const [titleText, setTitleText] = React.useState(title.value);
  const [descriptionText, setDescriptionText] = React.useState(
    description.value
  );
  const [actionLabelText, setActionLabelText] = React.useState(
    actionLabel.value
  );

  const handleInputChange = (e: any, fieldName: string) => {
    const newText = e.currentTarget.textContent;
    if (fieldName === "title") {
      setTitleText(newText);
    } else if (fieldName === "description") {
      setDescriptionText(newText);
    } else if (fieldName === "actionLabel") {
      setActionLabelText(newText);
    }
  };

  const updateWidgetDefinition = (widgetId: string, field: string) => {
    const currentDefintion = useEditorStore
      .getState()
      .definitions.widgets.find((widget) => widget.id === widgetId) as
      | Widget
      | Record<string, any>;

    const valueToUpdate = (field: string) => {
      if (field === "title") {
        return titleText;
      } else if (field === "description") {
        return descriptionText;
      } else if (field === "actionLabel") {
        return actionLabelText;
      }
    };

    const newDefinition = {
      ...currentDefintion,
      properties: {
        ...currentDefintion.properties,
        props: {
          ...currentDefintion.properties.props,
          [field]: {
            ...currentDefintion.properties.props[field],
            value: valueToUpdate(field),
          },
        },
      },
    };

    useEditorStoreActions()?.updateWidgetDefinition(
      widgetId,
      newDefinition,
      "widgetDefinition"
    );
  };
  const isContentEditable = useEditorStore.use.mode() === "edit" || false;

  return (
    <Card
      radius="md"
      ref={ref}
      className={`card draggable-widget widget-${id}`}
      style={{
        ...inheritedStyles,
        backgroundImage: `url(${imageSrc?.value ?? ""})`,
      }}
    >
      <Overlay className={"overlay"} opacity={0.55} zIndex={0} />

      <div className={"content"}>
        <Text
          size="lg"
          fw={700}
          className={"title"}
          onInput={(e) => handleInputChange(e, "title")}
          contentEditable={isContentEditable && true}
          autoFocus={true}
          onBlur={() => {
            updateWidgetDefinition(id, "title");
          }}
        >
          {title.value}
        </Text>

        <Text
          size="sm"
          className={"description"}
          onInput={(e) => handleInputChange(e, "description")}
          contentEditable={isContentEditable && true}
          autoFocus={true}
          onBlur={() => {
            updateWidgetDefinition(id, "description");
          }}
        >
          {description.value}
        </Text>

        <Button
          className={"action"}
          variant={actionButtonVariant?.value ?? "white"}
          color={actionButtonColor?.value ?? "dark"}
          size="xs"
          onClick={handleRedirection.bind(null, control as IControl)}
          styles={(theme) => ({
            label: {
              width: "100%",
              display: "flex",
              justifyContent: "center",
            },
          })}
        >
          <Text
            onInput={(e) => handleInputChange(e, "actionLabel")}
            contentEditable={isContentEditable && true}
            autoFocus={true}
            onBlur={() => {
              updateWidgetDefinition(id, "actionLabel");
            }}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {actionLabel.value}
          </Text>
        </Button>
      </div>
    </Card>
  );
};

export default ImageActionBanner;
