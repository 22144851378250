import MozillaDeviceIcon from "@assets/icons/mozilla-device.svg";
import ChromeDeviceIcon from "@assets/icons/chrome-device.svg";
import SafariDeviceIcon from "@assets/icons/safari-device.svg";
import BraveDeviceIcon from "@assets/icons/brave-device.svg";
import androiIcon from "@assets/icons/android.svg";
import iosIcon from "@assets/icons/ios.svg";
import windowIcon from "@assets/icons/microsoft.svg";
import linuxIcon from "@assets/icons/linus.svg";
import desktopIcon from "@assets/icons/desktop.svg";
import mobileIcon from "@assets/icons/mobile.svg";
import unknown from "@assets/icons/unknown.svg";
import edgeIcon from "@assets/icons/microsoft-edge.svg";
import arcIcon from "@assets/icons/arc-browser-logo.svg";

export type deviceType = "chrome" | "safari" | "brave" | "mozilla" | "other";

export const getDeviceIcon = (name: string) => {
  switch (name) {
    case "chrome":
      return ChromeDeviceIcon;
    case "safari":
      return SafariDeviceIcon;
    case "brave":
      return BraveDeviceIcon;
    case "mozilla":
    case "firefox":
      return MozillaDeviceIcon;
    case "edge":
      return edgeIcon;
    case "arc":
      return arcIcon;

    case "android":
      return androiIcon;
    case "ios":
    case "macos":
    case "mac":

    case "iOS":
      return iosIcon;
    case "windows":
      return windowIcon;
    case "linux":
      return linuxIcon;

    case "mobile":
      return mobileIcon;

    case "desktop":
      return desktopIcon;

    default:
      return unknown;
  }
};

export const humanReadableCount = (count: number) => {
  if (count >= 1000000) {
    return `${(count / 1000000).toFixed(1)}M`;
  } else if (count >= 1000) {
    return `${(count / 1000).toFixed(1)}K`;
  } else {
    return count;
  }
};

export const generateRandomHexCodes = (requiredNumber: number) => {
  const hexCodes = [];
  for (let i = 0; i < requiredNumber; i++) {
    hexCodes.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
  }
  return hexCodes;
};

export function timeSince(
  dateString: string,
  prefix: string = "Created"
): string {
  const now = new Date();
  const date = new Date(dateString);
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return `${prefix} ${interval} years ago`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${prefix} ${interval} months ago`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${prefix} ${interval} days ago`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${prefix} ${interval} hours ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${prefix} ${interval} minutes ago`;
  }
  return `${prefix} ${Math.floor(seconds)} seconds ago`;
}

export function timeAgo(lastEdited: string | Date): string {
  const now = new Date();
  const editedDate = new Date(lastEdited);
  const differenceInSeconds = Math.floor(
    (now.getTime() - editedDate.getTime()) / 1000
  );

  const intervals = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "week", seconds: 604800 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  for (const interval of intervals) {
    const count = Math.floor(differenceInSeconds / interval.seconds);
    if (count >= 1) {
      return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
    }
  }

  return "just now";
}

const mantineColors = [
  "dark",
  "grape",
  "gray",
  "green",
  "indigo",
  "lime",
  "orange",
  "pink",
  "red",
  "teal",
  "violet",
  "yellow",
] as const;

type MantineColor = (typeof mantineColors)[number];

export function getRandomMantineColor(): MantineColor {
  const randomIndex = Math.floor(Math.random() * mantineColors.length);
  return mantineColors[randomIndex];
}
