import { ActionIcon, Tooltip } from "@mantine/core";
import React from "react";
import {
  IconColorSwatch,
  IconHierarchy2,
  IconLockFilled,
  IconPaintFilled,
  IconStackFilled,
  IconTextResize,
  IconTypography,
  IconClipboardData,
  IconMoon,
  IconSettings,
  IconSun,
} from "@tabler/icons-react";
import { HousePlug, Network } from "lucide-react";

const icons: Record<string, React.ElementType> = {
  colorSwatch: IconColorSwatch,
  hierarchy: IconHierarchy2,
  lockFilleed: IconLockFilled,
  paintFilled: IconPaintFilled,
  stackFilled: IconStackFilled,
  textResize: IconTextResize,
  typography: IconTypography,
  clipboardData: IconClipboardData,
  moon: IconMoon,
  settings: IconSettings,
  sun: IconSun,
  network: Network,
  connector: HousePlug,
};

interface NavigationIconProps {
  label?: string;
  color?: string;
  transitionProps?: Record<string, any>;
  icon: string;
  onClick?: () => void;
  disabled?: boolean;
}

const NavigationIcon: React.FC<NavigationIconProps> = ({
  icon,
  label = "",
  transitionProps = {},
  color = "",
  onClick = undefined,
  disabled = false,
}) => {
  const IconComponent = icons[icon];
  return (
    <Tooltip
      label={label}
      position="bottom"
      color="indigo"
      transitionProps={transitionProps}
      disabled={!label}
    >
      <ActionIcon
        size="lg"
        {...(color && { color: color })}
        {...(onClick && { onClick: onClick })}
        disabled={disabled}
      >
        <IconComponent size="1.25rem" />
      </ActionIcon>
    </Tooltip>
  );
};

export default NavigationIcon;
