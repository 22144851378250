import { ActionIcon, Tooltip } from "@mantine/core";
import { IconEye } from "@tabler/icons-react";
import React from "react";
import { useEditorStoreActions } from "@/src/stores/editorstore";
import { useEditorStore } from "@/src/stores/editorstore";

const Preview: React.FC<{}> = () => {
  const { updateMode } = useEditorStoreActions();
  const isPublished = useEditorStore.use.metadata().published;

  return (
    <Tooltip label="Preview page" position="bottom" color="indigo">
      <ActionIcon
        variant="filled"
        color="teal"
        radius={"sm"}
        onClick={() => updateMode("preview")}
        sx={(theme) => ({
          height: "30px",
          width: "30px",
          "&:hover": {
            backgroundColor: theme.colors.teal[9],
          },
        })}
      >
        <IconEye size="1rem" />
      </ActionIcon>
    </Tooltip>
  );
};

export default Preview;
