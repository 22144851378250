import { Widget } from "../../types";

export const LinkIndicator: Widget = {
  id: "",
  name: "Link Indicator",
  description: "Attach link directly to background",
  meta: {
    type: "link_indicator",
    category: "components",
  },
  variant: "custom",
  baseClassName: "",
  properties: {
    styles: {},
    props: {
      color: {
        section: "customization",
        value: "#25262b",
        type: "mantine-color",
        name: "Indicator Color",
        key: "color",
      },
      imageSrc: {
        section: "customization",
        value:
          "https://assets.myntassets.com/h_1440,q_90,w_1080/v1/assets/images/21136246/2022/12/9/fcc7d166-0e4d-4fc4-8d0b-2d80d925180b1670581349599Tops1.jpg",
        type: "image-dropdown-selector",
        key: "imageSrc",
        name: "Add Image URL",
      },
      title: {
        section: "customization",
        value: "Black Halter Neck Crop Cotton Top",
        type: "text",
        key: "title",
        name: "Title",
      },
      showSubtitle: {
        section: "customization",
        value: true,
        type: "toggle",
        key: "showSubtitle",
        name: "Show Subtitle",
      },
      subtitle: {
        section: "customization",
        value: "₹ 499",
        type: "text",
        key: "subtitle",
        name: "Subtitle",
        dependencies: ["showSubtitle"],
        dependencyValue: true,
      },
      actionButton: {
        section: "customization",
        value: "SHOP NOW",
        type: "text",
        key: "actionButton",
        name: "Action Button Text",
      },
      actionButtonVariant: {
        section: "flexibility",
        value: "filled",
        type: "segmented-control",
        key: "actionButtonVariant",
        name: "Select Button Variant",
        options: ["filled", "light", "outline", "default", "subtle"],
      },
      actionButtonColor: {
        section: "flexibility",
        value: "#ffffff",
        type: "mantine-color",
        name: "Action Button Color",
        key: "actionButtonColor",
      },
      titleColorSelector: {
        section: "flexibility",
        value: "gradient",
        type: "segmented-control",
        key: "titleColorSelector",
        name: "Title Color",
        options: ["solid", "gradient"],
      },
      titleColor: {
        section: "flexibility",
        value: "#ffffff",
        type: "mantine-color",
        name: "Title Color",
        key: "titleColor",
        dependencies: ["titleColorSelector"],
        dependencyValue: "solid",
      },
      titleGradient: {
        section: "flexibility",
        value: {
          from: "#364FC7",
          to: "#FA5252",
          deg: 45,
        },
        type: "gradient",
        key: "titleGradient",
        name: "Title Gradient",
        dependencies: ["titleColorSelector"],
        dependencyValue: "gradient",
      },
    },
  },
  control: {
    redirectTo: "",
    type: "blink",
  },
  layout: {
    currentLayout: "mobile",
    mobile: {
      top: 0,
      left: 0,
      width: 25,
      height: 25,
    },
  },
};
