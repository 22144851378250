import { Widget } from "../../types";

export const UserInfoBasic: Widget = {
  id: "",
  name: "User Info",
  description: "Render user info action banner",
  meta: {
    type: "user_info_basic",
    category: "components",
  },
  variant: "filled",
  baseClassName: "",
  properties: {
    styles: {},
    props: {
      profilePicture: {
        section: "customization",
        value:
          "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-8.png",
        type: "image-dropdown-selector",
        key: "profilePicture",
        name: "Profile Picture",
      },
      profileName: {
        section: "customization",
        value: "Jane Fingerlicker",
        type: "text",
        key: "profileName",
        name: "Profile Name",
      },
      showBorder: {
        section: "flexibility",
        value: true,
        type: "toggle",
        key: "showBorder",
        name: "Show Border",
      },
      borderColor: {
        section: "flexibility",
        value: "#25262b",
        type: "color",
        key: "borderColor",
        name: "Border Color",
        dependencies: ["showBorder"],
        dependencyValue: true,
      },
      borderRadius: {
        section: "flexibility",
        value: "sm",
        type: "segmented-control",
        name: "Border radius",
        key: "borderRadius",
        options: ["xs", "sm", "md", "lg"],
        dependencies: ["showBorder"],
      },
      borderThickness: {
        section: "flexibility",
        value: 1,
        type: "number",
        key: "borderThickness",
        name: "Border Thickness",
        dependencies: ["showBorder"],
        dependencyValue: true,
      },
      profileNameTextColorSelector: {
        section: "flexibility",
        value: "gradient",
        type: "segmented-control",
        key: "profileNameTextColorSelector",
        name: "Profile Name",
        options: ["solid", "gradient"],
      },
      profileNameColor: {
        section: "flexibility",
        value: "#000000",
        type: "color",
        key: "profileNameColor",
        name: "Set Profile Name text color",
        dependencies: ["profileNameTextColorSelector"],
        dependencyValue: "solid",
      },
      gradient: {
        section: "flexibility",
        value: {
          from: "#364FC7",
          to: "#FA5252",
          deg: 45,
        },
        type: "gradient",
        key: "gradient",
        name: "Gradient",
        dependencies: ["profileNameTextColorSelector"],
        dependencyValue: "gradient",
      },
      showBio: {
        section: "customization",
        value: true,
        type: "toggle",
        key: "showBio",
        name: "Show Bio",
      },
      bio: {
        section: "customization",
        type: "text",
        value: "user@b-link.co | Creative director",
        key: "bio",
        name: "Bio",
      },
      showActionButton: {
        section: "customization",
        value: true,
        type: "toggle",
        key: "showActionButton",
        name: "Show Action Button",
      },
      ctaText: {
        section: "customization",
        value: "Send Message",
        type: "text",
        key: "ctaText",
        name: "Action button text",
        dependencies: ["showActionButton"],
        dependencyValue: true,
      },

      actionButtonVariant: {
        section: "flexibility",
        value: "light",
        type: "segmented-control",
        key: "actionButtonVariant",
        name: "Action Button Variant",
        options: ["default", "outline", "link", "light", "filled"],
        dependencies: ["showActionButton"],
        dependencyValue: true,
      },
      actionButtonBg: {
        section: "flexibility",
        value: "indigo",
        type: "mantine-color",
        key: "actionButtonBg",
        name: "Action Button Background",
        dependencies: ["showActionButton"],
        dependencyValue: true,
      },
    },
  },
  control: {
    redirectTo: "",
    type: "blink",
  },
  layout: {
    currentLayout: "mobile",
    mobile: {
      top: 0,
      left: 0,
      width: 390,
      height: 280,
    },
  },
};
