import React, { useEffect, useState } from "react";
import { useBlinksStore } from "@src/stores/blinks";
import { Segment } from "@src/types/modules/blinks";
import Listview from "./Listview";
import {
  Box,
  Button,
  Center,
  Pagination,
  Paper,
  Stack,
  Text,
} from "@mantine/core";
import { Image } from "@/src/components/BaseUI";
import { IconAppWindowFilled } from "@tabler/icons-react";
import EmptyPageSVG from "@assets/empty-states/page.svg";

type Props = {
  activeSegment: Segment;
  sortBy: string;
  order: string;
  searchTerm: string;
  open: () => void;
};

const RenderBlinks: React.FC<Props> = ({
  activeSegment,
  sortBy,
  order,
  searchTerm,
  open,
}) => {
  const blinks = useBlinksStore.use.blinks();

  const [currentBlinks, setCurrentBlinks] = React.useState(blinks);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    let filteredBlinks = blinks;

    if (activeSegment !== "all") {
      filteredBlinks = filteredBlinks.filter(
        (blink) => blink.status === activeSegment
      );
    }

    if (searchTerm) {
      filteredBlinks = filteredBlinks.filter((blink) =>
        blink.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (sortBy && order) {
      const blinksWithTimestamps = filteredBlinks.map((item) => ({
        ...item,
        createdAtTimestamp: new Date(item.createdAt).getTime(),
        updatedAtTimestamp: new Date(item.updatedAt).getTime(),
      }));

      if (sortBy === "last_created") {
        blinksWithTimestamps.sort((a, b) => {
          return order === "asc"
            ? a.createdAtTimestamp - b.createdAtTimestamp
            : b.createdAtTimestamp - a.createdAtTimestamp;
        });
      } else if (sortBy === "last_updated") {
        blinksWithTimestamps.sort((a, b) => {
          return order === "asc"
            ? a.updatedAtTimestamp - b.updatedAtTimestamp
            : b.updatedAtTimestamp - a.updatedAtTimestamp;
        });
      }
      filteredBlinks = blinksWithTimestamps;
    }

    setCurrentBlinks(filteredBlinks);
  }, [JSON.stringify(blinks), activeSegment, sortBy, order, searchTerm]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentBlinksPage = currentBlinks.slice(startIndex, endIndex);

  if (blinks.length === 0) {
    return (
      <Box
        style={{
          overflowY: "auto",
          // height: "calc(100vh - 100px)",
        }}
      >
        <Paper
          p="md"
          className="mt-3"
          sx={(theme) => ({
            height: "50%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            border: "2px dashed",
            borderColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[4]
                : theme.colors.gray[3],
          })}
        >
          <Center>
            <Stack>
              <Image
                src={EmptyPageSVG}
                alt="Empty Page"
                width={200}
                height={200}
              />

              <Text size="md" align="center" color="gray" fw={600}>
                You don't have any blinks yet!
              </Text>
              <Text size="xs" align="center">
                Click below to get started 🎉
              </Text>

              <Button
                leftIcon={<IconAppWindowFilled size={"1rem"} />}
                onClick={open}
                variant="outline"
                color="indigo"
                size="sm"
                radius={"sm"}
              >
                Create a new Blink
              </Button>
            </Stack>
          </Center>
        </Paper>
      </Box>
    );
  }

  return (
    <>
      <Listview>
        {currentBlinksPage.map((blink: any) => {
          return <Listview.ListItem key={blink.id} {...blink} />;
        })}
      </Listview>
      <Box mt={20}>
        <Center>
          <Pagination
            size={"sm"}
            color="dark"
            value={currentPage}
            total={Math.ceil(currentBlinks.length / itemsPerPage)}
            onChange={setCurrentPage}
          />
        </Center>
      </Box>
    </>
  );
};

export default RenderBlinks;
