import React from "react";
import { Box, Group, Text } from "@mantine/core";
import colorswap from "@assets/icons/colorswap.svg";
import { Image } from "@/src/components/BaseUI";

type Props = {};

const Header = (props: Props) => {
  return (
    <div className="brand-asset-banner-container">
      <Group>
        <Box>
          <Group>
            <Image src={colorswap} />
            <Text ta="center" fz="sm" fw={700}>
              Brand Asset Manager
            </Text>
          </Group>
        </Box>
      </Group>
    </div>
  );
};

export default Header;
