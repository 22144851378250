import React from "react";
import { useEditorStore } from "@/src/stores/editorstore";
import {
  ActionIcon,
  Box,
  Button,
  CopyButton,
  Group,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import { TruncatedContent } from "@/src/components/BaseUI/TruncatedContent";

interface AppnameBlinkUrlProps {}

const AppnameBlinkUrl: React.FC<AppnameBlinkUrlProps> = () => {
  const appBlinkURL = useEditorStore.use.appBlinkURL();
  const name = useEditorStore.use.metadata().name;

  return (
    <Box
      sx={{
        maxWidth: "60%",
        height: "100%",
      }}
    >
      <TruncatedContent
        content={name}
        type="text"
        showSymbol={false}
        size={"md"}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* <Group position="apart"> */}
        <>
          <div
            className="mt-1"
            style={{
              // textWrap: "wrap",
              overflow: "hidden",
            }}
          >
            <TruncatedContent
              content={appBlinkURL}
              type="text"
              showSymbol={false}
              size="xs"
              color="indigo"
            />
          </div>
          <CopyButton value={appBlinkURL} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? "Copied" : "Copy"}
                withArrow
                position="right"
              >
                <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                  {copied ? (
                    <IconCheck size="1rem" />
                  ) : (
                    <IconCopy size="1rem" />
                  )}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </>
      </div>
    </Box>
  );
};
export default AppnameBlinkUrl;
