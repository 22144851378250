import React, { useState, useRef } from "react";
import { Modal, Button, TextInput, Box, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import * as Icons from "@tabler/icons-react";
import { VirtuosoGrid } from "react-virtuoso";

interface Props {
  callback: (
    property: string,
    value: string | boolean | number | Record<string, any>
  ) => void;
  itemKey: string;
  item: Record<string, any>;
}

export const IconSelector: React.FC<Props> = ({ callback, itemKey, item }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [searchText, setSearchText] = useState<string>("");
  const iconList = useRef<string[]>(Object.keys(Icons));

  const searchIcon = (text: string) => {
    if (searchText === text) return;
    setSearchText(text);
  };

  const filteredIcons =
    searchText === ""
      ? iconList.current
      : iconList.current.filter((icon) =>
          icon.toLowerCase().includes(searchText.toLowerCase())
        );

  const onIconSelect = (icon: string) => {
    callback(itemKey, icon);
  };

  const CurrentIcon = Icons[
    item.value as keyof typeof Icons
  ] as React.ElementType;

  return (
    <div onMouseDown={(e) => e.stopPropagation()}>
      <Modal opened={opened} onClose={close} zIndex={3001} title="Select icon">
        <Modal.Header>
          <TextInput
            value={searchText}
            onChange={(event) => searchIcon(event.currentTarget.value)}
            placeholder="Search icon"
            sx={{ width: "100%" }}
          />
        </Modal.Header>
        <Modal.Body>
          <VirtuosoGrid
            style={{ height: 300 }}
            totalCount={filteredIcons.length}
            listClassName="icon-list-wrapper"
            itemClassName="icon-list"
            itemContent={(index) => {
              const iconName = filteredIcons[index];
              if (!iconName || iconName === "createReactComponent") return null;
              const IconElement = Icons[
                iconName as keyof typeof Icons
              ] as React.ElementType;
              return (
                <div
                  className="icon-element p-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onIconSelect(iconName);
                    close();
                  }}
                >
                  <IconElement
                    stroke={1.5}
                    strokeLinejoin="miter"
                    style={{ width: "24px", height: "24px" }}
                  />
                </div>
              );
            }}
          />
        </Modal.Body>
      </Modal>
      <Box>
        <Text size="xs" fw={500}>
          Icon
        </Text>
        <Button
          leftIcon={<CurrentIcon />}
          onClick={open}
          variant="outline"
          color="dark"
          size="xs"
          className="mt-1"
          fullWidth
          radius="sm"
        >
          {item?.value}
        </Button>
      </Box>
    </div>
  );
};
