import React, { useEffect, useRef, useState } from "react";
import { useEditorStore, useEditorStoreActions } from "@src/stores/editorstore";
import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Center,
  Group,
  Image as MantineImage,
  Paper,
  ScrollArea,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { appPageService } from "@src/services/apps.service";
import { IconCloudUpload } from "@tabler/icons-react";
import { useWorkspace } from "../stores/workspace";
import ImageList from "../modules/Pages/Panel/Manager/BrandAssetManager/ImageList";
import { NoImagesFound } from "./ImageDropDownSelector";
import { extractFileName, getImageDataUri } from "../lib/utils";
import { brandAssetsService } from "../services/brand_asset.service";
import { useWorkspaceId } from "../hooks";
import toast from "react-hot-toast";
export function BackgroundPicker() {
  const theme = useMantineTheme();

  const workspaceId = useWorkspaceId();
  const currentSelectedImage =
    useEditorStore.use.definitions().background.backgroundImage;

  const [backgroundImage, setBackgroundImage] = useState(
    () => currentSelectedImage ?? "IconCloudUpload"
  );
  const [backgroundImageFile, setBackgroundImageFile] = useState<File | null>(
    null
  );

  const appId = useEditorStore.use.id();

  const handleBgSelection = async (storageUrl: string) => {
    if (!workspaceId) {
      return toast.error("Something went wrong. Please try again later.");
    }

    useEditorStoreActions().setBackground("backgroundImage", storageUrl);

    const fileNameString = extractFileName(storageUrl);
    setBackgroundImage(storageUrl);

    const data = await brandAssetsService.getImageDataUri(
      workspaceId,
      fileNameString
    );

    extractColors(data);
  };

  const extractColors = (imageSrc: string) => {
    const img = new Image();
    img.src = imageSrc;

    img.onload = function () {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      if (context) {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0, img.width, img.height);

        const pixels: number[][] = [];

        for (let x = 0; x < canvas.width; x++) {
          for (let y = 0; y < canvas.height; y++) {
            const pixel = context.getImageData(x, y, 1, 1).data;
            pixels.push([pixel[0], pixel[1], pixel[2]]);
          }
        }

        // Process pixel data to extract dominant colors
        const processedColors = processPixels(pixels);

        // Generate a gradient once we have colors
        generateGradient(processedColors);
      }
    };
  };

  const processPixels = (pixels: number[][]) => {
    const colorCount: { [key: string]: number } = {};
    const dominantColors: string[] = [];

    pixels.forEach((pixel) => {
      const rgbString = `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;

      if (colorCount[rgbString]) {
        colorCount[rgbString]++;
      } else {
        colorCount[rgbString] = 1;
      }
    });

    // Get the most dominant colors
    const sortedColors = Object.keys(colorCount).sort(
      (a, b) => colorCount[b] - colorCount[a]
    );

    // Get top 5 most dominant colors
    for (let i = 0; i < Math.min(sortedColors.length, 5); i++) {
      dominantColors.push(sortedColors[i]);
    }

    return dominantColors;
  };

  const generateGradient = (colors: string[]) => {
    if (colors.length >= 2) {
      // Take the first two colors for the gradient
      //radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
      const gradient = `radial-gradient(circle, ${colors[0]} 0%, ${colors[1]} 100%)`;

      useEditorStoreActions().setBackground("gradient", colors[0]);
    }
  };

  // useEffect(() => {
  //   if (backgroundImageFile) {
  //     appPageService
  //       .uploadFile(appId, backgroundImageFile)
  //       .then((response) => {
  //         useEditorStoreActions().setBackground("backgroundImage", response);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // }, [backgroundImage]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleAvatarClick = () => {
    // @ts-ignore
    fileInputRef.current.click();
  };

  return <RenderBrandAssetImages callback={handleBgSelection} />;
}

const RenderBrandAssetImages: React.FC<{
  callback: (value: string) => void;
}> = ({ callback }) => {
  const workspaceBrandAssets = useWorkspace.use.brandAssets();

  const images = React.useMemo(() => {
    return workspaceBrandAssets[0]?.images || [];
  }, [workspaceBrandAssets]);

  const { setCurrentSegment, setShowFileUploader } = useEditorStoreActions();

  const { background } = useEditorStore.use.definitions();

  return (
    <Box>
      <Group position="left">
        <Box
          style={{
            height: "50px",
            width: "50px",
          }}
        >
          <MantineImage
            src={background.backgroundImage}
            alt="background"
            radius={"md"}
            height={50}
            width={50}
          />
        </Box>
        <Text size={"xs"}>
          {background.backgroundImage
            ? extractFileName(background.backgroundImage)
            : ""}
        </Text>
      </Group>

      <Paper className="mt-4" p="md" radius="md" shadow="sm" withBorder>
        <div className="brand-asset-banner-container">
          <Group>
            <Box>
              <Group>
                <Text ta="center" fz="sm" fw={500}>
                  Brand Asset Manager
                </Text>
              </Group>
            </Box>
          </Group>
        </div>

        {Array.isArray(images) && images.length > 0 ? (
          <>
            <ScrollArea
              className="mt-2"
              style={{ overflowY: "auto", maxHeight: "300px" }}
            >
              <ImageList
                images={images}
                size={50}
                cols={4}
                callback={(image) => callback(image)}
              />
            </ScrollArea>
            <Button
              fullWidth
              className={"sticky bottom-0 mt-4"}
              size="xs"
              color="indigo"
              variant="light"
              onClick={() => {
                setCurrentSegment("brand_assets");
                setShowFileUploader(true);
              }}
            >
              Add New Image
            </Button>
          </>
        ) : (
          <Center>
            <NoImagesFound />
          </Center>
        )}
      </Paper>
    </Box>
  );
};
