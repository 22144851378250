import { appConfig } from "@src/app.config";
import { handleResponseWithoutValidation } from "@src/lib/handleResponses";
import { authHeader, fileAuthHeader } from "@src/lib/requestHeaders";
import { CreateAppDto } from "./types";

export const appPageService = {
  create: create,
  delete: deleteApp,
  uploadFile: uploadFile,
  updateAppBg: updateAppBg,
  validatebBeforePublishing: validatebBeforePublishing,
  publishApp: publishApp,
  updateDraftMode: updateDraftMode,
  getPublicApp: getPublicApp,
};

async function create(createAppData: CreateAppDto): Promise<any> {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(createAppData),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(`${appConfig.apiURL}/apps`, requestOptions);
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function uploadFile(appId: string, file: File): Promise<any> {
  const formData = new FormData();
  formData.append("file", file);

  const requestOptions = {
    method: "POST",
    headers: fileAuthHeader(),
    body: formData,
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/upload/${appId}`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function updateAppBg(
  appId: string,
  updateAppData: Record<string, any>
): Promise<any> {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(updateAppData),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/apps/${appId}/app-background`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function validatebBeforePublishing(appId: string): Promise<any> {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/apps/${appId}/status`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function publishApp(
  appId: string,
  snapshot: Record<string, any>
): Promise<any> {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(snapshot),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/apps/${appId}/publish`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function updateDraftMode(
  appId: string,
  draftMode: boolean
): Promise<any> {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({ draftMode }),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/apps/${appId}/draft`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function getPublicApp(appId: string): Promise<any> {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/apps/${appId}`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function deleteApp(appId: string): Promise<any> {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/apps/${appId}`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}
