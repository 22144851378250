import React from "react";
import {
  Progress,
  Box,
  Text,
  Group,
  Paper,
  SimpleGrid,
  rem,
  ThemeIcon,
  Badge,
} from "@mantine/core";
import {
  IconArrowUpRight,
  IconBuildingSkyscraper,
  IconClick,
} from "@tabler/icons-react";
import "./styles.module.css";
import { useWorkspace, useWorkspaceState } from "@/src/stores/workspace";
import { DeviceTypeCount } from "..";
import AnimatedCounter from "@/src/components/AnimatedCounter";

const deviceColors: { [key: string]: string } = {
  mobile: "#ff922b",
  desktop: "#4263eb",
  others: "#ff6b6b",
};

function calculatePercentageGrowth(
  totalClicks: number,
  previousMonthClicks: number
): number {
  let percentageOfGrowth: number;

  if (previousMonthClicks === 0) {
    if (totalClicks === 0) {
      percentageOfGrowth = 0; // No clicks in both months
    } else {
      percentageOfGrowth = 100; // New user with clicks this month
    }
  } else {
    percentageOfGrowth = Math.round(
      ((totalClicks - previousMonthClicks) / previousMonthClicks) * 100
    );
  }

  return percentageOfGrowth;
}

export const StatsSegments: React.FC<{ deviceCount: DeviceTypeCount }> = ({
  deviceCount,
}) => {
  const totalClicks = useWorkspace.use.clickCount();

  const data = Object.entries(deviceCount).map(([device, count]) => ({
    value: count,
    percentage: count === 0 ? 0 : Math.round(totalClicks / count) * 100,
    color: deviceColors[device],
    label: device.toUpperCase(),
    tooltip: `${count} clicks`,
  }));

  const descriptions = data.map((stat) => (
    <Box style={{ borderBottomColor: stat.color }} className={"stat p-0"}>
      <Text tt="uppercase" fz="xs" c="dimmed" fw={700}>
        {stat.label}
      </Text>

      <Group position="apart" align="flex-end">
        <Text size={"xs"} fw={700}>
          {stat.value}
        </Text>
        <Text c={stat.color} fw={700} size="xs">
          {stat.percentage}%
        </Text>
      </Group>
    </Box>
  ));

  const workspaceName = useWorkspace.use.workspaceName();

  const previousMonthClicks = 0;
  const percentageOfGrowth = calculatePercentageGrowth(
    totalClicks,
    previousMonthClicks
  );

  return (
    <Paper
      p="md"
      radius="md"
      shadow="md"
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === "dark" ? theme.colors.dark[8] : "white",
      })}
    >
      <Group className="">
        <Badge
          size="xs"
          p={"xs"}
          radius={"sm"}
          variant={"light"}
          color={"grape"}
          leftSection={
            <IconBuildingSkyscraper
              size="1rem"
              className={"icon"}
              stroke={1.5}
            />
          }
          style={{
            textTransform: "capitalize",
          }}
        >
          {workspaceName}
        </Badge>
      </Group>

      <Group position="apart">
        <Group>
          <AnimatedCounter
            startValue={0}
            endValue={totalClicks}
            duration={1} // 2 seconds
            direction="up"
          />
          <Text c="dimmed" fw={500} size={"xs"}>
            Click rates compared to previous month
          </Text>
        </Group>

        <ThemeIcon variant="light" color="indigo">
          <IconClick size="1.4rem" className={"icon"} stroke={1.5} />
        </ThemeIcon>
      </Group>

      <Progress className="mt-2" radius="sm" size={14} sections={data} />
      <SimpleGrid cols={3} mt="sm">
        {descriptions}
      </SimpleGrid>
    </Paper>
  );
};
