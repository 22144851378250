import React from "react";
import { WidgetProps } from "../widgets/types";
import { Overlay as MantineOverlay } from "@mantine/core";

const Overlay: React.FC<WidgetProps> = ({
  id,
  inheritedStyles,
  properties,
}) => {
  const { blurIntensity, opacityIntensity, color } = properties.props;

  const opacityIntensityValue = opacityIntensity?.value / 100;
  const blurIntensityValue = blurIntensity?.value / 10;

  return (
    <MantineOverlay
      className={`user-profile widget-${id} draggable-widget p-0`}
      style={{
        ...inheritedStyles,
      }}
      color={color?.value}
      blur={blurIntensityValue}
      opacity={opacityIntensityValue}
      zIndex={1}
    />
  );
};

export default Overlay;
