import { Widget } from "../../types";

export const OverlayContainer: Widget = {
  id: "",
  name: "Overlay",
  description:
    "Overlays parent element with an element with any color and opacity",
  meta: {
    type: "overlay",
    category: "components",
  },
  variant: "custom",
  baseClassName: "",
  properties: {
    styles: {},
    props: {
      blurIntensity: {
        section: "customization",
        name: "Blur intensity",
        type: "slider",
        value: 10,
        key: "blurIntensity",
      },
      opacityIntensity: {
        section: "customization",
        name: "Opacity intensity",
        type: "slider",
        value: 50,
        key: "opacityIntensity",
      },
      color: {
        section: "customization",
        name: "Color",
        type: "color",
        value: "#91a7ff",
        key: "color",
      },
    },
  },
  layout: {
    currentLayout: "mobile",
    mobile: {
      top: 0,
      left: 0,
      width: 195,
      height: 845,
    },
  },
};
