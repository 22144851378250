import {
  Avatar,
  Button,
  ColorInput,
  Group,
  NumberInput,
  Paper,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
  Input,
  ScrollArea,
  ActionIcon,
  Slider,
  rem,
  Box,
  Menu,
  Popover,
  Center,
} from "@mantine/core";
import { Image, Toggle } from "@src/components/BaseUI";
import { MantineColorSelector } from "@src/components/MantineColorSelector";
import { Widget } from "@src/modules/Pages/widgets/types";
import React, { useCallback, useEffect } from "react";
import { CustomSelector } from "./Inspector.container";
import { GradientColorPicker, ImageDropDownSelector } from "@src/components";
import _ from "lodash";
import { ConditionalRenderer } from "./ConditionalRenderer";
import { BasicSegmentedControl } from "@UIComponents/index";
import colorswap from "@assets/icons/colorswap.svg";
import {
  IconGripHorizontal,
  IconHeart,
  IconLayoutList,
  IconTrashFilled,
} from "@tabler/icons-react";
import { IconSelector } from "@/src/components/IconSelector";

import { SocialIconSelector } from "@/src/components/IconSocialSelector";

import { useWorkspace } from "@/src/stores/workspace";
import ImageList from "../../BrandAssetManager/ImageList";
import { useEditorStoreActions } from "@/src/stores/editorstore";
import { extractFileName } from "@/src/lib/utils";
import { NoImagesFound } from "@/src/components/ImageDropDownSelector";

export const DynamicAccordionItem: React.FC<{
  item: Record<string, any>;
  handleComponentPropertiesChange: (
    property: string,
    value: string | boolean | number | Record<string, any>
  ) => void;
  currentWidgetDefinition: Widget | Record<string, any>;
}> = ({ item, handleComponentPropertiesChange, currentWidgetDefinition }) => {
  const itemKey = item?.key || "";
  const itemName = item?.name || "";
  const type = item?.type || "";

  const hasDependencies: [] = item["dependencies"] || [];

  const dependencyValues: Record<string, any> = {};

  if (hasDependencies) {
    hasDependencies.forEach((dependency) => {
      const dependencyProp =
        currentWidgetDefinition?.properties["props"][dependency] || null;

      if (!dependencyProp && !dependencyProp.value) return;

      if (item["dependencyValue"]) {
        dependencyValues[dependency] =
          item["dependencyValue"] === dependencyProp?.value;
        return;
      } else if (item["not"]) {
        const shouldNotRender = item["not"].includes(dependencyProp?.value);

        dependencyValues[dependency] = !shouldNotRender;
        return;
      }

      dependencyValues[dependency] = dependencyProp?.value;
    });
  }

  const conditionalRender =
    hasDependencies.length === 0
      ? true
      : Object.values(dependencyValues)
          .map((val) => val)
          .includes(true);

  const handleDebouncedPropertiesChange = useCallback(
    _.debounce(
      (itemKey: string, value: string | number) =>
        handleComponentPropertiesChange(itemKey, value),
      500
    ),
    [handleComponentPropertiesChange]
  );

  switch (type) {
    case "toggle":
      return (
        <ConditionalRenderer shouldRenderComponent={conditionalRender}>
          <Toggle
            checked={item.value}
            onChange={(event) =>
              handleComponentPropertiesChange(
                itemKey,
                event.currentTarget.checked
              )
            }
            label={_.capitalize(itemName)}
            size="xs"
            styles={{
              body: {
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
              },
              label: {
                fontWeight: 500,
              },
            }}
          />
        </ConditionalRenderer>
      );

    case "number":
      return (
        <ConditionalRenderer shouldRenderComponent={conditionalRender}>
          <NumberInput
            size="xs"
            label={_.capitalize(itemName)}
            defaultValue={item.value}
            onChange={(value) =>
              handleDebouncedPropertiesChange(itemKey, Number(value))
            }
          />
        </ConditionalRenderer>
      );

    case "text":
      return (
        <ConditionalRenderer shouldRenderComponent={conditionalRender}>
          <TextInput
            size="xs"
            placeholder="Your name"
            label={_.capitalize(itemName)}
            defaultValue={item.value}
            onChange={(event) =>
              handleDebouncedPropertiesChange(
                itemKey,
                event.currentTarget.value
              )
            }
          />
        </ConditionalRenderer>
      );

    case "textarea":
      return (
        <ConditionalRenderer shouldRenderComponent={conditionalRender}>
          <Textarea
            autosize
            label={_.capitalize(itemName)}
            size="xs"
            defaultValue={item.value}
            onChange={(event) =>
              handleDebouncedPropertiesChange(
                itemKey,
                event.currentTarget.value
              )
            }
            maxRows={10}
            minRows={4}
          />
        </ConditionalRenderer>
      );

    case "mantine-color":
      const handleUpdate = (val: string) =>
        handleComponentPropertiesChange(itemKey, val);

      return (
        <ConditionalRenderer shouldRenderComponent={conditionalRender}>
          <MantineColorSelector value={item.value} callBack={handleUpdate} />
        </ConditionalRenderer>
      );

    case "select":
      return (
        <ConditionalRenderer shouldRenderComponent={conditionalRender}>
          <div onMouseDown={(e) => e.stopPropagation()}>
            <Select
              data={item.options ?? []}
              defaultValue={item.value}
              placeholder="Pick one"
              label={item.name}
              variant="filled"
              size="xs"
              withinPortal={false}
              onChange={(value) => {
                value && handleComponentPropertiesChange(item.key, value);
              }}
              styles={(theme) => ({
                item: {
                  // applies styles to selected item
                  "&[data-selected]": {
                    "&, &:hover": {
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[9]
                          : theme.colors.gray[3],
                      color:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[0]
                          : theme.colors.dark[9],
                    },
                  },
                },
              })}
            />
          </div>
        </ConditionalRenderer>
      );

    case "segmented-control":
      return (
        <ConditionalRenderer shouldRenderComponent={conditionalRender}>
          <BasicSegmentedControl
            size="xs"
            fullWidth={true}
            options={item.options.map((option: any) => ({
              value: option,
              label: option,
            }))}
            value={item.value}
            callback={(value) =>
              handleComponentPropertiesChange(itemKey, value)
            }
            label={item.name}
            icons={item.icons}
            renderWithIcons={item?.icons && _.keys(item?.icons).length > 0}
            onlyIcons={item.onlyIcons ?? false}
          />
        </ConditionalRenderer>
      );

    case "gradient":
      return (
        <ConditionalRenderer shouldRenderComponent={conditionalRender}>
          <GradientColorPicker
            from={item.value.from}
            to={item.value.to}
            callback={(from, to) =>
              handleComponentPropertiesChange(itemKey, { from, to })
            }
          />
        </ConditionalRenderer>
      );

    case "custom-select":
      return (
        <ConditionalRenderer shouldRenderComponent={conditionalRender}>
          <CustomSelector
            item={item}
            callback={handleComponentPropertiesChange}
          />
        </ConditionalRenderer>
      );

    case "color":
      return (
        <ConditionalRenderer shouldRenderComponent={conditionalRender}>
          <ColorInput
            defaultValue={item.value}
            label={_.capitalize(itemName)}
            format="hex"
            swatches={[
              "#25262b",
              "#868e96",
              "#fa5252",
              "#e64980",
              "#be4bdb",
              "#7950f2",
              "#4c6ef5",
              "#228be6",
              "#15aabf",
              "#12b886",
              "#40c057",
              "#82c91e",
              "#fab005",
              "#fd7e14",
            ]}
            onChange={(color) => {
              handleComponentPropertiesChange(itemKey, color);
            }}
            withinPortal={false}
            size="xs"
          />
        </ConditionalRenderer>
      );

    case "array":
      return (
        <ColumnForArrayOptions
          callback={handleComponentPropertiesChange}
          itemKey={itemKey}
          data={item.value}
        />
      );

    case "image-dropdown-selector":
      return (
        <ImageDropDownSelector
          callback={handleComponentPropertiesChange}
          itemKey={itemKey}
          itemValue={item.value}
        />
      );
    case "icon-selector":
      return (
        <ConditionalRenderer shouldRenderComponent={conditionalRender}>
          <IconSelector
            callback={handleComponentPropertiesChange}
            itemKey={itemKey}
            item={item}
          />
        </ConditionalRenderer>
      );
    case "socialicon-selector":
      return (
        <ConditionalRenderer shouldRenderComponent={conditionalRender}>
          <SocialIconSelector
            callback={handleComponentPropertiesChange}
            itemKey={itemKey}
            item={item}
          />
        </ConditionalRenderer>
      );

    case "slider":
      return (
        <Box>
          <Text size="xs" weight={500}>
            {_.capitalize(item.name)}
          </Text>
          <Slider
            thumbChildren={<IconGripHorizontal size="1rem" />}
            color="dark"
            defaultValue={item.value}
            thumbSize={24}
            size={"sm"}
            styles={{
              thumb: {
                borderWidth: rem(2),
                padding: rem(2),
                borderRadius: "6px",
                width: rem("24px"),
                height: rem("20px"),
              },
            }}
            label={(value) => `opacity: ${value} `}
            min={5}
            onChange={(value) => {
              handleComponentPropertiesChange(itemKey, value);
            }}
          />
        </Box>
      );

    default:
      break;
  }
};

const ColumnForArrayOptions: React.FC<{
  callback: (
    property: string,
    value: string | boolean | number | Record<string, any>
  ) => void;
  itemKey: string;
  data: Record<string, string>[] | [];
}> = ({ callback, itemKey, data }) => {
  const [list, setList] = React.useState(() => data.map((i) => i?.src) ?? []);

  const addNew = () => {
    const newItem = {
      src: "https://images.unsplash.com/photo-1596394516093-501ba68a0ba6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
      alt: `Image ${list.length + 1}`,
    };

    setList([...list, newItem.src]);

    callback(itemKey, [...data, newItem]);
  };

  const removeItem = (index: number) => {
    const updatedList = list.filter((_, i) => i !== index);
    setList(updatedList);

    const currentData = _.cloneDeep(data);
    currentData.splice(index, 1);

    callback(itemKey, currentData);
  };

  const scrollHeight = list.length >= 5 ? 200 : "auto";

  const [inputVariant, setInputVariant] = React.useState<"filled" | "unstyled">(
    "unstyled"
  );

  useEffect(() => {
    const currentData = _.cloneDeep(data);

    if (currentData.length === list.length) {
      currentData.forEach((item, index) => {
        item.src = list[index];
      });

      callback(itemKey, currentData);
    }
  }, [list]);

  const handleUpdatesList = (index: number, value: string) => {
    const updatedList = [...list];
    updatedList[index] = value;
    setList(updatedList);
  };

  return (
    <Stack className="mt-2" w={"100%"}>
      <Group position="apart" spacing={"xs"}>
        <Text align="left" weight={500} size="xs">
          Data
        </Text>
        <Button
          size="xs"
          variant="light"
          color="indigo"
          compact
          onClick={addNew}
        >
          Add +
        </Button>
      </Group>

      <ScrollArea h={scrollHeight} scrollbarSize={10}>
        {list.length > 0 &&
          list.map((item, index) => (
            <Group position="apart" key={index} spacing="xs" className="my-1">
              <Text size="xs">
                {extractFileName(item).substring(0, 25)}
                {extractFileName(item).length > 20 ? "..." : ""}
              </Text>
              <Group position="apart">
                <RenderBrandAssetImages
                  index={index}
                  callback={handleUpdatesList}
                >
                  <Avatar
                    src={item}
                    size="sm"
                    radius="sm"
                    sx={{
                      cursor: "pointer",
                    }}
                  />
                </RenderBrandAssetImages>

                <ActionIcon onClick={() => removeItem(index)}>
                  <IconTrashFilled size={16} color="red" />
                </ActionIcon>
              </Group>
            </Group>
          ))}

        {list.length === 0 && <EmtptyStateList />}
      </ScrollArea>
    </Stack>
  );
};

const RenderBrandAssetImages: React.FC<{
  children: React.ReactNode;
  index: number;
  callback: (index: number, value: string) => void;
}> = ({ children, index, callback }) => {
  const workspaceBrandAssets = useWorkspace.use.brandAssets();

  const images = React.useMemo(() => {
    return workspaceBrandAssets[0]?.images || [];
  }, [workspaceBrandAssets]);

  const { setCurrentSegment, setShowFileUploader } = useEditorStoreActions();

  return (
    <Popover
      withinPortal={true}
      width={250}
      position="left-start"
      withArrow
      shadow="md"
      styles={{
        dropdown: {
          padding: "10px",
          borderRadius: "8px",
          overflow: "hidden",
          minHeight: images?.length > 0 ? "400" : "auto",
          maxHeight: "600px",
        },
      }}
    >
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown>
        <div className="brand-asset-banner-container">
          <Group>
            <Box>
              <Group>
                <Image src={colorswap} />
                <Text ta="center" fz="sm" fw={500}>
                  Brand Asset Manager
                </Text>
              </Group>
            </Box>
          </Group>
        </div>

        {Array.isArray(images) && images.length > 0 ? (
          <>
            <ScrollArea
              className="my-2"
              style={{ height: "300px", overflowY: "auto" }}
            >
              <ImageList
                images={images}
                size={50}
                cols={3}
                callback={(image) => callback(index, image)}
              />
            </ScrollArea>
            <Button
              fullWidth
              className={"sticky bottom-0"}
              size="xs"
              color="indigo"
              variant="light"
              onClick={() => {
                setCurrentSegment("brand_assets");

                setShowFileUploader(true);
              }}
            >
              Add New Image
            </Button>
          </>
        ) : (
          <Center>
            <NoImagesFound />
          </Center>
        )}
      </Popover.Dropdown>
    </Popover>
  );
};

const EmtptyStateList: React.FC<{}> = ({}) => {
  return (
    <Paper
      p="sm"
      shadow="xs"
      className="text-center"
      sx={{ border: "1px dotted #e9ecef" }}
    >
      <Group position="center" spacing="xs">
        <IconLayoutList size={20} color="gray" />
        <Text size="xs" color="gray">
          No items added
        </Text>
      </Group>
    </Paper>
  );
};
