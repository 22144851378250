import React, { useEffect, useState } from "react";
import { AppShellContainer } from "@containers/index";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
  Box,
  Button,
  Container,
  Group,
  LoadingOverlay,
  SegmentedControl,
} from "@mantine/core";

import { IconLinkPlus } from "@tabler/icons-react";
import RenderBlinks from "./RenderBlinks";
import CreateBlink from "./CreateBlink";
import { useBlinksStoreActions } from "@src/stores/blinks";
import { Segment } from "@src/types/modules/blinks";
import { Controls } from "@src/components/PagesControl";
import { useQueryParams, useWorkspaceId } from "@/src/hooks";

type THeader = {
  callback: () => void;
  activeSegment: Segment;
  onChangeSegment: (tab: Segment) => void;
};
interface Composition {
  Header: React.FC<THeader>;
}

type Props = {};

const BlinkModule: React.FC<Props> & Composition = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [visible, handlers] = useDisclosure(false);
  const currentWorkspaceId = useWorkspaceId();

  const [activeTab, setActiveTab] = useState<Segment>("all");
  const [sortBy, setSortBy] = useState<string>("last_created");
  const [order, setOrder] = useState<string>("desc");
  const [searchTerm, setSearchTerm] = useState<string>("");

  const fetchData = async (workspaceId: string) => {
    handlers.open(); // show loading overlay
    await useBlinksStoreActions()?.fetchAllBlinks(workspaceId);
    handlers.close();
  };

  useEffect(() => {
    currentWorkspaceId && fetchData(currentWorkspaceId);
  }, [currentWorkspaceId]);

  const toggleOpen = () => handlers.open();
  const toggleClose = () => handlers.close();

  const handleSortByChange = (value: string | null) => {
    setSortBy(value || "last_created");
  };

  const handleOrderChange = (value: string | null) => {
    setOrder(value || "asc");
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
  };

  const sortOptions = [
    { value: "last_created", label: "Last created" },
    { value: "last_updated", label: "Last updated" },
  ];

  const orderOptions = [
    { value: "asc", label: "Ascending" },
    { value: "desc", label: "Descending" },
  ];

  const queryParams = useQueryParams();

  useEffect(() => {
    if (queryParams["new"] === "true") {
      open();
    }
  }, []);

  return (
    <AppShellContainer>
      <LoadingOverlay
        visible={visible}
        overlayBlur={2}
        loaderProps={{ color: "dark" }}
      />
      <Container
        className="p-3"
        style={{
          maxWidth: 800,
          minHeight: 400,
          height: "100%",
          margin: "auto",
        }}
      >
        <BlinkModule.Header
          callback={open}
          activeSegment={activeTab}
          onChangeSegment={(tab) => setActiveTab(tab)}
        />
        <Controls
          sortOptions={sortOptions}
          orderOptions={orderOptions}
          searchPlaceholder="Search..."
          sortBy={sortBy}
          order={order}
          searchTerm={searchTerm}
          onSortByChange={handleSortByChange}
          onOrderChange={handleOrderChange}
          onSearchChange={handleSearchChange}
          visible={visible}
        />
        <Box my={20}>
          <RenderBlinks
            activeSegment={activeTab}
            sortBy={sortBy}
            order={order}
            searchTerm={searchTerm}
            open={open}
          />
        </Box>
      </Container>
      <CreateBlink
        opened={opened}
        close={close}
        toggleOpen={toggleOpen}
        toggleClose={toggleClose}
      />
    </AppShellContainer>
  );
};

const Header: React.FC<THeader> = ({
  callback,
  activeSegment,
  onChangeSegment,
}) => {
  const segmentControlWidthForResponsive = useMediaQuery(
    "(min-width: 56.25em)"
  );
  const buttonFullWidthForResponsive = useMediaQuery("(max-width: 28.125em)");
  return (
    <Group position="apart">
      <div
        style={{
          minWidth: segmentControlWidthForResponsive ? 300 : "fit-content",
        }}
      >
        <SegmentedControl
          fullWidth
          size="xs"
          value={activeSegment}
          onChange={onChangeSegment}
          sx={(theme) => {
            return {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[7]
                  : theme.colors.gray[1],
            };
          }}
          data={[
            { value: "all", label: "All Blinks" },
            { value: "active", label: "Active" },
            { value: "archived", label: "Archived" },
          ]}
        />
      </div>

      <Button
        size="xs"
        color="dark"
        leftIcon={<IconLinkPlus size="1rem" />}
        onClick={callback}
        fullWidth={buttonFullWidthForResponsive}
      >
        Create a new Blink
      </Button>
    </Group>
  );
};

BlinkModule.Header = Header;

export default BlinkModule;
