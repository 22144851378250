import { Widget } from "../../types";

export const YoutubeCard: Widget = {
  id: "",
  name: "Youtube Video",
  description: "Renders a Youtube video",
  meta: {
    type: "youtube_card",
    category: "components",
  },
  variant: "filled",
  baseClassName: "",
  properties: {
    styles: {},
    props: {
      youtubeUrl: {
        section: "customization",
        value: "https://www.youtube.com/watch?v=HpVOs5imUN0",
        type: "text",
        key: "youtubeUrl",
        name: "Youtube Video URL or Shorts URL",
      },
    },
  },
  control: {
    redirectTo: "",
    type: null,
  },
  layout: {
    currentLayout: "mobile",
    mobile: {
      top: 0,
      left: 0,
      width: 390,
      height: 300,
    },
  },
};
