import React from "react";
import { Button, MantineTheme, useMantineTheme, Text } from "@mantine/core";
import { IControl, Widget, WidgetProps } from "../widgets/types";
import { handleRedirection } from "@/src/helpers/widgets.helpers";
import {
  useEditorStore,
  useEditorStoreActions,
} from "@/src/stores/editorstore";

const ButtonComponent: React.FC<WidgetProps> = ({
  id,
  inheritedStyles,
  properties,
  control,
  ref,
}) => {
  const {
    label,
    color,
    disabled,
    borderRadius,
    radius,
    uppercase,
    backgroundColor,
    loaderPosition,
    loader,
    hoverBackgroundColor,
    variant,
    gradient,
    variantColor,
  } = properties.props;

  const [editedText, setEdtedText] = React.useState(label.value);
  const handleInputChange = (e: any) => {
    const newText = e.currentTarget.textContent;
    setEdtedText(newText);
  };

  const text = uppercase.value ? label.value.toUpperCase() : label.value;

  const isContentEditable = useEditorStore.use.mode() === "edit" || false;

  const conditionalStyles: Record<string, string> = {};

  if (borderRadius.value === "custom") {
    conditionalStyles.borderRadius = radius.value;
  }

  const customBgStyles = (theme: MantineTheme) => {
    if (variant.value === "filled") {
      return {
        backgroundColor: backgroundColor.value || "inherit",
        "&:not([data-disabled])": theme.fn.hover({
          backgroundColor: hoverBackgroundColor?.value || "inherit",
          color: theme.fn.darken(backgroundColor?.value, 0.2) || "inherit",
          borderColor: hoverBackgroundColor?.value
            ? "none"
            : theme.fn.darken(backgroundColor?.value, 0.8),
        }),
      };
    }

    return {};
  };

  const updateWidgetDefinition = (widgetId: string) => {
    const currentDefintion = useEditorStore
      .getState()
      .definitions.widgets.find((widget) => widget.id === widgetId) as
      | Widget
      | Record<string, any>;

    const newDefinition = {
      ...currentDefintion,
      properties: {
        ...currentDefintion.properties,
        props: {
          ...currentDefintion.properties.props,
          label: {
            ...currentDefintion.properties.props.label,
            value: editedText,
          },
        },
      },
    };

    useEditorStoreActions()?.updateWidgetDefinition(
      widgetId,
      newDefinition,
      "widgetDefinition"
    );
  };

  return (
    <Button
      ref={ref}
      className={`draggable-widget widget-${id}`}
      style={{ ...inheritedStyles, ...conditionalStyles }}
      gradient={{ from: gradient.value.from, to: gradient.value.to, deg: 45 }}
      styles={(theme) => ({
        root: {
          ...customBgStyles(theme),
          color: color.value || "inherit",
        },
        label: {
          width: "100%",
          display: "flex",
          justifyContent: "center",
        },

        leftIcon: {
          marginRight: theme.spacing.md,
        },
      })}
      variant={variant.value}
      onClick={handleRedirection.bind(null, control as IControl)}
      disabled={disabled.value}
      radius={borderRadius.value}
      loaderPosition={loaderPosition?.value || "center"}
      loading={loader?.value}
      color={variantColor?.value}
    >
      <Text
        autoFocus={true}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onInput={handleInputChange}
        contentEditable={isContentEditable && true}
        onBlur={() => {
          updateWidgetDefinition(id);
        }}
      >
        {label.value}
      </Text>
    </Button>
  );
};

export default ButtonComponent;
