import {
  Box,
  Button,
  Center,
  ColorPicker,
  ColorSwatch,
  Container,
  Grid,
  Group,
  Popover,
  ScrollArea,
  Select,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { BackgroundPicker } from "@src/components";
import { useEditorStore, useEditorStoreActions } from "@stores/editorstore";
import React, { useEffect, useState } from "react";

type TBgSelector = "bg-color" | "bg-image" | "bg-gradient" | "bg-gif";

type Props = {
  selector: TBgSelector;
  color?: string;
  image?: string;
  gradient?: string;
  gif?: string;
};

type PreviewProps = Partial<Props>;

interface Composition {
  BgImage: React.FC;
  BgColor: React.FC<{ color: string; onChange: (val: string) => void }>;
  RenderPreview: React.FC<
    {
      selector: TBgSelector;
      color: string;
      onChange: (val: string) => void;
    } & PreviewProps
  >;
}

const options = [
  { value: "bg-color", label: "Use Background Color" },
  { value: "bg-image", label: "Use Background Image" },
];

const BackgroundSelector: React.FC<{}> & Composition = ({}) => {
  const definitions = useEditorStore.use.definitions();

  const { setBackground } = useEditorStoreActions();

  const [selected, setSelected] = useState<TBgSelector>(
    () => definitions.background.type as TBgSelector
  );

  const handleSelect = (value: string) => {
    const selectedValue = value as TBgSelector;

    setSelected(selectedValue);
    setBackground("type", selectedValue);
  };

  const handleColorChange = (value: string) => {
    setBackground("backgroundColor", value);
  };

  useEffect(() => {
    if (!selected) {
      setSelected(definitions.background.type as TBgSelector);
    }
  }, [definitions.background.type]);

  return (
    <Box sx={{ width: "100%" }}>
      <Group spacing="lg">
        <Select
          size="xs"
          styles={(theme) => ({
            root: {
              maxWidth: "100%",
              width: "100%",
            },
            item: {
              // applies styles to selected item
              "&[data-selected]": {
                "&, &:hover": {
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[9]
                      : theme.colors.gray[3],
                  color:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[0]
                      : theme.colors.dark[9],
                },
              },
            },
            label: {
              fontSize: theme.fontSizes.sm,
              fontWeight: 500,
              marginBottom: 10,
            },
          })}
          value={selected}
          onChange={(value) => value && handleSelect(value)}
          data={options}
          label="Background"
          placeholder="Select background type"
          // withAsterisk
          withinPortal
        />

        <Box sx={{ width: "100%" }}>
          <BackgroundSelector.RenderPreview
            selector={selected}
            color={definitions.background.backgroundColor}
            onChange={handleColorChange}
          />
        </Box>
      </Group>
    </Box>
  );
};

const RenderPreview: React.FC<
  {
    selector: TBgSelector;
    color: string;
    onChange: (val: string) => void;
  } & PreviewProps
> = ({ selector, color, onChange }) => {
  switch (selector) {
    case "bg-color":
      return <BgColor color={color as string} onChange={onChange} />;
    case "bg-image":
      return <BgImage />;
    default:
      return <></>;
  }
};

const BgColor = ({
  color,
  onChange,
}: {
  color: string;
  onChange: (val: string) => void;
}) => {
  const theme = useMantineTheme();

  return (
    <Popover trapFocus position="bottom" withArrow shadow="md" withinPortal>
      <Popover.Target>
        <Grid>
          <Grid.Col span={6}>
            <Box
              sx={{
                padding: "5px 5px",
                width: "100%",
                height: "100%",
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[7]
                    : "#F9FAFB",
              }}
            >
              <ColorSwatch
                style={{ cursor: "pointer" }}
                color={color}
                radius={4}
                size={"100%"}
              ></ColorSwatch>
            </Box>
          </Grid.Col>
          <Grid.Col span={6}>
            <Box
              sx={{
                padding: "5px 5px",
                width: "100%",
                height: "100%",
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[7]
                    : "#F9FAFB",
              }}
            >
              <Text size={"xs"} color="dark">
                {color}
              </Text>
            </Box>
          </Grid.Col>
        </Grid>
      </Popover.Target>
      <Popover.Dropdown>
        <ColorPicker
          format="rgba"
          onChange={(value) => onChange(value)}
          swatchesPerRow={7}
          swatches={[
            "#25262b",
            "#868e96",
            "#fa5252",
            "#e64980",
            "#be4bdb",
            "#7950f2",
            "#4c6ef5",
            "#228be6",
            "#15aabf",
            "#12b886",
            "#40c057",
            "#82c91e",
            "#fab005",
            "#fd7e14",
          ]}
        />
      </Popover.Dropdown>
    </Popover>
  );
};

const BgImage = () => {
  return <BackgroundPicker />;
};

BackgroundSelector.BgImage = BgImage;
BackgroundSelector.BgColor = BgColor;
BackgroundSelector.RenderPreview = RenderPreview;
export default BackgroundSelector;
