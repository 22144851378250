import { SimpleGrid } from "@mantine/core";
import React from "react";
import TemplateCard from "./TemplateCard";
import { useAppStore } from "@/src/stores/appstore";

type Props = {};

const TemplateList = (props: Props) => {
  const templates = useAppStore.use.templates();

  return (
    <SimpleGrid cols={2} className="mt-2">
      {templates.map((template) => (
        <div key={template?.id}>
          <TemplateCard PageTemplate={template} />
        </div>
      ))}
    </SimpleGrid>
  );
};

export default TemplateList;
