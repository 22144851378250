import React, { useEffect, useLayoutEffect } from "react";
import { Box, Grid } from "@mantine/core";
import { useEditorStore, useEditorStoreActions } from "@stores/editorstore";
import { useDisclosure } from "@mantine/hooks";
import Header from "./Header";
import { useFetchAppData } from "./useFetchAppData";
import LeftSidebar from "./Leftsidebar";
import GridContainer from "./Canvas/GridContainer";
import Global from "@/components/magicui/globe";
import { VerticalIconList } from "@/src/components";

type Props = {};
const Pages: React.FC<Props> = (props: Props) => {
  //* this disclosure is used to open and close the widget manager
  const [opened, { open, close }] = useDisclosure(false);

  const currentMode = useEditorStore.use.mode();
  const { reset } = useEditorStoreActions();

  useFetchAppData();

  const isPreviewMode = currentMode === "preview";

  useEffect(() => {
    return reset();
  }, []);

  return (
    <Box
      className="editor-wrapper"
      sx={(theme) => {
        return {
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[9] : "#ffffff",
        };
      }}
    >
      <>
        {!isPreviewMode && <Header />}
        {isPreviewMode && <VerticalIconList classname="preview-menu-dock" />}

        <div>{isPreviewMode && <Global />}</div>

        <Box
          h="calc(100% - 60px)"
          sx={{
            position: `${isPreviewMode ? "absolute" : "fixed"}`,
            top: `${isPreviewMode ? "35px" : "60px"}`,
            left: "0",
          }}
        >
          <Grid className="editor-grid-container" gutter={0}>
            <Grid.Col span="content">
              {!isPreviewMode && <LeftSidebar />}
            </Grid.Col>
            <Grid.Col span="auto">
              <Box className="h-100">
                <GridContainer opened={opened} open={open} close={close} />
              </Box>
            </Grid.Col>
          </Grid>
        </Box>
      </>
      {/* )} */}
    </Box>
  );
};

export default Pages;
