import {
  Button,
  Text,
  ImageActionBanner,
  UserInfoBasic,
  LinkIndicator,
  InstagramCard,
  TwitterCard,
  YoutubeCard,
  Link,
  CorouselBasic,
  Icons,
  ProductCard,
  OverlayContainer,
  SocialIcons,
} from "./components";

export const allWidgets = [
  Button,
  Text,
  ImageActionBanner,
  UserInfoBasic,
  LinkIndicator,
  InstagramCard,
  TwitterCard,
  YoutubeCard,
  Link,
  CorouselBasic,
  Icons,
  ProductCard,
  OverlayContainer,
  SocialIcons,
];
