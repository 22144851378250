import { RenderIcon } from "@/src/modules/BlinkInBio/Editor/WidgetsUI/carousels/Icons";
import {
  Box,
  Center,
  Group,
  SegmentedControl,
  Text,
  Tooltip,
} from "@mantine/core";
import React from "react";

type option = {
  value: string;
  label: string;
};

type Props = {
  options: option[];
  callback: (value: string) => void;
  value: string;
  fullWidth?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  label?: string;
  icons?: Record<string, string>;
  renderWithIcons?: boolean;
  orientation?: "horizontal" | "vertical";
  onlyIcons?: boolean;
};

const renderOptionsWithIcons = (
  icons: Record<string, string>,
  useIcons: boolean,
  options: Array<{ label: string; value: string }>,
  onlyIcons?: boolean
) => {
  if (!useIcons) return options;

  if (onlyIcons) {
    return options.map((option) => {
      const iconName = icons[option.value];

      return {
        ...option,
        label: (
          <Center>
            <Tooltip label={option.label} color="indigo" withArrow withinPortal>
              <Box>
                <RenderIcon iconName={iconName} />
              </Box>
            </Tooltip>
          </Center>
        ),
      };
    });
  }

  return options.map((option) => {
    const iconName = icons[option.value];

    const labelForLink =
      option.label === "_blank"
        ? "New tab"
        : option.label === "_self"
        ? "Same tab"
        : option.label;

    return {
      ...option,
      label: (
        <Center>
          <RenderIcon iconName={iconName} />
          <Box ml={10}>{labelForLink}</Box>
        </Center>
      ),
    };
  });
};

const BasicSegmentedControl: React.FC<Props> = ({
  options,
  value,
  callback,
  fullWidth = false,
  size = "xs",
  label,
  icons = {},
  renderWithIcons = false,
  orientation = "horizontal",
  onlyIcons = false,
}) => {
  const dataOptions = renderOptionsWithIcons(
    icons,
    renderWithIcons,
    options,
    onlyIcons
  );

  return (
    <Group
      position="apart"
      className="mt-2"
      spacing={"xs"}
      style={{
        width: "100%",
      }}
    >
      {label && (
        <Text align="center" weight={500} size="xs">
          {label}
        </Text>
      )}

      <SegmentedControl
        orientation={orientation}
        color="dark"
        miw={230}
        fullWidth={fullWidth}
        size={size}
        value={value}
        sx={(theme) => {
          return {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[1],
          };
        }}
        data={dataOptions}
        onChange={callback}
      />
    </Group>
  );
};

export default BasicSegmentedControl;
