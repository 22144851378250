import React from "react";
import {
  Center,
  Container,
  Group,
  Paper,
  ScrollArea,
  SegmentedControl,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { getDeviceIcon, humanReadableCount } from "@src/utils";
import { Image } from "../BaseUI";
import MapIcon from "@assets/icons/map.svg";
import { Empty } from "@/src/modules/Blink/Details/Empty";
import { useViewportSize } from "@mantine/hooks";

interface AnalyticsEntry {
  Count: number;
  CreatedAt: string;
  UserAgent: {
    isMobile: boolean;
    browserName: string;
    os: string;
    platform: string;
  };
  GeoLocation: {
    countryName: string;
    cityName: string;
  };
}

type CombinedData = {
  name: string;
  value: number;
  percentage: string;
};

type Props = {
  data: AnalyticsEntry[];
  totalClicks: number;
};

type THeader = {
  activeSegment: SelectedSegment;
  setActiveSegment: React.Dispatch<React.SetStateAction<SelectedSegment>>;
};

type TItem = {
  name: string;
  value: any;
  substring: string;
  forLocation?: boolean;
};

type SelectedSegment = "browsers" | "devices" | "os";

interface Composition {
  Header: React.FC<THeader>;
  RenderItem: React.FC<TItem>;
  MainContainer: React.FC<{ children: React.ReactElement[] }>;
}

export const DeviceAnalytics: React.FC<Props> & Composition = ({
  data,
  totalClicks,
}) => {
  const [activeSegment, setActiveSegment] =
    React.useState<SelectedSegment>("browsers");

  const processedData = React.useMemo(() => {
    const devices: { [key: string]: number } = { Desktop: 0, Mobile: 0 };
    const browsers: { [key: string]: number } = {};
    const operatingSystems: { [key: string]: number } = {};

    data.forEach((entry) => {
      const deviceType = entry.UserAgent.isMobile ? "Mobile" : "Desktop";
      devices[deviceType] += entry.Count;
      browsers[entry.UserAgent.browserName] = entry.Count;
      operatingSystems[entry.UserAgent.os.toLocaleLowerCase()] =
        (operatingSystems[entry.UserAgent.os.toLocaleLowerCase()] || 0) +
        entry.Count;
    });

    const formatData = (dataObj: { [key: string]: number }): CombinedData[] =>
      Object.entries(dataObj).map(([name, value]) => ({
        name,
        value,
        percentage: ((value / totalClicks) * 100).toFixed(2) + "% Clicks",
      }));

    return {
      devices: formatData(devices),
      browsers: formatData(browsers),
      os: formatData(operatingSystems).sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    };
  }, [data, totalClicks]);

  return (
    <React.Fragment>
      <DeviceAnalytics.Header
        activeSegment={activeSegment}
        setActiveSegment={setActiveSegment}
      />

      {processedData[activeSegment].length === 0 && (
        <Center>
          <Empty />
        </Center>
      )}

      {processedData[activeSegment].length > 0 && (
        <DeviceAnalytics.MainContainer>
          {processedData[activeSegment].map((item) => (
            <DeviceAnalytics.RenderItem
              key={item.name}
              name={item.name}
              value={item.value}
              substring={item.percentage}
            />
          ))}
        </DeviceAnalytics.MainContainer>
      )}
    </React.Fragment>
  );
};

const MainContainer: React.FC<{ children: React.ReactElement[] }> = ({
  children,
}) => {
  return (
    <Container className="p-1">
      <Paper withBorder>
        <ScrollArea h={250}>{children}</ScrollArea>
      </Paper>
    </Container>
  );
};

const Header: React.FC<THeader> = ({ activeSegment, setActiveSegment }) => {
  const screenWidth = useViewportSize();
  return (
    <div className="mt-2">
      <SegmentedControl
        fullWidth={screenWidth.width > 400 ? true : false}
        size="xs"
        value={activeSegment}
        onChange={(tab) => setActiveSegment(tab as SelectedSegment)}
        sx={(theme) => {
          return {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[7]
                : theme.colors.gray[1],
          };
        }}
        data={[
          { value: "browsers", label: "Browsers" },
          { value: "devices", label: "Devices" },
          { value: "os", label: "OS" },
        ]}
      />
    </div>
  );
};

const RenderItem: React.FC<TItem> = ({
  name,
  value,
  substring,
  forLocation = false,
}) => {
  const deviceIcon = !forLocation
    ? getDeviceIcon(name?.toLowerCase())
    : MapIcon;

  return (
    <Paper>
      <UnstyledButton
        sx={(theme) => ({
          display: "block",
          width: "100%",
          padding: theme.spacing.md,
          color:
            theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

          "&:hover": {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        })}
      >
        <Group>
          <Image src={deviceIcon} handleDarkMode={forLocation} />

          <div style={{ flex: 1 }}>
            <Text size="sm" weight={500}>
              {name}
            </Text>

            <Text color="dimmed" size="xs">
              {substring}
            </Text>
          </div>

          <Text color="dimmed" size="xs">
            {humanReadableCount(value)} Clicks
          </Text>
        </Group>
      </UnstyledButton>
    </Paper>
  );
};

DeviceAnalytics.MainContainer = MainContainer;
DeviceAnalytics.Header = Header;
DeviceAnalytics.RenderItem = RenderItem;
