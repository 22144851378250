import {
  Avatar,
  Box,
  Center,
  Container,
  Divider,
  Group,
  Paper,
  Text,
  TextInput,
} from "@mantine/core";

import React from "react";
import { useAppStore } from "@/src/stores/appstore";
import { Socials } from "./Socials";
import { useViewportSize } from "@mantine/hooks";

export const Profile: React.FC = () => {
  const userData = useAppStore.use.userMetaData();
  const screenWidth = useViewportSize().width;

  return (
    <div>
      <Container
        style={{
          width: screenWidth > 768 ? 600 : "100%",
        }}
      >
        <Group
          position="left"
          spacing={4}
          align="center"
          style={{ maxWidth: 450 }}
        >
          <Text size={"lg"} fw={600}>
            Profile
          </Text>
          <Text size={"xs"} c="gray">
            This is a profile page. You can see and edit your personal
            information here.
          </Text>
        </Group>

        <Paper
          withBorder
          className="mt-2"
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[7]
                : theme.colors.gray[0],
          })}
        >
          <Header userName={userData.firstName} />
          <Container className="w-100  mt-2 my-2">
            <Center>
              <Box className="w-100">
                <Group position="apart">
                  <Text size={"md"} fw={500}>
                    Email
                  </Text>
                  <TextInput
                    placeholder="Email"
                    size="xs"
                    w={200}
                    value={userData.email}
                    disabled
                  />
                </Group>
              </Box>
            </Center>
            <Divider className="my-3" />
            <Center>
              <Box className="w-100">
                <Group position="apart">
                  <Text size={"md"} fw={500}>
                    First Name
                  </Text>
                  <TextInput
                    placeholder=""
                    size="xs"
                    w={200}
                    value={userData.firstName}
                  />
                </Group>
              </Box>
            </Center>
            <Divider className="my-3" />
            <Center>
              <Box className="w-100">
                <Group position="apart">
                  <Text size={"md"} fw={500}>
                    Last Name
                  </Text>
                  <TextInput
                    placeholder=""
                    size="xs"
                    w={200}
                    value={userData.lastName}
                  />
                </Group>
              </Box>
            </Center>
          </Container>
        </Paper>
      </Container>

      {/* <Container
        style={{ width: screenWidth > 768 ? 600 : "100%" }}
        className="mt-3"
      >
        <Socials />
      </Container> */}
    </div>
  );
};

const Header: React.FC<{ userName: string }> = ({ userName }) => {
  return (
    <Paper
      radius="sm"
      p="lg"
      className="w-100"
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[7]
            : theme.colors.gray[0],
        borderBottom: `1px solid ${
          theme.colorScheme === "dark"
            ? theme.colors.dark[4]
            : theme.colors.gray[3]
        }`,
      })}
    >
      <Avatar size={120} radius={120} mx="auto" />

      <Text ta="center" fz="lg" fw={500} variant={"text"}>
        Hi 👋🏻 {userName}
      </Text>
    </Paper>
  );
};
