import React, { useState } from "react";
import {
  Anchor,
  Box,
  Button,
  Center,
  Container,
  Dialog,
  Grid,
  Group,
  Overlay,
  Paper,
  PasswordInput,
  PinInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { Image } from "@src/components/BaseUI";
import Logo from "@assets/logo.svg";
import { PasswordComponent } from "@UIComponents/index";
import { AuthUserDetails } from "@src/types/modules/auth";
import { authenticationService } from "@services/auth.service";
import { redirect, redirectDocument, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { setCookie } from "@src/lib/cookie.lib";
import { useDisclosure } from "@mantine/hooks";
import { EmailService } from "@/src/services/email.service";
import { VerificationEmailPayload } from "@/src/services/types";

type Props = {};

const Register = (props: Props) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<AuthUserDetails>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [opened, { toggle, close }] = useDisclosure(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verfifictionError, setVerificationError] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [resendingCode, setResendingCode] = useState(false);

  const handlePasswordChange = (value: string) => {
    setUserData({ ...userData, password: value });
  };

  const handleEmailChange = (value: string) => {
    setUserData({ ...userData, email: value });
  };

  const handleNameChange = (value: string, type: string) => {
    if (type === "first") {
      setUserData({ ...userData, firstName: value });
    } else {
      setUserData({ ...userData, lastName: value });
    }
  };

  const resetState = () => {
    setUserData({
      email: "",
      password: "",
      firstName: "",
      lastName: "",
    });
  };

  const handleKeyEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleRegister();
    }
  };
  const handleRegister = () => {
    if (!userData.email || !userData.password) {
      return toast.error("Please fill in all fields");
    }

    setIsLoading(true);
    authenticationService
      .registerNewUser(userData)
      .then((res) => {
        if (res.statusCode === 201) {
          toast.success(res.message);
        }

        if (res.statusCode === 400) {
          toast.error(res.message);
          setTimeout(() => {
            close();
            window.location.href = res.Redirect as string;
          }, 1000);
        }

        if (res.statusCode === 404) {
          resetState();
        }
      })
      .finally(() => toggle())
      .catch((error) => {
        toast.error(error.message ?? "An error occurred");
        resetState();
      });
  };

  const handleOnChangeVerificationCode = (value: string) => {
    setVerificationError("");
    // the exepcted length of the verification code is 6
    if (value.length > 6) {
      setVerificationError("Verification code should be 6 characters long");
    }

    setVerificationCode(value);
  };

  const resendVerificationCode = async () => {
    setResendingCode(true);
    setVerificationCode("");
    setVerificationError("");
    if (!userData.email) {
      toast("Please try again later");
    }

    toast
      .promise(EmailService.resendVerificationCode(userData.email), {
        loading: "Resending verification code...",
        success: "Verification code sent successfully",
        error: "Error sending verification code",
      })
      .then(() => {
        setResendingCode(false);
      })
      .catch((error) => {
        toast.error(error.error);
        setResendingCode(false);
      });
  };

  const handleVerification = async () => {
    setIsVerifying(true);
    if (verificationCode.length !== 6) {
      setVerificationError("Verification code should be 6 characters long");
      return toast.error("Verification code should be 6 characters long");
    }

    try {
      const response: VerificationEmailPayload = await EmailService.verifyEmail(
        verificationCode
      );

      if (
        response.statusCode === 202 &&
        typeof response.Redirect === "string"
      ) {
        toast
          .promise(
            new Promise((resolve) => {
              setTimeout(() => {
                resolve("Redirecting...");
                setIsVerifying(false);
              }, 3000);
            }),
            {
              loading: "Verifying...",
              success: "Verified successfully",
              error: "Error redirecting...",
            }
          )
          .then(() => {
            setTimeout(() => {
              close();
              window.location.href = response.Redirect as string;
            }, 1000);
          });
      }
    } catch (error: any) {
      const errorMessage = error?.error || error?.data?.message;
      toast.error(errorMessage);
      setIsVerifying(false);
    }
  };

  const screenWidth: number = window.innerWidth;
  const isMobileView = screenWidth < 768;
  const determinePositions = () => {
    if (isMobileView) {
      return undefined;
    }
    return {
      top: "40%",
      left: "35%",
    };
  };
  const positionCalc = determinePositions();

  return (
    <div className="wait-list-page">
      <Toaster
        position="top-center"
        reverseOrder={true}
        toastOptions={{
          style: {
            background: "#25262b",
            color: "#fff",
          },
        }}
      />
      {opened && (
        <Overlay blur={10} center>
          <Dialog
            opened={true}
            withCloseButton
            onClose={() => {
              close();
              setIsLoading(false);
              resetState();
            }}
            size="lg"
            radius="md"
            shadow="md"
            position={positionCalc}
            sx={(theme) => ({
              backgroundColor: theme.colors.dark[9],
              color: "white",
            })}
          >
            <Text size="sm" mb="xs" weight={500}>
              Please enter the 6-digit code sent to your email
            </Text>
            <Group align="flex-start">
              <PinInput
                error={verfifictionError !== ""}
                length={6}
                oneTimeCode
                variant="filled"
                sx={(theme) => ({
                  "& input": {
                    color: "white",
                    backgroundColor: theme.colors.dark[9],
                    borderColor: theme.colors.dark[4],
                  },
                })}
                value={verificationCode}
                onChange={handleOnChangeVerificationCode}
              />

              <Button
                variant="filled"
                color="indigo"
                size="xs"
                onClick={handleVerification}
                loading={isVerifying}
                loaderPosition="center"
              >
                Verify code
              </Button>
            </Group>
            <Group position="right" className="mt-4">
              <Button
                variant="light"
                color="red"
                size="xs"
                loading={resendingCode}
                fullWidth
                onClick={resendVerificationCode}
              >
                Resend code
              </Button>
            </Group>
          </Dialog>
        </Overlay>
      )}

      <Grid>
        <Grid.Col
          xs={12}
          sm={12}
          md={3}
          lg={7}
          xl={7}
          className="p-0"
        ></Grid.Col>
        <Grid.Col
          xs={12}
          sm={12}
          md={9}
          lg={5}
          xl={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Container size={400} my={200}>
            <Paper
              shadow="md"
              p={30}
              radius="md"
              sx={(theme) => ({
                backgroundColor: theme.colors.dark[9],
              })}
            >
              {isMobileView && (
                <Center className="mb-3">
                  <Group position="left">
                    <Image
                      src={Logo}
                      alt="logo"
                      width={30}
                      height={30}
                      className="bridge-logo"
                    />
                    <Text
                      size="sm"
                      fw={600}
                      variant="gradient"
                      gradient={{ from: "indigo", to: "cyan", deg: 45 }}
                    >
                      b-link.co / bridging-your-links
                    </Text>
                  </Group>
                </Center>
              )}
              <Stack>
                <Box className="">
                  <Text c="white" fw={600} size="sm" ta="center" mt={5}>
                    Get started for free. No credit card required.
                  </Text>
                </Box>
                <Group grow>
                  <TextInput
                    labelProps={{ style: { color: "white" } }}
                    sx={(theme) => ({
                      "& input": {
                        color: "white",
                        backgroundColor: theme.colors.dark[9],
                        borderColor: theme.colors.dark[4],
                      },
                    })}
                    label="First Name"
                    placeholder="John"
                    required
                    onChange={(event) =>
                      handleNameChange(event.currentTarget.value, "first")
                    }
                  />
                  <TextInput
                    labelProps={{ style: { color: "white" } }}
                    sx={(theme) => ({
                      "& input": {
                        color: "white",
                        backgroundColor: theme.colors.dark[9],
                        borderColor: theme.colors.dark[4],
                      },
                    })}
                    label="Last Name"
                    placeholder="Doe"
                    required
                    onChange={(event) =>
                      handleNameChange(event.currentTarget.value, "last")
                    }
                  />
                </Group>

                <TextInput
                  labelProps={{ style: { color: "white" } }}
                  sx={(theme) => ({
                    "& input": {
                      color: "white",
                      backgroundColor: theme.colors.dark[9],
                      borderColor: theme.colors.dark[4],
                    },
                  })}
                  label="Email"
                  placeholder="you@mantine.dev"
                  required
                  onChange={(event) =>
                    handleEmailChange(event.currentTarget.value)
                  }
                />

                <PasswordComponent callback={handlePasswordChange} />
              </Stack>

              <Button
                disabled={opened}
                loading={isLoading}
                fullWidth
                mt="xl"
                variant="filled"
                color="indigo"
                onClick={handleRegister}
              >
                Create account
              </Button>
            </Paper>
            <Group position="left" className="mt-4">
              <Text c="white" size="sm">
                Already have an account?
              </Text>
              <Anchor
                fw={600}
                size="sm"
                component="button"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </Anchor>
            </Group>
          </Container>
        </Grid.Col>
        <Grid.Col span={1}></Grid.Col>
      </Grid>
    </div>
  );
};

export default Register;
