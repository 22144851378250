import React from "react";
import {
  Box,
  Center,
  Container,
  LoadingOverlay,
  Paper,
  SegmentedControl,
  Tooltip,
} from "@mantine/core";
import { IconDeviceDesktop, IconDeviceMobile } from "@tabler/icons-react";

import { useEditorStore, useEditorStoreActions } from "@src/stores/editorstore";
import { Dragable } from "./Dragable";
import DynamicImportComponent from "./DynamicImportComponent";

type Props = {
  isLoading: boolean;
};

interface Composition {
  MainContainer: React.FC<{ children: React.ReactNode }>;
  Header: React.FC;
  Layout: React.FC<{ children: React.ReactNode }>;
}

const Canvas: React.FC<Props> & Composition = ({ isLoading }) => {
  const { widgets, background } = useEditorStore.use.definitions();
  const { updateWidgetDefinition, setSelectedWidget } = useEditorStoreActions();

  const bgStyles = {
    backgroundColor: "#f9f9f9",
  };

  if (background.type === "bg-color") {
    // @ts-ignore
    bgStyles.backgroundColor = background.backgroundColor;
  }

  if (background.type === "bg-image") {
    // @ts-ignore
    bgStyles.backgroundImage = `url(${background.backgroundImage})`;
    // @ts-ignore
    bgStyles.backgroundSize = "cover";
    // @ts-ignore
    bgStyles.backgroundRepeat = "no-repeat";
  }

  const updateOnDragEnd = (componentId: string, top: number, left: number) => {
    const updateLayout = {
      layout: {
        mobile: {
          top,
          left,
        },
      },
    };

    updateWidgetDefinition(componentId, updateLayout, "layout");
  };

  const updateOnResizeEnd = (
    componentId: string,
    width: number,
    height: number
  ) => {
    updateWidgetDefinition(
      componentId,
      {
        layout: {
          mobile: {
            width,
            height,
          },
        },
      },
      "layout"
    );
  };

  const elementGuidelines = widgets.map((box) => `.widget-${box.id}`);
  const currentMode = useEditorStore.use.mode();

  const publishedStyles: Record<string, string> = {
    height: "800px",
    width: "400px",
  };

  return (
    <Center className="mt-">
      <Paper
        shadow={`${currentMode === "edit" ? "xs" : "none"}`}
        className={"grid-container"}
        style={{ ...publishedStyles, ...bgStyles }}
        sx={(theme) => ({
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
        })}
        onClick={(e) => {
          e.preventDefault();
          setSelectedWidget(null);
        }}
      >
        <LoadingOverlay visible={isLoading} overlayBlur={2} />

        {widgets.map((box) => {
          const layoutPos = box.layout.mobile;
          return (
            <Dragable
              key={box.id}
              id={box.id}
              mode={currentMode}
              elementGuidelines={elementGuidelines}
              updateOnDragEnd={updateOnDragEnd}
              updateOnResizeEnd={updateOnResizeEnd}
              styles={layoutPos}
            >
              <DynamicImportComponent
                type={box.meta.type as string}
                key={box.id}
                id={box.id}
                inheritedStyles={{ height: "100%", width: "100%" }}
                properties={box.properties}
                control={box.control}
                variant={box.variant}
              />
            </Dragable>
          );
        })}
      </Paper>
    </Center>
  );
};

const MainContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const currentMode = useEditorStore.use.mode();

  return (
    <>
      <Container>{children}</Container>
    </>
  );
};

const CanvasLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <Paper style={{ height: "100%" }}>{children}</Paper>;
};

const Header = () => {
  return (
    <Center className="mt-2">
      <Box>
        <Tooltip label="Desktop view is not available yet">
          <SegmentedControl
            size="xm"
            defaultValue="mobile"
            data={[
              {
                value: "mobile",
                label: (
                  <Center>
                    <IconDeviceMobile size="1rem" />
                    <Box ml={10}>Mobile</Box>
                  </Center>
                ),
              },
              {
                value: "desktop",
                disabled: true,
                label: (
                  <Center>
                    <IconDeviceDesktop size="1rem" />

                    <Box ml={10}>Desktop</Box>
                  </Center>
                ),
              },
            ]}
          />
        </Tooltip>
      </Box>
    </Center>
  );
};

Canvas.MainContainer = MainContainer;
Canvas.Layout = CanvasLayout;
Canvas.Header = Header;

export default Canvas;
