import React, { useEffect, useState } from "react";
import { Group, Box, Badge, Button, Container } from "@mantine/core";
import CustomisedUi from "@assets/scheduledui.png";
import { useQueryParams } from "@/src/hooks";
import { IconX } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import Whitelabel from "@UIComponents/Whitelabel";

export const NotificationHeader: React.FC<{ blinkURL: string }> = ({
  blinkURL,
}) => {
  //remove http(s) from the URL
  blinkURL = blinkURL.replace(/(^\w+:|^)\/\//, "");

  return (
    <Group>
      Scheduled Link:{" "}
      <Badge
        color="indigo"
        variant="filled"
        size="xs"
        radius={"xs"}
        style={{
          textTransform: "none",
        }}
      >
        {blinkURL}
      </Badge>
    </Group>
  );
};

function ScheduledUI() {
  const queryParams = useQueryParams();
  const [timeRemaining, setTimeRemaining] = useState("");

  const triggerNotificationBanner = (message: string) => {
    notifications.show({
      id: "scheduled-ui-notification",
      withCloseButton: false,
      autoClose: false,
      title: (
        <NotificationHeader blinkURL={queryParams["shortURL"] as string} />
      ),
      message: message,
      color: "red",
      icon: <IconX />,
      className: "my-notification-class",
      loading: false,
    });
  };

  const activeFrom = queryParams["activeFrom"] as string;

  useEffect(() => {
    if (!activeFrom) return; // Avoid running the effect if activeFrom is missing
    const now = new Date();
    const activeFromTime = new Date(activeFrom);
    const timeDifference = activeFromTime.getTime() - now.getTime();

    if (timeDifference > 0) {
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      const remainingTimeString = `${hours} hours, ${minutes} minutes, and ${seconds} seconds`;

      setTimeRemaining(remainingTimeString);
      triggerNotificationBanner(
        `This link will be active in ${remainingTimeString}`
      );
    } else {
      setTimeRemaining("The link is now active!");
      triggerNotificationBanner("The link is now active!");
    }
  }, []);

  return (
    <Box
      className="bg-black scheduled-container"
      style={{
        height: "100%",
        width: "inherit",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        backgroundImage: `url(${CustomisedUi})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        cursor: "pointer",
      }}
    >
      <Whitelabel isMobileLayoutActive={true} />
    </Box>
  );
}

export default ScheduledUI;
