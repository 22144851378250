import { useEditorStore } from "@src/stores/editorstore";
import _ from "lodash";
import React from "react";
import Moveable from "react-moveable";

type Props = {
  mode: "edit" | "preview" | "published";
  id: string;
  children: React.ReactNode;
  elementGuidelines: string[];
  updateOnDragEnd: (componentId: string, top: number, left: number) => void;
  updateOnResizeEnd: (
    componentId: string,
    width: number,
    height: number
  ) => void;
  styles: Record<string, any>;
};

export const Dragable: React.FC<Props> = ({
  mode,
  id,
  children,
  elementGuidelines,
  updateOnDragEnd,
  updateOnResizeEnd,
  styles,
}) => {
  const targetRef = React.useRef<HTMLDivElement>(null);
  const moveableRef = React.useRef<Moveable>(null);

  const [firstDrag, setFirstDrag] = React.useState(false);
  const [isDragging, setIsDragging] = React.useState(false);
  const [isResizing, setIsResizing] = React.useState(false);

  let _styles: React.CSSProperties = {
    width: `${styles.width}px`,
    height: `${styles.height}px`,
  };

  if ((!firstDrag && !_.isEmpty(styles)) || !isDragging || !isResizing) {
    _styles.transform = `translate(${styles.left}px, ${styles.top}px)`;
  }

  const widgetId = useEditorStore.use.selectedWidget();

  return (
    <div
      className={`component-dnd-widget ${widgetId === id && "active"} ${mode}`}
    >
      <div
        style={{
          position: "absolute",
          ..._styles,
        }}
        ref={targetRef}
      >
        {children}
      </div>
      <Moveable
        ref={moveableRef}
        target={targetRef}
        draggable={mode === "edit"}
        resizable={
          mode === "edit"
            ? {
                edge: ["nw", "n", "ne", "w", "e", "sw", "s", "se"],
                renderDirections: ["n", "w", "e", "s"],
              }
            : false
        }
        // rotatable={true}
        origin={false}
        throttleDrag={1}
        edgeDraggable={false}
        startDragRotate={0}
        throttleDragRotate={0}
        scalable={false}
        keepRatio={false}
        throttleScale={0}
        snappable={true}
        useAccuratePosition={true}
        elementGuidelines={elementGuidelines}
        padding={{
          left: 5,
          right: 5,
          top: 5,
          bottom: 5,
        }}
        // linePadding={30}
        // controlPadding={20}
        snapDirections={{
          top: true,
          left: true,
          bottom: true,
          right: true,
          center: true,
          middle: true,
        }}
        elementSnapDirections={{
          top: true,
          left: true,
          bottom: true,
          right: true,

          center: true,
          middle: true,
        }}
        maxSnapElementGuidelineDistance={200}
        maxSnapElementGapDistance={100}
        snapThreshold={9}
        bounds={{ left: 0, top: 0, right: 0, bottom: 0, position: "css" }}
        verticalGuidelines={[50, 150, 250, 450, 550]}
        horizontalGuidelines={[0, 100, 200, 400, 500]}
        // rotationPosition={"bottom-right"}
        linePadding={5}
        onDragStart={() => {
          _styles = {};
          setFirstDrag(true);
          setIsDragging(true);
        }}
        onDrag={(e) => {
          e.target.style.transform = e?.transform;
        }}
        onDragEnd={(e) => {
          const dimensions = e.lastEvent?.translate;
          updateOnDragEnd(id, dimensions?.[1], dimensions?.[0]);
          setIsDragging(false);
        }}
        onResizeStart={() => {
          setIsResizing(true);
        }}
        onResize={(e) => {
          e.target.style.width = `${e?.width}px`;
          e.target.style.height = `${e?.height}px`;
          e.target.style.transform = e?.drag?.transform;

          e.target.style.cssText += "position: absolute; top: 0; left: 0;";
        }}
        onRotate={(e) => {
          e.target.style.transform = e?.afterTransform;
        }}
        onResizeEnd={(e) => {
          const width = e.lastEvent?.width;
          const height = e.lastEvent?.height;
          updateOnResizeEnd(id, width, height);
          setIsResizing(false);
        }}
      />
    </div>
  );
};
