import React from "react";
import {
  Button,
  Group,
  Image,
  Indicator,
  Paper,
  Popover,
  Text,
} from "@mantine/core";
import { IControl, WidgetProps } from "../../widgets/types";
import { handleRedirection } from "@/src/helpers/widgets.helpers";

interface Props extends WidgetProps {
  variant: "filled" | "light" | "outline" | "default" | "subtle" | "custom";
}

const LinkIndicator: React.FC<Props> = ({
  id,
  inheritedStyles,
  properties,
  control,
  ref,
}) => {
  const {
    color,
    showSubtitle,
    imageSrc,
    title,
    subtitle,
    actionButton,
    actionButtonColor,
    titleColorSelector,
    titleGradient,
    titleColor,
    actionButtonVariant,
  } = properties.props;

  return (
    <Group style={{ ...inheritedStyles }} ref={ref} position="center">
      <Popover
        width={300}
        styles={{
          dropdown: {
            padding: "2px",
            borderRadius: 10,
          },
        }}
        // position="left"
      >
        <Popover.Target>
          <Indicator
            processing
            withBorder
            size={16}
            position="middle-center"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            sx={{
              ".mantine-Indicator-processing": {
                width: 24,
                height: 24,
              },
            }}
            color={color.value}
          >
            <span></span>
          </Indicator>
        </Popover.Target>
        <Popover.Dropdown>
          <Paper className="p-1" radius={"md"}>
            <Group position="apart">
              <Group>
                <Image
                  radius="xl"
                  src={imageSrc.value}
                  width={40}
                  height={40}
                />
                <div>
                  <Text
                    size={"sm"}
                    variant={
                      titleColorSelector.value === "gradient"
                        ? "gradient"
                        : "text"
                    }
                    gradient={{
                      from: titleGradient.value.from,
                      to: titleGradient.value.to,
                      deg: 45,
                    }}
                    color={titleColor.value}
                  >
                    {title.value}
                  </Text>
                  {showSubtitle.value && (
                    <Text size={"xs"} color={"gray"}>
                      {subtitle.value}
                    </Text>
                  )}
                </div>
              </Group>
              <Button
                variant={actionButtonVariant.value}
                color={actionButtonColor?.value}
                compact
                size="xs"
                radius={"sm"}
                onClick={handleRedirection.bind(null, control as IControl)}
              >
                {actionButton.value}
              </Button>
            </Group>
          </Paper>
        </Popover.Dropdown>
      </Popover>
    </Group>
  );
};

export default LinkIndicator;
