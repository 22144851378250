import React, { useEffect, useRef } from "react";
import { useInView, useMotionValue, useSpring } from "framer-motion";
import { Text } from "@mantine/core";

export default function AnimatedCounter({
  startValue = 0,
  endValue,
  duration = 1,
  direction = "up",
  className = "",
}: {
  startValue?: number;
  endValue: number;
  duration?: number; // duration in seconds
  direction?: "up" | "down";
  className?: string;
}) {
  const ref = useRef<HTMLSpanElement>(null);
  const motionValue = useMotionValue(
    direction === "down" ? endValue : startValue
  );
  const springValue = useSpring(motionValue, {
    damping: 60,
    stiffness: 100,
  });
  const isInView = useInView(ref, { once: true, margin: "0px" });

  useEffect(() => {
    if (isInView) {
      setTimeout(() => {
        motionValue.set(direction === "down" ? startValue : endValue);
      }, duration * 1000);
    }
  }, [motionValue, isInView, duration, endValue, direction, startValue]);

  useEffect(() => {
    const unsubscribe = springValue.on("change", (latest) => {
      if (ref.current) {
        ref.current.textContent = Intl.NumberFormat("en-US").format(
          Math.round(latest) // Ensure the displayed value is rounded
        );
      }
    });

    return () => unsubscribe(); // Clean up the subscription on component unmount
  }, [springValue]);

  return (
    <Text ta="center" size={"lg"} fw={700}>
      <span ref={ref}>{Intl.NumberFormat("en-US").format(startValue)}</span>
    </Text>
  );
}
