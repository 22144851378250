import { AnimatedRefreshIcon } from "@/src/components";
import { useEditorStore } from "@/src/stores/editorstore";
import { Button } from "@mantine/core";
import React from "react";

const AnimatedSaving: React.FC<{}> = () => {
  const lastSavedTime = useEditorStore.use.lastChangesSavedAt() as Date;
  const isSaving = useEditorStore.use.isSaving();

  return (
    <Button
      size="xs"
      variant="light"
      color="indigo"
      leftIcon={
        <AnimatedRefreshIcon
          size={18}
          isLoading={isSaving}
          tip={
            lastSavedTime
              ? `⏱️ Last saved at ${lastSavedTime.toLocaleTimeString()}`
              : "Nothing saved yet"
          }
        />
      }
    >
      {isSaving ? "Saving" : lastSavedTime ? "Saved" : "Nothing saved yet"}
    </Button>
  );
};

export default AnimatedSaving;
