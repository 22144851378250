import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { authenticationService } from "@services/auth.service";
import { appConfig } from "../app.config";
import { authHeader } from "./requestHeaders";

interface InternalAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

const apiClient = axios.create({
  baseURL: appConfig.apiURL,
  withCredentials: true,
  headers: authHeader(),
});

let isRefreshing = false;

const logoutUser = async () => {
  console.log("Logging out user due to authentication failure");
  await authenticationService.logout();
  window.location.href = "/login";
};

apiClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: unknown) => {
    console.log("Interceptor caught an error:", error);

    if (!axios.isAxiosError(error)) {
      console.error("Non-Axios error caught in interceptor:", error);
      return Promise.reject(error);
    }

    const originalRequest = error.config as InternalAxiosRequestConfig;

    console.log(
      "Error response:",
      error.response?.status,
      error.response?.data
    );

    // Check if the error is due to an expired token
    if (
      error.response?.status === 401 &&
      originalRequest &&
      !originalRequest._retry
    ) {
      console.log("401 error detected, attempting refresh");

      if (isRefreshing) {
        console.log("Refresh already in progress, rejecting request");
        return Promise.reject(error);
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const refreshResponse = await apiClient.get("/authentication/refresh", {
          withCredentials: true,
        });
        console.log(
          "Refresh response:",
          refreshResponse.status,
          refreshResponse.data
        );

        if (refreshResponse.status === 200) {
          const user = refreshResponse.data;
          authenticationService.updateCurrentSession({
            isAuthenticated: true,
            user,
          });

          return apiClient(originalRequest);
        } else {
          throw new Error(
            `Unexpected refresh response: ${refreshResponse.status}`
          );
        }
      } catch (refreshError) {
        console.error("Refresh attempt failed:", refreshError);
        await logoutUser();
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    // If it's a 401 or 403 error that's not handled by the refresh logic
    if (error.response?.status === 401 || error.response?.status === 403) {
      console.log("Authentication failed, initiating logout");
      await logoutUser();
    }

    console.log("Interceptor is rejecting the error");
    return Promise.reject(error);
  }
);

export default apiClient;
