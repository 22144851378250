import { Box } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { BlinkDirectives } from "@src/types/modules/blinks";
import React from "react";

type Props = {
  callback: (type: keyof BlinkDirectives, data: any) => void;
};

export const LinkExpiration: React.FC<Props> = ({ callback }) => {
  const handleChange = (value: Date) => {
    callback("expiration", value);
  };

  return (
    <Box className="mt-2">
      <DateTimePicker
        label="Expiration date"
        clearable
        valueFormat="DD MMM YYYY hh:mm A"
        placeholder="Pick date and time"
        style={{ width: "100%" }}
        defaultValue={new Date()}
        mx="auto"
        size="xs"
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
        onChange={handleChange}
      />
    </Box>
  );
};
