import React, { forwardRef, useEffect, useState } from "react";
import {
  Divider,
  Group,
  Navbar,
  UnstyledButton,
  Avatar,
  Text,
  Menu,
  rem,
  Stack,
  NavLink,
  Button,
  useMantineTheme,
  Switch,
  useMantineColorScheme,
  Paper,
  AppShell,
  Container,
  Box,
  Anchor,
  Breadcrumbs,
  Kbd,
  Badge,
  TextInput,
  Grid,
  Table,
  Checkbox,
  Center,
  Pagination,
  ActionIcon,
  Header,
  MediaQuery,
  Burger,
} from "@mantine/core";
import { Image } from "@UIComponents/BaseUI";
import Logo from "@assets/logo.svg";
import {
  Icon,
  IconArrowLeft,
  IconArrowNarrowDown,
  IconArrowRight,
  IconAutomaticGearbox,
  IconBriefcase,
  IconBrush,
  IconChecklist,
  IconChevronRight,
  IconClick,
  IconDeviceLaptop,
  IconLink,
  IconLogout,
  IconMoonStars,
  IconPhone,
  IconPlugConnected,
  IconSearch,
  IconSun,
  IconSwitchHorizontal,
  IconUserEdit,
  IconUserScan,
  IconUsersGroup,
  IconWorldCode,
} from "@tabler/icons-react";
import { useAppStore, useAppStoreActions } from "@src/stores/appstore";
import { useNavigate } from "react-router-dom";
import { useWorkspaceId } from "@src/hooks";
import UserTable from "./table/table";
import { Profile } from "./Profile";
import { useViewportSize } from "@mantine/hooks";

type Props = {};
interface UserButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  image: string;
  name: string;
  email: string;
  icon?: React.ReactNode;
}

interface Composition {
  UserButton: React.FC<UserButtonProps>;
  ThemeToggle: React.FC;
  RenderNavlink: React.FC<{
    navlink: string;
    isActive: boolean;
    callback: (val: string) => void;
  }>;
}

const NavLinks: Record<string, string> = {
  profile: "Profile",
  user_groups: "User Groups",
  groups: "Groups",
  blinks: "Blinks",
  pages: "Pages",
  utm_builders: "UTM Builders",
  white_labelling: "White labelling",
  theme_options: "Theme options",
  actions: "Actions",
  connectors: "Connectors",
  subscription: "Subscription",
};

const icons: Record<string, JSX.Element> = {
  profile: <IconUserScan size="1rem" stroke={2} />,
  user_groups: <IconUsersGroup size="1rem" stroke={2} />,
  groups: <IconBriefcase size="1rem" stroke={2} />,
  blinks: <IconLink size="1rem" stroke={2} />,
  pages: <IconPhone size="1rem" stroke={2} />,
  utm_builders: <IconClick size="1rem" stroke={2} />,
  white_labelling: <IconWorldCode size="1rem" stroke={2} />,
  theme_options: <IconBrush size="1rem" stroke={2} />,
  actions: <IconAutomaticGearbox size="1rem" stroke={2} />,
  connectors: <IconPlugConnected size="1rem" stroke={2} />,
  subscription: <IconChecklist size="1rem" stroke={2} />,
};

const SettingsModule: React.FC<Props> & Composition = (props) => {
  const navigate = useNavigate();
  const workspaceId = useWorkspaceId();

  const [activeTab, setActiveTab] = React.useState("user-groups");

  useEffect(() => {
    useAppStoreActions()?.loadSocialLinks();
  }, []);

  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();

  const screenWidth = useViewportSize().width;

  return (
    <AppShell
      padding="md"
      navbarOffsetBreakpoint="sm"
      navbar={
        <Navbar
          className="settings-module-navbar-shell"
          p="xs"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 200, lg: 300 }}
        >
          <Navbar.Section>
            <Group position="apart">
              <ActionIcon onClick={() => navigate(`/${workspaceId}/dashboard`)}>
                <Image
                  src={Logo}
                  width={30}
                  height={30}
                  alt="Logo"
                  style={{
                    cursor: "pointer",
                  }}
                />
              </ActionIcon>

              <SettingsModule.ThemeToggle />
            </Group>
          </Navbar.Section>
          <Divider my="sm" />
          <Navbar.Section grow mt="md">
            <Stack>
              {Object.keys(NavLinks).map((navlink, index) => (
                <RenderNavlink
                  key={index}
                  navlink={navlink}
                  isActive={activeTab === NavLinks[navlink]}
                  callback={(val) => setActiveTab(val)}
                />
              ))}
            </Stack>
          </Navbar.Section>

          {/* <Navbar.Section>
            <Group position="center">
              <Button
                fullWidth
                variant="gradient"
                gradient={{ from: "teal", to: "blue", deg: 60 }}
              >
                ✨ Upgrade plan
              </Button>
              <Menu position="top-end" offset={5} shadow="md" width={200}>
                <Menu.Target>
                  <SettingsModule.UserButton
                    image="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=255&q=80"
                    name={userMetaData.firstName + " " + userMetaData.lastName}
                    email={userMetaData.email}
                  />
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item icon={<IconDeviceLaptop size={rem(14)} />}>
                    All workspaces
                  </Menu.Item>
                  <Menu.Item icon={<IconUserEdit size={rem(14)} />}>
                    Profile
                  </Menu.Item>
                  <Menu.Item icon={<IconSwitchHorizontal size={rem(14)} />}>
                    Switch Workspace
                  </Menu.Item>

                  <Menu.Item color="red" icon={<IconLogout size={rem(14)} />}>
                    Logout
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Navbar.Section> */}
        </Navbar>
      }
      header={
        <Header
          height={screenWidth >= 768 ? { base: 0 } : { base: 50, md: 70 }}
          p={screenWidth >= 768 ? 0 : "md"}
        >
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
          </div>
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <Container
        style={{
          maxWidth: "100%",
          margin: "0 auto",
          padding: screenWidth >= 768 ? "auto 1rem" : 0,
        }}
      >
        <Container
          className="w-100"
          style={{ padding: screenWidth >= 768 ? "auto 1rem" : 0 }}
        >
          <Profile />
        </Container>
      </Container>
    </AppShell>
  );
};

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ image, name, email, icon, ...others }: UserButtonProps, ref) => (
    <UnstyledButton
      ref={ref}
      sx={(theme) => ({
        display: "block",
        width: "100%",
        padding: theme.spacing.md,
        color:
          theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

        "&:hover": {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
      {...others}
    >
      <Group>
        <Avatar src={image} radius="xl" />

        <div style={{ flex: 1 }}>
          <Text size="sm" weight={500}>
            {name}
          </Text>

          <Text color="dimmed" size="xs">
            {email}
          </Text>
        </div>

        {icon || <IconChevronRight size="1rem" />}
      </Group>
    </UnstyledButton>
  )
);

const ToggleTheme = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();

  return (
    <Group position="center">
      <Switch
        onChange={() => toggleColorScheme()}
        size="md"
        color={colorScheme === "dark" ? "gray" : "dark"}
        onLabel={
          <IconSun size="1rem" stroke={2.5} color={theme.colors.yellow[4]} />
        }
        offLabel={
          <IconMoonStars
            size="1rem"
            stroke={2.5}
            color={theme.colors.dark[2]}
          />
        }
      />
    </Group>
  );
};

const items = [
  { title: "Settings", href: "#" },
  { title: "User groups", href: "#" },
].map((item, index) => (
  <Anchor
    href={item.href}
    key={index}
    sx={(theme) => ({
      color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
      fontFamily: "inherit",
      fontSize: rem(12),
      "&:hover": {
        color:
          theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
      },
    })}
  >
    {item.title}
  </Anchor>
));

const RenderNavlink: React.FC<{
  navlink: string;
  isActive: boolean;
  callback: (val: string) => void;
}> = ({ navlink, isActive, callback }) => {
  const enabled = navlink === "profile";

  return (
    <NavLink
      disabled={!enabled}
      variant="light"
      color="indigo"
      style={{ borderRadius: 6 }}
      active={enabled}
      label={NavLinks[navlink]}
      icon={icons[navlink]}
      onClick={() => callback(NavLinks[navlink])}
    />
  );
};

SettingsModule.UserButton = UserButton;
SettingsModule.ThemeToggle = ToggleTheme;
SettingsModule.RenderNavlink = RenderNavlink;

export default SettingsModule;
