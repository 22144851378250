import { ComingSoon } from "@/src/components/Substitutes";
import {
  useEditorStore,
  useEditorStoreActions,
} from "@/src/stores/editorstore";
import {
  ActionIcon,
  Badge,
  Button,
  Group,
  Menu,
  Modal,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconCopyPlusFilled,
  IconDotsVertical,
  IconEdit,
  IconGrid4x4,
  IconInfoSquareRoundedFilled,
  IconLayoutSidebarRightExpandFilled,
  IconLockOff,
  IconLockOpen2,
  IconLockSquareRoundedFilled,
  IconTextCaption,
  IconTrashFilled,
} from "@tabler/icons-react";
import { LockOpen } from "lucide-react";
import React from "react";

type Props = {
  componentId: string;
};

export const ConfigHandler: React.FC<Props> = ({ componentId }) => {
  const {
    setSelectedWidget,
    lockWidget,
    unlockWidget,
    removeWidget,
    duplicateWidget,
  } = useEditorStoreActions();

  const isGridLocked = useEditorStore.use.lockGrid();
  const isEditableWidget = useEditorStore.use.editableWidget();

  const isLocked = useEditorStore((state) =>
    state.lockedWidgets.includes(componentId)
  );

  const [opened, { toggle, close }] = useDisclosure(false);

  const component = useEditorStore.use
    .definitions()
    .widgets.find((widget) => widget.id === componentId);

  const [name, setName] = React.useState(component?.name);

  const [isLoading, setIsLoading] = React.useState(false);

  const handleSave = () => {
    setIsLoading(true);
    useEditorStoreActions().renameWidget(componentId, name);
    setIsLoading(false);
    close();
  };

  return (
    <div>
      <Group>
        <Tooltip
          label={
            isLocked ? "Unlock widge to move" : "Lock widget to prevent moving"
          }
          multiline
          width={150}
          position="left"
          color="indigo"
          transitionProps={{ transition: "slide-down", duration: 300 }}
        >
          <ActionIcon
            disabled={isGridLocked}
            onClick={() => {
              isLocked ? unlockWidget(componentId) : lockWidget(componentId);
            }}
            color="dark"
            sx={(theme) => ({
              "&:hover": {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[6]
                    : theme.colors.gray[1],
              },
            })}
          >
            {isLocked ? (
              <IconLockSquareRoundedFilled size="1.15rem" />
            ) : (
              <IconLockOff size="1.15rem" />
            )}
          </ActionIcon>
        </Tooltip>

        {isEditableWidget === componentId ? (
          <Badge size="xs" variant="filled" color="teal" radius={"sm"}>
            edit mode
          </Badge>
        ) : (
          <>
            <Tooltip
              label="Duplicate widget"
              position="left"
              color="indigo"
              transitionProps={{ transition: "slide-down", duration: 300 }}
            >
              <ActionIcon onClick={() => duplicateWidget(componentId)}>
                <IconCopyPlusFilled size="1.15rem" color="#748ffc" />
              </ActionIcon>
            </Tooltip>

            <Tooltip
              label="⚠️ Delete widget"
              position="left"
              color="#F25C6D"
              transitionProps={{ transition: "slide-down", duration: 300 }}
            >
              <ActionIcon color="red" onClick={() => removeWidget(componentId)}>
                <IconTrashFilled size="1rem" />
              </ActionIcon>
            </Tooltip>
            <Tooltip
              label="Press enter or double click to edit"
              position="top"
              color="indigo"
              transitionProps={{ transition: "slide-down", duration: 300 }}
            >
              <ActionIcon color="orange">
                <IconInfoSquareRoundedFilled size="1rem" />
              </ActionIcon>
            </Tooltip>
          </>
        )}
      </Group>

      <Modal opened={opened} onClose={close} centered>
        <Text size="sm" mb="xs" weight={500}>
          Rename widget
        </Text>

        <Group align="flex-end">
          <TextInput
            size="xs"
            placeholder=""
            sx={{ flex: 1 }}
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            data-autofocus
          />
          <Button
            color="dark"
            variant="filled"
            size="xs"
            onClick={handleSave}
            loading={isLoading}
          >
            Save
          </Button>
        </Group>
      </Modal>
    </div>
  );
};
