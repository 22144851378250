import React from "react";
import { Header as MantineHeader } from "@mantine/core";

type Props = {
  children: React.ReactNode;
  height?: number;
  className?: string;
};

export const Header: React.FC<Props> = ({
  children,
  height = 40,
  className,
}) => {
  return (
    <MantineHeader height={height} p="xs" className={className}>
      {children}
    </MantineHeader>
  );
};
