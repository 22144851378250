import { authenticationService } from "../services/auth.service";

export const authHeader = () => {
  const { isAuthenticated, user } = authenticationService.currentSessionValue();
  const token = sessionStorage.getItem("sessions_id") || "";

  const url = window.location.href;

  const workspace_id = url.split("/")[3];

  return {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "blink-workspace-id": workspace_id,
    Authorization: token,
  };
};

export const fileAuthHeader = () => {
  const workspaceId = localStorage.getItem("workspaceId") || "";

  return {
    "blink-workspace-id": workspaceId,
    "Access-Control-Allow-Origin": "*",
  };
};
