import React, { useState } from "react";

import {
  IconGauge,
  IconFingerprint,
  IconActivity,
  IconChevronRight,
  IconCheckbox,
  IconUser,
} from "@tabler/icons-react";
import { Box, NavLink } from "@mantine/core";

const data = [
  { icon: IconGauge, label: "Dashboard" },
  {
    icon: IconFingerprint,
    label: "Security",
    rightSection: <IconChevronRight size="1rem" stroke={1.5} />,
    children: [
      { icon: IconCheckbox, label: "Fingerprint 1" },
      { icon: IconUser, label: "Fingerprint 2" },
    ],
  },
  { icon: IconActivity, label: "Activity" },
];

function FolderMenu() {
  const [active, setActive] = useState(0);

  const items = data.map((item, index) => (
    <NavLink
      key={item.label}
      active={index === active}
      label={item.label}
      //   description={item.description}
      rightSection={item.rightSection}
      icon={<item.icon size="1rem" stroke={1.5} />}
      onClick={() => setActive(index)}
      color="gray"
      variant="subtle"
      className="folder-nav-link"
      sx={(theme) => ({
        "&:hover": {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[6]
              : theme.colors.gray[2],
        },
      })}
    >
      {item.children &&
        item.children.map((child) => (
          <NavLink
            key={child.label}
            label={child.label}
            icon={<child.icon size="1rem" stroke={1.5} />}
            color="gray"
            variant="subtle"
            className="folder-nav-link"
            sx={(theme) => ({
              "&:hover": {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[6]
                    : theme.colors.gray[2],
              },
            })}
          />
        ))}
    </NavLink>
  ));

  return (
    <Box
      sx={(theme) => ({
        textAlign: "center",
        padding: 0,
      })}
      w={220}
    >
      {items}
    </Box>
  );
}

export default FolderMenu;
