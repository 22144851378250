import { Widget } from "../../types";

export const SocialIcons: Widget = {
  id: "",
  name: "Social Icons",
  description: "Render Social Icons",
  meta: {
    type: "social_icons",
    category: "components",
  },
  variant: "filled",
  baseClassName: "",
  properties: {
    styles: {},
    props: {
      icon: {
        section: "customization",
        value: "instagram",
        type: "socialicon-selector",
        name: "Social Icon",
        key: "icon",
      },

      size: {
        section: "customization",
        value: "xl",
        type: "segmented-control",
        name: "Size",
        key: "size",
        options: ["xs", "sm", "md", "lg", "xl"],
      },
    },
  },
  control: {
    redirectTo: "",
    type: "blink",
  },
  layout: {
    currentLayout: "mobile",
    mobile: {
      top: 0,
      left: 0,
      width: 44,
      height: 44,
    },
  },
};
