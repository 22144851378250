import React from "react";
import AppnameBlinkUrl from "./AppnameBlinkUrl";
import Logo from "./Logo";
import { Box } from "@mantine/core";

interface Props {}

const Index: React.FC<Props> = () => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Logo />
      <AppnameBlinkUrl />
    </Box>
  );
};
export default Index;
