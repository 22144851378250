import { workspaceService } from "@/src/services/workspace.service";
import { create, createSelectors } from "../utils";
import { State } from "./types";
import { brandAssetsService } from "@/src/services/brand_asset.service";

const initialState: State = {
  workspaceId: "",
  workspaceName: "",
  blinkCount: 0,
  appsCount: 0,
  licenceType: "freemium",
  clickCount: 0,
  workspaceUsers: [],
  quickAccess: {
    pages: [],
    blinks: [],
  },
  brandAssets: [],

  actions: null,
};

const useBaseWorkspace = create<State>()((set, get) => ({
  ...initialState,
  actions: {
    reset: () => {
      set(initialState);
    },

    setWorkspaceData: (data: Partial<State>) => {
      set({ ...get(), ...data });
    },

    setWorkspaceUsers: (users) => {
      set({ ...get(), workspaceUsers: users });
    },

    loadQuickAccess: (
      pages: Record<string, string | number>[],
      blinks: Record<string, string | number>[]
    ) => {
      if (!pages || !blinks) return;
      set({
        quickAccess: {
          pages: pages.map((page) => ({
            id: page.id as string,
            name: page.name as string,
          })),
          blinks: blinks.map((blink) => ({
            id: blink.id as string,
            name: blink.name as string,
          })),
        },
      });
    },

    addToQuickAccess: async (
      workspaceId: string,
      entityId: string,
      entityName: string,
      type: "blinks" | "pages"
    ) => {
      if (!workspaceId) return new Promise<void>((_, reject) => reject());
      if (entityId.length === 0 || entityName.length === 0 || !type) return;
      try {
        await workspaceService.addToQuickAccess(workspaceId, {
          [type]: [{ id: entityId, name: entityName }],
        });

        return new Promise<void>((resolve) => {
          resolve();
        });
      } catch (error) {
        console.error("Error adding to quick access", error);
        return new Promise<void>((_, reject) => {
          reject();
        });
      }
    },

    removeFromQuickAccess: async (
      workspaceId: string,
      type: "blinks" | "pages",
      entityId: string
    ) => {
      if (!workspaceId) return new Promise<void>((_, reject) => reject());
      if (entityId.length === 0 || !type) return;
      const existingQuickAccess = get().quickAccess;
      const newQuickAccess = {
        ...existingQuickAccess,
        [type]: existingQuickAccess[type].filter(
          (entity) => entity.id !== entityId
        ),
      };

      try {
        await workspaceService.updateQuickAccess(workspaceId, newQuickAccess);
        return new Promise<void>((resolve) => {
          set({ quickAccess: newQuickAccess });
          resolve();
        });
      } catch (error) {
        console.error("Error removing from quick access", error);
        return new Promise<void>((_, reject) => {
          reject();
        });
      }
    },

    getBrandAssets: async (workspaceId: string) => {
      if (!workspaceId) return;
      try {
        const brandAssets =
          await brandAssetsService.getAllBrandAssetsManagersOfWorkspace(
            workspaceId
          );

        set({ brandAssets });

        return new Promise<void>((resolve) => {
          resolve();
        });
      } catch (error) {
        console.error("Error getting brand assets", error);
        return new Promise<void>((_, reject) => {
          reject();
        });
      }
    },
  },
}));

export const useWorkspace = createSelectors(useBaseWorkspace);
export const useWorkspaceActions = () => useWorkspace.getState().actions;
export const useWorkspaceState = () => useWorkspace.getState();
