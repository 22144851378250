import React from "react";

import {
  Modal,
  Button,
  Group,
  Box,
  Input,
  Stack,
  Select,
  useMantineTheme,
  Loader,
  Text,
} from "@mantine/core";
import { IconSlash, IconTextResize } from "@tabler/icons-react";
import { PageLayoutSelector } from "./PageLayoutSelector";
import { appPageService } from "@src/services/apps.service";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useEditorStoreActions } from "@src/stores/editorstore";
import { validateShortUrlHandle } from "@/src/lib/utils";
import { appConfig } from "@/src/app.config";
import { useAppStore } from "@/src/stores/appstore";

type Props = {
  children?: React.ReactNode;
  opened: boolean;
  onClose: () => void;
  centered?: boolean;
};

const CreateModal: React.FC<Props> = ({
  children,
  opened,
  onClose,
  centered = false,
}) => {
  const Title = () => {
    return (
      <Box>
        <Text
          variant="gradient"
          gradient={{ from: "indigo", to: "cyan", deg: 45 }}
          sx={{ fontFamily: "Greycliff CF, sans-serif" }}
          ta="left"
          fz="md"
          fw={700}
        >
          Create a new page
        </Text>
        <Text size="xs" color="dimmed">
          Easily design and publish your own landing pages and link in bio
          pages.
        </Text>
      </Box>
    );
  };

  return (
    <>
      <Modal
        size={"md"}
        centered={true}
        opened={opened}
        onClose={onClose}
        withCloseButton={false}
        title={<Title />}
        padding={"xs"}
      >
        <ModalBody onClose={onClose} />
      </Modal>

      {children}
    </>
  );
};

type ErrorPageInput = {
  name: string | null;
  handle: string | null;
};

const ModalBody: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const theme = useMantineTheme().colorScheme;
  const subdomain = useAppStore.use.subdomain();

  const defaultDomain =
    appConfig.environment === "production"
      ? `https://${subdomain}.b-link.co`
      : `http://${subdomain}.localhost:8080`;

  const initalData = {
    name: "",
    blinkDomain: defaultDomain,
    handle: "",
    pageLayout: "dnd-builder",
    isPublic: false,
  };

  const [pageData, setPageData] = React.useState(initalData);
  const [loading, setLoading] = React.useState(false);

  const [errorDetails, setErrorDetails] = React.useState<ErrorPageInput>({
    name: null,
    handle: null,
  });

  const navigate = useNavigate();

  const onCancel = () => {
    setLoading(false);
    setPageData(initalData);
    onClose();
  };

  const validatePageName = (name: string) => {
    return name.length > 3;
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageData({ ...pageData, name: e.target.value });
  };

  const handleHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { error, valid } = validateShortUrlHandle(e.target.value);
    if (!valid) {
      setErrorDetails((prev) => ({ ...prev, handle: error }));
    } else {
      setErrorDetails((prev) => ({ ...prev, handle: null }));
    }

    setPageData({ ...pageData, handle: e.target.value });
  };

  const onSubmit = () => {
    //Todo: Validate the data: domain, handle and name.
    setLoading(true);

    const isValidName = validatePageName(pageData.name);
    const isValidHandle = validateShortUrlHandle(pageData.handle);

    if (!isValidName) {
      toast.error("Name should be atleast 3 characters long");
      setLoading(false);
      return;
    }

    if (!isValidHandle) {
      toast.error(
        "Handle should start with a letter and contain only letters, numbers, hyphens and underscores"
      );
      setLoading(false);
      return;
    }

    toast
      .promise(appPageService.create(pageData), {
        loading: "Creating your page...",
        success: `${pageData.name} has been saved successfully!`,
        error: `Failed to create ${pageData.name}!`,
      })
      .then((res) => {
        if (res?.statusCode === 201) {
          onCancel();

          useEditorStoreActions().setEditorState({
            id: res.data.appId,
            currentPageId: res.data.pageId,
          });
          navigate(`${res.data.appId}/edit`);
        }
      })
      .catch(({ error }) => {
        console.error(error);
        setLoading(false);
        toast.error(error);
      });
  };

  const RenderLoader = ({ isLoading }: { isLoading: boolean }) => {
    if (isLoading) {
      return <Loader size="xs" color="indigo" />;
    }

    return null;
  };

  return (
    <Box mx="auto">
      <Stack spacing={"xs"}>
        <Input.Wrapper id="input-blink-name" withAsterisk label="Name">
          <Input
            error={errorDetails.name}
            size="xs"
            id="input-blink-name"
            placeholder="Your Page Name"
            value={pageData.name}
            onChange={handleNameChange}
          />
        </Input.Wrapper>

        <Group grow>
          <Select
            value={pageData.blinkDomain}
            data={[defaultDomain]}
            placeholder="Select a short link"
            label="Short link"
            variant="filled"
            withAsterisk
            size="xs"
            onChange={(value) =>
              value && setPageData({ ...pageData, blinkDomain: value })
            }
          />

          <Input.Wrapper
            size="xs"
            id="input-blink-handle"
            withAsterisk
            label="Handle"
            error={errorDetails.handle}
          >
            <Input
              size="xs"
              id="input-blink-handle"
              placeholder="yourback-halves"
              rightSection={<RenderLoader isLoading={loading} />}
              value={pageData.handle}
              onChange={(e) => {
                setErrorDetails((prev) => ({ ...prev, handle: null }));
                handleHandleChange(e);
              }}
              icon={<IconSlash size={"1rem"} />}
            />
          </Input.Wrapper>
        </Group>

        <PageLayoutSelector
          value={pageData.pageLayout}
          onChange={(value: string) =>
            setPageData((prev) => ({ ...prev, pageLayout: value }))
          }
        />

        {/* <Checkbox
          color="dark"
          className="mt-2"
          checked={pageData.isPublic}
          onChange={(e) =>
            setPageData({ ...pageData, isPublic: e.target.checked })
          }
          label={
            <>
              Make this blink public?{" "}
              <Anchor href="https://mantine.dev" target="_blank" color="indigo">
                Learn more
              </Anchor>
            </>
          }
        /> */}
      </Stack>
      <Group grow className="mt-3">
        <Button size="xs" variant="light" color="red" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={
            loading || (errorDetails.handle || errorDetails.name) !== null
          }
          loading={loading}
          size="xs"
          variant={theme === "dark" ? "white" : "filled"}
          color="dark"
          onClick={onSubmit}
          leftIcon={<IconTextResize size={"1rem"} />}
        >
          Create
        </Button>
      </Group>
    </Box>
  );
};

export default CreateModal;
