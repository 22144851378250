import React from "react";
import { Avatar as MantineAvatar, Tooltip } from "@mantine/core";

interface Props {
  src: string | null | undefined;
  alt: string;
  radius?: "xl" | "lg" | "md" | "sm" | "xs";
  color?: string;
  label?: string;
}

interface Composition {
  Group: React.FC<AvatarGroupProps>;
}

export const Avatar: React.FC<Props> & Composition = ({
  src,
  alt,
  radius = "xl",
  color = "gray",
  label,
}) => {
  const imgSrc = !src ? null : src;

  const RendeerWithToolTip = ({
    children,
  }: {
    children: React.ReactElement<
      unknown,
      string | React.JSXElementConstructor<unknown>
    >;
  }) => {
    if (!label) return children;

    return (
      <Tooltip withArrow withinPortal label={label}>
        {children}
      </Tooltip>
    );
  };

  return (
    <RendeerWithToolTip>
      <MantineAvatar
        size={32}
        src={imgSrc}
        alt={alt}
        radius={radius}
        color={color}
        className="omt-avatar"
      />
    </RendeerWithToolTip>
  );
};

interface AvatarGroupProps {
  avatarGroup: Props[];
}

export const AvatarGroup: React.FC<AvatarGroupProps> = ({ avatarGroup }) => {
  return (
    <Tooltip.Group openDelay={300} closeDelay={100}>
      <MantineAvatar.Group spacing="xl">
        {avatarGroup.map(
          (avatar, index) =>
            index < 4 && (
              <Avatar
                key={index}
                src={avatar.src}
                alt={avatar.alt}
                label={avatar.label}
              />
            )
        )}

        {avatarGroup.length > 4 && (
          <Tooltip
            withinPortal
            withArrow
            label={avatarGroup.map(
              (avatar, index) =>
                index > 3 && <span key={index}> {avatar.label} </span>
            )}
          >
            <MantineAvatar radius="xl">{`+${
              avatarGroup.length - 4
            }`}</MantineAvatar>
          </Tooltip>
        )}
      </MantineAvatar.Group>
    </Tooltip.Group>
  );
};

Avatar.Group = AvatarGroup;
