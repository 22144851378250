import React from "react";
import { MultiSelect } from "@mantine/core";

type Props = {};

const FontSelector = (props: Props) => {
  return (
    <MultiSelect
      styles={{
        root: {
          maxWidth: "280px",
          width: "100%",
        },
      }}
      data={[
        "Open Sans (Semi-bold)",
        "Helvetica",
        "Times New Roman",
        "Courier New",
      ]}
      label="Font Family"
      placeholder="Select font family"
      maxSelectedValues={1}
      withAsterisk
    />
  );
};

export default FontSelector;
