import React, { useEffect, useRef, useState } from "react";
import {
  ActionIcon,
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Center,
  Container,
  Grid,
  Group,
  Kbd,
  Menu,
  Paper,
  SegmentedControl,
  Stack,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useMediaQuery, useViewportSize } from "@mantine/hooks";
import { AppShellContainer } from "@containers/index";
import {
  IconAlertCircle,
  IconAppWindowFilled,
  IconCopyPlusFilled,
  IconDotsVertical,
  IconPinFilled,
  IconRocket,
  IconTextResize,
  IconTrash,
} from "@tabler/icons-react";
import { Image } from "@UIComponents/BaseUI";
import CreateModal from "./CreateModal";
import FolderSidebar from "./FolderSidebar";
import { useQueryParams, useWorkspaceId } from "@src/hooks";
import { appPageService } from "@src/services/apps.service";
import { useNavigate } from "react-router-dom";

import toast from "react-hot-toast";
import EmptyPageSVG from "@assets/empty-states/page.svg";
import { workspaceService } from "@/src/services/workspace.service";
import { useWorkspaceActions } from "@/src/stores/workspace";
import { TruncatedContent } from "@/src/components/BaseUI/TruncatedContent";
type Props = {};

interface Composition {
  Header: React.FC<{
    value: string;
    callback: (value: string) => void;
    open: () => void;
    close: () => void;
    opened: boolean;
    desktopScreen: boolean;
  }>;
  FolderSidebar: React.FC;
  Container: React.FC<{ children: React.ReactNode }>;
}

const BlinkInBioModule: React.FC<Props> & Composition = (props) => {
  const currentWorkspaceId = useWorkspaceId();
  const [allApps, setAllApps] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentWorkspaceId) {
      setLoading(true);
      workspaceService
        .getAllApps(currentWorkspaceId)
        .then((res) => {
          setAllApps(res);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, []);

  const openAppInEditor = (appId: string) => {
    navigate(`${appId}/edit`);
  };

  const handleLaunchApp = async (appId: string, shortUrl: string) => {
    const { published } = await appPageService.validatebBeforePublishing(appId);

    if (!published) {
      toast("Please make sure you have at least once published", {
        icon: "🚨",
      });
      return;
    }

    window.open(shortUrl, "_blank");
  };

  const [pagesStatus, setPagesStatus] = useState("all");
  const [filtereAppList, setFilteredAppList] =
    useState<Record<string, any>[]>(allApps);

  useEffect(() => {
    if (pagesStatus === "published") {
      setFilteredAppList(allApps.filter((app) => app.published === true));
    } else if (pagesStatus === "draft") {
      setFilteredAppList(allApps.filter((app) => app.published === false));
    } else {
      setFilteredAppList(allApps);
    }
  }, [pagesStatus, allApps]);

  const [opened, { open, close }] = useDisclosure(false);

  const desktopScreen = useMediaQuery("(min-width: 74em)");

  const queryParams = useQueryParams();

  useEffect(() => {
    if (queryParams["new"] === "true") {
      open();
    }
  }, []);

  return (
    <AppShellContainer showLoader={loading}>
      <div className="p-2 d-flex h-100">
        <Grid
          style={{
            width: "100%",
          }}
        >
          <Container
            className="w-100 p-0"
            style={{
              minHeight: 400,
              height: "100%",
              margin: "auto",
            }}
          >
            <BlinkInBioModule.Container>
              <BlinkInBioModule.Header
                value={pagesStatus}
                callback={(value) => setPagesStatus(value)}
                open={open}
                close={close}
                opened={opened}
                desktopScreen={desktopScreen}
              />
              {filtereAppList.length === 0 && (
                <Box
                  style={{
                    overflowY: "auto",
                    height: "calc(100vh - 100px)",
                  }}
                >
                  <Paper
                    p="md"
                    className="mt-3"
                    sx={(theme) => ({
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "transparent",
                      border: "2px dashed",
                      borderColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[4]
                          : theme.colors.gray[3],
                    })}
                  >
                    <Center>
                      <Stack>
                        <Center>
                          <Image
                            src={EmptyPageSVG}
                            alt="Empty Page"
                            width={200}
                            height={200}
                          />
                        </Center>

                        <Text size="md" align="center" color="gray" fw={600}>
                          {`You don't have any ${
                            pagesStatus === "published" ? "published" : ""
                          } ${
                            pagesStatus === "draft" ? "draft" : ""
                          } pages yet!`}
                        </Text>
                        {!desktopScreen && (
                          <Alert
                            icon={<IconAlertCircle size="1rem" />}
                            title="Use a Larger Screen for Better Experience"
                            color="red"
                          >
                            You're currently using a mobile device. For the best
                            experience in creating or editing pages, please use
                            a device with a larger screen.
                          </Alert>
                        )}
                        <Text size="xs" align="center">
                          Click below to get started 🎉
                        </Text>

                        <Button
                          leftIcon={<IconAppWindowFilled size={"1rem"} />}
                          onClick={open}
                          variant="outline"
                          color="indigo"
                          size="sm"
                          radius={"sm"}
                          disabled={!desktopScreen}
                        >
                          Create a new Page
                        </Button>
                      </Stack>
                    </Center>
                  </Paper>
                </Box>
              )}

              <Group className="mt-3">
                <Grid sx={{ width: "100%", marginLeft: 0, marginRight: 0 }}>
                  {!desktopScreen && (
                    <Alert
                      icon={<IconAlertCircle size="1rem" />}
                      title="Use a Larger Screen for Better Experience"
                      color="red"
                    >
                      You're currently using a mobile device. For the best
                      experience in creating or editing pages, please use a
                      device with a larger screen.
                    </Alert>
                  )}
                  {filtereAppList.length > 0 &&
                    filtereAppList.map((app) => {
                      return (
                        <Grid.Col xs={12} sm={12} md={12} lg={4} xl={4}>
                          <PageCard
                            key={app.id}
                            app={app}
                            setAllApps={setAllApps}
                            handleLaunchApp={handleLaunchApp}
                            openAppInEditor={openAppInEditor}
                            desktopScreen={desktopScreen}
                          />
                        </Grid.Col>
                      );
                    })}
                </Grid>
              </Group>
            </BlinkInBioModule.Container>
          </Container>
        </Grid>
      </div>
    </AppShellContainer>
  );
};

BlinkInBioModule.Header = ({
  value,
  callback,
  open,
  close,
  opened,
  desktopScreen,
}: {
  value: string;
  callback: (value: string) => void;
  open: () => void;
  close: () => void;
  opened: boolean;
  desktopScreen: boolean;
}) => {
  const theme = useMantineTheme();
  const segmentControlWidthForResponsive = useMediaQuery(
    "(min-width: 57.25em)"
  );
  const buttonFullWidthForResponsive = useMediaQuery("(max-width: 29.125em)");

  return (
    <Group position="apart">
      <div
        style={{
          minWidth: segmentControlWidthForResponsive ? 300 : "fit-content",
        }}
      >
        <SegmentedControl
          fullWidth
          size="xs"
          sx={(theme) => {
            return {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[7]
                  : theme.colors.gray[1],
            };
          }}
          data={[
            { value: "all", label: "All pages" },
            { value: "published", label: "Published" },
            { value: "draft", label: "Draft" },
          ]}
          value={value}
          onChange={(value) => callback(value)}
        />
      </div>

      <CreateModal opened={opened} onClose={close} centered={false}>
        <Button
          leftIcon={<IconAppWindowFilled size={"1rem"} />}
          onClick={open}
          variant="filled"
          color={theme.colorScheme === "dark" ? "gray" : "dark"}
          size="xs"
          fullWidth={buttonFullWidthForResponsive}
          disabled={!desktopScreen}
        >
          Create a new Page
        </Button>
      </CreateModal>
    </Group>
  );
};

BlinkInBioModule.Container = ({ children }) => {
  return (
    <Container
      className="p-3 dashboard-container"
      sx={{
        width: "100%",
        maxWidth: 1200,
        minHeigth: 400,
        height: "100%",
        margin: "auto",
      }}
    >
      {children}
    </Container>
  );
};

const PageCard: React.FC<{
  app: Record<string, any>;
  handleLaunchApp: (appId: string, shortUrl: string) => void;
  setAllApps: React.Dispatch<React.SetStateAction<Record<string, any>[]>>;
  openAppInEditor: (appId: string) => void;
  desktopScreen?: boolean;
}> = ({ app, handleLaunchApp, setAllApps, openAppInEditor, desktopScreen }) => {
  const theme = useMantineTheme();
  const workspaceId = useWorkspaceId();

  const handleAddingToQuickAccess = () => {
    if (!workspaceId) return toast.error("Something went wrong");

    useWorkspaceActions()
      ?.addToQuickAccess(workspaceId, app.id, app.name, "pages")
      .then(() => toast.success("Added to quick access"))
      .catch(() => toast.error("Failed to add to quick access"));
  };

  return (
    <Card
      radius={"md"}
      style={{
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[7]
            : theme.colors.gray[1],

        minWidth: "100%",
        maxWidth: "100%",
        height: "162px",
        overflow: "visible",
      }}
    >
      <Card.Section className="p-2">
        <Group position="apart">
          <Avatar radius={"md"} size={"md"}>
            {app.name[0]?.toUpperCase()}
          </Avatar>

          <Menu shadow="md" width={150}>
            <Menu.Target>
              <ActionIcon color="dark" variant="transparent">
                <IconDotsVertical size={"1rem"} color="gray" />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Tooltip
                label={
                  app.published
                    ? "Launch the page"
                    : "Please make sure you have at least once published"
                }
                color="indigo"
                position={"right"}
              >
                <Menu.Item
                  icon={<IconRocket size={14} />}
                  onClick={() => {
                    return handleLaunchApp(
                      app.id,
                      `${app.blinkDomain}/${app.blinkSignature}`
                    );
                  }}
                >
                  Launch
                </Menu.Item>
              </Tooltip>
              <Menu.Item icon={<IconCopyPlusFilled size={14} />} disabled>
                Clone page
              </Menu.Item>
              <Tooltip
                label="Adds to quick access"
                color="indigo"
                position="right"
              >
                <Menu.Item
                  icon={<IconPinFilled size={14} />}
                  onClick={handleAddingToQuickAccess}
                >
                  Pin page
                </Menu.Item>
              </Tooltip>

              <Menu.Divider />

              <Menu.Item
                color="red"
                icon={<IconTrash size={14} />}
                onClick={() => {
                  appPageService
                    .delete(app.id)
                    .then(() => {
                      setAllApps((prev) => prev.filter((a) => a.id !== app.id));

                      toast.success("Page deleted successfully");
                    })
                    .catch((err) => {
                      toast.error("Failed to delete page");
                      console.log(err);
                    });
                }}
              >
                Delete page
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Card.Section>

      <Group position="apart">
        <div style={{ maxWidth: "50%", width: "50%" }}>
          <TruncatedContent content={app.name} type="text" showSymbol={false} />
        </div>
        <Badge
          variant={app.published ? "filled" : "light"}
          color={app.published ? "teal" : "gray"}
          radius={"sm"}
          size="sm"
        >
          {app.published ? "Published" : "Draft"}
        </Badge>
      </Group>
      <TruncatedContent
        content={`${app.blinkDomain}/${app.blinkSignature}`}
        type="kbd"
        showSymbol={false}
      />
      <Group position={"apart"} mt="md">
        <Button
          leftIcon={<IconTextResize size={"1rem"} />}
          onClick={() => openAppInEditor(app.id)}
          fullWidth
          radius="sm"
          size="xs"
          variant="light"
          color={"indigo"}
          disabled={!desktopScreen}
        >
          Open page in Page Builder
        </Button>
      </Group>
    </Card>
  );
};

BlinkInBioModule.FolderSidebar = FolderSidebar;

export default BlinkInBioModule;
