import React, { useEffect } from "react";
import { ActionIcon } from "@mantine/core";
import * as IconsElements from "@tabler/icons-react";
import { IControl, WidgetProps } from "../widgets/types";
import { handleRedirection } from "@/src/helpers/widgets.helpers";

interface IconProperties {
  variant?: { value: "filled" | "outline" | "light" };
  color?: { value: string };
  gradient?: { value: { from: string; to: string; deg?: number } };
  borderRadius?: { value: string | number };
  size?: { value: "xs" | "sm" | "md" | "lg" | "xl" };
  icon?: { value: keyof typeof IconsElements };
  withBorder?: { value: boolean };
}

interface SafeIconRendererProps {
  iconName: keyof typeof IconsElements;
  iconSize: string;
}

const SafeIconRenderer: React.FC<SafeIconRendererProps> = ({
  iconName,
  iconSize,
}) => {
  const IconComponent = IconsElements[iconName] as React.ElementType;

  // Check if the IconComponent is a valid React component
  if (!IconComponent) {
    console.error("Invalid icon component:", iconName);
    return null;
  }

  return <IconComponent size={iconSize} />;
};

const Icons: React.FC<WidgetProps> = ({ properties, control, id }) => {
  const { variant, color, gradient, borderRadius, size, withBorder } =
    properties.props as IconProperties;
  const icon = properties?.props?.icon?.value ?? "IconBrandInstagram";

  const Size = {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1.125rem",
    lg: "1.625rem",
    xl: "2.125rem",
  } as const;

  return (
    <ActionIcon
      variant={variant?.value}
      color={color?.value}
      gradient={{
        from: gradient?.value?.from || "",
        to: gradient?.value?.to || "",
        deg: gradient?.value?.deg ?? 45,
      }}
      radius={borderRadius?.value}
      size={size?.value}
      onClick={handleRedirection.bind(null, control as IControl)}
      style={{
        border: withBorder?.value ? "1px solid" : "none",
      }}
    >
      <SafeIconRenderer
        iconName={icon}
        iconSize={Size[size?.value as keyof typeof Size]}
      />
    </ActionIcon>
  );
};

export default Icons;
