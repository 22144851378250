import BtnIcon from "./components/buttonIcon.svg";
import TextIcon from "./components/textIcon.svg";
import Card from "./components/cards.svg";
import ImageBanner from "./components/imageBanner.svg";
import UserInfo from "./components/userInfo.svg";
import InstagramLogo from "./components/instagram_icon.svg";
import TwitterLogo from "./components/twitter_icon.svg";
import YoutubeLogo from "./components/youtube_icon.svg";
import Link from "./components/link.svg";
import Corousel from "./components/corousel.svg";
import Icons from "./components/iconswidget.svg";
import OverlayIcon from "./components/overlay.svg";
import Socials from "./components/socials.svg";

const widgetIcons = [
  {
    type: "button",
    icon: BtnIcon,
  },
  {
    type: "text",
    icon: TextIcon,
  },

  {
    type: "basic_card",
    icon: Card,
  },
  {
    type: "image_action_banner",
    icon: ImageBanner,
  },
  {
    type: "user_info_basic",
    icon: UserInfo,
  },
  {
    type: "link_indicator",
    icon: UserInfo,
  },
  {
    type: "instagram_card",
    icon: InstagramLogo,
  },
  {
    type: "twitter_card",
    icon: TwitterLogo,
  },
  {
    type: "youtube_card",
    icon: YoutubeLogo,
  },
  {
    type: "link",
    icon: Link,
  },
  {
    type: "carousel_basic",
    icon: Corousel,
  },
  {
    type: "icons",
    icon: Icons,
  },
  {
    type: "product_card",
    icon: Card,
  },
  {
    type: "overlay",
    icon: OverlayIcon,
  },
  {
    type: "social_icons",
    icon: Socials,
  },
];

export { widgetIcons };
