import { Widget } from "../../types";

export const Button: Widget = {
  id: "",
  name: "Button",
  description: "Render button or link with button styles",
  meta: {
    type: "button",
    category: "components",
  },
  variant: "filled",
  baseClassName: "",
  properties: {
    styles: {},
    props: {
      label: {
        section: "customization",
        value: "Button",
        type: "text",
        name: "Label",
        key: "label",
      },
      color: {
        section: "customization",
        value: "#ffffff",
        type: "color",
        name: "Color",
        key: "color",
      },
      variant: {
        section: "customization",
        value: "filled",
        type: "segmented-control",
        name: "Variant",
        key: "variant",
        options: ["filled", "outline", "gradient"],
      },
      variantColor: {
        section: "customization",
        value: "dark",
        type: "mantine-color",
        name: "Variant color",
        key: "variantColor",
        dependencies: ["variant"],
        not: ["filled", "gradient"],
      },
      backgroundColor: {
        section: "customization",
        value: "#25262b",
        type: "color",
        name: "Background color",
        key: "backgroundColor",
        dependencies: ["variant"],
        dependencyValue: "filled",
      },
      gradient: {
        section: "customization",
        value: {
          from: "#364FC7",
          to: "#FA5252",
          deg: 45,
        },
        type: "gradient",
        key: "gradient",
        name: "Gradient",
        dependencies: ["variant"],
        dependencyValue: "gradient",
      },
      disabled: {
        section: "flexibility",
        value: false,
        type: "toggle",
        name: "Disabled",
        key: "disabled",
      },
      loader: {
        section: "flexibility",
        value: false,
        type: "toggle",
        name: "Show loader",
        key: "loader",
      },
      loaderPosition: {
        section: "flexibility",
        value: "center",
        type: "select",
        options: ["left", "right", "center"],
        name: "Loader position",
        key: "loaderPosition",
        dependencies: ["loader"],
      },
      borderRadius: {
        section: "customization",
        value: "sm",
        type: "segmented-control",
        name: "Border radius",
        key: "borderRadius",
        options: ["xs", "sm", "md", "lg", "xl", "custom"],
      },
      radius: {
        section: "customization",
        value: 4,
        type: "number",
        name: "Custom radius",
        key: "radius",
        dependencies: ["borderRadius"],
        dependencyValue: "custom",
      },
      uppercase: {
        section: "flexibility",
        value: false,
        type: "toggle",
        name: "Uppercase",
        key: "uppercase",
      },
      hoverBackgroundColor: {
        section: "customization",
        value: "#E9ECEF",
        type: "color",
        name: "Hover background color",
        key: "hoverBackgroundColor",
      },
    },
  },
  control: {
    redirectTo: "",
    type: "blink",
  },
  layout: {
    currentLayout: "mobile",
    mobile: {
      top: 0,
      left: 0,
      width: 250,
      height: 60,
    },
  },
};
