import React from "react";
import { useLocation } from "react-router-dom";

type QueryParams = {
  [key: string]: string | null;
};

export const useQueryParams = (): QueryParams => {
  const location = useLocation();

  return React.useMemo(() => {
    const params = new URLSearchParams(location.search);
    const queryParams: QueryParams = {};

    params.forEach((value, key) => {
      queryParams[key] = value;
    });

    return queryParams;
  }, [location.search]);
};
