import React from "react";
import { BaseWidgetBoxProps } from "@src/types/modules/blinkInBio/widgets.types";
import WidgetLib from "../WidgetsUI";

interface Props extends BaseWidgetBoxProps {
  variant: "filled" | "light" | "outline" | "default" | "subtle" | "custom";
}

const DynamicImportComponent: React.FC<Props> = ({ type, ...restProps }) => {
  const Component = WidgetLib[type];

  return <Component {...restProps} />;
};

export default DynamicImportComponent;
