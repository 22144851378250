import { Box, Center, SegmentedControl } from "@mantine/core";
import { IconDeviceDesktop, IconDeviceMobile } from "@tabler/icons-react";
import React from "react";

const SegmentControlForDevice: React.FC<{}> = () => {
  return (
    <SegmentedControl
      data={[
        {
          value: "mobile",
          label: (
            <Center>
              <IconDeviceMobile size="1rem" />
              <Box ml={5}>Mobile</Box>
            </Center>
          ),
        },
        {
          value: "desktop",
          disabled: true,
          label: (
            <Center>
              <IconDeviceDesktop size="1rem" />
              <Box ml={5}>Desktop</Box>
            </Center>
          ),
        },
      ]}
      // value={selected}
      // onChange={(value) => onChange(value as Layout)}
      size="xs"
    />
  );
};

export default SegmentControlForDevice;
