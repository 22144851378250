import { useMantineTheme } from "@mantine/core";
import React from "react";

export interface ImageProps {
  src: string | React.FunctionComponent<React.SVGAttributes<SVGElement>> | null;
  width?: number;
  height?: number;
  className?: string;
  style?: React.CSSProperties;
  alt?: string;
  handleDarkMode?: boolean;
}

export interface Props extends ImageProps {
  onClick?: () => void;
}

export const Image: React.FC<Props> = ({
  src,
  width = 20,
  height = 20,
  className = "",
  style = {},
  alt = "",
  onClick,
  handleDarkMode = true,
}) => {
  const theme = useMantineTheme();

  const darkCls =
    !className.includes("bridge-logo") &&
    handleDarkMode &&
    theme.colorScheme === "dark"
      ? "icon-btn-dark"
      : "";

  const imageSrc = src ? src : "https://via.placeholder.com/150";

  return (
    <img
      src={imageSrc as string}
      width={width}
      height={height}
      className={`${className} ${darkCls}`}
      style={style}
      alt={alt}
      onClick={onClick}
    />
  );
};
