import React from "react";
import { Label, Pie, PieChart } from "recharts";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Button, Group, Paper, Text, ThemeIcon, Tooltip } from "@mantine/core";
import { IconLocation } from "@tabler/icons-react";
import { humanReadableCount } from "@/src/utils";
type TGeoData = {
  location: string;
  visitors: number;
  fill: string;
}[];

export const ClicksChart: React.FC<{
  chartData: TGeoData;
  totalClicks: number;
}> = ({ chartData, totalClicks }) => {
  const totalVisitors = React.useMemo(() => {
    return chartData.reduce((acc, curr) => acc + curr.visitors, 0);
  }, []);

  const chartConfig: ChartConfig = chartData.reduce(
    (acc, curr) => {
      return {
        ...acc,
        [curr.location.toLocaleLowerCase()]: {
          label: curr.location,
          color: curr.fill,
        },
      };
    },
    { visitors: { label: "Visitors" } }
  );

  return (
    <Paper
      p="md"
      radius="md"
      shadow="md"
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === "dark" ? theme.colors.dark[9] : "white",
      })}
    >
      <Tooltip label="Location data" color="indigo">
        <Group position="left">
          <ThemeIcon color="teal" variant="light">
            <IconLocation size={20} />
          </ThemeIcon>
          <Text size="md" weight={500} ml="sm">
            Clicks by location
          </Text>
        </Group>
      </Tooltip>
      <ChartContainer
        config={chartConfig}
        className="mx-auto aspect-square max-h-[250px]"
      >
        <PieChart>
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent hideLabel />}
          />
          <Pie
            data={chartData}
            dataKey="visitors"
            nameKey="location"
            innerRadius={60}
            strokeWidth={5}
          >
            <Label
              content={({ viewBox }) => {
                if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                  return (
                    <text
                      x={viewBox.cx}
                      y={viewBox.cy}
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      <tspan
                        x={viewBox.cx}
                        y={viewBox.cy}
                        className="fill-foreground text-3xl font-bold"
                      >
                        {humanReadableCount(totalClicks)}
                      </tspan>
                      <tspan
                        x={viewBox.cx}
                        y={(viewBox.cy || 0) + 24}
                        className="fill-muted-foreground"
                      >
                        Visitors
                      </tspan>
                    </text>
                  );
                }
              }}
            />
          </Pie>
        </PieChart>
      </ChartContainer>
    </Paper>
  );
};
