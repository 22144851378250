import React from "react";
import {
  Navbar as MantineNavbar,
  createStyles,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { Image } from "./BaseUI/Image";
import LinkTo from "../routes/LinkTo";
import { navLinks } from "@containers/AppShell";

interface NavbarLinkProps extends navLinks {
  active?: boolean;
  onClick?(): void;
  disabled?: boolean;
}
interface Composition {
  Link: React.FC<NavbarLinkProps>;
}

type Props = {
  children: React.ReactNode;
};

export const NavbarComponent: React.FC<Props> & Composition = ({
  children,
}) => {
  return (
    <MantineNavbar width={{ base: 50 }} p="xs">
      {children}
    </MantineNavbar>
  );
};

const useStyles = createStyles((theme) => ({
  link: {
    width: 30,
    height: 30,
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.colorScheme === "dark" ? "#EDEDED" : theme.colors.gray[7],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[2],
    },
  },

  active: {
    "&, &:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.dark[7],
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },
}));

const NavbarLink: React.FC<NavbarLinkProps> = ({
  icon,
  label,
  route,
  active,
  onClick,
  disabled,
  tip,
}) => {
  const { classes, cx, theme } = useStyles();

  const activeCls =
    theme.colorScheme === "light" && active ? "svg-icon-active" : "";
  const nonActiveCls =
    !active && theme.colorScheme === "dark" ? "icon-btn-dark" : "";

  return (
    <RenderWithLinkContainer route={route} disabled={disabled ?? false}>
      <Tooltip
        label={tip}
        withArrow
        position="bottom"
        color={!disabled ? "indigo" : "dark"}
        transitionProps={{ transition: "slide-down", duration: 300 }}
      >
        <UnstyledButton
          onClick={onClick}
          className={cx(classes.link, { [classes.active]: active })}
        >
          <Image
            src={icon}
            width={20}
            height={20}
            alt={label}
            className={`${nonActiveCls} ${activeCls}`}
            handleDarkMode={true}
          />
        </UnstyledButton>
      </Tooltip>
    </RenderWithLinkContainer>
  );
};

const RenderWithLinkContainer: React.FC<{
  children: React.ReactNode;
  disabled: boolean;
  route: string;
}> = ({ children, disabled, route }) => {
  if (disabled) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return <LinkTo to={route}>{children}</LinkTo>;
};

NavbarComponent.Link = NavbarLink;
