import React, { useEffect, useState, useCallback } from "react";
import { Box, Card, Center } from "@mantine/core";
import { appPageService } from "@src/services/apps.service";
import { useEditorStore, useEditorStoreActions } from "@src/stores/editorstore";
import { extractAppIdFromPathname } from "@src/helpers/routes.helpers";
import { useWorkspaceId } from "@src/hooks";
import Whitelabel from "@UIComponents/Whitelabel";
import { Widget } from "../BlinkInBio/Editor/widgets/types";
import DynamicImportComponent from "../Pages/Canvas/DynamicImportComponent";

const ORIGINAL_WIDTH = 390;
const ORIGINAL_HEIGHT = 844;

interface WidgetProps {
  id: string;
  layout: {
    mobile: { width: number; height: number; top: number; left: number };
  };
  type: string;
  properties: any;
  control: any;
  variant: any;
}

const AdaptiveWidget: React.FC<WidgetProps> = ({
  id,
  layout,
  type,
  properties,
  control,
  variant,
}) => {
  return (
    <div
      style={{
        position: "absolute",
        width: `${(layout.mobile.width / ORIGINAL_WIDTH) * 100}%`,
        height: `${(layout.mobile.height / ORIGINAL_HEIGHT) * 100}%`,
        top: `${(layout.mobile.top / ORIGINAL_HEIGHT) * 100}%`,
        left: `${(layout.mobile.left / ORIGINAL_WIDTH) * 100}%`,
        zIndex: type === "overlay" ? 1 : 2,
      }}
    >
      <DynamicImportComponent
        type={type}
        id={id}
        inheritedStyles={{ height: "100%", width: "100%" }}
        properties={properties}
        control={control}
        variant={variant}
      />
    </div>
  );
};

interface ViewerProps {}

const Viewer: React.FC<ViewerProps> = () => {
  const [loading, setLoading] = useState(true);
  const { setEditorState } = useEditorStoreActions();
  const { widgets, background } = useEditorStore.use.definitions();
  const workspaceId = useWorkspaceId();
  const url = window.location;
  const appIdFromUrl = extractAppIdFromPathname(url.pathname);

  useEffect(() => {
    setLoading(true);
    if (!workspaceId || !appIdFromUrl) {
      return;
    }

    appPageService
      .getPublicApp(appIdFromUrl)
      .then((response: any) => {
        setEditorState({
          id: response.id,
          currentPageId: response.currentPageId,
          metadata: response.metadata,
          definitions: response.definitions,
          mode: "published",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [appIdFromUrl, workspaceId, setEditorState]);

  let bgStyles: React.CSSProperties = {
    position: "relative",
    backgroundColor:
      background?.type === "bg-color" ? background.backgroundColor : "#fff",
    backgroundImage:
      background?.type === "bg-image"
        ? `url(${background.backgroundImage})`
        : "none",
    backgroundSize: background?.type === "bg-image" ? "cover" : "auto",
    backgroundRepeat: background?.type === "bg-image" ? "no-repeat" : "repeat",
  };

  if (loading) {
    return <Center style={{ height: "100vh" }}>Loading...</Center>;
  }

  const isMobileView = window.innerWidth <= 430;

  if (!isMobileView) {
    bgStyles = {
      ...bgStyles,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%) scale(0.95)",
      borderRadius: "20px",
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    };
  }

  const RenderLayout = isMobileView
    ? RenderForMobileLayout
    : RenderForDesktoplayout;

  return (
    <RenderLayout
      bgStyles={bgStyles}
      backgroundColorFromImage={
        background?.gradient ?? background.backgroundColor
      }
      widgets={widgets}
      isMobileView={isMobileView}
    />
  );
};

interface LayoutViewProps {
  bgStyles: React.CSSProperties;
  backgroundColorFromImage: string;
  widgets: Record<string, any>[] | Widget[];
  isMobileView: boolean;
}

const RenderForMobileLayout: React.FC<LayoutViewProps> = ({
  bgStyles,
  widgets,
  isMobileView,
}) => {
  return (
    <Box className="h-100 w-100">
      <Card
        className="p-0"
        style={{
          width: "100%",
          height: "100vh",
          maxWidth: "430px",
          maxHeight: "932px",
          position: "relative",
          overflow: "hidden",
          ...bgStyles,
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          {(widgets as Widget[])?.map((widget: Widget) => (
            <AdaptiveWidget
              key={widget.id}
              id={widget.id}
              layout={widget.layout}
              type={widget.meta.type}
              properties={widget.properties}
              control={widget.control}
              variant={widget.variant}
            />
          ))}
          <Whitelabel isMobileLayoutActive={isMobileView} />
        </Box>
      </Card>
    </Box>
  );
};

const RenderForDesktoplayout: React.FC<LayoutViewProps> = ({
  bgStyles,
  backgroundColorFromImage,
  widgets,
  isMobileView,
}) => {
  return (
    <React.Fragment>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          background: `radial-gradient(circle, ${backgroundColorFromImage} 50%, rgba(92, 124, 250, 1) 100%)`,
        }}
      >
        <Whitelabel isMobileLayoutActive={isMobileView} />
      </Box>
      <Card
        className="p-0"
        style={{
          width: `calc(100vh * ${ORIGINAL_WIDTH / ORIGINAL_HEIGHT})`,
          height: "100vh",
          maxWidth: "400px",
          maxHeight: "932px",
          overflow: "hidden",
          ...bgStyles,
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          {(widgets as Widget[])?.map((widget: Widget) => (
            <AdaptiveWidget
              key={widget.id}
              id={widget.id}
              layout={widget.layout}
              type={widget.meta.type}
              properties={widget.properties}
              control={widget.control}
              variant={widget.variant}
            />
          ))}
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default Viewer;
