import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { authenticationService } from "../services/auth.service";

export const useWorkspaceId = () => {
  const { workspaceId } = useParams();

  const userData = authenticationService.currentSessionValue().user;

  const currentWorkspaceId = userData?.currentWorkspaceId || null;

  const [storedWorkspaceId, setStoredWorkspaceId] = useState<string | null>();

  useEffect(() => {
    if (!currentWorkspaceId && workspaceId) {
      setStoredWorkspaceId(workspaceId);
    }

    if (workspaceId && workspaceId !== currentWorkspaceId) {
      setStoredWorkspaceId(workspaceId);
    }

    if (!workspaceId && currentWorkspaceId) {
      setStoredWorkspaceId(currentWorkspaceId);
    }
  }, [workspaceId, setStoredWorkspaceId]);

  useEffect(() => {
    localStorage.setItem("workspaceId", storedWorkspaceId || "");

    return () => {
      localStorage.removeItem("workspaceId");
    };
  }, [storedWorkspaceId]);

  // Return the URL workspaceId if available, otherwise the stored one
  return workspaceId || storedWorkspaceId;
};
