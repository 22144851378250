import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Container,
  Grid,
  Group,
  Menu,
  Paper,
  Text,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { useBlinksStoreActions } from "@src/stores/blinks";
import { IBlink } from "@src/stores/blinks/types";
import {
  IconAppWindowFilled,
  IconArchive,
  IconArrowsLeftRight,
  IconClick,
  IconClockHour4,
  IconCloudLock,
  IconCopy,
  IconDotsVertical,
  IconEditCircle,
  IconExternalLink,
  IconHours24,
  IconLink,
  IconLockAccess,
  IconLockCode,
  IconPinFilled,
  IconReportAnalytics,
  IconRowInsertBottom,
  IconTrash,
  IconUnlink,
  IconWorldWww,
} from "@tabler/icons-react";
import { min } from "lodash";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { humanizeTime } from "../../utils/blinks.utils";
import { useWorkspaceActions } from "@/src/stores/workspace";
import toast from "react-hot-toast";
import { useWorkspaceId } from "@/src/hooks";
import Lottie from "lottie-react";
import lottielock from "@assets/lottie/lock.json";
import lottiecalendar from "@assets/lottie/calendar.json";

type Props = {
  children: ReactNode;
};

interface Composition {
  ListItem: React.FC<IBlink>;
}

const Listview: React.FC<Props> & Composition = ({ children }) => {
  return <Container className="p-0">{children}</Container>;
};

const ListItem: React.FC<IBlink> = ({
  id,
  name,
  blinkDomain,
  blinkSignature,
  redirectUrl,
  status,
  directives,
  createdAt,
  type,
}) => {
  const statusColors: Record<string, string> = {
    active: "green",
    inactive: "red",
    archived: "gray",
  };

  const icon = <IconClick size={"0.75rem"} />;

  return (
    <Paper shadow="xs" className="p-0 mt-2" withBorder>
      <Grid align="center" justify="space-between">
        <Grid.Col
          span="auto"
          styles={{
            minWidth: 300,
            width: "50%",
          }}
        >
          <Group>
            <List
              name={name}
              redirectURL={redirectUrl}
              blink={`${blinkDomain}/${blinkSignature}`}
              createdAt={createdAt}
              type={type}
              blinkId={id}
            />
          </Group>
        </Grid.Col>
        <Grid.Col
          span={"auto"}
          style={{ display: "flex", justifyItems: "flex-end" }}
        >
          <Group>
            <Badge
              size="xs"
              radius={"sm"}
              color={"indigo"}
              variant={type === "blink" ? "filled" : "light"}
            >
              {type === "app" ? "page" : "blink"}
            </Badge>
            <Badge size="xs" color={statusColors[status]} variant={"dot"}>
              {status}
            </Badge>
            {/* <Tooltip
              multiline
              width={220}
              withArrow
              transitionProps={{ duration: 200 }}
              label="For detailed statistics, please visit the analytics page or view blink details"
            >
              <Badge leftSection={icon} variant={"light"} radius={"sm"}>
                2k
              </Badge>
            </Tooltip> */}
            {directives?.isPasswordProtected && (
              <Tooltip
                color="indigo"
                width={"auto"}
                label={"Password protected blink"}
                withArrow
              >
                <Box>
                  <Lottie
                    animationData={lottielock}
                    loop={true}
                    style={{
                      width: "2rem",
                      height: "2rem",
                    }}
                  />
                </Box>
              </Tooltip>
            )}
            {directives?.isSchedulingActive && (
              <Tooltip label="Scheduled blink" withArrow color="indigo">
                <Box>
                  <Lottie
                    animationData={lottiecalendar}
                    loop={true}
                    style={{
                      width: "4rem",
                      height: "4rem",
                    }}
                  />
                </Box>
              </Tooltip>
            )}

            {directives?.isPrivate && (
              <Tooltip label="Private blink" withArrow color="indigo">
                <IconCloudLock size={"1rem"} color="gray" />
              </Tooltip>
            )}

            {directives?.isSchedulingActive && directives?.expiration && (
              <Tooltip
                color="indigo"
                label={`Expires on ${new Date(
                  directives.expiration
                ).toLocaleString()}`}
                withArrow
              >
                <IconClockHour4 size={"1rem"} color="gray" />
              </Tooltip>
            )}

            {directives?.isCloakingActive && (
              <Tooltip label="Cloaking enabled" withArrow color="indigo">
                <IconWorldWww size={"1rem"} color="gray" />
              </Tooltip>
            )}
          </Group>
        </Grid.Col>

        <Grid.Col span={"content"}>
          <OptionMenu
            blinkURL={`${blinkDomain}/${blinkSignature}`}
            blinkId={id}
            name={name}
          >
            <ActionIcon>
              <IconDotsVertical size="1.125rem" />
            </ActionIcon>
          </OptionMenu>
        </Grid.Col>
      </Grid>
    </Paper>
  );
};

const OptionMenu: React.FC<{
  children: React.ReactNode;
  blinkURL: string;
  blinkId: string;
  name: string;
}> = ({ children, blinkURL, blinkId, name }) => {
  const navigate = useNavigate();
  const workspaceId = useWorkspaceId();

  const handleAddingToQuickAccess = () => {
    if (!workspaceId) return toast.error("Something went wrong");

    useWorkspaceActions()
      ?.addToQuickAccess(workspaceId, blinkId, name, "blinks")
      .then(() => toast.success("Added to quick access"))
      .catch(() => toast.error("Failed to add to quick access"));
  };

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    toast.success("Copied to clipboard");
  };

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>{children}</Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          onClick={() => {
            navigate(`${blinkId}`);
          }}
          icon={<IconEditCircle size={14} />}
        >
          Open
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            window.open(blinkURL, "_blank");
          }}
          icon={<IconExternalLink size={14} />}
        >
          Preview
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            copyToClipboard(blinkURL);
          }}
          icon={<IconCopy size={14} />}
        >
          <Tooltip label="Copy blink URL" color="indigo" position="top">
            <Text>Copy</Text>
          </Tooltip>
        </Menu.Item>
        <Tooltip label="Adds to quick access" color="indigo" position="right">
          <Menu.Item
            icon={<IconPinFilled size={14} />}
            onClick={handleAddingToQuickAccess}
          >
            Pin blink
          </Menu.Item>
        </Tooltip>

        {/* <Menu.Item icon={<IconRowInsertBottom size={14} />}>
          Duplicate
        </Menu.Item> */}

        <Menu.Item disabled icon={<IconArchive size={14} />}>
          Archive
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item disabled icon={<IconArrowsLeftRight size={14} />}>
          Transfer blink's data
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            useBlinksStoreActions()?.delete(blinkId);
          }}
          color="red"
          icon={<IconTrash size={14} />}
        >
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

type ListProps = {
  name: string;
  redirectURL: string;
  blink: string;
  createdAt: string;
  type: "blink" | "app";
  blinkId: string;
};

const List = React.forwardRef<HTMLButtonElement, ListProps>(
  ({ name, redirectURL, blink, createdAt, type, blinkId }: ListProps, ref) => {
    const navigate = useNavigate();
    return (
      <UnstyledButton
        onClick={(e) => {
          e.preventDefault();
          navigate(`${blinkId}`);
        }}
        ref={ref}
        sx={(theme) => ({
          display: "block",
          width: "100%",
          padding: theme.spacing.xs,
          color:
            theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
        })}
      >
        <Group>
          <ThemeIcon radius={"lg"} color={"dark"}>
            {type === "blink" ? (
              <IconLink size={"1rem"} />
            ) : (
              <IconAppWindowFilled size={"1rem"} />
            )}
          </ThemeIcon>

          <div style={{ flex: 1 }}>
            <Text size="sm" weight={500}>
              {name.substring(0, min([name.length, 30]))}{" "}
              {name.length > 30 && "..."}
            </Text>

            <Text c="blue" size="xs">
              {blink.substring(0, min([blink.length, 30]))}{" "}
              {blink.length > 30 && "..."}
            </Text>
            <Text c="gray" size="xs">
              Redirected to{" "}
              {redirectURL.substring(0, min([redirectURL.length, 30]))}{" "}
              {redirectURL.length > 30 && "..."}
            </Text>
            <Text c="dimmed" variant="text" size="xs">
              {humanizeTime(new Date(createdAt))}
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    );
  }
);

Listview.ListItem = ListItem;

export default Listview;
