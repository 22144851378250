import { Widget } from "../../types";

export const InstagramCard: Widget = {
  id: "",
  name: "Instagram Post",
  description: "Render Instagram post",
  meta: {
    type: "instagram_card",
    category: "components",
  },
  variant: "filled",
  baseClassName: "",
  properties: {
    styles: {},
    props: {
      postUrl: {
        section: "customization",
        value: "https://www.instagram.com/p/CSlQ2v1L5b6/",
        type: "text",
        key: "postUrl",
        name: "Post URL",
      },
    },
  },
  control: {
    redirectTo: "",
    type: null,
  },
  layout: {
    currentLayout: "mobile",
    mobile: {
      top: 0,
      left: 0,
      width: 390,
      height: 500,
    },
  },
};
