import { componentsService } from "@src/services/components.service";
import {
  IControl,
  Widget,
} from "../../modules/BlinkInBio/Editor/widgets/types";
import { create, createSelectors } from "../utils";
import { State, Actions, UpdaterState } from "./types";
import { CreateComponentDto } from "@src/services/types";
import { camputeDiff } from "@src/helpers";
import _ from "lodash";
import { appPageService } from "@src/services/apps.service";
import {
  createSnapshotForPublishedApp,
  getUniqueWidgetname,
  validateWidgetName,
} from "../helpers";
import toast from "react-hot-toast";
import { generateUniqueId } from "@/src/lib";
import { blinkService } from "@/src/services/blinks.services";
import { workspaceService } from "@/src/services/workspace.service";

const initialState: State = {
  id: "",
  currentPageId: "",
  metadata: {
    name: "",
    description: null,
    tags: [],
    groups: [],
    published: false,
    urls: [],
    draftMode: false,
    draftId: "",
  },
  activeSegment: "widget_library",
  definitions: {
    background: {
      type: "",
      backgroundColor: "",
      backgroundImage: "",
    },
    fontFamily: {
      name: "",
      url: "",
    },
    pageLayout: {
      type: "custom",
    },
    widgets: [],
  },
  analytics: {},
  settings: {},
  isSaving: false,
  isPublishing: false,
  isWidgetManagerLoading: false,
  actions: {},
  mode: "edit",
  selectedWidget: null,
  currentLayout: "mobile",
  lastChangesSavedAt: null,
  lastPublishedAt: null,
  lockedWidgets: [],
  workspaceBlinks: [],
  appBlinkURL: "",
  associatedBlinkId: "",
  lockGrid: false,
  isLoadingOverlayActive: true,
  showPanelBody: true,
  editableWidget: null,
  showFileUploader: false,
};

const useBaseEditorStore = create<State>()((set, get) => ({
  ...initialState,
  actions: {
    setEditorState: (state: UpdaterState) => {
      const newState = { ...get(), ...state };
      set(newState);
    },
    setCurrentSegment: (segment) => {
      set({ activeSegment: segment });
    },
    setBackground: (type: string, value: string) => {
      const newDefinition = { ...get().definitions.background, [type]: value };

      set({
        definitions: {
          ...get().definitions,
          background: newDefinition,
        },
      });
      appPageService.updateAppBg(get().id, newDefinition);
    },
    addWidget: (widget: Widget) => {
      set({ isSaving: true });

      const { currentPageId, id } = get();

      const widgetname = getUniqueWidgetname(widget.name);

      set({
        definitions: {
          ...get().definitions,
          widgets: [
            ...get().definitions.widgets,
            {
              ...widget,
              name: widgetname,
            },
          ],
        },
        isWidgetManagerLoading: false,
        selectedWidget: widget.id,
      });

      const widgetDefinition: CreateComponentDto = {
        component: {
          id: widget.id,
          name: widgetname,
          category: widget.meta.category,
          type: widget.meta.type,
          baseClassName: widget.baseClassName,
          properties: widget.properties,
          control: widget.control,
          pageId: currentPageId,
        },
        layouts: widget.layout,
      };

      try {
        componentsService
          .create(id, currentPageId, widgetDefinition)
          .then(() => {
            setTimeout(() => {
              // in human readable format like "Last changes saved at 15min ago"
              const lastChangesSavedAt = new Date();
              set({ isSaving: false, lastChangesSavedAt: lastChangesSavedAt });
            }, 300);
          });
      } catch (error) {
        console.log(error, id);
      }
    },
    duplicateWidget: (widgetId: string) => {
      const widget = get().definitions.widgets.find(
        (widget) => widget.id === widgetId
      );

      if (!widget) return;
      const { currentPageId, id, currentLayout } = get();
      const widgetName = getUniqueWidgetname(widget.name);

      widget.name = widgetName;

      const newLayout = {
        ...widget.layout,
        [currentLayout]: {
          ...widget.layout[currentLayout],
          top: widget.layout[currentLayout].top + 20,
        },
      };

      const widgetDefinition: CreateComponentDto = {
        component: {
          id: generateUniqueId(),
          name: widget.name,
          category: widget.meta.category,
          type: widget.meta.type,
          baseClassName: widget.baseClassName,
          properties: widget.properties,
          control: widget.control,
          pageId: currentPageId,
        },
        layouts: {
          currentLayout: "mobile",
          ...newLayout,
        },
      };

      const newWidget = {
        ...widget,
        name: `${widget.name} copy`,
        id: widgetDefinition.component.id,
        layout: newLayout,
      };

      set({
        definitions: {
          ...get().definitions,
          widgets: [...get().definitions.widgets, newWidget],
        },
      });

      try {
        componentsService
          .create(id, currentPageId, widgetDefinition)
          .then(() => {
            const lastChangesSavedAt = new Date();
            set({ isSaving: false, lastChangesSavedAt: lastChangesSavedAt });
          });
      } catch (error) {
        toast.error("Failed to duplicate widget");
        // remove the widget from the list
        set({
          definitions: {
            ...get().definitions,
            widgets: get().definitions.widgets.filter(
              (widget) => widget.id !== widgetDefinition.component.id
            ),
          },
        });
      }
    },
    updateWidgetControl: (componentId: string, control: IControl) => {
      set({ isSaving: true });
      const currentWidget = get().definitions.widgets.find(
        (widget) => widget.id === componentId
      );

      if (!currentWidget) return set({ isSaving: false });

      const diff = camputeDiff(currentWidget.control, control);

      if (_.isEmpty(diff)) return;

      const updatedWidget = {
        ...currentWidget,
        control,
      };

      set({
        definitions: {
          ...get().definitions,
          widgets: get().definitions.widgets.map((widget) =>
            widget.id === componentId ? updatedWidget : widget
          ),
        },
      });

      componentsService
        .updateComponentControl(componentId, control)
        .then(() => {
          setTimeout(() => {
            set({ isSaving: false, lastChangesSavedAt: new Date() });
          }, 300);
        });
    },
    updateWidgetDefinition: (
      id: string,
      newDefinition: Record<string, any>,
      type: string
    ) => {
      set({ isSaving: true });
      const getCurrentWidgets = get().definitions.widgets;
      const updatedWidget = getCurrentWidgets.map((widget) => {
        if (widget.id === id) {
          return { ...widget, ...newDefinition };
        }
        return widget;
      });

      set({
        definitions: {
          ...get().definitions,
          widgets: updatedWidget,
        },
      });

      //Todo: handle it in a separate function or module
      if (type === "layout") {
        const currentLayout = get().currentLayout;

        return componentsService
          .updateComponentLayout(id, currentLayout, newDefinition["layout"])
          .then(() =>
            setTimeout(() => {
              set({ isSaving: false, lastChangesSavedAt: new Date() });
            }, 300)
          );
      }

      if (type === "widgetDefinition") {
        const previusWidgetDefinition = getCurrentWidgets.find(
          (widget) => widget.id === id
        );

        const currentWidgetDefinition = updatedWidget.find(
          (widget) => widget.id === id
        );

        const preparePayload = camputeDiff(
          previusWidgetDefinition,
          currentWidgetDefinition
        );

        return componentsService
          .updateComponentProperties(id, preparePayload)
          .then(() =>
            setTimeout(() => {
              set({ isSaving: false, lastChangesSavedAt: new Date() });
            }, 300)
          );
      }
    },
    setWidgetManagerLoading: (isLoading: boolean) =>
      set({ isWidgetManagerLoading: isLoading }),

    updateMode: (mode: "edit" | "preview" | "published") => {
      set({ mode });

      return new Promise((resolve, reject) => {
        resolve();
      });
    },
    getWidget: (widgetId: string) => {
      const found = get().definitions.widgets.find(
        (widget) => widget.id === widgetId
      );

      if (!found) return null;

      return found as Widget;
    },

    setSelectedWidget: (widgetId: string | null) => {
      set({ selectedWidget: widgetId });
    },

    publishApp: async (onConfirm = false) => {
      set({ isSaving: true });
      const appId = get().id;

      try {
        const isAlreadyPublished =
          await appPageService.validatebBeforePublishing(appId);

        if (onConfirm || !isAlreadyPublished.published) {
          const latestState = get();
          const snapshotJSON = createSnapshotForPublishedApp(latestState);

          const resp = await appPageService.publishApp(appId, snapshotJSON);

          if (resp) {
            set({
              lastChangesSavedAt: new Date(),
              metadata: {
                ...get().metadata,
                published: true,
                draftMode: false,
              },
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    updateDraftMode: (draftMode: boolean) => {
      set({ isSaving: true });

      const appId = get().id;

      if (!appId) return;

      appPageService.updateDraftMode(appId, draftMode).then(() => {
        set({
          isSaving: false,
          lastChangesSavedAt: new Date(),
          metadata: {
            ...get().metadata,
            draftMode,
          },
        });
      });
    },

    lockWidget: (widgetId: string) => {
      set({
        lockedWidgets: [...get().lockedWidgets, widgetId],
      });
    },

    unlockWidget: (widgetId: string) => {
      set({
        lockedWidgets: get().lockedWidgets.filter((id) => id !== widgetId),
      });
    },

    removeWidget: (widgetId: string) => {
      const widgetList = get().definitions.widgets;

      const newWidgets = widgetList.filter((widget) => widget.id !== widgetId);

      set({
        selectedWidget: null,
        definitions: {
          ...get().definitions,
          widgets: newWidgets,
        },
      });

      componentsService
        .delete(widgetId)
        .then(() => {
          toast.success("Widget deleted successfully");
        })
        .catch(() => {
          toast.error("Failed to delete widget");
        });
    },

    renameWidget: (widgetId: string, name: string) => {
      set({ isSaving: true });

      const isValidName = validateWidgetName(name);

      if (!isValidName) {
        toast.error("Widget name already exists");
        set({ isSaving: false });
        return;
      }

      const currentWidgets = get().definitions.widgets;

      const updatedWidgets = currentWidgets.map((widget) => {
        if (widget.id === widgetId) {
          return { ...widget, name };
        }
        return widget;
      });

      set({
        definitions: {
          ...get().definitions,
          widgets: updatedWidgets,
        },
      });

      componentsService
        .updateComponentName(widgetId, name)
        .then(() => {
          toast.success("Widget renamed successfully");
        })
        .then(() => {
          setTimeout(() => {
            set({ isSaving: false, lastChangesSavedAt: new Date() });
          }, 300);
        })
        .catch(() => {
          toast.error("Failed to rename widget");
        });
    },

    fetchWorkspaceBlinks: async (workspaceId: string) => {
      const workspaceBlinks = await workspaceService.getAllBlinks(workspaceId);

      set({ workspaceBlinks });
    },

    getAppBlinkURL: async (blinkId: string) => {
      const appBlinkURL = await blinkService.getAppBlinkURL(blinkId);

      set({
        appBlinkURL: appBlinkURL,
        associatedBlinkId: blinkId,
      });
    },

    setGridLock: (lock: boolean) => {
      set({ lockGrid: lock });
    },

    setLoadingOverlay: (loading: boolean) => {
      set({ isLoadingOverlayActive: loading });
    },

    setShowPanelBody: (show: boolean) => {
      set({ showPanelBody: show });
    },

    setEditableWidget: (widgetId: string | null) => {
      set({ editableWidget: widgetId });
    },

    setShowFileUploader: (show: boolean) => set({ showFileUploader: show }),

    reset: () => {
      const actions = get().actions;

      set({
        ...initialState,
        actions,
      });
    },
  },
}));

export const useEditorStore = createSelectors(useBaseEditorStore);

export const useEditorStoreActions = () =>
  useEditorStore.getState().actions as Actions;
export const useEditorStoreState = () => useEditorStore.getState();
