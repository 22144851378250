import { workspaceService } from "@/src/services/workspace.service";
import { useEffect } from "react";
import { AppResponse } from "./types";
import { useEditorStoreActions } from "@stores/editorstore";
import { useWorkspaceId } from "@/src/hooks";
import { extractAppIdFromPathname } from "@/src/helpers/routes.helpers";
import { useAppStoreActions } from "@/src/stores/appstore";
import { useWorkspaceActions } from "@/src/stores/workspace";

export const useFetchAppData = () => {
  const { setEditorState, fetchWorkspaceBlinks, getAppBlinkURL } =
    useEditorStoreActions();

  const workspaceId = useWorkspaceId();
  const url = window.location;
  const appIdFromUrl = extractAppIdFromPathname(url.pathname);

  useEffect(() => {
    if (workspaceId && appIdFromUrl) {
      const defaultDefintion = {
        background: {
          type: "",
          backgroundColor: "",
          backgroundImage: "",
        },
        fontFamily: {
          name: "",
          url: "",
        },
        pageLayout: {
          type: "custom",
        },
        widgets: [],
      };

      let blinkId = "";

      try {
        useEditorStoreActions()?.setLoadingOverlay(true);
        workspaceService
          .getAppById(workspaceId, appIdFromUrl as string)
          .then((response: AppResponse) => {
            blinkId = response.blink_id;
            setEditorState({
              id: appIdFromUrl as string,
              currentPageId: response.pages[0].id,
              metadata: {
                name: response.name,
                description: response.description,
                tags: [],
                groups: [],
                published: response.published,
                urls: [],
                draftMode: response.draft_mode ?? false,
                draftId: response.draft_id,
              },
              definitions: {
                background: response.background || defaultDefintion.background,
                fontFamily: response.fontFamily || defaultDefintion.fontFamily,
                pageLayout: response.pageLayout || defaultDefintion.pageLayout,
                widgets: response.components || defaultDefintion.widgets,
              },
              lastPublishedAt: response.last_published_at,
            });
          })
          .finally(() => {
            fetchWorkspaceBlinks(workspaceId);
            getAppBlinkURL(blinkId);
            useAppStoreActions()?.loadTemplates();
            useEditorStoreActions()?.setLoadingOverlay(false);
            useWorkspaceActions()?.getBrandAssets(workspaceId);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, [appIdFromUrl]);
};
