import React from "react";
import Lottie from "lottie-react";
import emptyList from "@assets/lottie/empty.json";

type Props = {};

export const Empty = (props: Props) => {
  return (
    <Lottie
      animationData={emptyList}
      loop={true}
      style={{
        width: "250px",
        height: "250px",
      }}
    />
  );
};
