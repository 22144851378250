import React, { useEffect, useState } from "react";
import { Stack, Navbar } from "@mantine/core";
import { NavbarComponent } from "./Navbar";
import { ThemeToggle } from "./ThemeToggle";
import { navLinks } from "@containers/AppShell";

type Props = {
  navLinks: navLinks[];
  controls: navLinks[];
};

const NavContainer: React.FC<Props> = ({ navLinks, controls }) => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    const path = window.location.pathname.split("/").pop();

    const index = navLinks.findIndex((link) => link.route === path);
    setActive(index);
  }, []);

  const links = navLinks.map((link, index) => {
    const isDisabled = link.disabled || false;

    return (
      <NavbarComponent.Link
        {...link}
        key={link.label}
        active={index === active}
        onClick={() => {
          if (!isDisabled) {
            setActive(index);
          }
        }}
        disabled={isDisabled}
      />
    );
  });

  const controlsLinks = controls.map((link) => (
    <NavbarComponent.Link {...link} key={link.label} active={false} />
  ));

  return (
    <NavbarComponent>
      <Navbar.Section grow={true}>
        <Stack
          spacing="xs"
          justify="center"
          style={{
            gap: "2rem",
          }}
        >
          {links}
        </Stack>
      </Navbar.Section>
      <Navbar.Section
        style={{
          height: "30%",
          marginBottom: "0.5rem",
        }}
      >
        <Stack align="center" spacing="xs" h={100}>
          {controlsLinks}
          <ThemeToggle />
        </Stack>
      </Navbar.Section>
    </NavbarComponent>
  );
};

export default NavContainer;
