/**
 * Extracts the app ID from a pathname that follows the pattern `:workspaceId/pages/:appId/edit`
 * @param pathname - The full pathname from which to extract the app ID
 * @returns The extracted app ID, or null if not found
 */
export function extractAppIdFromPathname(pathname: string): string | null {
  const pathParts = pathname.split("/");
  const pagesIndex = pathParts.findIndex((part) => part === "pages");

  if (pagesIndex !== -1 && pagesIndex < pathParts.length - 1) {
    return pathParts[pagesIndex + 1];
  }

  return null;
}
