const { v4: uuidv4 } = require("uuid");

export function generateUniqueId() {
  return uuidv4();
}

export const santizeDefintion = (definition: any) => {
  const sanitized = JSON.parse(JSON.stringify(definition));
  return sanitized;
};
