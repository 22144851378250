export class ShareLinksGenerator {
  private encode(value: string): string {
    return encodeURIComponent(value);
  }

  /**
   * Generates a LinkedIn share link.
   * @param url The URL to share.
   * @returns The LinkedIn share link.
   */
  getLinkedInShareLink(url: string): string {
    const encodedUrl = this.encode(url);
    return `https://www.linkedin.com/feed/?shareActive=true&shareUrl=${encodedUrl}`;
  }

  /**
   * Generates a WhatsApp share link.
   * @param url The URL to share.
   * @param text Optional text message to include.
   * @returns The WhatsApp share link.
   */
  getWhatsAppShareLink(url: string, text?: string): string {
    const message = text ? `${text} ${url}` : url;
    const encodedMessage = this.encode(message);
    return `https://wa.me/?text=${encodedMessage}`;
  }

  /**
   * Generates an Email share link.
   * @param url The URL to share.
   * @param subject Optional email subject.
   * @param bodyText Optional email body text.
   * @returns The Email share link.
   */
  getEmailShareLink(url: string, subject?: string, bodyText?: string): string {
    const encodedSubject = this.encode(subject || "");
    const body = bodyText ? `${bodyText} ${url}` : url;
    const encodedBody = this.encode(body);
    return `mailto:?subject=${encodedSubject}&body=${encodedBody}`;
  }

  /**
   * Generates a Twitter share link.
   * @param url The URL to share.
   * @param text Optional tweet text.
   * @returns The Twitter share link.
   */
  getTwitterShareLink(url: string, text?: string): string {
    const encodedUrl = this.encode(url);
    const encodedText = this.encode(text || "");
    return `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedText}`;
  }

  /**
   * Handles Instagram sharing.
   * @returns A message explaining Instagram's sharing limitations.
   */
  getInstagramShareLink(): string {
    // Instagram does not support direct sharing via URL from web applications.
    // Consider using the Web Share API for mobile devices.
    return "Instagram sharing is not supported via direct URL. Please use the Web Share API on supported devices.";
  }

  /**
   * Generates a Facebook share link.
   * @param url The URL to share.
   * @returns The Facebook share link.
   */
  getFacebookShareLink(url: string): string {
    const encodedUrl = this.encode(url);
    return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
  }
}
