import { humanReadableCount } from "@/src/utils";
import {
  Badge,
  Center,
  Group,
  Paper,
  Stack,
  Text,
  UnstyledButton,
} from "@mantine/core";

import React from "react";
import { SocialIcon } from "react-social-icons";
import { Empty } from "./Empty";
import { DeviceAnalytics } from "@/src/components";
import { parseDomain, ParseResultType } from "parse-domain";
import _ from "lodash";

type Props = {
  sourceData: Record<string, any>[];
};

const SourceList: React.FC<Props> = ({ sourceData }) => {
  if (sourceData.length === 0) {
    return (
      <Center>
        <Empty />
      </Center>
    );
  }

  const forTwitter = ["twitter.com", "t.co"];

  return (
    <DeviceAnalytics.MainContainer>
      {sourceData.map((item, index) => {
        const parseResult = parseDomain(item["name"]);

        //@ts-ignore
        const domainName = forTwitter.includes(item["name"])
          ? "Twitter"
          : parseResult.type === ParseResultType.Listed
          ? _.capitalize(parseResult?.domain)
          : item["name"];

        return (
          <Paper key={index}>
            <UnstyledButton
              sx={(theme) => ({
                display: "block",
                width: "100%",
                padding: theme.spacing.md,
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[0]
                    : theme.black,

                "&:hover": {
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[8]
                      : theme.colors.gray[0],
                },
              })}
            >
              <Group position="apart">
                <Group>
                  <SocialIcon
                    url={
                      forTwitter.includes(item["name"])
                        ? "https://twitter.com"
                        : `https://${item["name"]}`
                    }
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                  />

                  <Text size="sm" weight={500}>
                    {(domainName as string) === "" ? "Direct" : domainName}
                  </Text>
                </Group>

                <Group position="apart">
                  {index === 0 && (
                    <Badge
                      size="xs"
                      radius={"sm"}
                      color="indigo"
                      variant="filled"
                    >
                      top
                    </Badge>
                  )}
                  <Text color="dimmed" size="xs" fw={500}>
                    {humanReadableCount(item["count"])} Clicks
                  </Text>
                </Group>
              </Group>
            </UnstyledButton>
          </Paper>
        );
      })}
    </DeviceAnalytics.MainContainer>
  );
};

export default SourceList;
