import React from "react";
import { Box, Grid, Select, Input, Loader } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";

interface SelectOption {
  value: string;
  label: string;
}

interface ControlsProps {
  sortOptions: SelectOption[];
  orderOptions: SelectOption[];
  searchPlaceholder: string;
  sortBy: string;
  order: string;
  searchTerm: string;
  onSortByChange: (value: string) => void;
  onOrderChange: (value: string) => void;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  visible: boolean;
}

export const Controls: React.FC<ControlsProps> = ({
  sortOptions,
  orderOptions,
  searchPlaceholder,
  sortBy,
  order,
  searchTerm,
  onSortByChange,
  onOrderChange,
  onSearchChange,
  visible,
}) => {
  return (
    <Box my={10}>
      <Grid>
        <Grid.Col xs={6} sm={6} md={6} lg={3} xl={3}>
          <Select
            size="xs"
            label="Sort by"
            placeholder="Pick one"
            value={sortBy}
            onChange={onSortByChange}
            data={sortOptions}
          />
        </Grid.Col>
        <Grid.Col xs={6} sm={6} md={6} lg={3} xl={3}>
          <Select
            size="xs"
            label="Order"
            placeholder="Pick one"
            value={order}
            onChange={onOrderChange}
            data={orderOptions}
          />
        </Grid.Col>

        <Grid.Col xl={"auto"} md={12} xs={12} sm={12} lg={"auto"}>
          <Input.Wrapper id="input-demo" label="Search" size="xs">
            <Input
              icon={<IconSearch size={18} />}
              placeholder={searchPlaceholder}
              value={searchTerm}
              onChange={onSearchChange}
              rightSection={visible && <Loader size="xs" />}
              size="xs"
            />
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
    </Box>
  );
};
