import React, { useEffect, useState } from "react";
import { Box, Switch, useMantineTheme } from "@mantine/core";
import { IconClockFilled } from "@tabler/icons-react";
import { DateTimePicker } from "@mantine/dates";
import { BlinkDirectives } from "@src/types/modules/blinks";

type Props = {
  callback: (type: keyof BlinkDirectives, data: any) => void;
};

export const LinkSchedule: React.FC<Props> = ({ callback }) => {
  const theme = useMantineTheme();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    callback("isSchedulingActive", checked);
  }, [checked]);

  const handleDebouncedChange = (value: Date) => {
    callback("scheduledFrom", value);
  };

  return (
    <Box className="mt-2">
      <Switch
        onChange={(e) => setChecked(e.target.checked)}
        size="xs"
        label="Schedule link"
        color="dark"
        thumbIcon={
          <IconClockFilled
            size={"0.6rem"}
            color={
              theme.colorScheme === "dark"
                ? theme.colors.teal[theme.fn.primaryShade()]
                : theme.colors.dark[theme.fn.primaryShade()]
            }
            stroke={3}
            style={{ cursor: "pointer" }}
          />
        }
      />
      {checked && (
        <div className="mt-2">
          <DateTimePicker
            clearable
            valueFormat="DD MMM YYYY hh:mm A"
            placeholder="Pick date and time"
            style={{ width: "100%" }}
            defaultValue={new Date()}
            mx="auto"
            size="xs"
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
            onChange={handleDebouncedChange}
          />
        </div>
      )}
    </Box>
  );
};
