import React from "react";
import {
  IconAlignCenter,
  IconAlignJustified,
  IconAlignLeft,
  IconAlignRight,
  IconAppWindow,
  IconBoxAlignLeftFilled,
  IconBoxAlignRightFilled,
  IconCarouselHorizontalFilled,
  IconCarouselVerticalFilled,
  IconExternalLink,
  IconLayoutAlignCenterFilled,
  IconImageInPicture,
} from "@tabler/icons-react";

export const RenderIcon: React.FC<{ iconName: string }> = ({ iconName }) => {
  switch (iconName) {
    case "vertical-carousel":
      return <IconCarouselVerticalFilled size={"1rem"} />;

    case "horizontal-carousel":
      return <IconCarouselHorizontalFilled size={"1rem"} />;

    case "start":
    case "start-align":
      return <IconBoxAlignLeftFilled size={"1rem"} />;
    case "end":
    case "end-align":
      return <IconBoxAlignRightFilled size={"1rem"} />;

    case "center":
      return <IconLayoutAlignCenterFilled size={"1rem"} />;

    case "left-align":
      return <IconAlignLeft size={"1rem"} />;

    case "right-align":
      return <IconAlignRight size={"1rem"} />;

    case "center-align":
      return <IconAlignCenter size={"1rem"} />;

    case "justify":
      return <IconAlignJustified size={"1rem"} />;

    case "self":
      return <IconAppWindow size={"1rem"} />;

    case "blank":
      return <IconExternalLink size={"1rem"} />;
    case "image":
      return <IconImageInPicture size={"1rem"} />;
    default:
      return null;
  }
};
