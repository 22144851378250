import { useMantineColorScheme } from "@mantine/core";
import NavigationIcon from "../NavigationIcon";
import React from "react";
import {
  useEditorStore,
  useEditorStoreActions,
} from "@/src/stores/editorstore";

const Index: React.FC<{}> = () => {
  const { setCurrentSegment } = useEditorStoreActions();
  const activeSegment = useEditorStore.use.activeSegment();

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <div
      className="d-flex flex-column"
      style={{ gap: "20px", justifyContent: "center", alignItems: "center" }}
    >
      <NavigationIcon
        icon="settings"
        label="Settings"
        onClick={() => setCurrentSegment("settings")}
        {...(activeSegment === "settings" && { color: "green" })}
        transitionProps={{ transition: "slide-down", duration: 300 }}
      />

      <NavigationIcon
        icon="clipboardData"
        onClick={() => setCurrentSegment("analytics")}
        {...(activeSegment === "analytics" && { color: "green" })}
        transitionProps={{ transition: "slide-down", duration: 300 }}
      />
      <NavigationIcon
        icon={colorScheme === "dark" ? "sun" : "moon"}
        label=""
        onClick={() => toggleColorScheme()}
        transitionProps={{ transition: "slide-down", duration: 300 }}
      />
    </div>
  );
};

export default Index;
