import React from "react";
import { Anchor } from "@mantine/core";
import { WidgetProps } from "../widgets/types";

const LinkComponent: React.FC<WidgetProps> = ({
  id,
  inheritedStyles,
  properties,
  control,
}) => {
  const { redirectTo } = control;
  const {
    text,
    textAlign,
    fontSize,
    fontWeight,
    fontFamily,
    color,
    cursor,
    truncate,
    underline,
    target,
  } = properties.props;

  return (
    <Anchor
      variant={"text"}
      sx={{ cursor: cursor.value || "pointer" }}
      size={fontSize?.value}
      align={textAlign?.value}
      weight={fontWeight?.value}
      className={`widget-${id} draggable-widget`}
      style={{
        ...inheritedStyles,
        width: "100%",
        display: "inline-block",
      }}
      color={color?.value}
      href={redirectTo.length > 0 ? redirectTo : undefined}
      target={target?.value || "_blank"}
      truncate={truncate?.value ?? true}
      underline={underline?.value ?? true}
    >
      {text?.value || redirectTo || ""}
    </Anchor>
  );
};

export default LinkComponent;
