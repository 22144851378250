import { DeviceAnalytics } from "@/src/components";
import { Center, Text } from "@mantine/core";
import React from "react";
import { Empty } from "./Empty";

type Props = {
  locationData: {
    country: string;
    city: string;
    value: string;
  }[];
};

const LocationList: React.FC<Props> = ({ locationData }) => {
  if (locationData.length === 0) {
    return (
      <Center>
        <Empty />
      </Center>
    );
  }

  return (
    <DeviceAnalytics.MainContainer>
      {locationData.map((item) => (
        <DeviceAnalytics.RenderItem
          key={item.city}
          forLocation={true}
          name={item.city}
          value={item.value}
          substring={item.country}
        />
      ))}
    </DeviceAnalytics.MainContainer>
  );
};

export default LocationList;
