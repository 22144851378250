import React from "react";

import { Bot, TrendingUp } from "lucide-react";
import { CartesianGrid, Line, LineChart, XAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Paper, Text, ThemeIcon, Tooltip } from "@mantine/core";
import {
  IconDeviceMobileFilled,
  IconDevices,
  IconDevices2,
} from "@tabler/icons-react";
import { TChartData } from ".";

const chartConfig = {
  desktop: {
    label: "Desktop",
    color: "red",
  },
  mobile: {
    label: "Mobile",
    color: "green",
  },
} satisfies ChartConfig;

const DeviceRadialChart: React.FC<{ chartData: TChartData[] }> = ({
  chartData,
}) => {
  return (
    <Paper
      p="md"
      radius="md"
      shadow="md"
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === "dark" ? theme.colors.dark[9] : "white",
      })}
    >
      <Tooltip label="Device data" color="indigo">
        <ThemeIcon color="teal" variant="light">
          <IconDevices size={20} />
        </ThemeIcon>
      </Tooltip>
      <ChartContainer config={chartConfig}>
        <LineChart
          accessibilityLayer
          data={chartData}
          margin={{
            left: 12,
            right: 12,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="date"
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            tickFormatter={(value) =>
              new Date(value).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
              })
            }
          />
          <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
          <Line
            dataKey="desktop"
            type="monotone"
            stroke="var(--color-desktop)"
            strokeWidth={2}
            dot={false}
          />
          <Line
            dataKey="mobile"
            type="monotone"
            stroke="var(--color-mobile)"
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ChartContainer>
    </Paper>
  );
};

export default DeviceRadialChart;
