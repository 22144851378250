import React from "react";
import { FeatureList } from "@/src/components/Comingsoon";

import {
  Box,
  Button,
  Center,
  Grid,
  Group,
  Paper,
  Stack,
  Text,
} from "@mantine/core";
import { IconBrandDiscordFilled } from "@tabler/icons-react";

import { CollectWaitlist } from "@/src/components/CollectEmailsforWaitlist";

type Props = {};

const index = (props: Props) => {
  const screenWidth: number = window.innerWidth;
  const isMobileView = screenWidth < 768;
  return (
    <div className="wait-list-page">
      <Grid>
        <Grid.Col xs={12} sm={12} md={12} lg={4} xl={4}></Grid.Col>
        <Grid.Col xs={12} sm={12} md={12} lg={4} xl={4}></Grid.Col>
        <Grid.Col xs={12} sm={12} md={12} lg={6} xl={6}>
          <Paper
            shadow="lg"
            p={"md"}
            style={{
              width: isMobileView ? "100%" : "25rem",
              height: "fit-content",
              position: isMobileView ? "relative" : "absolute",
              top: isMobileView ? "" : "25%",
              left: isMobileView ? "" : "55%",
              background: "transparent",
            }}
          >
            <Stack align="flex-start" spacing={"xs"}>
              <Group spacing={"xs"} position="apart">
                <Text
                  variant="gradient"
                  gradient={{ from: "indigo", to: "cyan", deg: 45 }}
                  sx={{ fontFamily: "Greycliff CF, sans-serif" }}
                  ta="center"
                  fz="lg"
                  fw={700}
                >
                  Be the First to Access Exciting New Features!
                </Text>
              </Group>

              <Group>
                <Text variant="text" color="dimmed" fz={"xs"}>
                  Our latest update is just around the corner. Join the waitlist
                  now and be among the first to experience our powerful new
                  tools. Get early access, stay informed, and unlock exclusive
                  features before anyone else.
                </Text>

                <FeatureList fontColor="white" />
              </Group>
              <Group position="apart">
                <CollectWaitlist />
                <Button
                  variant={"filled"}
                  size="xs"
                  onClick={() => {
                    window.open("https://discord.gg/RzUA2R6g", "_blank");
                  }}
                  sx={(theme) => ({
                    backgroundColor: "#5865f2",
                    color: "fff",

                    "&:hover": {
                      backgroundColor: theme.colors.indigo[7],
                    },
                  })}
                  leftIcon={<IconBrandDiscordFilled size={"1rem"} />}
                >
                  Join our Discord
                </Button>
              </Group>
            </Stack>
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default index;
