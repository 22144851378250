import React, { forwardRef } from "react";
import { Group, Avatar, Text, Select, Box, Badge } from "@mantine/core";
import { IconLayoutDashboardFilled, IconTextResize } from "@tabler/icons-react";
import { ComingSoon } from "@/src/components/Substitutes";

const data = [
  {
    image: () => <IconTextResize size="1.5rem" />,
    label: "Use Drag and Drop UI Builder",
    value: "dnd-builder",
    description: "Create your page layout with drag and drop builder",
  },
  {
    image: () => <IconLayoutDashboardFilled size="1rem" />,
    label: "Pre-defined layout",
    value: "predefined-layout",
    description: "Customize predefined layout",
    disabled: true,
    commingSoon: true,
  },
];

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: () => JSX.Element;
  label: string;
  description: string;
  commingSoon?: boolean;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        {image()}

        <div>
          <Group position="apart" spacing={"xl"}>
            <Text fw={500} size="sm">
              {label}
            </Text>
            {others.commingSoon && (
              <Badge color="indigo" variant="filled" size="xs" radius={"sm"}>
                ✨ Coming soon
              </Badge>
            )}
          </Group>

          <Text size="xs" opacity={0.65}>
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

export const PageLayoutSelector: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => {
  return (
    <Select
      size="xs"
      label="Select page layout"
      placeholder="Select page layout"
      itemComponent={SelectItem}
      value={value}
      data={data}
      searchable
      required
      clearable
      withAsterisk
      withinPortal
      maxDropdownHeight={400}
      nothingFound="Nobody here"
      filter={(value, item) =>
        (item.label &&
          item.label.toLowerCase().includes(value.toLowerCase().trim())) ||
        item.description.toLowerCase().includes(value.toLowerCase().trim())
      }
      onChange={(value) => value && onChange(value)}
      styles={(theme) => ({
        item: {
          // applies styles to selected item
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[9]
                  : theme.colors.gray[3],
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors.dark[9],
            },
          },
        },
      })}
    />
  );
};
