import React from "react";
import Publish from "./Publish";
import Preview from "./Preview";
import Share from "./Share";
import { Box, Group } from "@mantine/core";
import { useEditorStore } from "@/src/stores/editorstore";
interface Props {}

const Index: React.FC<Props> = (props) => {
  const appBlinkURL = useEditorStore.use.appBlinkURL();

  return (
    <Box>
      <Group position="right">
        <Preview />
        <Share url={appBlinkURL} />
        <Publish />
      </Group>
    </Box>
  );
};

export default Index;
