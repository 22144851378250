import { appConfig } from "@src/app.config";
import { handleResponseWithoutValidation } from "@src/lib/handleResponses";
import { authHeader } from "@src/lib/requestHeaders";
import { IQuickAccess } from "./types";

export const workspaceService = {
  getWorkspaceData,
  onLoad,
  getAppById,
  getAllApps,
  getAllBlinks,
  addToQuickAccess,
  updateQuickAccess,
};

async function getWorkspaceData(workspaceId: string): Promise<any> {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/workspaces/${workspaceId}/meta`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function onLoad(workspaceId: string) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/workspaces/${workspaceId}/on-load`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function getAppById(workspaceId: string, appId: string): Promise<any> {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/workspaces/${workspaceId}/${appId}`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function getAllApps(workspaceId: string): Promise<any> {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/workspaces/${workspaceId}/apps`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function getAllBlinks(workspaceId: string): Promise<any> {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/workspaces/${workspaceId}/blinks`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function addToQuickAccess(
  workspaceId: string,
  data: Partial<IQuickAccess>
): Promise<any> {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${appConfig.apiURL}/workspaces/${workspaceId}/quick-access`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function updateQuickAccess(
  workspaceId: string,
  data: Partial<IQuickAccess>
): Promise<any> {
  const requestOptions = {
    method: "PATCH",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${appConfig.apiURL}/workspaces/${workspaceId}/quick-access`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}
