export function handleResponseWithoutValidation(
  response: Response
): Promise<any> {
  const contentType = response.headers.get("content-type");

  return response
    .text()
    .then((text: string) => {
      try {
        // Check if the content type is JSON
        if (contentType && contentType.includes("application/json")) {
          const data = text ? JSON.parse(text) : null;

          if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject({ error, data });
          }

          return data;
        } else {
          // Handle non-JSON response
          if (!response.ok) {
            const error = text || response.statusText;
            return Promise.reject({ error, data: text });
          }
          return text; // or you can return a custom object with the URL
        }
      } catch (error) {
        // Handle any errors that occur during processing
        console.error("Error processing response:", error);
        return Promise.reject({
          error: "Error processing response",
          originalError: error,
        });
      }
    })
    .catch((error) => {
      // Handle any network errors or errors in reading the response
      console.error("Error reading response:", error);
      return Promise.reject(error);
    });
}

export interface IResponseError {
  error: string;
  originalError?: any;
}
