import React from "react";
import { Link, useParams } from "react-router-dom";

interface ILinkTo {
  to: string;
  children: React.ReactNode;
}

const LinkTo: React.FC<ILinkTo> = ({ to, children }) => {
  const { workspaceId } = useParams();
  const url = window.location.pathname;

  const navigateTo = "app" + workspaceId ? `/${workspaceId}/${to}` : to;

  return (
    <React.Fragment>
      <Link to={navigateTo}>{children}</Link>
    </React.Fragment>
  );
};

export default LinkTo;
