import { appConfig } from "@src/app.config";
import { handleResponseWithoutValidation } from "@src/lib/handleResponses";
import { authHeader } from "@src/lib/requestHeaders";
import { CreateBlinkDto, CreateBlinkWithDirectivesDto } from "./types";

export const blinkService = {
  create: create,
  getOne: getOne,
  getAppBlinkURL: getAppBlinkURL,
  update: update,
  deleteOne: deleteOne,
  createWithDirectives: createWithDirectives,
  updateDirectives: updateBlinkDirectives,
  enableOrDisableDirectives: enableOrDisableDirectives,
  updateAnalytics: updateAnalytics,
  fetchSocialPreview: fetchSocialPreview,
  validateBlink: validateBlink,
  getFallbackURL: getFallbackURL,
  getUniqueVisitorsCount: getUniqueVisitorsCount,
};

async function create(blinkData: CreateBlinkDto): Promise<any> {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(blinkData),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(`${appConfig.apiURL}/blinks`, requestOptions);
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function createWithDirectives(
  blinkData: CreateBlinkWithDirectivesDto
): Promise<any> {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(blinkData),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/blinks/with-directives`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function deleteOne(blinkId: string) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/blinks/${blinkId}`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}
async function getOne(blinkId: string, queryParams?: Record<string, string>) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const params = new URLSearchParams(queryParams).toString();

  const response = await fetch(
    `${appConfig.apiURL}/blinks/${blinkId}?${params}`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function getAppBlinkURL(blinkId: string) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/blinks/${blinkId}/url`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function enableOrDisableDirectives(id: string, enable: boolean) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({ enable }),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/blinks/${id}/enable-directives`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function updateAnalytics(
  id: string,
  updateAnalytics: Record<string, boolean>
) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(updateAnalytics),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/blinks/${id}/analytics`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function update(id: string, updateBlinkDto: any) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(updateBlinkDto),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/blinks/${id}`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function updateBlinkDirectives(id: string, updateDirectives: any) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(updateDirectives),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/blinks/${id}/directives`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function fetchSocialPreview(url: string) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ url }),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/link-preview`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function validateBlink(blinkDomain: string, blinkSignature: string) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ blinkDomain, blinkSignature }),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/blinks/validate`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function getFallbackURL(shortUrl: string) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/blinks/redirect/fallback?shortUrl=${shortUrl}`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function getUniqueVisitorsCount(
  id: string,
  startDate?: string,
  endDate?: string
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/blinks/${id}/unique/count?startDate=${startDate}&endDate=${endDate}`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}
