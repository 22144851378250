import React, { useEffect, useState } from "react";
import {
  TextInput,
  PasswordInput,
  Anchor,
  Paper,
  Text,
  Button,
  Center,
  Box,
  Grid,
  Group,
  Divider,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import Logo from "@assets/logo.svg";
import { Image } from "@UIComponents/BaseUI";
import {
  authenticationService,
  authTokenSessionService,
} from "@src/services/auth.service";
import toast, { Toaster } from "react-hot-toast";
import { useAppStoreActions } from "@src/stores/appstore";
import { IUserMetaData } from "@src/stores/appstore/types";
import { IconEyeCheck, IconEyeOff } from "@tabler/icons-react";
import { useWorkspaceActions } from "@/src/stores/workspace";
import { findDefaultWorkspace, IWorkspaceUsers } from "@/src/lib/utils";
import { useSubdomainRedirect } from "@/src/hooks";
import { appConfig } from "@/src/app.config";
import { GoogleButton } from "@/src/components/SSO/Google";

type IUserMetadataWithRequiredWorkspace<TWorkspaceUsers> =
  Partial<IUserMetaData> & {
    workspaceUsers: TWorkspaceUsers[];
  };

function Login() {
  useSubdomainRedirect(["app"]);
  const navigate = useNavigate();

  const defaultDomain =
    appConfig.environment === "production" ? "b-link.co" : "localhost:8080";
  const protocol = appConfig.environment === "production" ? "https" : "http";

  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const handleOnChange = (value: string, type: string) => {
    if (type === "email") {
      setUserData({ ...userData, email: value });
    } else {
      setUserData({ ...userData, password: value });
    }
  };

  const handleKeyPassword = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSignIn();
    }
  };
  const handleSignIn = () => {
    authenticationService
      .login(userData.email, userData.password)
      .then((res) => {
        if (res.statusCode === 400) {
          setTimeout(() => {
            close();
            window.location.href = res.Redirect as string;
          }, 1000);

          return Promise.reject(res);
        }

        return res;
      })
      .then((data: IUserMetadataWithRequiredWorkspace<IWorkspaceUsers>) => {
        const workspaceId = data?.defaultWorkspaceId;
        const currentSubdomain = window.location.hostname.split(".")[0];
        const defaultWorkspace = findDefaultWorkspace(data.workspaceUsers);
        const subdomain = defaultWorkspace.workspaceName;

        useAppStoreActions()?.updateUserMetaData({
          currentWorkspaceId: workspaceId,
          defaultWorkspaceId: workspaceId,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
        });

        useAppStoreActions()?.setSubdomain(subdomain);

        useWorkspaceActions()?.setWorkspaceUsers(data.workspaceUsers);

        const token = authTokenSessionService.sessionValue().token;

        // Redirect to the appropriate subdomain
        if (subdomain !== currentSubdomain) {
          window.location.href = `${protocol}://${subdomain}.${defaultDomain}/${workspaceId}/dashboard?t_id=${token}`;
        } else {
          navigate(`/${workspaceId}/dashboard`);
        }
      })
      .catch((err) => {
        toast.error(err?.error || err?.message || "An error occurred");
      });
  };

  const OAuthRedirectURI =
    appConfig.environment === "production"
      ? "https://auth.b-link.co/api/authentication/google/callback"
      : "http://localhost:3000/api/authentication/google/callback";

  return (
    <div className="wait-list-page">
      <Toaster
        position="top-center"
        reverseOrder={true}
        toastOptions={{
          style: {
            background: "#25262b",
            color: "#fff",
          },
        }}
      />
      <Grid>
        <Grid.Col
          xs={12}
          sm={12}
          md={7}
          lg={7}
          xl={7}
          className="p-0"
        ></Grid.Col>
        <Grid.Col
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Paper
            // withBorder
            shadow="md"
            p={30}
            radius="md"
            sx={(theme) => ({
              backgroundColor: theme.colors.dark[9],
            })}
          >
            <Box className="mb-3">
              <Center>
                <Image
                  className="bridge-logo root__logo"
                  src={Logo}
                  width={50}
                  height={50}
                  alt="Logo"
                />
              </Center>
              <Text c="white" fw={600} size="sm" ta="center" mt={5}>
                Do not have an account yet?{" "}
                <Anchor
                  fw={600}
                  size="sm"
                  component="button"
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                  Create account
                </Anchor>
              </Text>
            </Box>
            <TextInput
              label="Email"
              placeholder="user@b-link.co"
              required
              onChange={(event) => {
                handleOnChange(event.currentTarget.value, "email");
              }}
              labelProps={{ style: { color: "white" } }}
              sx={(theme) => ({
                "& input": {
                  color: "white",
                  backgroundColor: theme.colors.dark[9],
                  borderColor: theme.colors.dark[4],
                },
              })}
            />
            <PasswordInput
              onChange={(event) => {
                handleOnChange(event.currentTarget.value, "password");
              }}
              className="mt-3"
              labelProps={{ style: { color: "white" } }}
              label="Password"
              placeholder="***********"
              visibilityToggleIcon={({ reveal, size }) =>
                reveal ? (
                  <IconEyeOff size={size} />
                ) : (
                  <IconEyeCheck size={size} />
                )
              }
              sx={(theme) => ({
                "& input": {
                  color: "white",
                  backgroundColor: theme.colors.dark[9],
                },

                ".mantine-PasswordInput-input": {
                  color: "white",
                  backgroundColor: theme.colors.dark[9],
                  border: `1px solid ${theme.colors.dark[4]}`,
                },

                " .mantine-16c6coe:hover": {
                  backgroundColor: theme.colors.dark[4],
                },
              })}
              onKeyDown={handleKeyPassword}
            />
            <Group position="apart" mt="lg">
              <Anchor
                component="button"
                size="sm"
                onClick={() => {
                  navigate("/forgot-password");
                }}
              >
                Forgot password?
              </Anchor>
            </Group>
            <Button
              fullWidth
              mt="xl"
              variant="filled"
              color="indigo"
              onClick={handleSignIn}
            >
              Sign in
            </Button>
            <Divider
              my="xs"
              label="OR"
              labelPosition="center"
              labelProps={{
                style: {
                  color: "white",
                  backgroundColor: "transparent",
                },
              }}
            />
            <GoogleButton
              fullWidth
              onClick={() => {
                window.location.href = OAuthRedirectURI;
              }}
            >
              Continue with Google
            </GoogleButton>
          </Paper>
        </Grid.Col>
        <Grid.Col span={1}></Grid.Col>
      </Grid>
    </div>
  );
}
export default Login;
