import React from "react";
import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Center,
  Group,
  Image,
  Overlay,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import {
  useEditorStore,
  useEditorStoreActions,
} from "@/src/stores/editorstore";
import { templateService } from "@/src/services/template.service";
import { Car } from "lucide-react";
import { IconEye } from "@tabler/icons-react";

interface Background {
  type: string;
  gradient: string;
  backgroundColor: string;
  backgroundImage: string;
}

interface App {
  id: string;
  blinkId: string;
  background: Background;
}

interface Blink {
  id: string;
  name: string;
  blinkDomain: string;
  blinkSignature: string;
}

interface PageTemplate {
  id: string;
  name: string;
  appId: string;
  template: boolean;
  app: App;
  created_at: Record<string, never>; // or Date if you prefer
  updated_at: Record<string, never>; // or Date if you prefer
  blink: Blink;
}

// If you need to pass an array of these templates:
export type PageTemplates = PageTemplate[];

type Props = {
  PageTemplate: PageTemplate;
};

const TemplateCard: React.FC<Props> = ({ PageTemplate }) => {
  const previewLink = `${PageTemplate.blink.blinkDomain}/${PageTemplate.blink.blinkSignature}`;
  const isLoading = useEditorStore.use.isLoadingOverlayActive();

  const currentPageId = useEditorStore.use.currentPageId();
  const templateId = PageTemplate.id;

  const [isHovered, setHovered] = React.useState(false);

  const handleCreateFromTemplate = () => {
    useEditorStoreActions()?.setLoadingOverlay(true);
    templateService
      .createFromPage(templateId, currentPageId)
      .then((res) => {
        window.location.reload();
      })
      .finally(() => {
        useEditorStoreActions()?.setLoadingOverlay(false);
      });
  };

  return (
    <Card
      mih={200}
      p="sm"
      shadow="sm"
      radius="md"
      style={{
        backgroundImage: `url("https://storage.googleapis.com/blink-pages/templates/${templateId}-template.png")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        cursor: "pointer",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Group className="" position="apart">
        <Tooltip
          label="Preview Template"
          position="top"
          withArrow
          color="indigo"
        >
          <ThemeIcon
            onClick={() => window.open(previewLink, "_blank")}
            color="dark"
          >
            <IconEye size={20} />
          </ThemeIcon>
        </Tooltip>
      </Group>

      {isHovered && (
        <Center
          className="sticky-bottom "
          style={{
            position: "absolute",
            zIndex: 999,
            width: "85%",
          }}
        >
          <Button
            className="p-2 mb-2"
            variant={"light"}
            color={"indigo"}
            size="xs"
            onClick={handleCreateFromTemplate}
            loading={isLoading}
          >
            Create from Template
          </Button>
        </Center>
      )}
    </Card>
  );
};

export default TemplateCard;
