import React, { useCallback, useEffect } from "react";
import {
  Accordion,
  ColorInput,
  SegmentedControl,
  Select,
  TextInput,
  Stack,
  Container,
  Box,
  Text,
  Menu,
  ActionIcon,
  Modal,
  Group,
  Button,
  Center,
} from "@mantine/core";
import _, { debounce } from "lodash";
import { useEditorStore, useEditorStoreActions } from "@src/stores/editorstore";

import { Widget } from "@src/modules/Pages/widgets/types";
import { DynamicAccordionItem } from "./DynamicComponent";
import {
  IconArtboardFilled,
  IconDots,
  IconEdit,
  IconLockSquareRoundedFilled,
  IconTrashX,
  IconUnlink,
} from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { Workflow } from "lucide-react";

type Props = {
  children: React.ReactNode;
};

type AccordionContainerProps = {
  data?: Record<string, any>[];
  widgetId: string;
};

interface Composition {
  Header: React.FC<{
    segment: string;
    onChange: (value: string) => void;
    enableControl: boolean;
  }>;
  AccordionContainer: React.FC<AccordionContainerProps>;
  CommonContainer: React.FC<{ widgetName?: string }>;
}

const BaseContainer: React.FC<Props> & Composition = ({ children }) => {
  const openInspector = useEditorStore(
    (state) => state.selectedWidget !== null
  );

  if (!openInspector) return null;

  return <Container className="w-100 p-0">{children}</Container>;
};

const Header: React.FC<{
  segment: string;
  onChange: (value: string) => void;
  enableControl: boolean;
}> = ({ segment, onChange, enableControl }) => {
  return (
    <SegmentedControl
      size="xs"
      fullWidth
      value={segment}
      onChange={(value) => onChange(value)}
      data={[
        {
          value: "properties",
          label: (
            <Center>
              <IconArtboardFilled size="1rem" />
              <Box ml={5}>Design</Box>
            </Center>
          ),
        },
        {
          value: "control",
          label: (
            <Center>
              <IconUnlink size="1rem" />
              <Box ml={5}>Add Link</Box>
            </Center>
          ),
        },
        {
          value: "current_actions",
          disabled: true,
          label: (
            <Center>
              <Workflow size={"1rem"} />
              <Box ml={5}>Add Actions</Box>
            </Center>
          ),
        },
      ]}
    />
  );
};

const AccordionContainer: React.FC<AccordionContainerProps> = React.memo(
  ({ data, widgetId }) => {
    const items: Record<string, any>[] = data
      ? data?.map((item) => {
          return {
            ...item[1],
          };
        })
      : [];

    const { updateWidgetDefinition } = useEditorStoreActions();
    const currentWidget = _.cloneDeep(
      useEditorStore
        .getState()
        .definitions.widgets.find((widget) => widget.id === widgetId)
    ) as Widget;

    const handleComponentPropertiesChange = (
      property: string,
      value: string | boolean | number | Record<string, any>,
      childProps = false
    ) => {
      if (currentWidget) {
        const properties = JSON.parse(
          JSON.stringify(currentWidget.properties["props"])
        );

        if (childProps) {
          properties["children"]["childProps"][property] = value;
        } else {
          properties[property] = {
            ...properties[property],
            value: value,
          };
        }

        const newWidgetDefinition = {
          ...currentWidget,
          properties: {
            ...currentWidget.properties,
            props: properties,
          },
        };

        updateWidgetDefinition(
          widgetId,
          newWidgetDefinition,
          "widgetDefinition"
        );
      }
    };

    return (
      <Accordion
        defaultValue={"customization"}
        variant="separated"
        styles={(theme) => ({
          content: {
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          },
        })}
      >
        <Accordion.Item value={"customization"}>
          <Accordion.Control>Customization</Accordion.Control>
          <Accordion.Panel>
            <Stack spacing="xs" style={{ gap: "0.5rem" }}>
              {items.map((item, index) => {
                if (item.section !== "customization") return null;

                return (
                  <DynamicAccordionItem
                    key={index}
                    item={item}
                    handleComponentPropertiesChange={
                      handleComponentPropertiesChange
                    }
                    currentWidgetDefinition={currentWidget}
                  />
                );
              })}
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
        {
          //hide flexibility section if there are no items
          items.filter((item) => item.section === "flexibility").length > 0 && (
            <Accordion.Item value={"flexibility"}>
              <Accordion.Control>Flexibility</Accordion.Control>
              <Accordion.Panel>
                {items.map((item, index) => {
                  if (item.section !== "flexibility") return null;

                  return (
                    <DynamicAccordionItem
                      key={index}
                      item={item}
                      handleComponentPropertiesChange={
                        handleComponentPropertiesChange
                      }
                      currentWidgetDefinition={currentWidget}
                    />
                  );
                })}
              </Accordion.Panel>
            </Accordion.Item>
          )
        }
      </Accordion>
    );
  }
);

export const CustomSelect: React.FC<{
  item: Record<string, any>;
  handleComponentPropertiesChange: (
    property: string,
    value: string,
    childProps?: boolean
  ) => void;
}> = ({ item, handleComponentPropertiesChange }) => {
  const options = item?.options || [];
  const selectedValue = item?.value || "";

  const conditionalRendererActive = item?.key === "border_radius";

  return (
    <div onMouseDown={(e) => e.stopPropagation()}>
      <Select
        data={options}
        defaultValue={selectedValue}
        placeholder="Pick one"
        label={item.name}
        variant="filled"
        size="xs"
        onChange={(value) => {
          value && handleComponentPropertiesChange(item.key, value);
        }}
        withinPortal={false}
      />
      {conditionalRendererActive && <TextInput size="xs" />}
    </div>
  );
};

export const CustomSelector: React.FC<{
  item: Record<string, any>;
  callback: (param: string, value: string, childProps?: boolean) => void;
}> = ({ item, callback }) => {
  const childrenPropesToRender = item?.value ?? null;
  const childrenProps = item?.childProps[childrenPropesToRender] ?? null;

  const handleUpdate = (key: string, value: string, subparam?: string) => {
    if (!subparam) {
      const newProps = { ...childrenProps, [key]: value };
      callback(childrenPropesToRender, newProps, true);
    } else {
      const newProps = {
        ...childrenProps,
        [subparam]: value,
      };
      callback(childrenPropesToRender, newProps, true);
    }
  };

  const debounceHandleUpdate = useCallback(
    debounce(
      (key: string, value: string, subparam?: string) =>
        handleUpdate(key, value),
      500
    ),
    []
  );

  return (
    <React.Fragment>
      <CustomSelect item={item} handleComponentPropertiesChange={callback} />

      {childrenPropesToRender === "cta" && (
        <CallToActionElement
          childrenProps={childrenProps}
          callback={(subparam, value) => {
            handleUpdate(childrenPropesToRender, value, subparam);
          }}
          debounceCallback={debounceHandleUpdate}
        />
      )}
      {childrenPropesToRender === "image" && (
        <ImageElement
          src={childrenProps.src}
          alt={childrenProps.alt}
          callback={(val) => {
            handleUpdate("src", val);
          }}
        />
      )}

      {childrenPropesToRender === "text" ||
      childrenPropesToRender === "subtext" ? (
        <TextInput
          size="xs"
          placeholder="Your name"
          label={_.capitalize(childrenPropesToRender)}
          defaultValue={childrenProps.value}
          onChange={(event) => {
            const key =
              childrenPropesToRender === "text" ? "title" : "subtitle";
            debounceHandleUpdate(key, event.currentTarget.value);
          }}
        />
      ) : null}
    </React.Fragment>
  );
};

const CallToActionElement: React.FC<{
  childrenProps: any;
  callback: (subparam: string, val: string) => void;
  debounceCallback: (subparam: string, val: string) => void;
}> = ({ childrenProps, callback, debounceCallback }) => {
  const props = Object.keys(childrenProps).map((key) => {
    return {
      label: key,
      value: childrenProps[key],
    };
  });

  return (
    <div>
      {props.map((prop, index) => {
        if (prop.label === "color" || prop.label === "text color") {
          return (
            <ColorInput
              key={index}
              defaultValue={prop.value}
              label={_.capitalize(prop.label)}
              format="hex"
              swatches={[
                "#25262b",
                "#868e96",
                "#fa5252",
                "#e64980",
                "#be4bdb",
                "#7950f2",
                "#4c6ef5",
                "#228be6",
                "#15aabf",
                "#12b886",
                "#40c057",
                "#82c91e",
                "#fab005",
                "#fd7e14",
              ]}
              onChange={(color) => color && callback(prop.label, color)}
              withinPortal={false}
            />
          );
        }

        return (
          <TextInput
            key={index}
            size="xs"
            placeholder="Your name"
            label={_.capitalize(prop.label)}
            defaultValue={prop.value}
            onChange={(event) =>
              debounceCallback(prop.label, event.currentTarget.value)
            }
          />
        );
      })}
    </div>
  );
};

const ImageElement: React.FC<{
  src: string;
  callback: (val: string) => void;
  alt?: string;
}> = ({ src, callback, alt = "" }) => {
  const handleChange = useCallback(
    debounce((value) => callback(value), 500),
    []
  );
  return (
    <div>
      <TextInput
        size="xs"
        placeholder="Your name"
        label="src"
        defaultValue={src}
        onChange={(event) => handleChange(event.currentTarget.value)}
      />
      <TextInput
        size="xs"
        placeholder="Your name"
        label="alt"
        defaultValue={alt}
      />
    </div>
  );
};

const CommonContainer: React.FC<{ widgetName?: string }> = ({
  widgetName = "",
}) => {
  const { widgets } = useEditorStore.use.definitions();
  const widgetId = useEditorStore.use.selectedWidget() || "";

  const [opened, { toggle, close }] = useDisclosure(false);
  const [name, setName] = React.useState(widgetName || "");
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => setName(widgetName), [widgetName]);

  const widgetListOnCanvas = widgets.map((widget) => {
    return {
      value: widget.id,
      label: widget.name,
    };
  });

  const { lockWidget, unlockWidget, removeWidget, setSelectedWidget } =
    useEditorStoreActions();

  const isLocked = useEditorStore((state) =>
    state.lockedWidgets.includes(widgetId)
  );

  const handleSave = () => {
    setIsLoading(true);
    useEditorStoreActions().renameWidget(widgetId, name);
    setIsLoading(false);
    close();
  };
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Text size="md" weight={500}>
        <Select
          value={widgetId}
          onChange={(value) => {
            setSelectedWidget(value);
          }}
          data={widgetListOnCanvas}
          variant="unstyled"
          styles={(theme) => ({
            item: {
              // applies styles to selected item
              "&[data-selected]": {
                "&, &:hover": {
                  backgroundColor: theme.colors.indigo[4],
                },
              },
            },
          })}
        />
      </Text>
      <Box>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <ActionIcon size="lg" variant="transparent">
              <IconDots size="1.625rem" />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              onClick={(e) => {
                // e.preventDefault();
                // e.stopPropagation();
                toggle();
              }}
              icon={<IconEdit size="1rem" />}
            >
              Rename
            </Menu.Item>
            <Menu.Item
              onClick={(e) => {
                e.stopPropagation();
                isLocked ? unlockWidget(widgetId) : lockWidget(widgetId);
              }}
              icon={<IconLockSquareRoundedFilled size="1rem" />}
            >
              Lock/unlock
            </Menu.Item>
            <Menu.Item
              color="red"
              onClick={(e) => {
                e.stopPropagation();
                removeWidget(widgetId);
              }}
              icon={<IconTrashX size="1rem" />}
            >
              Delete
            </Menu.Item>
          </Menu.Dropdown>
          <Modal opened={opened} onClose={close} centered>
            <Text size="sm" mb="xs" weight={500}>
              Rename widget
            </Text>

            <Group align="flex-end">
              <TextInput
                size="xs"
                placeholder=""
                sx={{ flex: 1 }}
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                data-autofocus
              />
              <Button
                color="dark"
                variant="filled"
                size="xs"
                onClick={handleSave}
                loading={isLoading}
              >
                Save
              </Button>
            </Group>
          </Modal>
        </Menu>
      </Box>
    </Box>
  );
};

BaseContainer.Header = Header;
BaseContainer.AccordionContainer = AccordionContainer;
BaseContainer.CommonContainer = CommonContainer;
export default BaseContainer;
