import {
  CreateBlinkDto,
  CreateBlinkWithDirectivesDto,
} from "@src/services/types";
import { create, createSelectors } from "../utils";
import { State } from "./types";
import { blinkService } from "@src/services/blinks.services";
import toast from "react-hot-toast";
import { IResponseError } from "@/src/lib/handleResponses";
import { workspaceService } from "@/src/services/workspace.service";

const initialState: State = {
  blinks: [],
  actions: null,
};

const useBaseBlinksStore = create<State>()((set, get) => ({
  ...initialState,
  actions: {
    reset: () => {
      set(initialState);
    },
    createBlinkWithDirectives: async (
      blinkData: CreateBlinkWithDirectivesDto
    ) => {
      const blink = await blinkService.createWithDirectives(blinkData);
      const currentBlinks = get().blinks as any;

      currentBlinks.push(blink);

      set({ blinks: currentBlinks });
      return new Promise((resolve) => resolve());
    },
    create: (
      payload: CreateBlinkDto | CreateBlinkWithDirectivesDto,
      withDirectives: boolean
    ): Promise<void> => {
      if (withDirectives) {
        return get().actions?.createBlinkWithDirectives(
          payload as CreateBlinkWithDirectivesDto
        ) as Promise<void>;
      } else {
        return get().actions?.createBlink(
          payload as CreateBlinkDto
        ) as Promise<void>;
      }
    },
    createBlink: async (blinkData: CreateBlinkDto) => {
      try {
        const blink = await blinkService.create(blinkData);

        const currentBlinks = get().blinks as any;
        currentBlinks.push(blink);

        set({ blinks: currentBlinks });

        return Promise.resolve({ status: "ok" });
      } catch (err: IResponseError | any) {
        return Promise.reject(err);
      }
    },
    fetchByBlinkById: (blinkId: string) => null,
    fetchAllBlinks: async (workspaceId: string) => {
      const allBlinks = await workspaceService.getAllBlinks(workspaceId);

      set({ blinks: allBlinks });

      return new Promise((resolve) => resolve());
    },
    updateBlinkConfig: (config: Partial<CreateBlinkDto>) => null,
    updateBlinkDirectives: (
      blinkId: string,
      directives: Record<string, string>
    ) => null,
    delete: async (blinkId: string) => {
      const blinkName = get().blinks.find((blink: any) => blink.id === blinkId);

      const response: { message: string; deleteSuccess: string } =
        await blinkService.deleteOne(blinkId);

      if (response.deleteSuccess) {
        const currentBlinks = get().blinks as any;
        const updatedBlinks = currentBlinks.filter(
          (blink: any) => blink.id !== blinkId
        );
        set({ blinks: updatedBlinks });
        toast.success(`Blink ${blinkName?.name} deleted`);
      } else {
        console.error(response.message);
        toast.error(response.message);
      }
    },
    bulkDelete: (blinkIds: string[], workspaceId: string) => null,
  },
}));

export const useBlinksStore = createSelectors(useBaseBlinksStore);
export const useBlinksStoreActions = () => useBlinksStore.getState().actions;
export const useBlinksStoreState = () => useBlinksStore.getState();
