import React, { useEffect } from "react";
import {
  Box,
  Text,
  Button,
  Container,
  useMantineTheme,
  MantineTheme,
  Group,
} from "@mantine/core";
import { IconReload } from "@tabler/icons-react";

export function ErrorBoundaryModal() {
  const theme = useMantineTheme();

  const getSvgColor = (theme: MantineTheme) => {
    return theme.colorScheme === "dark"
      ? theme.colors.dark[4]
      : theme.colors.gray[8];
  };
  return (
    <Container
      size="xl"
      style={{
        height: "100vh",
        position: "relative",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          maxWidth: "400px",
          width: "100%",
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          padding: "16px",
        }}
      >
        <Text
          size="xl"
          weight={700}
          align="center"
          style={{ marginBottom: theme.spacing.md, width: "100%" }}
        >
          Oops! Something Went Wrong
        </Text>
        <Button
          color="dark"
          size="sm"
          fullWidth
          onClick={() => window.location.reload()}
          variant="filled"
          leftIcon={<IconReload size="1rem" />}
        >
          Reload the page
        </Button>
      </Box>
      <svg
        viewBox="0 0 1000 1000"
        preserveAspectRatio="xMinYMid meet"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "100%",
          height: "auto",
          maxWidth: "1000px",
          zIndex: 0,
          opacity: 0.1,
        }}
      >
        <text
          x="10%"
          y="50%"
          dy=".35em"
          textAnchor="start"
          fontSize="420"
          fontWeight="bold"
          fill={getSvgColor(theme)}
        >
          503
        </text>
      </svg>
    </Container>
  );
}
export default ErrorBoundaryModal;
