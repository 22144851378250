import { PasswordComponent } from "@/src/components";
import { EmailService } from "@/src/services/email.service";
import { userService } from "@/src/services/users.service";
import {
  Anchor,
  Box,
  Button,
  Center,
  Container,
  Dialog,
  Grid,
  Group,
  Overlay,
  Paper,
  PinInput,
  rem,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowLeft } from "@tabler/icons-react";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

type Props = {};

const RegisteredUserVerification = (props: Props) => {
  const navigate = useNavigate();
  const [opened, { toggle, close }] = useDisclosure(false);

  const [email, setEmail] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [sendingCode, setSendingCode] = React.useState(false);
  const [showCodeInput, setShowCodeInput] = React.useState(false);
  const [verifyingEmail, setVerifyingEmail] = React.useState(false);
  const [passwordResetting, setPasswordResetting] = React.useState(false);

  const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    if (newPassword !== e.target.value) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
    }
  };

  const validateEmail = async () => {
    setVerifyingEmail(true);
    if (!email) {
      setVerifyingEmail(false);
      return toast.error("Please enter your email");
    }

    setTimeout(async () => {
      try {
        const response: { statusCode: number; message: string } =
          await userService.validateUserEmailExists(email);
        setVerifyingEmail(false);
        if (response.statusCode === 302) {
          setTimeout(() => {
            toggle();
          }, 600);
        } else {
          toast.error(response.message + " Please try again");
        }
      } catch (error) {
        setVerifyingEmail(false);
        toast.error("User not found");
      }
    }, 2000);
  };

  const sendVerificationCode = async () => {
    setSendingCode(true);
    try {
      const response: { statusCode: number; message: string } =
        await EmailService.sendVerficationCodeForPasswordReset(email);

      if (response.statusCode === 200) {
        toast.success(response.message);
        setShowCodeInput(true);
      }
      setSendingCode(false);
    } catch (error) {
      toast.error("An error occured while sending the code");
    }
  };

  const handlePasswordReset = async (code: string) => {
    setPasswordResetting(true);
    if (code.length !== 6 || !email || !confirmPassword) {
      return toast.error("Email, password and code are required");
    }

    try {
      toast.promise(EmailService.resetPassword(email, code, confirmPassword), {
        loading: "Resetting password...",
        success: (data: {
          statusCode: number;
          message: string;
          Redirect: string;
        }) => {
          if (data.statusCode === 202) {
            setPasswordResetting(false);
            setTimeout(() => {
              close();
              navigate(data.Redirect);
            }, 1000);

            return data.message;
          }

          return "An error occured while resetting your password";
        },
        error: (error) => {
          setPasswordResetting(false);
          return error.message;
        },
      });
    } catch (error) {
      setPasswordResetting(false);
      toast.error("An error occured while resetting your password");
    }
  };

  return (
    <div className="wait-list-page">
      <Toaster
        position="top-center"
        reverseOrder={true}
        toastOptions={{
          style: {
            background: "#25262b",
            color: "#fff",
          },
        }}
      />
      {opened && (
        <Overlay blur={10} center>
          <Toaster
            position="top-center"
            reverseOrder={true}
            toastOptions={{
              style: {
                background: "#25262b",
                color: "#fff",
              },
            }}
          />
          <Dialog
            opened={true}
            withCloseButton
            onClose={() => {
              setPasswordError("");
              setNewPassword("");
              setConfirmPassword("");
              setShowCodeInput(false);
              close();
            }}
            size="lg"
            radius="md"
            shadow="md"
            position={{
              top: "30%",
              left: "35%",
            }}
            sx={(theme) => ({
              backgroundColor: theme.colors.dark[9],
              color: "white",
            })}
          >
            <Text size="sm" mb="xs" weight={500}>
              {showCodeInput
                ? "Reset password"
                : "Please enter your new password"}
            </Text>

            <Stack>
              {!showCodeInput ? (
                <>
                  <TextInput
                    value={email}
                    type="text"
                    labelProps={{ style: { color: "white" } }}
                    sx={(theme) => ({
                      "& input": {
                        color: "white",
                        backgroundColor: theme.colors.dark[9],
                        borderColor: theme.colors.dark[4],
                      },
                    })}
                    label="Email"
                    disabled
                  />
                  <PasswordComponent callback={setNewPassword} />
                  <TextInput
                    error={passwordError}
                    onChange={handleConfirmPassword}
                    value={confirmPassword}
                    type="text"
                    labelProps={{ style: { color: "white" } }}
                    sx={(theme) => ({
                      "& input": {
                        color: "white",
                        backgroundColor: theme.colors.dark[9],
                        borderColor: theme.colors.dark[4],
                      },
                    })}
                    label="Confirm password"
                    placeholder="********"
                    required
                  />
                  <Group position="right" className="mt-4">
                    <Button
                      variant="filled"
                      color="indigo"
                      size="xs"
                      loading={sendingCode}
                      fullWidth
                      onClick={sendVerificationCode}
                    >
                      Confirm
                    </Button>
                  </Group>
                </>
              ) : (
                <Group position="center">
                  <Text size="md" c="white">
                    Enter the 6-digit code sent to your email
                  </Text>
                  <PinInput
                    disabled={passwordResetting}
                    length={6}
                    oneTimeCode
                    variant="filled"
                    sx={(theme) => ({
                      "& input": {
                        color: "white",
                        backgroundColor: theme.colors.dark[9],
                        borderColor: theme.colors.dark[4],
                      },
                    })}
                    onComplete={(code) => handlePasswordReset(code)}
                  />
                </Group>
              )}
            </Stack>
          </Dialog>
        </Overlay>
      )}
      <Grid>
        <Grid.Col span={7} className="p-0"></Grid.Col>
        <Grid.Col
          span={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Paper
            // withBorder
            shadow="md"
            p={30}
            radius="md"
            sx={(theme) => ({
              backgroundColor: "transparent",
            })}
          >
            <Container size={460} my={30}>
              <Title ta="center" c="white">
                Verify your email
              </Title>
              <Text c="dimmed" fz="sm" ta="center">
                Enter your email to get a reset link
              </Text>

              <Paper
                shadow="md"
                p={30}
                radius="md"
                mt="xl"
                sx={(theme) => ({
                  backgroundColor: theme.colors.dark[9],
                })}
              >
                <TextInput
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  labelProps={{ style: { color: "white" } }}
                  sx={(theme) => ({
                    "& input": {
                      color: "white",
                      backgroundColor: theme.colors.dark[9],
                      borderColor: theme.colors.dark[4],
                    },
                  })}
                  label="Your email"
                  placeholder="me@b-link.co"
                  required
                />
                <Group mt="lg">
                  <Anchor
                    c="dimmed"
                    size="sm"
                    sx={(theme) => ({
                      "&:hover": {
                        color: theme.colors.indigo[6],
                      },
                    })}
                    onClick={() => navigate("/login")}
                  >
                    <Center inline>
                      <IconArrowLeft
                        style={{ width: rem(12), height: rem(12) }}
                        stroke={1.5}
                      />
                      <Box ml={5}>Back to the login page</Box>
                    </Center>
                  </Anchor>
                  <Button
                    color="indigo"
                    onClick={validateEmail}
                    loading={verifyingEmail}
                    loaderPosition="center"
                  >
                    Reset password
                  </Button>
                </Group>
              </Paper>
            </Container>
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default RegisteredUserVerification;
