import React, { useEffect } from "react";
import { AppShellContainer } from "@/src/containers";
import {
  Box,
  Button,
  Card,
  Grid,
  Group,
  Highlight,
  LoadingOverlay,
  Overlay,
  Paper,
  Text,
  Badge,
  Menu,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import {
  IconBrandDiscordFilled,
  IconCaretDownFilled,
  IconCaretUpDownFilled,
  IconDevicesCode,
  IconExternalLink,
  IconLinkPlus,
} from "@tabler/icons-react";

import { Area, AreaChart, CartesianGrid, XAxis } from "recharts";

import { StatsSegments } from "./AnalyticsUI/StateSegments";

import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";

import QuickLinks from "./QuickLinks";
import { useAppStore } from "@/src/stores/appstore";
import { useWorkspaceId } from "@/src/hooks";
import { workspaceService } from "@/src/services/workspace.service";
import { StatWorkspaceResponse } from "@/src/services/types";
import { useWorkspaceActions, useWorkspaceState } from "@/src/stores/workspace";
import { intanceLimits } from "@/src/utils/license/limits";
import { useNavigate } from "react-router-dom";

type Props = {};

export type DeviceTypeCount = {
  desktop: number;
  mobile: number;
  others: number;
};

export type TChartData = {
  date: string;
  desktop: number;
  mobile: number;
};

const getDeviceCount = (data: any[]) => {
  const count = { desktop: 0, mobile: 0, others: 0 };

  data.forEach((item) => {
    item?.info.forEach((info: any) => {
      if (info?.deviceType.toLowerCase() === "desktop") {
        count.desktop += info.count;
      }
      if (info?.deviceType.toLowerCase() === "mobile") {
        count.mobile += info.count;
      }

      if (
        info?.deviceType.toLowerCase() !== "desktop" &&
        info?.deviceType.toLowerCase() !== "mobile"
      ) {
        count.others += info.count;
      }
    });
  });

  return count;
};

const index = (props: Props) => {
  const [visible, handlers] = useDisclosure(false);

  const [blinksDeviceCount, setBlinksDeviceCount] =
    React.useState<DeviceTypeCount>({
      desktop: 0,
      mobile: 0,
      others: 0,
    });

  const [chartData, setChartData] = React.useState<TChartData[]>([]);

  const workspaceId = useWorkspaceId();

  useEffect(() => {
    if (workspaceId) {
      workspaceService
        .getWorkspaceData(workspaceId)
        .then((data: StatWorkspaceResponse) => {
          useWorkspaceActions()?.setWorkspaceData({
            workspaceId: data.workspaceId,
            workspaceName: data.stats.name,
            blinkCount: Number(data.stats.blinkCount),
            appsCount: Number(data.stats.appCount),
          });
        })
        .then(() => {
          workspaceService.onLoad(workspaceId).then((data) => {
            setChartData(data.chartData);
            const countData = getDeviceCount(data.data);

            useWorkspaceActions()?.setWorkspaceData({
              clickCount:
                countData.desktop + countData.mobile + countData.others,
            });

            useWorkspaceActions()?.loadQuickAccess(
              data?.quickAccess?.pages,
              data?.quickAccess?.blinks
            );

            setBlinksDeviceCount(countData);
          });
        });
    }
  }, []);

  return (
    <AppShellContainer>
      <LoadingOverlay
        visible={visible}
        overlayBlur={2}
        loaderProps={{ color: "dark" }}
      />
      <Box
        className="p-3"
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Component deviceCount={blinksDeviceCount} chartData={chartData} />
        </div>
      </Box>
    </AppShellContainer>
  );
};

const chartConfig = {
  views: {
    label: "Page Views",
  },
  desktop: {
    label: "Desktop",
    color: "red",
  },
  mobile: {
    label: "Mobile",
    color: "blue",
  },
} satisfies ChartConfig;

const Component: React.FC<{
  deviceCount: DeviceTypeCount;
  chartData: TChartData[];
}> = ({ deviceCount, chartData }) => {
  const { firstName } = useAppStore.use.userMetaData();
  const { blinkCount, appsCount } = useWorkspaceState();

  const blinksUsage = `${blinkCount}/${intanceLimits.blinks} `;
  const appsUsage = `${appsCount}/${intanceLimits.apps}`;

  const navigate = useNavigate();
  const workspaceId = useWorkspaceId();

  return (
    <Box p={0}>
      <Box className="p-1">
        <Grid>
          <Grid.Col span={12} h={"20rem"}>
            <Card
              radius="md"
              className={"dashboard-banner-card"}
              style={{
                backgroundImage: `url(https://storage.googleapis.com/blink-pages/blink-assets/main-banner.svg)`,
              }}
            >
              <Overlay className={"overlay"} opacity={0.55} zIndex={0} />

              <div className={"content"}>
                <Group>
                  <Text size="lg" fw={700} className={"title"}>
                    Hello {firstName.toLocaleUpperCase()} 👋
                  </Text>

                  {/* <Badge size="sm" radius={"sm"} variant="filled" color="teal">
                    Beta User
                  </Badge> */}
                </Group>

                <Text size="sm" className={"description"}>
                  Welcome back to your dashboard. Here's a quick look at your
                  stats.
                </Text>

                <Menu width={150} shadow="md">
                  <Menu.Target>
                    <Button
                      className={"action"}
                      variant={"filled"}
                      color="indigo"
                      size="sm"
                      rightIcon={<IconCaretDownFilled size={"1rem"} />}
                    >
                      Create New
                    </Button>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item
                      icon={<IconLinkPlus size={"1rem"} />}
                      onClick={() =>
                        navigate(`/${workspaceId}/blinks?new=true`)
                      }
                    >
                      <Tooltip
                        label="Create a new shortened link"
                        color="indigo"
                        position="left"
                      >
                        <Text>Blink</Text>
                      </Tooltip>
                    </Menu.Item>

                    <Menu.Item
                      icon={<IconDevicesCode size={"1rem"} />}
                      onClick={() =>
                        navigate(`/${workspaceId}/pages?new=true`, {
                          replace: false,
                        })
                      }
                    >
                      <Tooltip
                        label="Create a new page"
                        color="indigo"
                        position="left"
                      >
                        <Text>Page</Text>
                      </Tooltip>
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </div>
            </Card>
          </Grid.Col>
        </Grid>
      </Box>
      <Box className="">
        <Grid
          sx={{
            backgroundColor: "transparent",
            height: 500,
            maxHeight: "500px",
          }}
        >
          <Grid.Col
            // span={8}
            md={12}
            lg={8}
            xl={8}
          >
            <Grid>
              <Grid.Col
                // span={6}
                md={12}
                lg={6}
                xl={6}
              >
                <StatsSegments deviceCount={deviceCount} />
              </Grid.Col>
              <Grid.Col
                // span={6}
                md={12}
                lg={6}
                xl={6}
              >
                <Paper
                  p="md"
                  radius="md"
                  shadow="md"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[9]
                        : "white",
                  })}
                >
                  <Text fz="xs" fw={500}>
                    Shortlink Creation Limit
                  </Text>
                  <Group position="apart">
                    <Group align="flex-end">
                      <Highlight
                        size={"sm"}
                        fw={500}
                        align="center"
                        highlight={[blinksUsage]}
                        highlightStyles={(theme) => ({
                          backgroundImage: theme.fn.linearGradient(
                            45,
                            theme.colors.pink[5],
                            theme.colors.blue[5]
                          ),
                          fontWeight: 700,
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        })}
                      >
                        {`${blinksUsage} URLs Created`}
                      </Highlight>
                    </Group>

                    <Button
                      disabled
                      compact
                      size="xs"
                      variant="gradient"
                      gradient={{ from: "orange", to: "red" }}
                    >
                      ✨ Upgrade
                    </Button>
                  </Group>
                </Paper>
                <Paper
                  p="md"
                  radius="md"
                  shadow="md"
                  className="mt-2"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[9]
                        : "white",
                  })}
                >
                  <Text fz="xs" fw={500}>
                    Pages Creation Limit
                  </Text>
                  <div>
                    <Group position="apart">
                      <Group align="flex-end">
                        <Highlight
                          size={"sm"}
                          fw={500}
                          align="center"
                          highlight={[appsUsage]}
                          highlightStyles={(theme) => ({
                            backgroundImage: theme.fn.linearGradient(
                              45,
                              theme.colors.pink[5],
                              theme.colors.blue[5]
                            ),
                            fontWeight: 700,

                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                          })}
                        >
                          {`${appsUsage} Pages Created`}
                        </Highlight>
                      </Group>
                      <Button
                        disabled
                        compact
                        size="xs"
                        variant="gradient"
                        gradient={{ from: "orange", to: "red" }}
                      >
                        ✨ Upgrade
                      </Button>
                    </Group>
                  </div>
                </Paper>
              </Grid.Col>
              <Box className="w-100 " sx={{ padding: "0px 8px" }}>
                <Grid className="h-100">
                  <Grid.Col span={12}>
                    <Paper shadow="sm" withBorder p={"sm"}>
                      <ChartContainer
                        config={chartConfig}
                        style={{ maxHeight: "300px", width: "100%" }}
                        className="aspect-auto h-[250px] w-full"
                      >
                        <AreaChart data={chartData}>
                          <defs>
                            <linearGradient
                              id="fillDesktop"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="var(--color-desktop)"
                                stopOpacity={0.8}
                              />
                              <stop
                                offset="95%"
                                stopColor="var(--color-desktop)"
                                stopOpacity={0.1}
                              />
                            </linearGradient>
                            <linearGradient
                              id="fillMobile"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="var(--color-mobile)"
                                stopOpacity={0.8}
                              />
                              <stop
                                offset="95%"
                                stopColor="var(--color-mobile)"
                                stopOpacity={0.1}
                              />
                            </linearGradient>
                          </defs>
                          <CartesianGrid vertical={false} />
                          <XAxis
                            dataKey="date"
                            tickLine={false}
                            axisLine={false}
                            tickMargin={8}
                            minTickGap={32}
                            tickFormatter={(value) => {
                              const date = new Date(value);
                              return date.toLocaleDateString("en-US", {
                                month: "short",
                                day: "numeric",
                              });
                            }}
                          />
                          <ChartTooltip
                            cursor={false}
                            content={
                              <ChartTooltipContent
                                labelFormatter={(value) => {
                                  return new Date(value).toLocaleDateString(
                                    "en-US",
                                    {
                                      month: "short",
                                      day: "numeric",
                                    }
                                  );
                                }}
                                indicator="dot"
                              />
                            }
                          />
                          <Area
                            dataKey="mobile"
                            type="natural"
                            fill="url(#fillMobile)"
                            stroke="var(--color-mobile)"
                            stackId="a"
                          />
                          <Area
                            dataKey="desktop"
                            type="natural"
                            fill="url(#fillDesktop)"
                            stroke="var(--color-desktop)"
                            stackId="a"
                          />
                          <ChartLegend content={<ChartLegendContent />} />
                        </AreaChart>
                      </ChartContainer>
                    </Paper>
                  </Grid.Col>
                </Grid>
              </Box>
            </Grid>
          </Grid.Col>

          <Grid.Col
            //  span={4}
            md={12}
            lg={4}
            xl={4}
            h={"100%"}
          >
            <Paper h={"100%"} p={"xs"} shadow="sm" radius={"sm"}>
              <QuickLinks />
            </Paper>
          </Grid.Col>
        </Grid>
      </Box>
    </Box>
  );
};

export default index;
