import { BaseProps } from "@src/types/common.types";
import React from "react";

interface IConditionalRendererProps extends BaseProps {
  shouldRenderComponent: boolean;
}

export const ConditionalRenderer: React.FC<IConditionalRendererProps> = ({
  children,
  shouldRenderComponent,
}) => {
  if (!shouldRenderComponent) return null;

  return <React.Fragment>{children}</React.Fragment>;
};
