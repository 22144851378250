import { templateService } from "@/src/services/template.service";
import { create, createSelectors } from "../utils";
import { IUserMetaData, State } from "./types";
import { userService } from "@/src/services/users.service";
import toast from "react-hot-toast";

const initialState: State = {
  userMetaData: {
    currentWorkspaceId: "",
    defaultWorkspaceId: "",
    email: "",
    firstName: "",
    lastName: "",
  },
  subdomain: "app",
  version: "",
  currentPlan: "free",
  limits: {},
  actions: null,
  showUnreleasedFeatureModal: false,
  templates: [],
  socialLinks: [],
};

const useBaseAppStore = create<State>()((set, get) => ({
  ...initialState,
  actions: {
    reset: () => {
      set(initialState);
    },
    updateUserMetaData: (data: Partial<IUserMetaData>) => {
      set({ userMetaData: { ...get().userMetaData, ...data } });
    },
    updateVersion: (version) => {
      set({ version });
    },
    updateCurrentPlan: (plan) => {
      set({ currentPlan: plan });
    },
    updateLimits: (limits) => {
      set({ limits });
    },
    toggleUnreleasedFeatureModal: () => {
      set({ showUnreleasedFeatureModal: !get().showUnreleasedFeatureModal });
    },
    setSubdomain: (subdomain: string) => {
      set({ subdomain });
    },
    loadTemplates: async () => {
      const templates = await templateService.getTemplatePages();

      set({ templates });
    },
    loadSocialLinks: async () => {
      const socialLinks = await userService.getSocialLinks();

      if (!socialLinks || socialLinks.length === 0) {
        return;
      }

      set({
        socialLinks: socialLinks.map((link: Record<string, string>) => ({
          id: link?.id || "",
          url: link?.url || "",
          type: link?.type || "url",
          redirectURL: link?.redirectURL || "",
        })),
      });
    },

    saveSocialLinks: async (links) => {
      const response = await userService.saveSocialLinks({
        socialLinks: links,
      });
      set({ socialLinks: links });

      if (response.status === 201) {
        return Promise.resolve();
      }
    },

    deleteSocialLink: async (id: string) => {
      const existing = get().socialLinks;
      const index = existing.findIndex((link) => link.id === id);
      existing.splice(index, 1);

      try {
        const response = await userService.saveSocialLinks({
          socialLinks: existing,
        });

        set({ socialLinks: existing });
        return Promise.resolve(response);
      } catch (error) {
        toast.error("Failed to delete social link");
        return Promise.reject();
      }
    },
  },
}));

export const useAppStore = createSelectors(useBaseAppStore);
export const useAppStoreActions = () => useAppStore.getState().actions;
export const useAppStoreState = () => useAppStore.getState();
