import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function validateEmail(email: string) {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return re.test(email);
}

export function validateURL(input: string): boolean {
  // Check if input is a string and not too long
  if (typeof input !== "string" || input.length > 2000) {
    return false;
  }

  // Trim the input to remove any leading/trailing whitespace
  const url = input.trim();

  // Check if the URL contains any newline characters
  if (url.includes("\n") || url.includes("\r")) {
    return false;
  }

  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i" // case-insensitive
  );

  return urlPattern.test(url);
}

export function validateShortUrlHandle(handle: string): {
  error: null | string;
  valid: boolean;
} {
  // Check if handle is empty
  if (handle.length === 0) {
    return { error: "Handle should not be empty", valid: false };
  }

  // Check if handle starts with a letter
  if (!/^[a-zA-Z]/.test(handle)) {
    return { error: "Handle should start with a letter", valid: false };
  }

  // Check if handle contains only allowed characters
  if (!/^[a-zA-Z0-9-_]+$/.test(handle)) {
    return {
      error:
        "Handle should contain only letters, numbers, hyphens and underscores",
      valid: false,
    };
  }

  // Check if handle contains subpaths
  if (handle.includes("/")) {
    return { error: "Handle should not contain subpaths", valid: false };
  }

  return { error: null, valid: true };
}

interface IWorkspace {
  id: string;
  name: string;
  description: string;
  isOnboardingComplete: boolean;
  createdAt: Date | string; // or just Date if you're sure it's always a Date object
  updatedAt: Date | string;
}

export interface IWorkspaceUsers {
  id: string;
  role: "admin" | "member" | "guest";
  invited: boolean;
  workspace: IWorkspace;
}

export function findDefaultWorkspace(workspaces: IWorkspaceUsers[]): {
  workspaceName: string;
  isAdmin: boolean;
  isDefualt: boolean;
  workspaceId: string;
} {
  //Todo: For now the first workspace is the default workspace
  const defaultWorkspace = workspaces[0];

  return {
    workspaceName: defaultWorkspace.workspace.name,
    isAdmin: defaultWorkspace.role === "admin",
    isDefualt: true,
    workspaceId: defaultWorkspace.workspace.id,
  };
}

export function extractFileName(url: string): string {
  if (url.length === 0) {
    return "";
  }

  const parts = url.split("/");
  const lastPart = parts[parts.length - 1];

  // Decode the URL-encoded string
  const decodedLastPart = decodeURIComponent(lastPart);

  return decodedLastPart;
}

export function getImageDataUri(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = reject;
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });
}
