import { appConfig } from "@src/app.config";
import { handleResponseWithoutValidation } from "@src/lib/handleResponses";
import { authHeader } from "@src/lib/requestHeaders";

export const redirectionProxyService = {
  validatePasswordProtectedBlinks: validatePasswordProtectedBlinks,
};

//redirection-proxy

async function validatePasswordProtectedBlinks(
  shortUrl: string,
  password: string
): Promise<any> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include" as RequestCredentials,
    body: JSON.stringify({ shortURL: shortUrl, password: password }),
  };

  const response = await fetch(
    `${appConfig.apiURL}/redirection-proxy/verify-password`,
    requestOptions
  );
  try {
    const response_1 = await handleResponseWithoutValidation(response);
    return response_1;
  } catch (error) {
    throw new Error("Invalid password");
  }
}
