import {
  Alert,
  Box,
  Button,
  Center,
  Container,
  Group,
  Paper,
  Text,
  Title,
} from "@mantine/core";
import React, { useEffect } from "react";
import Whitelabel from "@UIComponents/Whitelabel";
import { notifications } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";
import { blinkService } from "@/src/services/blinks.services";
import { useQueryParams } from "@/src/hooks";
import toast, { Toaster } from "react-hot-toast";

type Props = {};

const Expired = (props: Props) => {
  const [fallBackURL, setFallBackURL] = React.useState<string | null>(null);
  const queryParams = useQueryParams();

  const triggerNotificationBanner = () => {
    notifications.show({
      id: "scheduled-ui-notification",
      withCloseButton: false,
      autoClose: false,
      title: "Oops! This link has expired",
      message:
        "We're sorry, but the short link you're trying to access is no longer active.",
      color: "red",
      icon: <IconX />,
      className: "my-notification-class",
      loading: false,
    });
  };

  useEffect(() => {
    triggerNotificationBanner();
    blinkService
      .getFallbackURL(queryParams["shortURL"] as string)
      .then((res) => {
        if (res) {
          setFallBackURL(res);
        }
      });
  }, []);

  return (
    <Box
      className="bg-black expired-container"
      style={{
        height: "100%",
        width: "inherit",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
      }}
    >
      <Container>
        <Content
          isMobileLayoutActive={true}
          position="relative"
          fallBackURL={fallBackURL}
        />
      </Container>

      <Whitelabel isMobileLayoutActive={true} />
    </Box>
  );
};

const Content: React.FC<{
  isMobileLayoutActive: boolean;
  position?: string;
  fallBackURL?: string | null;
}> = ({ isMobileLayoutActive, position = "absolute", fallBackURL }) => {
  let containerStyle: Record<string, string> = {
    display: "flex",
    flexDirection: "row" as const,
    alignItems: "center",
    padding: "10px",
    borderRadius: "10px",
    margin: "auto",
    gap: "5px",
    position: position,
    maxWidth: "500px",
  };

  if (!isMobileLayoutActive) {
    containerStyle.right = "0%";
    containerStyle.left = "auto";
  }

  useEffect(() => {
    if (fallBackURL) {
      toast
        .promise(
          new Promise((resolve) => {
            setTimeout(() => {
              resolve("Redirecting...");
            }, 5000);
          }),
          {
            loading: "Redirecting...",
            success: "Redirecting...",
            error: "Error redirecting...",
          }
        )
        .then(() => {
          const currentURL = window.location.href;
          window.location.href = fallBackURL + "?referrer=" + currentURL;
        });
    }
  }, [fallBackURL]);

  //   What can you do?
  // 1. Check with the person or organization who shared this link. They may have an updated link for you.
  // 2. If you believe this is an error, please contact our support team at support@yourcompany.com.
  // 3. Return to our homepage to create your own short links that last longer!

  return (
    <div className="mb-2" style={containerStyle}>
      <Toaster
        position="top-right"
        reverseOrder={true}
        toastOptions={{
          style: {
            background: "#25262b",
            color: "#fff",
          },
        }}
      />
      <Center>
        <Text
          fw={500}
          c="white"
          size={"xl"}
          sx={{
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          This link was set to expire after a certain time or number of uses.
        </Text>
      </Center>
    </div>
  );
};

export default Expired;
