import { Widget } from "../../types";

export const ImageActionBanner: Widget = {
  id: "",
  name: "Banner",
  description: "Render image action banner",
  meta: {
    type: "image_action_banner",
    category: "components",
  },
  variant: "filled",
  baseClassName: "",
  properties: {
    styles: {},
    props: {
      imageSrc: {
        section: "customization",
        value:
          "https://images.unsplash.com/photo-1596394516093-501ba68a0ba6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
        type: "image-dropdown-selector",
        name: "Image Source",
        key: "imageSrc",
      },
      title: {
        section: "customization",
        value: "Plan & save",
        type: "text",
        name: "Title",
        key: "title",
      },
      description: {
        section: "customization",
        value:
          "Save up to 25% at Fifth Season Hotels in Europe, the Middle East, Africa and Asia Pacific",
        type: "textarea",
        name: "Description",
        key: "description",
      },

      actionLabel: {
        section: "customization",
        value: "Book now",
        type: "text",
        name: "Action Label",
        key: "actionLabel",
      },

      actionButtonVariant: {
        section: "customization",
        value: "light",
        type: "segmented-control",
        options: ["white", "filled", "outline", "light", "default", "subtle"],
        name: "Action Button Variant",
        key: "actionButtonVariant",
      },

      actionButtonColor: {
        section: "customization",
        value: "indigo",
        type: "mantine-color",
        name: "Action Button Color",
        key: "actionButtonColor",
      },
    },
  },
  control: {
    redirectTo: "",
    type: "blink",
  },
  layout: {
    currentLayout: "mobile",
    mobile: {
      top: 0,
      left: 0,
      width: 390,
      height: 300,
    },
  },
};
