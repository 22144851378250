import {
  Badge,
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  Group,
  Input,
  Modal,
  Paper,
  Select,
  Text,
  TextInput,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { appConfig } from "@src/app.config";
import { CreateableMultiSelect, PasswordToggle } from "@src/components";
import { CreateBlinkDto } from "@src/services/types";
import { useBlinksStoreActions } from "@src/stores/blinks";

import React, { useEffect, useState } from "react";
import { PasswordProtected } from "./PasswordProtected";
import { LinkSchedule } from "./LinkSchedule";
import { LinkCloaking } from "./LinkCloaking";
import { LinkExpiration } from "./LinkExpiration";
import { BlinkDirectives } from "@src/types/modules/blinks";
import { sanitizePayload } from "@src/helpers/services.helpers";
import { CardsCarousel } from "./CardCarousel";
import { blinkService } from "@src/services/blinks.services";
import toast from "react-hot-toast";
import { validateShortUrlHandle } from "@/src/lib/utils";
import { useAppStore } from "@/src/stores/appstore";
import { IconSlash } from "@tabler/icons-react";

type Props = {
  opened: boolean;
  close: () => void;
  toggleOpen: () => void;
  toggleClose: () => void;
};

type DrawerProps = {
  opened: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

type RenderAdvancedOptionsProps = {
  show: boolean;
  updateDirectives: (type: keyof BlinkDirectives, data: any) => void;
};

interface Composition {
  Drawer: React.FC<DrawerProps>;
  RenderAdvancedOptions: React.FC<RenderAdvancedOptionsProps>;
}

const initialDirectives: BlinkDirectives = {
  expiration: null,
  isCloakingActive: false,
  isPrivate: false,
  isSchedulingActive: false,
  isPasswordProtected: false,
  password: null,
  scheduledFrom: null,
  inactiveLinkUrl: null,
};

type updateType = "name" | "blinkDomain" | "blinkSignature" | "redirectUrl";

type SocialPreview = {
  title: string;
  description: string;
  image: string;
  url: string;
};
type ErrorPageInput = {
  name: string | null;
  handle: string | null;
};

const CreateBlink: React.FC<Props> & Composition = ({
  opened,
  close,
  toggleOpen,
  toggleClose,
}) => {
  const subdomain = useAppStore.use.subdomain();

  const defaultDomain =
    appConfig.environment === "production"
      ? `https://${subdomain}.b-links.co`
      : `http://${subdomain}.localhost:3001`;

  const initialValue: CreateBlinkDto = {
    name: "",
    blinkDomain: defaultDomain,
    blinkSignature: "",
    redirectUrl: "",
    enableDirectives: false,
  };

  const [newBlink, set] = useState<CreateBlinkDto>(initialValue);
  const [shouldAdvancedOptions, setShouldAdvancedOptions] = useState(false);
  const [blinkDirectives, setBlinkDirectives] =
    useState<BlinkDirectives>(initialDirectives);
  const [isCreating, setIsCreating] = useState(false);

  const [socialPreview, setSocialPreview] = useState<SocialPreview | null>(
    null
  );

  const [redirectUrlError, setRedirectUrlError] = useState("");
  const [errorDetails, setErrorDetails] = React.useState<ErrorPageInput>({
    name: null,
    handle: null,
  });

  function isValidURL(urlString: string) {
    try {
      new URL(urlString);
      return true;
    } catch (_) {
      return false;
    }
  }

  useEffect(() => {
    if (isValidURL(newBlink.redirectUrl)) {
      redirectUrlError && setRedirectUrlError("");
      blinkService
        .fetchSocialPreview(newBlink.redirectUrl)
        .then((data) => {
          setSocialPreview(data);
        })
        .catch(() => {
          setSocialPreview(null);
        });
    } else {
      newBlink.redirectUrl &&
        !redirectUrlError &&
        setRedirectUrlError("Please enter valid destination URL");
    }
  }, [newBlink.redirectUrl]);

  const handleUpdates = (type: updateType, data: string) => {
    if (type === "blinkSignature") {
      const isValid = validateShortUrlHandle(data);

      if (!isValid.valid) {
        setErrorDetails((prev) => ({ ...prev, handle: isValid.error }));
      } else {
        setErrorDetails((prev) => ({ ...prev, handle: null }));
      }
    }

    set((prev) => ({
      ...prev,
      [type]: data,
    }));
  };

  const hanldeUpdatesForDirectives = (
    type: keyof BlinkDirectives,
    data: any
  ) => {
    setBlinkDirectives((prev) => ({
      ...prev,
      [type]: data,
    }));
  };

  const handleCreateNewBlink = () => {
    setIsCreating(true);
    toggleOpen();

    const payload = !shouldAdvancedOptions
      ? newBlink
      : sanitizePayload({
          blink: { ...newBlink, enableDirectives: shouldAdvancedOptions },
          directives: blinkDirectives,
        });

    useBlinksStoreActions()
      ?.create(payload, shouldAdvancedOptions)
      .then(() => {
        setIsCreating(false);
        set(initialValue);
        setRedirectUrlError("");
        close();
        toggleClose();
      })
      .catch((error) => {
        const errMessage =
          error?.error ?? "Something went wrong, new blink is not created !";

        toast.error(errMessage);
        setIsCreating(false);
        toggleClose();
      });
  };

  const cardData = socialPreview
    ? [
        {
          image: socialPreview.image,
          title: socialPreview.title,
          category: "some",
        },
      ]
    : [];

  return (
    <CreateModalContainer
      opened={opened}
      onClose={() => {
        set(initialValue);
        setRedirectUrlError("");
        close();
      }}
    >
      <Input.Wrapper
        size="xs"
        className="mb-1"
        id="destination-url"
        withAsterisk
        label="Blink Name"
      >
        <Input
          size="xs"
          id="destination-url"
          placeholder="Blink Name"
          onChange={(e) => handleUpdates("name", e.target.value)}
        />
      </Input.Wrapper>
      <Input.Wrapper
        size="xs"
        className="mb-1"
        id="destination-url"
        withAsterisk
        label="Destination URL"
        description="Where the user will be redirected after clicking the Blink"
        error={redirectUrlError && `${redirectUrlError}`}
      >
        <Input
          size="xs"
          id="destination-url"
          placeholder="https://www.b-link.co/wait-list?utm_source=blink_test-1"
          onChange={(e) => handleUpdates("redirectUrl", e.target.value)}
          error={!!redirectUrlError}
        />
      </Input.Wrapper>

      {isValidURL(newBlink.redirectUrl) && <CardsCarousel data={cardData} />}

      <Grid>
        <Grid.Col span={6}>
          <Select
            withAsterisk
            variant="filled"
            label="Select a short link"
            placeholder="Select a short link domain"
            defaultValue={defaultDomain}
            data={[defaultDomain]}
            size="xs"
            onChange={(value) =>
              typeof value === "string" && handleUpdates("blinkDomain", value)
            }
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Input.Wrapper
            size="xs"
            id="input-blink-handle"
            withAsterisk
            label="Handle"
            error={errorDetails.handle}
          >
            <Input
              size="xs"
              id="input-blink-handle"
              placeholder="yourback-halves"
              // rightSection={<RenderLoader isLoading={loading} />}

              onChange={(e) => handleUpdates("blinkSignature", e.target.value)}
              icon={<IconSlash size={"1rem"} />}
            />
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
      {/* <CreateableMultiSelect
        size="xs"
        label="Select or create tags"
        isSearchable={true}
        isCreatable={true}
        cx="mb-3"
      /> */}

      {/* Todo: for now hiding it from UI */}
      {/* <Box
        className="mb-3"
        style={{
          border: "1px solid #f0f0f0",
          borderRadius: "6px",
          marginTop: "1rem",
          padding: "1rem",
        }}
      >
        <Tooltip
          label="Coming soon"
          color="indigo"
          arrowOffset={50}
          arrowSize={4}
          withArrow
        >
          <div>
            <PasswordToggle
              size="sm"
              label="Advanced Options"
              color="green"
              description="Enable advanced options like password protection, link cloaking, and more"
              checked={shouldAdvancedOptions}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setShouldAdvancedOptions(e.target.checked)
              }
              disabled={true}
            />
          </div>
        </Tooltip>
        <CreateBlink.RenderAdvancedOptions
          show={shouldAdvancedOptions}
          updateDirectives={hanldeUpdatesForDirectives}
        />
      </Box> */}

      <Group grow mt="xl">
        <Button
          loading={isCreating}
          // compact
          variant="filled"
          color="dark"
          onClick={handleCreateNewBlink}
          disabled={
            ["", null].includes(newBlink.name) ||
            ["", null].includes(newBlink.redirectUrl) ||
            ["", null, undefined].includes(newBlink.blinkDomain) ||
            !!redirectUrlError
          }
        >
          Create
        </Button>
      </Group>
    </CreateModalContainer>
  );
};

const CreateDrawer: React.FC<DrawerProps> = ({ opened, onClose, children }) => {
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      title="Create a new Blink"
      position="right"
      size={"md"}
    >
      <Box>{children}</Box>
    </Drawer>
  );
};

const ModalTitle: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Box>
      <Text
        variant="gradient"
        gradient={{ from: "indigo", to: "cyan", deg: 45 }}
        sx={{ fontFamily: "Greycliff CF, sans-serif" }}
        ta="left"
        fz="md"
        fw={700}
      >
        {title}
      </Text>
      <Text size="xs" color="dimmed">
        Short link to your destination URL
      </Text>
    </Box>
  );
};

const CreateModalContainer: React.FC<DrawerProps> = ({
  opened,
  onClose,
  children,
}) => {
  return (
    <Modal
      size={"md"}
      centered={true}
      opened={opened}
      onClose={onClose}
      title={<ModalTitle title="Create a new Blink" />}
    >
      {children}
    </Modal>
  );
};

const RenderAdvancedOptions: React.FC<RenderAdvancedOptionsProps> = ({
  show,
  updateDirectives,
}) => {
  if (!show) return null;

  return (
    <Container className="mt-2 p-0">
      <PasswordProtected callback={updateDirectives} />
      <LinkSchedule callback={updateDirectives} />
      <LinkCloaking callback={updateDirectives} />
      <LinkExpiration callback={updateDirectives} />
    </Container>
  );
};

CreateBlink.Drawer = CreateDrawer;
CreateBlink.RenderAdvancedOptions = RenderAdvancedOptions;

export default CreateBlink;
