import { Input, Loader, PasswordInput } from "@mantine/core";
import {
  IconEyeCheck,
  IconEyeOff,
  IconLock,
  IconLockCode,
  IconLockPassword,
} from "@tabler/icons-react";
import React, { useEffect } from "react";

type Props = {
  value: string;
  setValue: (value: string) => void;
  isValid: boolean;
  isLoading: boolean;
};

enum PasswordErrors {
  INCORRECT = "Incorrect password",
  EMPTY = "Password cannot be empty",
}

export const PasswordProtectUI: React.FC<Props> = ({
  value,
  setValue,
  isValid,
  isLoading,
}) => {
  const [error, setError] = React.useState<string | null>(() =>
    !isValid ? PasswordErrors.INCORRECT : null
  );

  useEffect(() => {
    if (!isValid) {
      setError(PasswordErrors.INCORRECT);
    } else {
      setError(null);
    }
  }, [isValid]);

  return (
    <PasswordInput
      icon={<IconLockPassword size={"1rem"} />}
      label="Enter password to continue"
      placeholder="passcode here"
      defaultValue="secret"
      value={value}
      onChange={(event) => {
        setError(null);
        setValue(event.currentTarget.value);
      }}
      error={error}
      visibilityToggleIcon={({ reveal, size }) =>
        reveal ? <IconEyeOff size={size} /> : <IconEyeCheck size={size} />
      }
      disabled={isLoading}
    />
  );
};
