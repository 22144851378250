import { Widget } from "../../types";

export const Text: Widget = {
  id: "",
  name: "Text",
  description: "Headline component to display text",
  meta: {
    type: "text",
    category: "components",
  },
  variant: "custom",
  baseClassName: "",
  properties: {
    styles: {},
    props: {
      text: {
        section: "customization",
        value: "Hello ! Welcome to blink",
        type: "text",
        key: "text",
        name: "Text",
      },
      colorVariant: {
        section: "customization",
        value: "solid",
        type: "segmented-control",
        options: ["solid", "gradient"],
        key: "colorVariant",
        name: "Color variant",
      },
      color: {
        section: "customization",
        value: "#25262b",
        type: "color",
        key: "color",
        name: "Color",
        dependencies: ["colorVariant"],
        dependencyValue: "solid",
      },
      gradient: {
        section: "customization",
        value: {
          from: "",
          to: "",
          deg: 45,
        },
        type: "gradient",
        key: "gradient",
        name: "Gradient",
        dependencies: ["colorVariant"],
        dependencyValue: "gradient",
      },
      fontFamily: {
        section: "customization",
        value: "Honk",
        type: "select",
        key: "fontFamily",
        name: "Font family",
        options: [
          "Arial",
          "Verdana",
          "Helvetica",
          "Tahoma",
          "Trebuchet MS",
          "Times New Roman",
          "Georgia",
          "Garamond",
          "Courier New",
          "Brush Script MT",
          "Lucida Handwriting",
          "Copperplate",
          "Papyrus",
          "Palatino",
          "Bookman",
          "Comic Sans MS",
          "Impact",
          "Arial Black",
          "Lucida Console",
          "Consolas",
          "Bungee Tint",
          "Gochi Hand",
          "Sigma",
          "Jersey 10 Charted",
          "Honk",
        ],
      },
      textAlign: {
        section: "customization",
        value: "center",
        type: "segmented-control",
        options: ["left", "right", "center", "justify"],
        icons: {
          left: "left-align",
          right: "right-align",
          center: "center-align",
          justify: "justify",
        },
        onlyIcons: true,
        key: "textAlign",
        name: "Text align",
      },
      fontWeight: {
        section: "customization",
        value: 700,
        type: "number",
        key: "fontWeight",
        name: "Font weight",
      },
      fontSize: {
        section: "customization",
        value: 44,
        type: "number",
        key: "fontSize",
        name: "Font Size",
      },
    },
  },
  layout: {
    currentLayout: "mobile",
    mobile: {
      top: 0,
      left: 0,
      width: 390,
      height: 150,
    },
  },
};
