import { RefObject, useEffect } from "react";

type Handler = () => void;

export function useOutsideClick<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler,
  targetClassName: string
): void {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const el = ref?.current;
      const target = event.target as HTMLElement;

      if (!el || el.contains(target)) {
        return;
      }

      if (target.classList.contains(targetClassName)) {
        handler();
        return;
      }
    };

    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
}
