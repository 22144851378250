import { Widget } from "../../types";

export const CorouselBasic: Widget = {
  id: "",
  name: "Carousel",
  description: "Carousel component to display images",
  meta: {
    type: "carousel_basic",
    category: "components",
  },
  variant: "filled",
  baseClassName: "",
  properties: {
    styles: {},
    props: {
      length: {
        section: "customization",
        value: 3,
        type: "number",
        key: "length",
        name: "Number of images",
      },
      data: {
        section: "customization",
        value: [
          {
            src: "https://images.unsplash.com/photo-1583337130417-3346a1be7dee?q=80&w=2564&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            alt: "Image 1",
          },
          {
            src: "https://images.unsplash.com/photo-1722770773805-c65915cb0035?q=80&w=2572&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            alt: "Image 2",
          },
          {
            src: "https://images.unsplash.com/photo-1495147466023-ac5c588e2e94?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            alt: "Image 3",
          },
        ],
        type: "array",
        key: "data",
        name: "Images",
      },
      align: {
        section: "customization",
        value: "center",
        type: "segmented-control",
        options: ["center", "start", "end"],
        icons: {
          center: "center",
          start: "start",
          end: "end",
        },
        key: "align",
        name: "Align",
      },
      orientation: {
        section: "customization",
        value: "horizontal",
        type: "segmented-control",
        options: ["horizontal", "vertical"],
        icons: {
          horizontal: "horizontal-carousel",
          vertical: "vertical-carousel",
        },
        key: "orientation",
        name: "Orientation",
      },

      withControls: {
        section: "customization",
        value: true,
        type: "toggle",
        key: "withControls",
        name: "With controls",
      },

      withIndicators: {
        section: "customization",
        value: true,
        type: "toggle",
        key: "withIndicators",
        name: "With indicators",
      },
      height: {
        section: "flexibility",
        value: 200,
        type: "number",
        key: "height",
        name: "Adjust Image Height",
      },
      width: {
        section: "flexibility",
        value: 300,
        type: "number",
        key: "width",
        name: "Adjust Image Width",
      },
    },
  },
  control: {
    redirectTo: "",
    type: null,
  },
  layout: {
    currentLayout: "mobile",
    mobile: {
      top: 0,
      left: 0,
      width: 390,
      height: 200,
    },
  },
};
