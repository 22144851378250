import React from "react";
import { Carousel } from "@mantine/carousel";
import { Badge, Paper, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

interface CardProps {
  image: string;
  title: string;
  category: string;
}

function Card({ image, title, category }: CardProps) {
  return (
    <Paper
      withBorder
      shadow="md"
      p="xl"
      radius="sm"
      style={{ backgroundImage: `url(${image})` }}
      className={"carousel-card"}
    >
      <Badge
        style={{ position: "absolute", bottom: 10, padding: "2px 5px" }}
        radius={"sm"}
        variant="filled"
        color={"dark"}
      >
        {title}
      </Badge>
    </Paper>
  );
}

type IProps = {
  data: CardProps[];
};

export const CardsCarousel: React.FC<IProps> = ({ data }) => {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const slides = data.map((item) => (
    <Carousel.Slide key={item.title}>
      <Card {...item} />
    </Carousel.Slide>
  ));

  return (
    <Carousel
      withControls={false}
      align="start"
      slidesToScroll={mobile ? 1 : 2}
    >
      {slides}
    </Carousel>
  );
};
