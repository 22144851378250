import React from "react";
import { Button, MantineColor } from "@mantine/core";
import { omit } from "lodash";

interface Props {
  variant:
    | "default"
    | "outline"
    | "light"
    | "filled"
    | "gradient"
    | "link"
    | "white";
  fullWidth: boolean;
  loading?: boolean;
  className?: string;
  color: MantineColor;
  withIcon?: boolean;
  iconPosition?: "left" | "right";
  onClick: () => void;
  compact?: boolean;
}

interface ThemeButtonProps extends Props {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  loaderPosition?: "left" | "right" | "center";
  children: React.ReactNode;
  uppercase?: boolean;
  disabled?: boolean;
  compact?: boolean;
}

enum BtnIconPosition {
  left = "leftIcon",
  right = "rightIcon",
}

type FillButtonProps = Partial<ThemeButtonProps>;

interface Composition {
  Filled: React.FC<FillButtonProps>;
  Unstyled: React.FC<{ children: React.ReactNode; className: "string" }>;
}

const defaultConfig = {
  variant: "default",
  fullWidth: false,
  loading: false,
  color: "dark",
};

export const BaseButton: React.FC<ThemeButtonProps> & Composition = ({
  children,
  ...restProps
}) => {
  const propsWithDefault = {
    ...defaultConfig,
    ...omit(restProps, ["withIcon", "iconPosition"]),
  };
  return (
    <Button type="button" {...propsWithDefault}>
      {children}
    </Button>
  );
};

const FilledButton: React.FC<FillButtonProps> = (props) => {
  const iconPos =
    props.withIcon && props.iconPosition
      ? BtnIconPosition[props.iconPosition]
      : undefined;

  const iconProps = iconPos ? { [iconPos]: props.leftIcon } : {};
  const compact = props.compact ?? false;

  return (
    <Button
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[6]
            : theme.colors.dark[8],
        "&:hover": {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[4]
              : theme.colors.dark[9],
        },
      })}
      compact={compact}
      onClick={props.onClick}
      type="button"
      color={props.color}
      variant={"filled"}
      {...omit(iconProps, ["withIcon", "iconPosition"])}
    >
      {props.children}
    </Button>
  );
};

const UnstyledButton: React.FC<{
  children: React.ReactNode;
  className: "string";
}> = ({ children, className }) => {
  return <UnstyledButton className={className}>{children}</UnstyledButton>;
};

BaseButton.Filled = FilledButton;
BaseButton.Unstyled = UnstyledButton;
