import { cn } from "@/lib/utils";
import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import { IconUpload } from "@tabler/icons-react";
import { useDropzone } from "react-dropzone";
import { Box, Button, Center, Grid, Group } from "@mantine/core";
import toast from "react-hot-toast";

const mainVariant = {
  initial: {
    x: 0,
    y: 0,
  },
  animate: {
    x: 20,
    y: -20,
    opacity: 0.9,
  },
};

const secondaryVariant = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

export const FileUpload = ({
  onChange,
  onClear,
  limit = 1,
  fileIsUploading,
  upload,
}: {
  onChange?: (files: File) => void;
  onClear?: () => void;
  limit?: number;
  fileIsUploading: boolean;
  upload: () => void;
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const acceptedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/jpg",
  ];

  const fileSize = 1024 * 1024 * 5; // 5MB

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (newFiles: File[]) => {
    const fileType = newFiles[0].type;

    if (newFiles[0].size > fileSize) {
      return toast.error("File size is too large. Maximum file size is 5MB");
    }

    if (!acceptedFileTypes.includes(fileType)) {
      return toast.error("Invalid file type. Please upload an image file");
    }

    if (files.length + newFiles.length > limit) {
      return toast.error(`You can only upload ${limit} files`);
    }

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    onChange && onChange(newFiles[0]);
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const onClearAll = () => {
    setFiles([]);
    onClear && onClear();
  };

  const { getRootProps, isDragActive, acceptedFiles } = useDropzone({
    accept: {
      "image/png": [
        ".png",
        ".PNG",
        ".jpeg",
        ".JPEG",
        ".jpg",
        ".JPG",
        ".WEBP",
        ".webp",
        ".gif",
        ".GIF",
      ],
    },
    noKeyboard: true,
    multiple: false,
    noClick: true,
    maxSize: fileSize,
    onDropRejected: (error) => {
      const fileRejectedMsg = error[0].errors[0].message || "Invalid file";
      console.log("acceptedFiles", error[0].errors);
      toast.error(fileRejectedMsg);
    },
    onDropAccepted: handleFileChange,
  });

  return (
    <div className="tw-w-full" {...getRootProps()}>
      <motion.div
        onClick={(e) => {
          e.stopPropagation();
          handleClick();
        }}
        whileHover="animate"
        className={`${
          files.length === 0 ? "tw-p-10" : "tw-p-1"
        } tw-group/file tw-block tw-rounded-lg tw-cursor-pointer tw-w-full tw-relative tw-overflow-hidden`}
      >
        <input
          disabled={fileIsUploading}
          ref={fileInputRef}
          id="file-upload-handle"
          type="file"
          onChange={(e) => handleFileChange(Array.from(e.target.files || []))}
          className="tw-hidden"
        />

        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
          {files.length === 0 && (
            <Group>
              <p
                className="tw-relative tw-z-20 tw-font-sans tw-font-normal tw-text-neutral-400 dark:tw-text-neutral-400 tw-text-base tw-mt-2
              tw-text-center
              "
              >
                Drag or drop your files here or click to upload
              </p>
            </Group>
          )}

          <Center>
            <div
              className="tw-relative tw-w-full tw-mt-10 tw-max-w-xl tw-mx-auto"
              style={{
                width: "250px",
              }}
            >
              {files.length > 0 &&
                files.map((file, idx) => (
                  <motion.div
                    key={"file" + idx}
                    layoutId={idx === 0 ? "file-upload" : "file-upload-" + idx}
                    className={cn(
                      "tw-relative tw-overflow-hidden tw-z-40 tw-bg-white dark:tw-bg-neutral-900 tw-flex tw-flex-col tw-items-start tw-justify-start md:tw-h-24 tw-p-4 tw-mt-4 tw-w-full tw-mx-auto tw-rounded-md",
                      "tw-shadow-md"
                    )}
                  >
                    <div className="tw-flex tw-justify-between tw-w-full tw-items-center tw-gap-4">
                      <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        layout
                        className="tw-text-base tw-text-neutral-700 dark:tw-text-neutral-300 tw-truncate tw-max-w-xs"
                      >
                        {file.name}
                      </motion.p>
                      <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        layout
                        className="tw-rounded-lg tw-px-2 tw-py-1 tw-w-fit tw-flex-shrink-0 tw-text-sm tw-text-neutral-600 dark:tw-bg-neutral-800 dark:tw-text-white tw-shadow-input"
                      >
                        {(file.size / (1024 * 1024)).toFixed(2)} MB
                      </motion.p>
                    </div>

                    <div className="tw-flex tw-text-sm md:tw-flex-row tw-flex-col tw-items-start md:tw-items-center tw-w-full tw-mt-2 tw-justify-between tw-text-neutral-600 dark:tw-text-neutral-400">
                      <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        layout
                        className="tw-px-1 tw-py-0.5 tw-rounded-md tw-bg-gray-100 dark:tw-bg-neutral-800 tw-"
                      >
                        {file.type}
                      </motion.p>

                      <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        layout
                      >
                        modified{" "}
                        {new Date(file.lastModified).toLocaleDateString()}
                      </motion.p>
                    </div>
                  </motion.div>
                ))}

              {!files.length && (
                <motion.div
                  layoutId="file-upload"
                  variants={mainVariant}
                  transition={{
                    type: "spring",
                    stiffness: 300,
                    damping: 20,
                  }}
                  className={cn(
                    "tw-relative tw-group-hover/file:shadow-2xl tw-z-40 tw-bg-white dark:tw-bg-neutral-900 tw-flex tw-items-center tw-justify-center tw-h-32 tw-mt-4 tw-w-full tw-max-w-[8rem] tw-mx-auto tw-rounded-md",
                    "tw-shadow-[0px_10px_50px_rgba(0,0,0,0.1)]"
                  )}
                >
                  {isDragActive ? (
                    <motion.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="tw-text-neutral-600 tw-flex tw-flex-col tw-items-center"
                    >
                      Drop it
                      <IconUpload className="tw-h-4 tw-w-4 tw-text-neutral-600 dark:tw-text-neutral-400" />
                    </motion.p>
                  ) : (
                    <IconUpload className="tw-h-4 tw-w-4 tw-text-neutral-600 dark:tw-text-neutral-300" />
                  )}
                </motion.div>
              )}

              {!files.length && (
                <motion.div
                  variants={secondaryVariant}
                  className="tw-absolute tw-opacity-0 tw-border tw-border-dashed tw-border-sky-400 tw-inset-0 tw-z-30 tw-bg-transparent tw-flex tw-items-center tw-justify-center tw-h-32 tw-mt-4 tw-w-full tw-max-w-[8rem] tw-mx-auto tw-rounded-md"
                ></motion.div>
              )}
            </div>
          </Center>
        </div>
      </motion.div>

      {files.length > 0 && (
        <Box className="mx-4 my-2">
          <Grid className="px-1">
            <Grid.Col span={4}>
              <Button
                disabled={fileIsUploading}
                fullWidth
                color="red"
                variant="outline"
                size="xs"
                onClick={onClearAll}
              >
                Clear
              </Button>
            </Grid.Col>
            <Grid.Col span={8}>
              <Button
                loading={fileIsUploading}
                size="xs"
                fullWidth
                color="indigo"
                onClick={upload}
              >
                Upload
              </Button>
            </Grid.Col>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export function GridPattern() {
  const columns = 41;
  const rows = 11;
  return (
    <div className="tw-flex tw-bg-gray-100 dark:tw-bg-neutral-900 tw-flex-shrink-0 tw-flex-wrap tw-justify-center tw-items-center tw-gap-x-px tw-gap-y-px tw- tw-scale-105">
      {Array.from({ length: rows }).map((_, row) =>
        Array.from({ length: columns }).map((_, col) => {
          const index = row * columns + col;
          return (
            <div
              key={`${col}-${row}`}
              className={`w-10 h-10 flex flex-shrink-0 rounded-[2px] ${
                index % 2 === 0
                  ? "bg-gray-50 dark:bg-neutral-950"
                  : "bg-gray-50 dark:bg-neutral-950 shadow-[0px_0px_1px_3px_rgba(255,255,255,1)_inset] dark:shadow-[0px_0px_1px_3px_rgba(0,0,0,1)_inset]"
              }`}
            />
          );
        })
      )}
    </div>
  );
}
