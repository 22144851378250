import { Image, SimpleGrid } from "@mantine/core";
import React from "react";

type Props = {
  images: string[];
  size?: number;
  cols?: number;
  callback?: (image: string) => void;
};

const ImageList: React.FC<Props> = ({
  images,
  size = 120,
  cols = 2,
  callback,
}) => {
  return (
    <SimpleGrid cols={cols} className="mt-2">
      {images.map((image, index) => (
        <Image
          radius="md"
          key={index}
          src={image}
          height={size}
          width={size}
          style={{
            cursor: typeof callback === "function" ? "pointer" : "default",
          }}
          onClick={() => {
            if (typeof callback === "function") {
              callback(image);
            }
          }}
        />
      ))}
    </SimpleGrid>
  );
};

export default ImageList;
