import React, { useState, useEffect, useRef } from "react";
import {
  ActionIcon,
  Box,
  Center,
  Grid,
  Paper,
  Tooltip,
  Transition,
  useMantineTheme,
} from "@mantine/core";
import { useEditorStore, useEditorStoreActions } from "@src/stores/editorstore";
import { IPhoneFrame } from "../Preview";
import { ILayoutPos, NewDragable } from "./NewDragableComponent";
import DynamicImportComponent from "./DynamicImportComponent";
import WidgetSelector from "../Panel/Manager/EditorManager/WidgetSelector";
import Whitelabel from "@UIComponents/Whitelabel";
import { useOutsideClick } from "@/src/hooks/use-outside-click";
import PanelBody from "../Panel/PanelBody";
import {
  IconLayoutSidebarLeftExpandFilled,
  IconLayoutSidebarRightExpandFilled,
  IconLockAccess,
  IconLockAccessOff,
} from "@tabler/icons-react";
import { RenderWithScrollOption } from "./RenderWithScrollOption";

interface CanvasDimensions {
  width: number;
  height: number;
}

type Layout = "mobile" | "desktop";

interface DragableContainerProps {
  layout: Layout;
  canvasDimensions: CanvasDimensions;
}

const ORIGINAL_WIDTH = 390;
const ORIGINAL_HEIGHT = 844;

const AdaptiveCanvas: React.FC<{
  mode: "edit" | "preview" | "published";
  children: (dimensions: CanvasDimensions) => React.ReactNode;
}> = ({ mode, children }) => {
  const [canvasDimensions, setCanvasDimensions] = useState<CanvasDimensions>({
    width: ORIGINAL_WIDTH,
    height: ORIGINAL_HEIGHT,
  });

  useEffect(() => {
    const updateDimensions = () => {
      if (mode === "published") {
        const aspectRatio = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        if (screenHeight / screenWidth > aspectRatio) {
          setCanvasDimensions({
            width: screenWidth,
            height: screenWidth * aspectRatio,
          });
        } else {
          setCanvasDimensions({
            width: screenHeight / aspectRatio,
            height: screenHeight,
          });
        }
      } else {
        setCanvasDimensions({ width: ORIGINAL_WIDTH, height: ORIGINAL_HEIGHT });
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [mode]);

  return (
    <div
      style={{
        width: `${canvasDimensions.width}px`,
        height: `${canvasDimensions.height}px`,
        position: "relative",
        margin: "0 auto",
      }}
    >
      <RenderWithScrollOption mode={mode}>
        {children(canvasDimensions)}
      </RenderWithScrollOption>
    </div>
  );
};

const DragableContainer: React.FC<DragableContainerProps> = ({
  layout,
  canvasDimensions,
}) => {
  const { widgets, background } = useEditorStore.use.definitions();
  const currentMode = useEditorStore.use.mode();
  const currentLayout = useEditorStore.use.currentLayout();
  const { updateWidgetDefinition, setSelectedWidget } = useEditorStoreActions();

  const bgStyles = {
    backgroundColor:
      background.type === "bg-color"
        ? background.backgroundColor
        : background.type === "bg-image"
        ? "none"
        : "#f9f9f9",
    backgroundImage:
      background.type === "bg-image"
        ? `url("${background.backgroundImage}")`
        : "none",
    backgroundSize: background.type === "bg-image" ? "cover" : "auto",
    backgroundRepeat: background.type === "bg-image" ? "no-repeat" : "repeat",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
  };

  const elementGuidelines = widgets.map((box) => `.widget-${box.id}`);

  const updateOnDragEnd = (
    componentId: string,
    top: number,
    left: number,
    layoutPos: ILayoutPos
  ) => {
    updateWidgetDefinition(
      componentId,
      {
        layout: {
          [currentLayout]: {
            ...layoutPos[currentLayout],
            top,
            left,
          },
        },
      },
      "layout"
    );
  };

  const updateOnResizeEnd = (
    componentId: string,
    width: number,
    height: number,
    layoutPos: ILayoutPos
  ) => {
    updateWidgetDefinition(
      componentId,
      {
        layout: {
          [currentLayout]: {
            ...layoutPos[currentLayout],
            width,
            height,
          },
        },
      },
      "layout"
    );
  };

  const overlayComponentExists = widgets.some(
    (box) => box.meta.type === "overlay"
  );

  return (
    <IPhoneFrame>
      <div
        className={`grid-container ${currentMode}`}
        style={{
          width: `${canvasDimensions.width}px`,
          height: `${canvasDimensions.height}px`,
          position: "relative",
          zIndex: overlayComponentExists ? 1 : 2,
          ...bgStyles,
          // boxShadow: "0 0 0 6px #101010, 0 0 0 11px #2f2f2f",
          // borderRadius: "68px",
          // padding: "5px",
        }}
      >
        {widgets.map((box) => (
          <div
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                useEditorStoreActions()?.lockWidget(box.id);
                useEditorStoreActions()?.setEditableWidget(box.id);
              }
            }}
          >
            <NewDragable
              key={box.id}
              id={box.id}
              mode={currentMode}
              elementGuidelines={elementGuidelines}
              updateOnDragEnd={updateOnDragEnd}
              updateOnResizeEnd={updateOnResizeEnd}
              layout={layout}
              layoutPos={box.layout}
              canvasDimensions={canvasDimensions}
              overlayWidget={box.meta.type === "overlay"}
              overlayComponentExists={false}
            >
              <DynamicImportComponent
                type={box.meta.type as string}
                id={box.id}
                inheritedStyles={{ height: "100%", width: "100%" }}
                properties={box.properties}
                control={box.control}
                variant={box.variant}
              />
            </NewDragable>
          </div>
        ))}
        <Center>{<Whitelabel isMobileLayoutActive={true} />}</Center>
      </div>
    </IPhoneFrame>
  );
};

const GridContainer: React.FC<{
  opened: boolean;
  open: () => void;
  close: () => void;
}> = ({ opened, open, close }) => {
  const [currentLayout, setCurrentLayout] = useState<Layout>("mobile");

  const { setSelectedWidget, setGridLock, setShowPanelBody } =
    useEditorStoreActions();

  const activeSegment = useEditorStore.use.activeSegment();
  const lockGrid = useEditorStore.use.lockGrid();
  const showPanelBody = useEditorStore.use.showPanelBody();

  const ref = useRef(null);

  const mode = useEditorStore.use.mode();
  const isPreviewMode = mode === "preview";

  const handleOutsideClick = () => {
    setSelectedWidget(null);
  };

  useOutsideClick(ref, handleOutsideClick, "grid-container");
  const theme = useMantineTheme();

  return (
    <Grid
      className={`${
        mode === "preview" ? "preview-drag-container" : "editor-drag-container"
      }`}
    >
      <Transition
        mounted={showPanelBody}
        transition="slide-right"
        duration={100}
        timingFunction="ease"
      >
        {(styles) => (
          <Grid.Col
            span={3}
            style={{
              ...styles,
            }}
          >
            <Paper
              shadow="xs"
              style={{
                visibility: !isPreviewMode ? "visible" : "hidden",
                padding: "10px 20px",
                height: "92vh",
                overflowY: "auto",
              }}
            >
              <Box>
                <PanelBody render={activeSegment} />
              </Box>
            </Paper>
          </Grid.Col>
        )}
      </Transition>
      <Grid.Col
        span={showPanelBody ? 6 : "auto"}
        className="position-relative"
        style={{
          transition: "grid-template-columns 0.s ease-in-out",
        }}
      >
        <Box>
          {!isPreviewMode && (
            <>
              <div style={{ position: "absolute", left: "10px", top: "30px" }}>
                <Tooltip
                  label={`${showPanelBody ? "Hide Panel" : "Show Panel"}`}
                  position="bottom"
                  color="indigo"
                >
                  <ActionIcon
                    onClick={() => setShowPanelBody(!showPanelBody)}
                    color="dark"
                    size="md"
                  >
                    {showPanelBody ? (
                      <IconLayoutSidebarRightExpandFilled />
                    ) : (
                      <IconLayoutSidebarLeftExpandFilled />
                    )}
                  </ActionIcon>
                </Tooltip>
              </div>
              <div style={{ position: "absolute", right: "10px", top: "30px" }}>
                <Tooltip
                  label={`${lockGrid ? "Unlock Grid" : "Lock Grid"}`}
                  position="bottom"
                  color="indigo"
                >
                  <ActionIcon
                    onClick={() => setGridLock(!lockGrid)}
                    variant="filled"
                    color={
                      // theme.colorScheme === "dark"
                      // ? theme.colors.orange[2]
                      "dark"
                    }
                    size="sm"
                  >
                    {lockGrid ? (
                      <IconLockAccessOff size="1rem" />
                    ) : (
                      <IconLockAccess size="1rem" />
                    )}
                  </ActionIcon>
                </Tooltip>
              </div>
            </>
          )}

          <AdaptiveCanvas mode={mode}>
            {(adaptiveDimensions) => (
              <DragableContainer
                layout={currentLayout}
                canvasDimensions={adaptiveDimensions}
              />
            )}
          </AdaptiveCanvas>
        </Box>
      </Grid.Col>
      <Grid.Col span={3}>
        <Paper
          ref={ref}
          p="md"
          shadow="xs"
          className="d-flex w-100 grid-wrapper"
          style={{
            height: "92vh",
            visibility: !isPreviewMode ? "visible" : "hidden",
            overflowY: "auto",
          }}
        >
          <WidgetSelector
            setSelectedWidget={setSelectedWidget}
            openInspector={open}
          />
        </Paper>
      </Grid.Col>
    </Grid>
  );
};

export default GridContainer;
