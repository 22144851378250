import { ScrollArea } from "@mantine/core";
import React from "react";

export const RenderWithScrollOption: React.FC<{
  children: React.ReactNode;
  mode: "edit" | "preview" | "published";
}> = ({ children, mode }) => {
  if (mode !== "edit") return children;

  return <ScrollArea h={"96vh"}>{children}</ScrollArea>;
};
