import { appConfig } from "@src/app.config";
import { handleResponseWithoutValidation } from "@src/lib/handleResponses";
import { authHeader, fileAuthHeader } from "@src/lib/requestHeaders";
import { CreateSocialLinksDto } from "./types";

export const userService = {
  saveSocialLinks: saveSocialLinks,
  getProfile: getProfile,
  getSocialLinks: getSocialLinks,
  validateUserEmailExists: validateUserEmailExists,
};

async function saveSocialLinks(
  createSocialLinks: CreateSocialLinksDto
): Promise<any> {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(createSocialLinks),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/users/social-links`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function getProfile(): Promise<any> {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/users/profile`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function getSocialLinks(): Promise<any> {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/users/social-links`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function validateUserEmailExists(email: string) {
  // validate-email
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),

    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/users/validate-email`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}
