import { useAppStoreActions } from "@/src/stores/appstore";
import { useEditorStore } from "@/src/stores/editorstore";
import { useWorkspaceState } from "@/src/stores/workspace";
import {
  Accordion,
  ActionIcon,
  Box,
  Button,
  Card,
  CopyButton,
  Divider,
  Group,
  MultiSelect,
  PasswordInput,
  Radio,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import {
  IconCheck,
  IconCopy,
  IconSend,
  IconShare,
  IconUserPlus,
} from "@tabler/icons-react";

import React from "react";

type Props = {};

const index = (props: Props) => {
  return (
    <>
      <SettingOptions />
      <Divider className="my-2" />

      <Stack spacing={"xl"}>
        <Group
          noWrap
          position="apart"
          className="mt-4 "
          onClick={() => useAppStoreActions()?.toggleUnreleasedFeatureModal()}
        >
          <Box>
            <Text size={"sm"} weight={400}>
              Integrate with actions
            </Text>
            <Text size={"xs"} c={"dimmed"} inline={true}>
              User interactions that trigger actions or send data to your
              server, Zapier, or API endpoints.
            </Text>
          </Box>
          <Switch
            disabled
            labelPosition="left"
            size="xs"
            color="dark"
            radius={"sm"}
          />
        </Group>
        <Group
          noWrap
          position="apart"
          className=""
          onClick={() => useAppStoreActions()?.toggleUnreleasedFeatureModal()}
        >
          <Box>
            <Text size={"sm"} weight={400}>
              Hide Blink's Logo on your page
            </Text>
          </Box>
          <Switch
            disabled
            labelPosition="left"
            size="xs"
            color="dark"
            radius={"sm"}
          />
        </Group>

        {/* <SharePage /> */}
      </Stack>
    </>
  );
};

const SettingOptions: React.FC = () => {
  const workspaceInfo = useWorkspaceState();

  return (
    <Accordion variant="separated" radius="md" defaultValue="general">
      <Accordion.Item value="general">
        <Accordion.Control>
          <Text size={"sm"} weight={500}>
            Page settings
          </Text>
          <Text size={"xs"} c={"dimmed"}>
            Manage your page settings here
          </Text>
        </Accordion.Control>
        <Accordion.Panel
          onClick={() => useAppStoreActions()?.toggleUnreleasedFeatureModal()}
        >
          <Group noWrap position="apart" className="mb-1">
            <Box>
              <Text size={"xs"} weight={500}>
                Scheduling Active
              </Text>
            </Box>
            <Switch
              disabled
              labelPosition="left"
              size="xs"
              color="dark"
              radius={"sm"}
            />
          </Group>

          <Group noWrap position="apart" className="my-2">
            <Box>
              <Text size={"xs"} weight={500}>
                Scheduled From
              </Text>
            </Box>
            <DateInput
              disabled
              defaultValue={new Date()}
              placeholder="Current date"
              size="xs"
            />
          </Group>

          <Group noWrap position="apart" className="my-2">
            <Box>
              <Text size={"xs"} weight={500}>
                Password Protected
              </Text>
            </Box>
            <Switch
              disabled
              labelPosition="left"
              size="xs"
              color="dark"
              radius={"sm"}
            />
          </Group>
          <Group noWrap position="apart" className="my-2">
            <Box>
              <Text size={"xs"} weight={500}>
                Password
              </Text>
            </Box>
            <PasswordInput disabled size="xs" className="w-50" />
          </Group>

          <Group noWrap position="apart" className="my-2">
            <Box>
              <Text size={"xs"} weight={500}>
                Expiration
              </Text>
            </Box>
            <DateInput
              disabled
              defaultValue={new Date()}
              placeholder="Current date"
              size="xs"
            />
          </Group>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="meta">
        <Accordion.Control>
          <Text size={"sm"} weight={500}>
            Meta Data
          </Text>
        </Accordion.Control>
        <Accordion.Panel
          onClick={() => useAppStoreActions()?.toggleUnreleasedFeatureModal()}
        >
          <Box className="mb-1">
            <Radio.Group
              defaultValue="30days"
              label="Analytics Retention"
              labelProps={{
                size: "xs",
              }}
            >
              <Group>
                <Radio
                  disabled
                  color="dark"
                  size="xs"
                  value="30days"
                  label="30 days"
                />
                <Radio
                  disabled
                  color="dark"
                  size="xs"
                  value="1year"
                  label="1 year"
                />
                <Radio
                  disabled
                  color="dark"
                  size="xs"
                  value="2year"
                  label="2 year"
                />
              </Group>
            </Radio.Group>
          </Box>

          <Box className="my-2">
            <MultiSelect
              label="Export Data As"
              labelProps={{
                size: "xs",
              }}
              disabled
              size="xs"
              data={[
                { value: "csv", label: "CSV" },
                { value: "json", label: "JSON" },
                { value: "pdf", label: "PDF" },
                { value: "datasource", label: "Connec to a datasource" },
              ]}
              defaultValue={["csv"]}
            />
          </Box>
          <Group noWrap position="apart" className="my-2">
            <Box>
              <Text size={"xs"} weight={500}>
                Click Rates
              </Text>
            </Box>
            <Switch
              disabled
              labelPosition="left"
              size="xs"
              radius={"sm"}
              color="dark"
              defaultChecked
            />
          </Group>
          <Group noWrap position="apart" className="my-2">
            <Box>
              <Text size={"xs"} weight={500}>
                Referrer
              </Text>
            </Box>
            <Switch
              defaultChecked
              disabled
              labelPosition="left"
              size="xs"
              radius={"sm"}
              color="dark"
            />
          </Group>
          <Group noWrap position="apart" className="my-2">
            <Box>
              <Text size={"xs"} weight={500}>
                Geo Location
              </Text>
            </Box>
            <Switch
              defaultChecked
              disabled
              labelPosition="left"
              size="xs"
              radius={"sm"}
              color="dark"
            />
          </Group>
          <Group noWrap position="apart" className="my-2">
            <Box>
              <Text size={"xs"} weight={500}>
                Device Data
              </Text>
            </Box>
            <Switch
              defaultChecked
              disabled
              labelPosition="left"
              size="xs"
              radius={"sm"}
              color="dark"
            />
          </Group>
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="advanced">
        <Accordion.Control>
          <Text size={"sm"} weight={500}>
            Advanced Settings
          </Text>
        </Accordion.Control>
        <Accordion.Panel
          onClick={() => useAppStoreActions()?.toggleUnreleasedFeatureModal()}
        >
          <Group noWrap position="apart">
            <Box>
              <Text size={"xs"} weight={500}>
                Transfer Ownership
              </Text>
            </Box>
            <Select
              className="w-50"
              variant="filled"
              value={workspaceInfo.workspaceId}
              data={[
                {
                  value: workspaceInfo.workspaceId,
                  label: workspaceInfo.workspaceName,
                },
              ]}
              size="xs"
              disabled
            />
          </Group>

          <Button
            color="red"
            uppercase
            compact
            fullWidth
            className="mt-5"
            radius={"sm"}
            variant="light"
            disabled
          >
            Archive Page
          </Button>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

const CopyPageLink: React.FC<{ url: string }> = ({ url }) => {
  return (
    <CopyButton value={url} timeout={2000}>
      {({ copied, copy }) => (
        <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
          {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
        </ActionIcon>
      )}
    </CopyButton>
  );
};

const SharePage: React.FC = () => {
  const [isPublic, setIsPublic] = React.useState(false);

  const handleSwitch = () => {
    setIsPublic((state) => !state);
  };

  const appBlinkUrl = useEditorStore.use.appBlinkURL();
  const { published: isPublished } = useEditorStore.use.metadata();

  return (
    <Card classNames={"w-100"} shadow="lg" withBorder padding={"sm"}>
      <Stack spacing="xs">
        <Group noWrap position="left">
          <IconShare size={"1rem"} />
          <Tooltip
            label={
              "⚠️ Sharing will be available once you've published your changes."
            }
            position="top"
            multiline
            color="yellow"
            width={200}
            withinPortal
          >
            <Box>
              <Text size={"sm"} weight={500}>
                Share
              </Text>
              <Text size={"xs"} c={"dimmed"} inline={true}>
                Everyone with the link can view
              </Text>
            </Box>
          </Tooltip>

          {/* <Switch
            labelPosition="left"
            size="xs"
            radius={"sm"}
            color="dark"
            style={{ marginLeft: "auto" }}
            checked={isPublic}
            onChange={handleSwitch}
            disabled
          /> */}
        </Group>
        <Tooltip
          label={"Copy blink URL of your page"}
          position="left"
          withArrow
          withinPortal
          multiline
          color="indigo"
          width={"auto"}
        >
          <TextInput
            label=""
            size="xs"
            variant="filled"
            value={appBlinkUrl}
            rightSection={<CopyPageLink url={appBlinkUrl} />}
            onChange={() => {}}
            disabled={!isPublished}
          />
        </Tooltip>

        {/* {isPublic && <InviteUser />} */}
      </Stack>
    </Card>
  );
};

const SendInviteButton: React.FC = () => {
  return (
    <Button
      compact
      size="xs"
      radius={"sm"}
      variant="gradient"
      gradient={{ from: "indigo", to: "cyan" }}
      leftIcon={<IconSend size={"0.75rem"} />}
    >
      Invite
    </Button>
  );
};

const InviteUser: React.FC = () => {
  return (
    <TextInput
      label=""
      size="xs"
      variant="default"
      placeholder="Enter email to invite"
      icon={<IconUserPlus size="0.75rem" />}
      rightSection={<SendInviteButton />}
      rightSectionWidth={80}
    />
  );
};

export default index;
