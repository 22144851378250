import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme,
} from "@mantine/core";
import React, { useState } from "react";
import { useThemeDetector } from "../hooks";
import { UnreleasedFeatures } from "../components/Comingsoon";
import { useAppStore, useAppStoreActions } from "../stores/appstore";

interface ThemeProviderProps {
  children: React.ReactNode;
  theme: "light" | "dark" | "auto";
  toggleTheme: () => void;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
  theme,
  toggleTheme,
}) => {
  const isDarkPreferedColorScheme = useThemeDetector();

  const currentTheme =
    theme === "auto" ? (isDarkPreferedColorScheme ? "dark" : "light") : theme;

  const [colorScheme, setColorScheme] = useState<ColorScheme>("light");
  const toggleColorScheme = (value?: ColorScheme) => {
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
    toggleTheme();
  };

  const showUnreleasedFeatureModal =
    useAppStore.use.showUnreleasedFeatureModal();

  const handleOnClose = () =>
    useAppStoreActions()?.toggleUnreleasedFeatureModal();

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{
          colorScheme: currentTheme,
          breakpoints: {
            xs: "30em",
            sm: "48em",
            md: "64em",
            lg: "74em",
            xl: "90em",
          },
        }}
        withGlobalStyles
        withNormalizeCSS
      >
        <UnreleasedFeatures
          opened={showUnreleasedFeatureModal}
          close={handleOnClose}
        />

        {children}
      </MantineProvider>
    </ColorSchemeProvider>
  );
};
