import React, { useEffect, useState } from "react";
import {
  Accordion,
  ActionIcon,
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Group,
  Input,
  List,
  MultiSelect,
  Paper,
  PasswordInput,
  Radio,
  SegmentedControl,
  Select,
  Stack,
  Switch,
  Tabs,
  Text,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { IconAB2, IconRefresh, IconSettings } from "@tabler/icons-react";
import { DateInput, DatePickerInput, DateTimePicker } from "@mantine/dates";
import { DeviceAnalytics } from "@src/components";
import { Blink, BlinkDirectives } from "@src/types/modules/blinks";
import _ from "lodash";
import { blinkService } from "@src/services/blinks.services";
import toast from "react-hot-toast";
import { camputeDiff } from "@src/helpers";
import AnalyticsGraph from "../AnalyticsGraph";
import { AreaChartComponent } from "../AreaChartComponent";
import { TChartData } from "../../Dashboard";
import { defaultDateRange } from "@/src/utils/constants";
import { notifications } from "@mantine/notifications";
import { BlinkDetail } from ".";
import { SocialIcon } from "react-social-icons";
import { humanReadableCount } from "@/src/utils";
import { Empty } from "./Empty";
import LocationList from "./LocationList";
import SourceList from "./SourceList";

import { useDidUpdate, useViewportSize } from "@mantine/hooks";

import { useWorkspace, useWorkspaceState } from "@/src/stores/workspace";

const today = new Date();
const tenYearsFromNow = new Date(today.setFullYear(today.getFullYear() + 10));

type DeviceBrowserInfo = {
  name: string;
  deviceType: string;
  browserName: string;
  count: number;
  date: string;
  os: string;
  platform: string;
  isMobile: boolean;
  geoLocation: {
    countryName: string;
    cityName: string;
  };
};

interface Props extends BlinkDetail {
  fetchBlinkDataWithFilters: (filters: Record<string, string>) => void;
  uniqueVisitorsCount: number;
}

interface AnalyticsEntry {
  CreatedAt: string;
  Count: number;
  UserAgent: {
    isMobile: boolean;
    browserName: string;
    os: string;
    platform: string;
  };
  GeoLocation: {
    countryName: string;
    cityName: string;
  };
}

function transformDataForAnalyticsGraph(analyticsData: {
  count: number;
  deviceBrowserInfo: Record<string, DeviceBrowserInfo>;
}): TChartData[] {
  if (!analyticsData.deviceBrowserInfo) return [];

  const { deviceBrowserInfo } = analyticsData;

  const data: TChartData[] = [];

  Object.entries(deviceBrowserInfo).forEach(([_, entry]) => {
    const isSameDate = data.find((item) => item.date === entry.date);

    if (isSameDate) {
      isSameDate.mobile +=
        entry.deviceType.toLocaleLowerCase() === "mobile" ? entry.count : 0;
      isSameDate.desktop +=
        entry.deviceType.toLocaleLowerCase() === "desktop" ? entry.count : 0;
      return;
    }

    data.push({
      date: entry.date,
      mobile:
        entry.deviceType.toLocaleLowerCase() === "mobile" ? entry.count : 0,
      desktop:
        entry.deviceType.toLocaleLowerCase() === "desktop" ? entry.count : 0,
    });
  });

  return data;
}

function transformLocationData(
  analyticsData: AnalyticsEntry[],
  totalCount: number
): { country: string; city: string; value: string }[] {
  const locationCounts: { [key: string]: number } = {};

  analyticsData.forEach((entry) => {
    const locationKey = `${entry.GeoLocation.countryName}-${entry.GeoLocation.cityName}`;

    const locationCount = entry.Count || 0;

    locationCounts[locationKey] = locationCounts[locationKey]
      ? locationCounts[locationKey] + locationCount
      : locationCount;
  });

  const transformedData = Object.entries(locationCounts).map(
    ([locationKey, count]) => {
      const [country, city] = locationKey.split("-");
      const percentage = ((count / totalCount) * 100).toFixed(2);
      return {
        country,
        city,
        value: `${percentage}%`,
        count,
      };
    }
  );

  transformedData.sort((a, b) => b.count - a.count);

  return transformedData.map(({ count, ...rest }) => rest);
}

const MainBody: React.FC<Props> = ({
  analytics,
  fetchBlinkDataWithFilters,
  uniqueVisitorsCount,
  ...blinkData
}) => {
  const analyticsData: AnalyticsEntry[] = analytics?.deviceBrowserInfo
    ? Object.values(analytics.deviceBrowserInfo).map((item) => ({
        CreatedAt: item.date,
        UserAgent: {
          isMobile: item.isMobile,
          browserName: item.browserName,
          os: item.os.toLocaleLowerCase(),
          platform: item.platform,
        },
        GeoLocation: item.geoLocation,
        Count: item.count,
      }))
    : [];

  const areaChartData = analytics.dateWiseCount;
  const analyticsGraphData = transformDataForAnalyticsGraph(analytics);
  const locationData = transformLocationData(analyticsData, analytics.count);
  const sourceData = Array.isArray(analytics.sourceCount)
    ? analytics.sourceCount.sort((i, j) => j["count"] - i["count"])
    : [];
  const screenWidth = useViewportSize();
  return (
    <Tabs color="dark" variant="outline" defaultValue="overview">
      <Tabs.List>
        <Tabs.Tab value="overview" icon={<IconAB2 size="0.8rem" />}>
          Overview
        </Tabs.Tab>
        <Tabs.Tab
          value="settings"
          icon={<IconSettings size="0.8rem" />}
          onClick={() =>
            notifications.show({
              title: "🚧 Features Blocked",
              message:
                "Some features are currently unavailable. We're working hard to unlock them, and they'll be live soon! ⏳✨",
              autoClose: true,
              styles: (theme) => ({
                root: {
                  backgroundColor: theme.colors.indigo[5],
                  borderColor: theme.colors.indigo[9],

                  "&::before": { backgroundColor: theme.white },
                },

                title: { color: theme.white },
                description: { color: theme.white },
                closeButton: {
                  color: theme.white,
                  "&:hover": { backgroundColor: theme.colors.indigo[9] },
                },
              }),
            })
          }
        >
          Settings
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="overview" pt="xs">
        <Box>
          <DateRangeSelector
            fetchWithFilter={fetchBlinkDataWithFilters}
            uniqueVisitorsCount={uniqueVisitorsCount}
          />
          <Grid mt="xl">
            <Grid.Col span={12}>
              <AreaChartComponent data={areaChartData} />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={12}>
              <AnalyticsGraph data={analyticsGraphData} />
            </Grid.Col>
          </Grid>
          <Grid className="mt-2">
            <Grid.Col xs={12} sm={12} md={12} lg={6} xl={6} className="mt-2">
              <RenderLocationAndSource
                locationData={locationData}
                sourceData={sourceData}
                screenWidth={screenWidth}
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="">
                <DeviceAnalytics
                  data={analyticsData}
                  totalClicks={analytics.count}
                />
              </div>
            </Grid.Col>
          </Grid>
        </Box>
      </Tabs.Panel>

      <Tabs.Panel value="settings" pt="xs">
        <Settings blink={blinkData} />
      </Tabs.Panel>
    </Tabs>
  );
};

const RenderLocationAndSource: React.FC<{
  locationData: {
    country: string;
    city: string;
    value: string;
  }[];
  screenWidth: { width: number; height: number };
  sourceData: Record<string, any>[];
}> = ({ locationData, sourceData, screenWidth }) => {
  const [activeSegment, setActiveSegment] = useState("location");

  return (
    <React.Fragment>
      <SegmentedControl
        value={activeSegment}
        onChange={(value) => setActiveSegment(value as string)}
        fullWidth={screenWidth?.width > 450 ? true : false}
        size="xs"
        data={[
          { value: "location", label: "Top Locations" },
          { value: "source", label: "Top Source" },
        ]}
      />

      <React.Fragment>
        {activeSegment === "location" ? (
          <LocationList locationData={locationData} />
        ) : (
          <SourceList sourceData={sourceData} />
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

const DateRangeSelector: React.FC<{
  fetchWithFilter: (filters: Record<string, string>) => void;
  uniqueVisitorsCount: number;
}> = ({ fetchWithFilter, uniqueVisitorsCount }) => {
  const [value, setValue] = useState<[Date | null, Date | null]>([
    new Date(defaultDateRange.startDate),
    new Date(defaultDateRange.endDate),
  ]);

  const [isFiltersActive, setIsFiltersActive] = useState(false);

  const handleApply = () => {
    const filters: Record<string, string> = {};
    filters.startDate = value[0]?.toISOString() || defaultDateRange.startDate;
    filters.endDate = value[1]?.toISOString() || defaultDateRange.endDate;
    fetchWithFilter(filters);
  };

  const clearFilters = () => {
    setValue([
      new Date(defaultDateRange.startDate),
      new Date(defaultDateRange.endDate),
    ]);
    setIsFiltersActive(false);
    // apply default date range
    fetchWithFilter({
      startDate: defaultDateRange.startDate,
      endDate: defaultDateRange.endDate,
    });
  };

  const handleOnChange = (value: [Date | null, Date | null]) => {
    setValue(value);
    setIsFiltersActive(true);
  };

  const [filters, setFilters] = useState([
    { value: "bot", label: "Bot Traffic" },
    { value: "unique", label: "Unique Clicks" },
  ]);

  const handleRefresh = () => {
    const filters: Record<string, string> = {};
    filters.startDate = value[0]?.toISOString() || defaultDateRange.startDate;
    filters.endDate = new Date().toISOString();
    fetchWithFilter(filters);
  };

  return (
    <>
      <Group position="apart">
        <Group position="left">
          <DatePickerInput
            size="xs"
            type="range"
            label="Select date range for analytics"
            value={value}
            onChange={handleOnChange}
            maw={325}
          />
          <MultiSelect
            disabled
            size="xs"
            label="Select filters or add new"
            data={filters}
            placeholder="Select filters"
            searchable
            creatable
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
              const item = { value: query, label: query };
              setFilters((current) => [...current, item]);
              return item;
            }}
          />
        </Group>

        <Badge variant="filled" radius={"sm"} color="dark">
          {humanReadableCount(uniqueVisitorsCount)} Unique Visitors
        </Badge>

        <Box className="p-1">
          <Tooltip
            label="Fetch the latest real-time data"
            color="indigo"
            multiline
            width={200}
            withArrow
          >
            <ActionIcon
              variant="light"
              color="indigo"
              onClick={handleRefresh}
              disabled={isFiltersActive}
            >
              <IconRefresh size="1rem" />
            </ActionIcon>
          </Tooltip>
        </Box>
      </Group>
      {isFiltersActive && (
        <Group position="left" className="mt-4">
          <>
            <Button
              onClick={handleApply}
              size="xs"
              compact
              color="indigo"
              sx={{
                minWidth: "120px",
              }}
            >
              Apply filters
            </Button>
            <Button
              size="xs"
              compact
              variant="light"
              color="red"
              sx={{
                minWidth: "120px",
              }}
              onClick={clearFilters}
            >
              Clear all filters
            </Button>
          </>
        </Group>
      )}
    </>
  );
};

const AccordionSettings: React.FC<{
  directives: BlinkDirectives | undefined;
  blinkId: string | undefined;
  isDirectivesActive: boolean;
  toggleDirective: (id: string, enable: boolean) => void;
  noWrapContent: boolean;
}> = ({
  directives,
  blinkId,
  isDirectivesActive,
  toggleDirective,
  noWrapContent,
}) => {
  const [blinkDirectives, set] = useState<BlinkDirectives>({
    isCloakingActive: directives?.isCloakingActive || false,
    isSchedulingActive: directives?.isSchedulingActive || false,
    scheduledFrom: directives?.scheduledFrom || "",
    isPasswordProtected: directives?.isPasswordProtected ?? false,
    password: directives?.password || "",
    isPrivate: directives?.isPrivate || false,
    expiration: directives?.expiration || tenYearsFromNow.toISOString(),
    inactiveLinkUrl: directives?.inactiveLinkUrl || "",
  });

  const [showSaveButton, setShowShowButton] = useState(false);
  const [enableDirectiveState, setEnableDirectiveState] = useState(false);

  const handleUpdates = (key: string, value: any) => {
    set({ ...blinkDirectives, [key]: value });
    setShowShowButton(true);
  };

  const onCanceled = () => {
    set(directives as BlinkDirectives);
    setShowShowButton(false);
  };

  const onSave = async () => {
    if (!blinkId) {
      toast.error("Something went wrong. Please try again.");
      return onCanceled();
    }

    const preparePayload = camputeDiff(directives, blinkDirectives);

    //remove empty string
    Object.keys(preparePayload).forEach((key) => {
      if (preparePayload[key] === "") {
        delete preparePayload[key];
      }
    });

    const updated = await blinkService.updateDirectives(
      blinkId,
      preparePayload
    );

    if (updated) {
      toast.success("Blink directives updated successfully");
      setShowShowButton(false);
    } else {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const workspaceInfo = useWorkspaceState();

  useDidUpdate(
    () => toggleDirective(blinkId as string, enableDirectiveState),
    [enableDirectiveState]
  );

  return (
    <Accordion variant="separated" radius="md" defaultValue="directives">
      <Accordion.Item value="directives">
        <Accordion.Control>
          <Text size={"md"} weight={500}>
            Blink Directives
          </Text>
          <Text size={"xs"} c={"dimmed"}>
            Manage your directives to customize how your blinks behave. These
            settings allow you to control link branding, scheduling, access, and
            expiration, ensuring your blinks meet your specific needs.
          </Text>
        </Accordion.Control>
        <Accordion.Panel>
          <Group noWrap position="apart">
            <Box>
              <Text size={"sm"} weight={500}>
                Enable Directives
              </Text>
              <Text size={"xs"} c="dimmed" maw={300}>
                Enable or disable directives for your blink. This allows you to
                customize the behavior of your blink to meet your specific needs
              </Text>
            </Box>
            <Switch
              labelPosition="left"
              size="xs"
              color="dark"
              checked={isDirectivesActive}
              onChange={(event) => {
                setEnableDirectiveState(event.currentTarget.checked);
              }}
            />
          </Group>

          {isDirectivesActive && (
            <>
              <Group noWrap position="apart">
                <Box>
                  <Text size={"sm"} weight={500}>
                    Scheduling Active
                  </Text>
                  <Text size={"xs"} c="dimmed" maw={300}>
                    Activate or deactivate scheduling for your blink. This
                    allows you to set specific times when your blink is
                    accessible.
                  </Text>
                </Box>
                <Switch
                  labelPosition="left"
                  size="xs"
                  color="dark"
                  checked={blinkDirectives.isSchedulingActive as any}
                  onChange={(event) =>
                    handleUpdates(
                      "isSchedulingActive",
                      event.currentTarget.checked
                    )
                  }
                />
              </Group>

              {blinkDirectives.isSchedulingActive && (
                <>
                  <Group noWrap position="apart">
                    <Box>
                      <Text size={"sm"} weight={500}>
                        Scheduled From
                      </Text>
                      <Text size={"xs"} c="dimmed" maw={300}>
                        Set the start date and time for when your blink will
                        become active. This works in conjunction with the
                        scheduling feature.
                      </Text>
                    </Box>

                    <DateTimePicker
                      maw={300}
                      value={
                        blinkDirectives.scheduledFrom
                          ? new Date(blinkDirectives.scheduledFrom)
                          : new Date()
                      }
                      clearable
                      valueFormat="DD MMM YYYY hh:mm A"
                      placeholder="Pick date and time"
                      style={{ width: "100%" }}
                      defaultValue={new Date()}
                      size="xs"
                      onPointerEnterCapture={() => {}}
                      onPointerLeaveCapture={() => {}}
                      onChange={(value) =>
                        handleUpdates("scheduledFrom", value?.toISOString())
                      }
                    />
                  </Group>
                  <Group noWrap position="apart">
                    <Box>
                      <Text size={"sm"} weight={500}>
                        Expiration
                      </Text>
                      <Text size={"xs"} c="dimmed" maw={300}>
                        Set an expiration date for your blink. The link will
                        become inactive after this date.
                      </Text>
                    </Box>
                    <DateTimePicker
                      maw={300}
                      value={
                        blinkDirectives.expiration
                          ? new Date(blinkDirectives.expiration)
                          : tenYearsFromNow
                      }
                      clearable
                      valueFormat="DD MMM YYYY hh:mm A"
                      placeholder="Pick date and time"
                      style={{ width: "100%" }}
                      defaultValue={new Date()}
                      size="xs"
                      onPointerEnterCapture={() => {}}
                      onPointerLeaveCapture={() => {}}
                      onChange={(value) => {
                        const isoValue = value?.toISOString();

                        if (isoValue) {
                          handleUpdates("expiration", isoValue);
                        }
                      }}
                    />
                  </Group>
                  <Group noWrap position="apart">
                    <Box>
                      <Text size={"sm"} weight={500}>
                        Expiration Redirect
                      </Text>
                      <Text size={"xs"} c="dimmed" maw={300}>
                        Set an alternate web address for your link to redirect
                        to once it expires or is no longer active. This keeps
                        your short link useful even after its primary purpose is
                        complete.
                      </Text>
                    </Box>
                    <Input
                      size="xs"
                      className="wd-300"
                      value={blinkDirectives.inactiveLinkUrl as string}
                      onChange={(e) =>
                        handleUpdates("inactiveLinkUrl", e.currentTarget.value)
                      }
                    />
                  </Group>
                </>
              )}

              <Group noWrap position="apart">
                <Box>
                  <Text size={"sm"} weight={500}>
                    Password Protected
                  </Text>
                  <Text size={"xs"} c="dimmed" maw={300}>
                    Enable password protection for your blink. Only users with
                    the correct password can access the link.
                  </Text>
                </Box>
                <Switch
                  labelPosition="left"
                  size="xs"
                  color="dark"
                  checked={blinkDirectives.isPasswordProtected}
                  onChange={(event) =>
                    handleUpdates(
                      "isPasswordProtected",
                      event.currentTarget.checked
                    )
                  }
                />
              </Group>
              {blinkDirectives?.isPasswordProtected && (
                <Group noWrap position="apart">
                  <Box>
                    <Text size={"sm"} weight={500}>
                      Password
                    </Text>
                    <Text size={"xs"} c="dimmed" maw={300}>
                      Set a password to secure your blink. Ensure only
                      authorized users can access your link.
                    </Text>
                  </Box>
                  <PasswordInput
                    size="xs"
                    className="wd-300"
                    value={blinkDirectives.password as string}
                    onChange={(event) =>
                      handleUpdates("password", event.target.value)
                    }
                  />
                </Group>
              )}

              {showSaveButton && (
                <Flex
                  gap={"lg"}
                  className="mt-4"
                  justify="flex-end"
                  align="center"
                >
                  <Button
                    size="xs"
                    variant="light"
                    color="gray"
                    uppercase
                    radius={"sm"}
                    onClick={onCanceled}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="xs"
                    variant="light"
                    color="indigo"
                    uppercase
                    onClick={onSave}
                  >
                    Save Changes
                  </Button>
                </Flex>
              )}
            </>
          )}
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="config">
        <Accordion.Control disabled>
          <Text size={"md"} weight={500}>
            Analytics Settings
          </Text>
        </Accordion.Control>
        <Accordion.Panel>
          <Group noWrap position="apart">
            <Box>
              <Text size={"sm"} weight={500}>
                Analytics Retention
              </Text>
              <Text size={"xs"} c="dimmed" maw={300}>
                Choose how long to retain your analytics data.
              </Text>
            </Box>
            <Radio.Group defaultValue="30days">
              <Group>
                <Radio color="dark" size="xs" value="30days" label="30 days" />
                <Radio color="dark" size="xs" value="1year" label="1 year" />
                <Radio color="dark" size="xs" value="2year" label="2 year" />
              </Group>
            </Radio.Group>
          </Group>
          <Group noWrap position="apart">
            <Box>
              <Text size={"sm"} weight={500}>
                Data Export Option
              </Text>
              <Text size={"xs"} c="dimmed" maw={300}>
                Select the format for exporting your analytics data
              </Text>
            </Box>
            <MultiSelect
              className="wd-300"
              size="xs"
              data={[
                { value: "csv", label: "CSV" },
                { value: "json", label: "JSON" },
                { value: "pdf", label: "PDF" },
                { value: "datasource", label: "Connec to a datasource" },
              ]}
              defaultValue={["csv"]}
            />
          </Group>
          <Group noWrap position="apart">
            <Box>
              <Text size={"sm"} weight={500}>
                Click Rates
              </Text>
              <Text size={"xs"} c="dimmed" maw={300}>
                Track and analyze the number of clicks on your blinks.
              </Text>
            </Box>
            <Switch labelPosition="left" size="xs" color="dark" />
          </Group>
          <Group noWrap position="apart">
            <Box>
              <Text size={"sm"} weight={500}>
                Referrer
              </Text>
              <Text size={"xs"} c="dimmed" maw={300}>
                Identify where your traffic is coming from.
              </Text>
            </Box>
            <Switch labelPosition="left" size="xs" color="dark" />
          </Group>
          <Group noWrap position="apart">
            <Box>
              <Text size={"sm"} weight={500}>
                Geo Location
              </Text>
              <Text size={"xs"} c="dimmed" maw={300}>
                Analyze the geographic distribution of your link clicks
              </Text>
            </Box>
            <Switch labelPosition="left" size="xs" color="dark" />
          </Group>
          <Group noWrap position="apart">
            <Box>
              <Text size={"sm"} weight={500}>
                Device Data
              </Text>
              <Text size={"xs"} c="dimmed" maw={300}>
                Understand the devices used to access your blinks, including
                browser types (e.g., Chrome, Firefox, Safari), operating systems
                (e.g., Windows, macOS, Linux), and specific device types (e.g.,
                desktop, mobile
              </Text>
            </Box>
            <Switch labelPosition="left" size="xs" color="dark" />
          </Group>
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="link_management">
        <Accordion.Control>
          <Text size={"md"} weight={500}>
            Link Management
          </Text>
        </Accordion.Control>
        <Accordion.Panel>
          <Group noWrap={noWrapContent} position="apart">
            <Box>
              <Text size={"sm"} weight={500}>
                Transfer Ownership
              </Text>
              <Text size={"xs"} c="dimmed" maw={300}>
                Transfer ownership of this blink to a different workspace that
                you own. This allows you to better organize and manage your
                blinks across multiple workspaces
              </Text>
            </Box>
            <Select
              className="wd-300"
              variant="filled"
              value={workspaceInfo.workspaceId}
              data={[
                {
                  value: workspaceInfo.workspaceId,
                  label: workspaceInfo.workspaceName,
                },
              ]}
              size="xs"
              disabled
            />
          </Group>
          <Flex className="mt-4" justify="flex-end" align="center">
            <Button color="red" uppercase>
              Delete Blink
            </Button>
          </Flex>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

const Settings: React.FC<{ blink: Partial<Blink> }> = ({ blink }) => {
  const [blinkSettings, set] = useState<Partial<Blink>>({
    id: blink.id,
    name: blink.name,
    blinkDomain: blink.blinkDomain,
    blinkSignature: blink.blinkSignature,
    redirectUrl: blink.redirectUrl,
    status: blink.status,
    enableDirectives: blink.enableDirectives || false,
  });

  const [showSaveButton, setShowShowButton] = useState(false);
  const [counterForTogglingDirectives, setCounterForTogglingDirectives] =
    useState(0);

  const handleUpdates = (key: string, value: any) => {
    set({ ...blinkSettings, [key]: value });
    setShowShowButton(true);
  };

  const onCanceled = () => {
    set(blink);
    setShowShowButton(false);
  };

  const onSave = async () => {
    if (!blink.id) {
      toast.error("Something went wrong. Please try again.");
      return onCanceled();
    }

    const updatedBlink = _.omit(blinkSettings, ["redirectUrl"]);

    const updated = await blinkService.update(blink.id as string, updatedBlink);

    if (updated) {
      toast.success("Blink updated successfully");
      setShowShowButton(false);
    } else {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const enableOrDisableDirectives = async (id: string, enable: boolean) => {
    if (counterForTogglingDirectives >= 5) {
      toast.error(
        "⚠️ Too Many Attempts: There have been too many attempts to set directives. Please try again in a few minutes."
      );
      return setTimeout(() => {
        setCounterForTogglingDirectives(0);
      }, 300000); // 5 minutes = 300000ms
    }

    setCounterForTogglingDirectives((prev) => prev + 1);
    try {
      await blinkService.enableOrDisableDirectives(id, enable);
      toast.success(
        `Directives ${enable ? "enabled" : "disabled"} successfully`
      );
      set((prev) => ({ ...prev, enableDirectives: enable }));
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
      set((prev) => ({ ...prev, enableDirectives: !enable }));
    }
  };

  const noWrapContent = useViewportSize().width > 763;

  return (
    <Box>
      <Stack spacing="xs">
        <Group noWrap={noWrapContent} position="apart">
          <Box>
            <Text size={"sm"} weight={500}>
              Blink name
            </Text>
            <Text size={"xs"} c="dimmed">
              This is the name of the link you created.
            </Text>
          </Box>
          <Input
            size="xs"
            className="wd-300"
            value={blinkSettings.name}
            onChange={(e) => handleUpdates("name", e.currentTarget.value)}
          />
        </Group>
        <Group noWrap={noWrapContent} position="apart">
          <Box>
            <Text size={"sm"} weight={500}>
              Redirect URL
            </Text>
            <Text size={"xs"} c="dimmed">
              This is the URL where the blink will direct users to.
            </Text>
          </Box>
          <Tooltip label="The redirect URL is set and cannot be altered.">
            {/* <div> */}
            <Input
              disabled
              size="xs"
              className="wd-300"
              value={blinkSettings.redirectUrl}
            />
            {/* </div> */}
          </Tooltip>
        </Group>
        <Group noWrap={noWrapContent} position="apart">
          <Box>
            <Text size={"sm"} weight={500}>
              Blink Domain
            </Text>
            <Text size={"xs"} c="dimmed" maw={300}>
              This is the web address for your blink links. It sets the base URL
              for all the links you create.
            </Text>
          </Box>
          <Select
            className="wd-300"
            variant="filled"
            defaultValue={"https://b-links.co"}
            disabled
            // value={blinkSettings.blinkDomain}
            onChange={(value) => handleUpdates("blinkDomain", value)}
            data={[
              {
                value: "https://b-links.co",
                label: "https://b-links.co",
                group: "default",
              },
            ]}
            size="xs"
          />
        </Group>
        <Group noWrap={noWrapContent} position="apart">
          <Box>
            <Text size={"sm"} weight={500}>
              Blink Signature
            </Text>
            <Text size={"xs"} c="dimmed" maw={300}>
              This is your unique identifier for blinks, adding a personal touch
              to the links you share
            </Text>
          </Box>
          <Input
            disabled
            size="xs"
            className="wd-300"
            value={blinkSettings.blinkSignature}
            onChange={(e) =>
              handleUpdates("blinkSignature", e.currentTarget.value)
            }
          />
        </Group>
        <Group noWrap={noWrapContent} position="apart">
          <Box>
            <Text size={"sm"} weight={500}>
              Status
            </Text>
            <Text size={"xs"} c="dimmed" maw={300}>
              Set the status of your blink to active, inactive, or archived
              based on your needs. This helps you manage the availability and
              visibility of your blink.
            </Text>
          </Box>
          <Radio.Group
            defaultValue={blinkSettings.status}
            onChange={(value) => handleUpdates("status", value)}
          >
            <Group>
              <Radio
                color="dark"
                size="xs"
                value="active"
                label="active"
                disabled
              />
              <Radio
                color="dark"
                size="xs"
                value="inactive"
                label="disable"
                disabled
              />
              <Radio
                color="dark"
                size="xs"
                value="archived"
                label="archive"
                disabled
              />
            </Group>
          </Radio.Group>
        </Group>

        {showSaveButton && (
          <Flex gap={"lg"} className="mt-4" justify="flex-end" align="center">
            <Button
              size="xs"
              variant="light"
              color="gray"
              uppercase
              radius={"sm"}
              onClick={onCanceled}
            >
              Cancel
            </Button>
            <Button
              size="xs"
              variant="light"
              color="indigo"
              uppercase
              onClick={onSave}
            >
              Save Changes
            </Button>
          </Flex>
        )}

        <Divider my="xs" label="Advanced settings" labelPosition="center" />
        <AccordionSettings
          directives={blink.directives}
          blinkId={blink.id}
          isDirectivesActive={blinkSettings.enableDirectives ?? false}
          toggleDirective={enableOrDisableDirectives}
          noWrapContent={noWrapContent}
        />
      </Stack>
    </Box>
  );
};

export default MainBody;
