import { Box, Kbd, MantineColor, Text, Tooltip } from "@mantine/core";
import { ColorSlider } from "@mantine/core/lib/ColorPicker/ColorSlider/ColorSlider";
import React, { useState, useEffect, useRef } from "react";

interface TruncatedTextProps {
  content: string;
  type: string;
  showSymbol?: boolean;
  size?: string;
  color?: MantineColor;
}
export const TruncatedContent: React.FC<TruncatedTextProps> = ({
  content,
  type,
  showSymbol = true,
  size = "lg",
  color = "dark",
}) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const element = textRef.current as HTMLElement | null;
    if (element) {
      setIsTruncated(element.scrollWidth > element.clientWidth);
    }
  }, [content]);
  return (
    <Tooltip
      label={content}
      color="indigo"
      position="top-start"
      disabled={!isTruncated}
      multiline={true}
      withinPortal={false}
      width={400}
      sx={{ overflowWrap: "break-word" }}
    >
      <Box>
        {type === "text" ? (
          <Text weight={500} size={size} ref={textRef} truncate>
            {showSymbol ? `@ ${content}` : content}
          </Text>
        ) : (
          <div
            style={{ overflow: "hidden" }}
            ref={textRef}
            className="text-truncate"
          >
            <Kbd>{content}</Kbd>
          </div>
        )}
      </Box>
    </Tooltip>
  );
};
