export const humanizeTime = (dateInstance: Date | string): string => {
  const date =
    typeof dateInstance === "string" ? new Date(dateInstance) : dateInstance;

  const now = new Date();
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  const intervals = [
    { label: "years", value: 31536000 },
    { label: "months", value: 2592000 },
    { label: "weeks", value: 604800 },
    { label: "days", value: 86400 },
    { label: "hours", value: 3600 },
    { label: "minutes", value: 60 },
  ];

  for (const { label, value } of intervals) {
    const interval = seconds / value;
    if (interval >= 1) {
      return `created ${Math.floor(interval)} ${label} ago`;
    }
  }

  return `created ${seconds} seconds ago`;
};

const usedDomains = new Set<string>();
const usedShortUrls = new Set<string>();

export function generateShortUrl(longUrl: string): string {
  const url = new URL(longUrl);
  const domain = url.hostname.split(".").slice(-2, -1)[0];

  if (!usedDomains.has(domain)) {
    usedDomains.add(domain);
    usedShortUrls.add(domain);
    return domain;
  }

  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  const length = 6;
  let shortUrl: string;

  do {
    shortUrl =
      domain +
      "-" +
      Array(length)
        .fill(null)
        .map(() =>
          characters.charAt(Math.floor(Math.random() * characters.length))
        )
        .join("");
  } while (usedShortUrls.has(shortUrl));

  usedShortUrls.add(shortUrl);
  return shortUrl;
}

export const extractDomainFromUrl = (url: string): string => {
  const domain = new URL(url).hostname;
  return domain;
};

/**
 * Converts a date string from the format "Sat Apr 27 2024 00:00:00 GMT+0530 (India Standard Time)"
 * to ISO 8601 format "YYYY-MM-DDTHH:mm:ssZ"
 *
 * @param dateString The input date string to convert
 * @returns The date in ISO 8601 format, or null if the input is invalid
 */
export function convertToISO8601(dateString: string): string | null {
  // Parse the input date string
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error("Invalid date string provided");
    return null;
  }

  // Convert to ISO 8601 format
  const isoString = date.toISOString();

  return isoString;
}
