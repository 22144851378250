import React from "react";
import { Text, Group } from "@mantine/core";
import Logo from "@assets/logo.svg";
import { Image } from "../BaseUI";
import { useEditorStore } from "@/src/stores/editorstore";

const index: React.FC<{ isMobileLayoutActive: boolean; position?: string }> = ({
  isMobileLayoutActive,
  position = "absolute",
}) => {
  let containerStyle: Record<string, string> = {
    display: "flex",
    flexDirection: "row" as const,
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    padding: "10px",
    borderRadius: "10px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
    height: "50px",
    width: "270px",
    margin: "auto",
    gap: "5px",
    position: position,
    cursor: "pointer",
    left: "50%",
    transform: "translate(-50%, -10%)",
  };

  if (!isMobileLayoutActive) {
    containerStyle.right = "0%";
    containerStyle.left = "auto";
  }

  const isEditMode = useEditorStore.use.mode() === "edit";

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (!isEditMode) {
      window.open("https://b-link.co", "_blank");
    }
  };

  return (
    <div
      className="editor-dock sticky-bottom glowing-div mb-2"
      style={containerStyle}
      onClick={handleOnClick}
    >
      <Group position="apart">
        <Image
          src={Logo}
          alt="logo"
          width={30}
          height={30}
          className="bridge-logo"
        />
        <Text
          size="sm"
          fw={600}
          variant="gradient"
          gradient={{ from: "indigo", to: "cyan", deg: 45 }}
        >
          b-link.co / bridging-your-links
        </Text>
      </Group>
    </div>
  );
};

export default index;
