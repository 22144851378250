import { ActionIcon, Tooltip, useMantineTheme } from "@mantine/core";
import { IconChevronsLeft, IconDevices, IconShare } from "@tabler/icons-react";
import React from "react";
import { ThemeToggle } from "../ThemeToggle";
import { useEditorStoreActions } from "@/src/stores/editorstore";

export const VerticalIconList: React.FC<{ classname?: string }> = ({
  classname,
}) => {
  const theme = useMantineTheme();

  const containerStyle = {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    padding: "10px",
    borderRadius: "10px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
    margin: "10px auto",
    gap: "5px",
  };

  const gobackToEditor = () => {
    useEditorStoreActions().updateMode("edit");
  };

  return (
    <div className={classname} style={containerStyle}>
      <Tooltip
        label="Go back to editor"
        position="left"
        color="indigo"
        transitionProps={{ transition: "slide-down", duration: 300 }}
      >
        <ActionIcon size="lg" onClick={gobackToEditor}>
          <IconChevronsLeft size="1rem" />
        </ActionIcon>
      </Tooltip>

      <Tooltip
        label="Social share"
        position="left"
        color="indigo"
        transitionProps={{ transition: "slide-down", duration: 300 }}
      >
        <ActionIcon size="lg" disabled>
          <IconShare size="1rem" />
        </ActionIcon>
      </Tooltip>

      <Tooltip
        label="Switch device preview"
        position="left"
        color="indigo"
        transitionProps={{ transition: "slide-down", duration: 300 }}
      >
        <ActionIcon size="lg" disabled>
          <IconDevices size="1rem" />
        </ActionIcon>
      </Tooltip>
      <ThemeToggle />
    </div>
  );
};
