import { Box, Input, Switch, useMantineTheme } from "@mantine/core";
import { BlinkDirectives } from "@src/types/modules/blinks";
import { IconLink, IconLock, IconLockAccessOff } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";

type Props = {
  callback: (type: keyof BlinkDirectives, data: any) => void;
};

export const PasswordProtected: React.FC<Props> = ({ callback }) => {
  const [checked, setChecked] = useState(false);
  const [password, setPassword] = useState<string>("");

  const theme = useMantineTheme();

  useEffect(() => {
    callback("isPasswordProtected", checked);
  }, [checked]);

  const handleDebouncedPasswordChange = (value: string) => {
    setPassword(value);
    callback("password", value);
  };

  return (
    <Box className="mt-3">
      <Switch
        size="xs"
        label="Enable password protection"
        color="dark"
        onChange={(e) => setChecked(e.target.checked)}
        thumbIcon={
          checked ? (
            <IconLock
              size={"0.6rem"}
              color={theme.colors.dark[theme.fn.primaryShade()]}
              stroke={3}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <IconLockAccessOff
              size={"0.6rem"}
              color={theme.colors.gray[theme.fn.primaryShade()]}
              stroke={3}
              style={{ cursor: "pointer" }}
            />
          )
        }
      />

      {checked && (
        <Input
          value={password}
          onChange={(e) => handleDebouncedPasswordChange(e.currentTarget.value)}
          className="mt-2"
          size="xs"
          icon={<IconLink size={"0.8rem"} />}
          placeholder="password"
        />
      )}
    </Box>
  );
};
