import React from "react";
import FolderMenu from "./FolderMenu";
import {
  ActionIcon,
  Box,
  Container,
  Group,
  Text,
  Tooltip,
  rem,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";

type Props = {};

interface Composition {
  Header: React.FC;
  Folder: React.FC;
  FolderTree: React.FC;
}

const FolderSidebar: React.FC<Props> & Composition = (props) => {
  return (
    <Container className="p-1">
      <FolderSidebar.Header />
      <FolderSidebar.FolderTree />
    </Container>
  );
};

FolderSidebar.Header = () => {
  return (
    <Box
      sx={(theme) => ({
        textAlign: "center",
        padding: theme.spacing.sm,
        cursor: "pointer",
      })}
    >
      <Group position="apart">
        <Text size="xs">My Folders</Text>
        <Tooltip label="Create a new folder" withArrow position="right">
          <ActionIcon variant="default" size={18}>
            <IconPlus
              style={{ width: rem(12), height: rem(12) }}
              stroke={1.5}
            />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Box>
  );
};

FolderSidebar.Folder = () => {
  return <div>Folder</div>;
};

FolderSidebar.FolderTree = () => {
  return <FolderMenu />;
};

export default FolderSidebar;
