import React from "react";
import { Box, Group } from "@mantine/core";
import { useEditorStore } from "@src/stores/editorstore";
import { WidgetInspector } from "./WidgetInspector";
import RightSidebar from "./RightSidebar";

type Props = {
  setSelectedWidget: (id: string) => void;
  openInspector: () => void;
};

const index = (props: Props) => {
  const selectedWidget = useEditorStore.use.selectedWidget();

  return (
    <Box className="w-100">
      <Group>{selectedWidget ? <WidgetInspector /> : <RightSidebar />}</Group>
    </Box>
  );
};

export default index;
