import {
  Box,
  Center,
  Group,
  NativeSelect,
  SegmentedControl,
  Text,
} from "@mantine/core";
import React from "react";
import BackgroundSelector from "../BackgroundSelector";
import TemplateList from "../Templates/TemplateList";
import { IconRectangleVerticalFilled, IconTemplate } from "@tabler/icons-react";

type segment = "templates" | "canvas_settings";

type Props = {};

interface Composition {
  CanvasSettings: React.FC;
  RenderSegment: React.FC<{ type: segment }>;
}

const RightSidebar: React.FC<Props> & Composition = () => {
  const [activeTab, setActiveTab] = React.useState<segment>("canvas_settings");

  const handleTabChange = (tab: "templates" | "canvas_settings") => {
    setActiveTab(tab);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <SegmentedControl
        size="xs"
        fullWidth
        value={activeTab}
        onChange={handleTabChange}
        data={[
          {
            value: "templates",
            disabled: true,
            label: (
              <Center>
                <IconTemplate size="1rem" />
                <Box ml={5}>Templates</Box>
              </Center>
            ),
          },
          {
            value: "canvas_settings",
            label: (
              <Center>
                <IconRectangleVerticalFilled size="1rem" />
                <Box ml={5}>Canvas settings</Box>
              </Center>
            ),
          },
        ]}
      />
      <RenderSegment type={activeTab} />
    </Box>
  );
};

const RenderSegment: React.FC<{ type: segment }> = ({ type }) => {
  switch (type) {
    case "templates":
      return <TemplateList />;
    case "canvas_settings":
      return <RightSidebar.CanvasSettings />;
    default:
      return null;
  }
};

const RenderCanvasSettings = () => {
  return (
    <Box sx={{ width: "100%" }} className="mt-1">
      <Group sx={{ padding: "20px 0" }}>
        <NativeSelect
          size="xs"
          data={[
            { label: "Mobile view", value: "mobile" },
            { label: "Desktop view", value: "desktop" },
          ]}
          labelProps={{
            style: {
              fontSize: "14px",
              fontWeight: 500,
              marginBottom: "10px",
            },
          }}
          label="Canvas layout"
          variant="filled"
          value={"mobile"}
          disabled
          w={"100%"}
        />
        <BackgroundSelector />
      </Group>
    </Box>
  );
};

RightSidebar.CanvasSettings = RenderCanvasSettings;
RightSidebar.RenderSegment = RenderSegment;

export default RightSidebar;
