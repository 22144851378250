// cookieUtils.ts
export function setCookie(
  name: string,
  value: string,
  expiryMinutes?: number
): void {
  let expires = "";
  if (expiryMinutes) {
    const date = new Date();
    date.setTime(date.getTime() + expiryMinutes * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${
    value || ""
  }${expires}; path=/; SameSite=None; Secure`;
}

export function getCookie(name: string): string | undefined {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return undefined;
}

export function eraseCookie(name: string): void {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
