import { diff } from "deep-object-diff";
import toast from "react-hot-toast";

export function copyToClipboard(text: string): void {
  if (!navigator.clipboard) {
    // Use the old method if the Clipboard API is not supported
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
    return;
  }

  navigator.clipboard.writeText(text).then(
    () => {
      toast.success("Copied to clipboard");
    },
    (err) => {
      console.error("Async: Could not copy text: ", err);
      toast.error("Could not copy to clipboard");
    }
  );
}

export const camputeDiff = (oldObj: any, newObj: any) => {
  return removeUndefined(diff(oldObj, newObj));
};

// remove undefined values from the objects
export const removeUndefined = (obj: any) => {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
  return obj;
};
