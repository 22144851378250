import React from "react";
import { Box, Grid, useMantineTheme } from "@mantine/core";
import LeftHeaderSection from "./LeftSection";
import RightHeaderSection from "./RightSection";
import MiddleHeaderSection from "./MiddleSection";
const Index: React.FC<{}> = () => {
  const theme = useMantineTheme();

  return (
    <Box
      sx={() => {
        return {
          position: "fixed",
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: "0 10px",
          height: "60px",
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : "#F9FAFB",
          zIndex: 2,
        };
      }}
    >
      <Grid
        sx={{
          width: "100%",
          alignItems: "center",
          marginLeft: "0",
          marginRight: "0",
          height: "100%",
          marginTop: "0",
          marginBottom: "0",
        }}
      >
        <Grid.Col
          span={4}
          sx={{
            minWidth: "27%",
            height: "100%",
            padding: "0",
            maxWidth: "27%",
          }}
        >
          <LeftHeaderSection />
        </Grid.Col>
        <Grid.Col span={"auto"} sx={{ minWidth: "33.33%" }}>
          <MiddleHeaderSection />
        </Grid.Col>

        <Grid.Col
          span={4}
          sx={{ minWidth: "23.33%", padding: "0", maxWidth: "23.33%" }}
        >
          <RightHeaderSection />
        </Grid.Col>
      </Grid>
    </Box>
  );
};
export default Index;
