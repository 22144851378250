import { appConfig } from "@src/app.config";
import { handleResponseWithoutValidation } from "@src/lib/handleResponses";
import { authHeader } from "@src/lib/requestHeaders";
import { CreateComponentDto } from "./types";
import { IControl } from "@src/modules/BlinkInBio/Editor/widgets/types";

export const componentsService = {
  create: create,
  delete: deleteComponent,
  updateComponentLayout: updateComponentLayout,
  updateComponentProperties: updateComponentProperties,
  updateComponentControl: updateComponentControl,
  updateComponentName: updateComponentName,
};

async function create(
  appId: string,
  pageId: string,
  createComponentData: CreateComponentDto
): Promise<any> {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(createComponentData),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/components/${appId}/${pageId}`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function deleteComponent(id: string): Promise<any> {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/components/${id}`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function updateComponentLayout(
  componentId: string,
  currentLayout: "mobile" | "desktop",
  layout: any
): Promise<any> {
  const requestOptions = {
    method: "PATCH",
    headers: authHeader(),
    body: JSON.stringify({ currentLayout, layout }),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/components/${componentId}/layout`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function updateComponentProperties(
  componentId: string,
  properties: any
): Promise<any> {
  const requestOptions = {
    method: "PATCH",
    headers: authHeader(),
    body: JSON.stringify(properties),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/components/${componentId}/properties`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function updateComponentControl(
  componentId: string,
  control: IControl
): Promise<any> {
  const requestOptions = {
    method: "PATCH",
    headers: authHeader(),
    body: JSON.stringify({ control }),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/components/${componentId}/control`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}

async function updateComponentName(
  componentId: string,
  name: string
): Promise<any> {
  const requestOptions = {
    method: "PATCH",
    headers: authHeader(),
    body: JSON.stringify({ name }),
    credentials: "include" as RequestCredentials,
  };

  const response = await fetch(
    `${appConfig.apiURL}/components/${componentId}/name`,
    requestOptions
  );
  const response_1 = await handleResponseWithoutValidation(response);
  return response_1;
}
