import React from "react";
import {
  AppShell,
  Group,
  Loader,
  Menu,
  Avatar,
  Divider,
  Tooltip,
  Button,
  Center,
  Container,
} from "@mantine/core";
import { Image, Header } from "@UIComponents/BaseUI";
import NavContainer from "@UIComponents/Navigation";
import Logo from "@assets/logo.svg";
import Actions from "@assets/icons/actions.svg";
import Connectors from "@assets/icons/connectors.svg";
import BlinkInBio from "@assets/icons/pages.svg";
import Settings from "@assets/icons/settings.svg";
import Blink from "@assets/icons/blink.svg";
import Dashboard from "@assets/icons/dash.svg";
import { RouteMap } from "@utils/constants";
import {
  IconAdjustmentsAlt,
  IconArrowsExchange2,
  IconLogout,
  IconSettingsPlus,
  IconUserScan,
  IconUsersGroup,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../stores/appstore";
import { authenticationService } from "../services/auth.service";

export type navLinks = {
  icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  label: string;
  route: string;
  disabled?: boolean;
  tip?: string;
};

const navlinks: navLinks[] = [
  {
    icon: Dashboard,
    label: RouteMap.dashboard.name,
    route: RouteMap.dashboard.path,
    tip: "Dashboard",
  },
  {
    icon: Blink,
    label: RouteMap.blinks.name,
    route: RouteMap.blinks.path,
    tip: "Create and manage your branded short links",
  },
  {
    icon: BlinkInBio,
    label: RouteMap.blinkinbio.name,
    route: RouteMap.blinkinbio.path,
    tip: "Craft your Funnel Pages and Link-in-Bios",
  },
  {
    icon: Actions,
    label: RouteMap.actions.name,
    route: RouteMap.actions.path,
    disabled: true,
    tip: "⚠️ Actions are coming soon",
  },
  {
    icon: Connectors,
    label: RouteMap.connectors.name,
    route: RouteMap.connectors.path,
    disabled: true,
    tip: "⚠️ Connectors are coming soon",
  },
];

const controls = [
  {
    icon: Settings,
    label: RouteMap.settings.name,
    route: RouteMap.settings.path,
    disabled: false,
    tip: "Settings",
  },
];

type Props = {
  children: React.ReactNode;
  showLoader?: boolean;
};

export const AppShellContainer: React.FC<Props> = ({
  children,
  showLoader = false,
}) => {
  const navigate = useNavigate();
  const { userMetaData } = useAppStore();
  const workspaceId = userMetaData.currentWorkspaceId;

  return (
    <AppShell
      padding="xs"
      navbar={<NavContainer navLinks={navlinks} controls={controls} />}
      header={
        <Header className="app-header">
          <Group position="apart">
            <Image
              className="bridge-logo"
              src={Logo}
              width={30}
              height={30}
              alt="Logo"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/${workspaceId}/blinks`);
              }}
            />
            <Menu position="bottom-end" width={200} shadow="md">
              <Menu.Target>
                <Button
                  mr="xs"
                  p={"6"}
                  size="xs"
                  radius="sm"
                  leftIcon={
                    <Avatar size="xs" color="cyan" radius="xl">
                      {`${userMetaData?.firstName?.charAt(
                        0
                      )}${userMetaData?.lastName?.charAt(0)}`}
                    </Avatar>
                  }
                  variant="light"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[9]
                        : theme.colors.dark[7],

                    color: theme.colors.dark[0],

                    "&:hover": {
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[9]
                          : theme.colors.gray[9],
                    },
                  })}
                >
                  {userMetaData?.email}
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Tooltip
                  label="Coming soon"
                  withArrow
                  position="bottom"
                  color="indigo"
                  transitionProps={{ transition: "slide-down", duration: 300 }}
                >
                  <div>
                    <Menu.Item disabled icon={<IconUserScan size={15} />}>
                      My Profile
                    </Menu.Item>
                    <Menu.Item disabled icon={<IconAdjustmentsAlt size={15} />}>
                      Account setting
                    </Menu.Item>
                    <Menu.Item disabled icon={<IconSettingsPlus size={15} />}>
                      Admin console
                    </Menu.Item>
                    <Menu.Item disabled icon={<IconUsersGroup size={15} />}>
                      Manage team
                    </Menu.Item>
                  </div>
                </Tooltip>
                <Menu.Item
                  color="red"
                  icon={<IconLogout size={15} />}
                  onClick={() => {
                    authenticationService.logout().then(() => {
                      navigate("/login", { replace: true });
                    });
                  }}
                >
                  Logout
                </Menu.Item>
                <Divider />
                <Tooltip
                  label="Coming soon"
                  withArrow
                  position="bottom"
                  color="indigo"
                  transitionProps={{ transition: "slide-down", duration: 300 }}
                >
                  <div>
                    <Menu.Item
                      disabled
                      icon={<IconArrowsExchange2 size={15} />}
                      className="mt-1"
                    >
                      Switch Workspace
                    </Menu.Item>
                  </div>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
          paddingLeft: "calc(var(--mantine-navbar-width, 0px))",
          paddingRight: "alc(var(--mantine-aside-width, 0px))",
          paddingTop: "calc(var(--mantine-header-height, 0px) + 0px);",
        },
      })}
    >
      {showLoader ? (
        <Container
          style={{
            height: "100vh",
          }}
        >
          <Center className="h-100">
            <Loader color="indigo" variant="oval" />
          </Center>
        </Container>
      ) : (
        children
      )}
    </AppShell>
  );
};
