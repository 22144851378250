import React, { useState } from "react";
import {
  Box,
  Card,
  Center,
  Container,
  Drawer,
  Grid,
  Paper,
  SegmentedControl,
  Space,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconCpu2,
  IconLayoutDistributeHorizontalFilled,
} from "@tabler/icons-react";
import { TFilteredWidget, getWidgetMetaData } from "@helpers/widgets.helpers";
import { allWidgets } from "@src/modules/Pages/widgets/widgetTypes";
import { Image } from "@UIComponents/BaseUI";
import { useEditorStoreActions } from "@stores/editorstore";
import toast from "react-hot-toast";
import { generateUniqueId } from "@src/lib";
import { WidgetSegment } from "@src/types/modules/blinkInBio/widgets.types";

const allWidgetsMeta = getWidgetMetaData(allWidgets as []);

const segments = [
  {
    value: "components",
    label: (
      <Center>
        <IconLayoutDistributeHorizontalFilled size="1rem" />
        <Box ml={10}>UI widgets</Box>
      </Center>
    ),
  },
  // {
  //   value: "connectors",
  //   disabled: true,
  //   label: (
  //     <Center>
  //       <IconPlugConnected size="1rem" />
  //       <Box ml={10}>Connectors</Box>
  //     </Center>
  //   ),
  // },
  {
    value: "power_blocks",
    label: (
      <Center>
        <IconCpu2 size="1rem" />
        <Box ml={10}>Power blocks</Box>
      </Center>
    ),
    disabled: true,
  },
];

type Props = {};

type SegmentedContainerProps = {
  selected: WidgetSegment;
  onChange: (segment: WidgetSegment) => void;
};

interface Composition {
  SegmentedContainer: React.FC<SegmentedContainerProps>;
  WidgetList: React.FC<{
    category: string;
    segmentedWidgets: TFilteredWidget[];
  }>;
}

const WidgetManager: React.FC<Props> & Composition = ({}) => {
  const [segment, set] = useState<WidgetSegment>("components");
  const [segmentedWidgets, setSegmentedWidgets] = useState(
    allWidgetsMeta[segment] ?? []
  );

  React.useEffect(() => {
    const currentWidgets = allWidgetsMeta[segment] ?? [];
    setSegmentedWidgets(currentWidgets);
  }, [segment]);

  return (
    <Paper>
      <Box>
        <Box className="w-100 px-1">
          <WidgetManager.SegmentedContainer
            selected={segment}
            onChange={(segment) => {
              set(segment);
            }}
          />
        </Box>
      </Box>
      <Box>
        <WidgetManager.WidgetList
          category={segment}
          segmentedWidgets={segmentedWidgets}
        />
      </Box>
    </Paper>
  );
};

const SegmentedWidgetList: React.FC<{
  category: string;
  segmentedWidgets: TFilteredWidget[];
}> = ({ category, segmentedWidgets }) => {
  const { addWidget, setWidgetManagerLoading } = useEditorStoreActions();

  return (
    <Grid className="mt-2">
      {segmentedWidgets.map((widget) => (
        <Grid.Col
          span={4}
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "fit-content",
            alignItems: "center",
          }}
        >
          <Tooltip
            label={`Click to add ${widget.name} to the canvas`}
            position="bottom"
            multiline
            color="indigo"
            transitionProps={{ transition: "slide-down", duration: 300 }}
            width={150}
          >
            <Card
              shadow="xs"
              padding="xs"
              sx={(theme) => ({
                background:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[5]
                    : theme.colors.gray[0],
                cursor: "pointer",
                "&:hover": {
                  background:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[9]
                      : "#F8F9FA",
                },
                width: "75px",
                height: "50px",
                minHeight: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              })}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const widgetData = allWidgets.find(
                  (w) => w.name === widget.name
                );

                if (widgetData) {
                  setWidgetManagerLoading(true);
                  //@ts-ignore
                  addWidget({
                    ...widgetData,
                    id: generateUniqueId(),
                    meta: { ...widgetData.meta, category: "components" },
                  });
                  toast.success("Widget added successfully");
                }
              }}
            >
              <Center mx="auto">
                <Image src={widget.icon} width={20} height={20} />
              </Center>
            </Card>
          </Tooltip>
          <Text
            fw={500}
            size={"xs"}
            className="mt-1"
            style={{
              textAlign: "center",
              textWrap: "wrap",
              wordBreak: "break-word",
            }}
          >
            {widget.name}
          </Text>
        </Grid.Col>
      ))}
    </Grid>
  );
};

const SegmentedContainer: React.FC<SegmentedContainerProps> = ({
  onChange,
  selected,
}) => {
  return (
    <SegmentedControl
      fullWidth
      data={segments}
      value={selected}
      onChange={onChange}
      size="xs"
    />
  );
};

WidgetManager.SegmentedContainer = SegmentedContainer;
WidgetManager.WidgetList = SegmentedWidgetList;
export default WidgetManager;
