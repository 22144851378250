import React from "react";
import { Box, Switch } from "@mantine/core";

import { BlinkDirectives } from "@src/types/modules/blinks";

type Props = {
  callback: (type: keyof BlinkDirectives, data: any) => void;
};

export const LinkCloaking: React.FC<Props> = ({ callback }) => {
  return (
    <Box className="mt-2">
      <Switch
        size="xs"
        label="Enable link cloaking"
        color="dark"
        onChange={(e) => callback("isCloakingActive", e.target.checked)}
      />
    </Box>
  );
};
