import { Widget } from "../../types";

export const TwitterCard: Widget = {
  id: "",
  name: "X (twitter) Post",
  description: "Render Twitter post",
  meta: {
    type: "twitter_card",
    category: "components",
  },
  variant: "filled",
  baseClassName: "",
  properties: {
    styles: {},
    props: {
      postUrl: {
        section: "customization",
        value: "https://twitter.com/PixelAndBracket/status/1356633038717923333",
        type: "text",
        key: "postUrl",
        name: "Post URL",
      },
    },
  },
  control: {
    redirectTo: "",
    type: null,
  },
  layout: {
    currentLayout: "mobile",
    mobile: {
      top: 0,
      left: 0,
      width: 390,
      height: 500,
    },
  },
};
