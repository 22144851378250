import React, { useCallback, useEffect, useRef, useState } from "react";
import Moveable from "react-moveable";
import { Popover, Menu } from "@mantine/core";
import { useEditorStore, useEditorStoreActions } from "@src/stores/editorstore";
import { ConfigHandler } from "./Confighandler";

const ORIGINAL_WIDTH = 390;
const ORIGINAL_HEIGHT = 844;

type Props = {
  mode: "edit" | "preview" | "published";
  id: string;
  children: React.ReactNode;
  elementGuidelines: string[];
  updateOnDragEnd: (
    componentId: string,
    top: number,
    left: number,
    layoutPos: ILayoutPos
  ) => void;
  updateOnResizeEnd: (
    componentId: string,
    width: number,
    height: number,
    layoutPos: ILayoutPos
  ) => void;
  layoutPos: ILayoutPos;
  layout: "mobile" | "desktop";
  canvasDimensions: { width: number; height: number };
  overlayWidget: boolean;
  overlayComponentExists: boolean;
};

export interface ILayoutPos {
  mobile: { width: number; height: number; top: number; left: number };
  desktop: { width: number; height: number; top: number; left: number };
}

export const NewDragable: React.FC<Props> = ({
  mode,
  id,
  children,
  elementGuidelines,
  updateOnDragEnd,
  updateOnResizeEnd,
  layout,
  canvasDimensions,
  layoutPos,
  overlayWidget,
  overlayComponentExists,
}) => {
  const moveableRef = useRef<Moveable>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  const [scaleFactor, setScaleFactor] = useState({
    x: canvasDimensions.width / ORIGINAL_WIDTH,
    y: canvasDimensions.height / ORIGINAL_HEIGHT,
  });

  const [itemSize, setItemSize] = useState({
    width: layoutPos[layout].width,
    height: layoutPos[layout].height,
  });
  const [itemPosition, setItemPosition] = useState({
    x: layoutPos[layout].left,
    y: layoutPos[layout].top,
  });

  const [isDragOrResize, setIsDragOrResize] = useState(false);
  const [staticPosition, setStaticPosition] = useState(true);

  useEffect(() => {
    setScaleFactor({
      x: canvasDimensions.width / ORIGINAL_WIDTH,
      y: canvasDimensions.height / ORIGINAL_HEIGHT,
    });
  }, [canvasDimensions]);

  const scaleValue = useCallback(
    (value: number, axis: "x" | "y") => {
      return value * scaleFactor[axis];
    },
    [scaleFactor]
  );

  const unscaleValue = useCallback(
    (value: number, axis: "x" | "y") => {
      return value / scaleFactor[axis];
    },
    [scaleFactor]
  );

  const widgetId = useEditorStore.use.selectedWidget();
  const draftMode = useEditorStore.use.metadata().draftMode;
  const { updateDraftMode, setSelectedWidget } = useEditorStoreActions();
  const isPreviewMode = useEditorStore.use.mode();
  const isGridLocked = useEditorStore.use.lockGrid();
  const isLocked = useEditorStore.use.lockedWidgets().includes(id);

  const disableDrag =
    isGridLocked ||
    isLocked ||
    isPreviewMode === "preview" ||
    isPreviewMode === "published";

  const [firstDrag, setFirstDrag] = useState(false);

  const handleFirstDragUpdate = useCallback(() => {
    if (!firstDrag) {
      setFirstDrag(true);
      updateDraftMode(true);
    }
    setIsDragOrResize(true);
  }, [firstDrag, updateDraftMode]);

  useEffect(() => {
    if (firstDrag && !draftMode) {
      setFirstDrag(false);
    }
  }, [draftMode, firstDrag]);

  const isSelctedWidgetIsLinkIndicator =
    useEditorStore.use
      .definitions()
      .widgets.find((widget) => widget.id === widgetId)?.meta.type ===
    "link_indicator";

  return (
    <Menu
      shadow="md"
      width="auto"
      position={isSelctedWidgetIsLinkIndicator ? "top" : undefined}
      opened={widgetId === id && mode === "edit"}
    >
      <div
        className={`component-dnd-widget ${
          !disableDrag && widgetId === id ? "active" : ""
        } ${mode}`}
        style={{
          //! why do we need this - zIndex: widgetId === id ? 10 : 1,
          position: "absolute",
        }}
        onClick={() => setSelectedWidget(id)}
      >
        <Menu.Target>
          <div
            onDoubleClick={() => {
              useEditorStoreActions()?.lockWidget(id);
              useEditorStoreActions()?.setEditableWidget(id);
            }}
            onBlur={() => {
              useEditorStoreActions()?.unlockWidget(id);
              useEditorStoreActions()?.setEditableWidget(null);
            }}
            ref={targetRef}
            style={{
              position: "absolute",
              width: `${scaleValue(itemSize.width, "x")}px`,
              height: `${scaleValue(itemSize.height, "y")}px`,
              transform: `translate(${scaleValue(
                itemPosition.x,
                "x"
              )}px, ${scaleValue(itemPosition.y, "y")}px)`,
            }}
          >
            {children}
          </div>
        </Menu.Target>
        <Moveable
          ref={moveableRef}
          target={targetRef}
          draggable={!disableDrag}
          resizable={
            !disableDrag
              ? {
                  edge: ["nw", "n", "ne", "w", "e", "sw", "s", "se"],
                  renderDirections: ["n", "w", "e", "s"],
                }
              : false
          }
          rotatable={false}
          snappable={true}
          bounds={{
            left: 0,
            top: 0,
            right: canvasDimensions.width,
            bottom: canvasDimensions.height,
          }}
          origin={false}
          padding={{ left: 5, right: 5, top: 5, bottom: 5 }}
          throttleDrag={1}
          edgeDraggable={false}
          useAccuratePosition={true}
          elementGuidelines={elementGuidelines}
          snapDirections={{
            top: true,
            left: true,
            bottom: true,
            right: true,
            center: true,
            middle: true,
          }}
          elementSnapDirections={{
            top: true,
            left: true,
            bottom: true,
            right: true,
            center: true,
            middle: true,
          }}
          maxSnapElementGuidelineDistance={200}
          maxSnapElementGapDistance={100}
          snapThreshold={9}
          verticalGuidelines={[
            0,
            ORIGINAL_WIDTH / 4,
            ORIGINAL_WIDTH / 2,
            (ORIGINAL_WIDTH * 3) / 4,
            ORIGINAL_WIDTH,
          ].map((v) => scaleValue(v, "x"))}
          horizontalGuidelines={[
            0,
            ORIGINAL_HEIGHT / 4,
            ORIGINAL_HEIGHT / 2,
            (ORIGINAL_HEIGHT * 3) / 4,
            ORIGINAL_HEIGHT,
          ].map((v) => scaleValue(v, "y"))}
          linePadding={5}
          onDragStart={handleFirstDragUpdate}
          onDrag={({ target, transform }) => {
            if (disableDrag) return;
            target.style.transform = transform;
          }}
          onDragEnd={({ target, lastEvent }) => {
            if (disableDrag || !lastEvent) return;
            const { translate } = lastEvent;
            const newX = unscaleValue(translate[0], "x");
            const newY = unscaleValue(translate[1], "y");

            setItemPosition({ x: newX, y: newY });
            updateOnDragEnd(id, newY, newX, layoutPos);
            setIsDragOrResize(false);
          }}
          onResizeStart={handleFirstDragUpdate}
          onResize={({ target, width, height, drag }) => {
            if (disableDrag) return;
            target.style.width = `${width}px`;
            target.style.height = `${height}px`;
            target.style.transform = `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)`;
          }}
          onResizeEnd={({ target, lastEvent }) => {
            if (disableDrag || !lastEvent) return;
            const { width, height, drag } = lastEvent;
            const newWidth = unscaleValue(width, "x");
            const newHeight = unscaleValue(height, "y");
            const newX = unscaleValue(drag.beforeTranslate[0], "x");
            const newY = unscaleValue(drag.beforeTranslate[1], "y");

            setItemSize({ width: newWidth, height: newHeight });
            setItemPosition({ x: newX, y: newY });

            updateOnResizeEnd(id, newWidth, newHeight, layoutPos);
            setIsDragOrResize(false);
          }}
        />
      </div>

      <Menu.Dropdown>
        {mode === "edit" && <ConfigHandler componentId={id} />}
      </Menu.Dropdown>
    </Menu>
  );
};
