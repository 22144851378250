import React, { forwardRef, useEffect, useState } from "react";
import {
  ActionIcon,
  Autocomplete,
  Badge,
  Box,
  Button,
  Grid,
  Group,
  Kbd,
  Paper,
  SelectItemProps,
  Space,
  Switch,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import BaseWidgetInspectorContainer from "./Inspector.container";
import { useEditorStore, useEditorStoreActions } from "@src/stores/editorstore";
import { IControl, Widget } from "../../../../widgets/types";
import { IconLink, IconLinkPlus, IconSelector } from "@tabler/icons-react";
import { validateURL } from "@/src/lib/utils";
import BaseContainer from "./Inspector.container";
import { useDidUpdate } from "@mantine/hooks";
import { useWorkspaceId } from "@/src/hooks";

type Props = {};

interface Composition {
  RenderPropertiesPane: React.FC<AccordionPaneProps>;
  RenderControlPane: React.FC<{ widgetId: string }>;
  RenderSegmentPane: React.FC<{
    segment: string;
    widgetData: Record<string, any>[];
    widgetId: string;
    widgetName?: string;
  }>;
}

type AccordionPaneProps = {
  data: Record<string, any>[];
  widgetId: string;
};

const getWidgetData = (
  widgetId: string | null,
  allWidgets: Record<string, any>[] | Widget[]
): Widget | Record<string, any> | undefined => {
  if (!widgetId) return;

  return allWidgets.find((widget) => widget.id === widgetId);
};

export const WidgetInspector: React.FC<Props> & Composition = () => {
  const { widgets } = useEditorStore.use.definitions();
  const widgetId = useEditorStore.use.selectedWidget();

  const selectedWidgetData = getWidgetData(widgetId, widgets) ?? {};
  const enableControl = selectedWidgetData.control?.type !== null;
  const widgetName = selectedWidgetData.name;

  const [activeSegment, setActiveSegment] =
    React.useState<string>("properties");

  const widgetDefinitions: {
    [key: string]: Record<string, any>[];
  } = {
    properties: selectedWidgetData.properties
      ? (Object.entries(selectedWidgetData.properties?.props) as Record<
          string,
          any
        >[])
      : [],
    control: selectedWidgetData.control
      ? (Object.entries(selectedWidgetData.control) as Record<string, any>[])
      : [],
  };

  const widgetPaneData = widgetDefinitions[activeSegment];

  return (
    <BaseWidgetInspectorContainer>
      <Box>
        <BaseWidgetInspectorContainer.Header
          segment={activeSegment}
          onChange={setActiveSegment}
          enableControl={enableControl}
        />
      </Box>
      <Space h="lg" />

      <WidgetInspector.RenderSegmentPane
        segment={activeSegment}
        widgetData={widgetPaneData}
        widgetId={widgetId ?? ""}
        widgetName={widgetName}
      />
    </BaseWidgetInspectorContainer>
  );
};

const RenderPropertiesPane: React.FC<AccordionPaneProps> = ({
  data,
  widgetId,
}) => {
  const [showStyleEditor, setShowStyleEditor] = React.useState(false);

  return (
    <>
      <Box>
        <BaseWidgetInspectorContainer.AccordionContainer
          data={data}
          widgetId={widgetId}
        />
      </Box>
      <Space h="lg" />
      {/* <Box>
        <Toggle
          label="Show Style Editor"
          checked={showStyleEditor}
          onChange={(event) => setShowStyleEditor(event.currentTarget.checked)}
        />
        <Space h="sm" />
        {showStyleEditor && <CodeEditor />}
      </Box> */}
    </>
  );
};

const RenderControlPane: React.FC<{ widgetId: string }> = ({ widgetId }) => {
  const workspaceId = useWorkspaceId();
  const isSaving = useEditorStore.use.isSaving();
  const { updateWidgetControl, getWidget } = useEditorStoreActions();
  const widgetControl: IControl = getWidget(widgetId)?.control ?? {
    redirectTo: "",
    type: null,
    enableAnalytics: false,
    blinkId: null,
  };

  const allBlinks = useEditorStore.use.workspaceBlinks();
  const currentappBlinkUrl = useEditorStore.use.appBlinkURL();

  const data = allBlinks
    .map((item) => ({
      label: item.name,
      value: item.name,
      blinkSignature: item.blinkSignature,
      blinkId: item.id,
      redirectedTo: item.redirectUrl,
      type: item.type,
    }))
    .filter((item) => !currentappBlinkUrl.includes(item.blinkSignature));

  const [redirectURL, setRedirectURL] = useState(() => ({
    redirectTo: widgetControl.redirectTo,
    id: widgetControl.blinkId,
    value: data.find((blink) => blink.blinkId === widgetControl.blinkId)?.value,
  }));
  const [useBlinksRedirection, setUseBlinksRedirection] = useState(
    () => widgetControl.type === "blink"
  );
  const [urlValidationError, setUrlValidationError] = useState("");
  const [canSave, setCanSave] = useState(false);

  useDidUpdate(() => setCanSave(true), [redirectURL.redirectTo]);

  useEffect(() => {
    const isValidURL = validateURL(redirectURL.redirectTo);

    if (isValidURL) {
      setUrlValidationError("");
    } else {
      setUrlValidationError("Invalid URL");
    }
  }, [redirectURL.redirectTo]);

  const handleSaveControlSettings = () => {
    if (urlValidationError.length > 0) return;
    const redirectionURLType = useBlinksRedirection ? "blink" : "url";
    updateWidgetControl(widgetId, {
      type: redirectionURLType,
      redirectTo: redirectURL.redirectTo,
      blinkId: redirectURL.id,
    });
    setCanSave(false);
  };

  const handleAddNewBlink = () => {
    const url = `/${workspaceId}/blinks?new=true`;
    window.open(url, "_blank");
  };

  return (
    <Box>
      <Paper>
        <Text size={"sm"} fw={500}>
          Redirection URL
        </Text>
        <Box className="my-2" onMouseDown={(e) => e.stopPropagation()}>
          <Switch
            labelPosition="left"
            label="Use blinks"
            description="Turn on to use Blink for URL redirection and gain deeper analytics. Paste a link or select from existing Blinks below"
            size="xs"
            radius={"sm"}
            color="dark"
            checked={useBlinksRedirection}
            onChange={(event) => {
              setUseBlinksRedirection(event.currentTarget.checked);
              if (event.currentTarget.checked) {
                setRedirectURL({ redirectTo: "", id: null, value: "" });
              } else {
                setRedirectURL({
                  redirectTo: widgetControl.redirectTo,
                  id: widgetControl.blinkId,
                  value: data.find(
                    (blink) => blink.blinkId === widgetControl.blinkId
                  )?.value,
                });
              }
            }}
          />

          {useBlinksRedirection ? (
            <Grid className="mt-2">
              <Grid.Col span={10}>
                <Autocomplete
                  size="xs"
                  placeholder="Select from blinks"
                  itemComponent={AutoCompleteItem}
                  data={data}
                  value={redirectURL.value}
                  filter={(value, item) =>
                    item.value
                      .toLowerCase()
                      .includes(value.toLowerCase().trim()) ||
                    item.blinkSignature
                      .toLowerCase()
                      .includes(value.toLowerCase().trim())
                  }
                  onClick={(e) => e.stopPropagation()}
                  onChange={(value) => {
                    const selectedBlink = data.find(
                      (blink) => blink.value === value
                    );

                    setRedirectURL({
                      redirectTo: selectedBlink?.redirectedTo,
                      id: selectedBlink?.blinkId,
                      value: selectedBlink?.value,
                    });
                  }}
                  icon={<IconLink size="0.75rem" color="gray" />}
                  rightSection={<IconSelector size="1rem" color="gray" />}
                />
              </Grid.Col>

              <Grid.Col span={2}>
                <ActionIcon onClick={handleAddNewBlink}>
                  <IconLinkPlus size="1rem" />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          ) : (
            <Tooltip
              label={urlValidationError === "" && redirectURL.redirectTo}
              position="top"
              multiline={true}
              disabled={urlValidationError !== ""}
            >
              <TextInput
                size="xs"
                value={!useBlinksRedirection ? redirectURL.redirectTo : ""}
                placeholder="https://example.com"
                label="Enter URL"
                error={urlValidationError}
                onChange={(event) => {
                  setRedirectURL({
                    redirectTo: event.currentTarget.value,
                    id: null,
                    value: event.currentTarget.value,
                  });
                }}
              />
            </Tooltip>
          )}

          <Group className="mt-3" position="right">
            <Button
              disabled={!canSave}
              loading={isSaving}
              size="xs"
              color="teal"
              style={{ width: "100px" }}
              onClick={handleSaveControlSettings}
            >
              Save
            </Button>
          </Group>
        </Box>
      </Paper>
    </Box>
  );
};

const RenderSegmentPane: React.FC<{
  segment: string;
  widgetData: Record<string, any>[];
  widgetId: string;
  enableControl?: boolean;
  widgetName?: string;
}> = ({ segment, widgetData, widgetId, enableControl, widgetName }) => {
  const SegmentPane =
    segment === "properties" ? RenderPropertiesPane : RenderControlPane;

  return (
    <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
      {segment === "properties" && (
        <BaseContainer.CommonContainer widgetName={widgetName} />
      )}
      <SegmentPane data={widgetData} widgetId={widgetId} />
    </Box>
  );
};

interface ItemProps extends SelectItemProps {
  label: string;
  blinkSignature: string;
  blinkId: string;
  redirectedTo: string;
  type: string;
}

const CustomTipLabel = ({
  label,
  redirectedTo,
  type,
}: {
  label: string;
  redirectedTo: string;
  blinkSignature: string;
  type: string;
}) => {
  return (
    <Box
      sx={{
        wordWrap: "break-word",
        overflowWrap: "break-word",
      }}
    >
      <Group position="apart" className="my-1">
        <Text size="sm" color="dark" fw={500}>
          {label}
        </Text>
        <Badge
          size="xs"
          color={type === "blink" ? "indigo" : "teal"}
          variant="filled"
          radius={"sm"}
        >
          {type === "blink" ? "blink" : "pages"}
        </Badge>
      </Group>
      <Text
        size="xs"
        color="dark"
        style={{
          textWrap: "wrap",
        }}
      >
        {redirectedTo}
      </Text>
    </Box>
  );
};

const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  (
    { label, redirectedTo, blinkSignature, type, ...others }: ItemProps,
    ref
  ) => (
    <Tooltip
      label={
        <CustomTipLabel
          label={label}
          redirectedTo={redirectedTo}
          blinkSignature={blinkSignature}
          type={type}
        />
      }
      position="right"
      color="#f1f3f5"
      withArrow
      withinPortal
      offset={15}
      multiline={true}
      width={"auto"}
      maw={300}
    >
      <div ref={ref} {...others}>
        <Group>
          <Kbd size="xs">/ {blinkSignature}</Kbd>
        </Group>
      </div>
    </Tooltip>
  )
);

WidgetInspector.RenderPropertiesPane = RenderPropertiesPane;
WidgetInspector.RenderControlPane = RenderControlPane;
WidgetInspector.RenderSegmentPane = RenderSegmentPane;
