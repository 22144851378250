import React, { useEffect } from "react";
import { Button, Center, Container, Group, Stack, Text } from "@mantine/core";
import { PasswordProtectUI } from "@UIComponents/BlinkDirectives";
import Whitelabel from "@UIComponents/Whitelabel";
import { redirectionProxyService } from "@/src/services/redirection_proxy.service";
import { useQueryParams } from "@/src/hooks";
import toast from "react-hot-toast";

const PasswordProtected: React.FC = () => {
  const [password, setPassword] = React.useState("");
  const [isValid, setIsValid] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);

  const queryParams = useQueryParams();

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!password || password.length < 1) {
      setIsValid(false);
      setIsLoading(false);
    }

    const shortURL = queryParams["shortURL"];
    if (!shortURL) {
      toast.error("Invalid short URL");
    }

    if (typeof shortURL === "string") {
      try {
        const resp =
          await redirectionProxyService.validatePasswordProtectedBlinks(
            shortURL,
            password
          );

        if (resp?.redirectURL) {
          // Redirect to the URL
          window.location.href = resp.redirectURL;
        }

        if (resp.status === 401 || resp.status === 404 || resp.status === 500) {
          toast.error("Invalid password or short URL");
          setIsValid(false);
        }

        setIsLoading(false);
      } catch (error) {
        toast.error("Invalid password");
        setIsLoading(false);
      }
    }
  };

  return (
    <Container>
      <Center h={"100%"}>
        <Stack>
          <Whitelabel isMobileLayoutActive={true} position="relative" />
          <Text
            variant="gradient"
            gradient={{ from: "indigo", to: "cyan", deg: 45 }}
            sx={{ fontFamily: "Greycliff CF, sans-serif" }}
            ta="left"
            fz="xl"
            fw={700}
          >
            Password Protected Page
          </Text>
          <PasswordProtectUI
            value={password}
            setValue={setPassword}
            isLoading={isLoading}
            isValid={isValid}
          />
          <Group>
            <Button
              variant="filled"
              color="dark"
              size="xs"
              loading={isLoading}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Group>
        </Stack>
      </Center>
    </Container>
  );
};

export default PasswordProtected;
