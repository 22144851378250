import React from "react";

import {
  Modal,
  Button,
  Group,
  Container,
  Paper,
  Text,
  Center,
  Stack,
  List,
  ThemeIcon,
  Overlay,
} from "@mantine/core";
import { BaseProps } from "@/src/types/common.types";

import "./styles.scss";

import {
  IconBrandDiscordFilled,
  IconBrandTeams,
  IconCircleCheck,
  IconColorSwatch,
  IconDimensions,
  IconPasswordMobilePhone,
  IconSourceCode,
  IconTypography,
  IconWorldWww,
} from "@tabler/icons-react";

type Props = {
  opened: boolean;
  close: () => void;
  waitlistPage?: boolean;
};

interface DiscloureProps extends BaseProps {
  opened: boolean;
  onClose: () => void;
  waitlistPage?: boolean;
}

interface Composition {
  Container: React.FC<DiscloureProps>;
}

export const UnreleasedFeatures: React.FC<Props> & Composition = ({
  opened,
  close,
  waitlistPage = false,
}) => {
  return (
    <UnreleasedFeatures.Container opened={opened} onClose={close}>
      <Center>
        <Paper
          shadow="lg"
          p={"md"}
          style={{
            width: "20rem",
            height: "30rem",
            position: "absolute",
            top: "25%",
          }}
        >
          <Stack align="flex-start" spacing={"xs"}>
            <Group spacing={"sm"}>
              <Text
                variant="gradient"
                gradient={{ from: "indigo", to: "cyan", deg: 45 }}
                sx={{ fontFamily: "Greycliff CF, sans-serif" }}
                ta="center"
                fz="xl"
                fw={700}
              >
                Get early access to
              </Text>
            </Group>

            <Group>
              <Text variant="text" color="dimmed" fz={"xs"}>
                This feature is coming soon in our next release. Sign up for the
                waitlist to get notified when it's available and receive early
                access.
              </Text>

              <FeatureList />
            </Group>
            <Button
              className="mt-3"
              fullWidth
              variant={"filled"}
              size="xs"
              onClick={() => {
                window.open("https://discord.gg/RzUA2R6g", "_blank");
              }}
              sx={(theme) => ({
                backgroundColor: "#5865f2",
                color: "fff",

                "&:hover": {
                  backgroundColor: theme.colors.indigo[7],
                },
              })}
              leftIcon={<IconBrandDiscordFilled size={"1rem"} />}
            >
              Join Discord Community
            </Button>
          </Stack>
        </Paper>
      </Center>
    </UnreleasedFeatures.Container>
  );
};

const ContainerComponent: React.FC<DiscloureProps> = ({
  opened,
  onClose,
  children,
  waitlistPage,
}) => {
  if (waitlistPage) {
    <Container p={"lg"}>{children}</Container>;
  }

  return (
    <Modal.Root opened={opened} onClose={onClose} size={"70%"}>
      <Modal.Overlay />
      <Modal.Content
        style={{
          overflow: "hidden",
        }}
      >
        <Modal.Body className="unreleased-features-container">
          <Overlay
            gradient="linear-gradient(145deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0) 100%)"
            opacity={0.95}
          >
            <Container p={"lg"}>{children}</Container>
          </Overlay>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export const FeatureList: React.FC<{ fontColor?: string }> = ({
  fontColor = "dark",
}) => {
  return (
    <List
      spacing="xs"
      size="sm"
      center
      icon={
        <ThemeIcon color="teal" size={24} radius="xl">
          <IconCircleCheck size="1rem" />
        </ThemeIcon>
      }
      style={{
        color: fontColor,
      }}
    >
      <List.Item
        icon={
          <ThemeIcon color="indigo" size={24} radius="xl">
            <IconTypography size="1rem" />
          </ThemeIcon>
        }
      >
        Font family and typography settings
      </List.Item>
      <List.Item
        icon={
          <ThemeIcon color="indigo" size={24} radius="xl">
            <IconColorSwatch size="1rem" />
          </ThemeIcon>
        }
      >
        Brand assets and color palette
      </List.Item>
      <List.Item
        icon={
          <ThemeIcon color="indigo" size={24} radius="xl">
            <IconPasswordMobilePhone size="1rem" />
          </ThemeIcon>
        }
      >
        Password protection
      </List.Item>
      <List.Item
        icon={
          <ThemeIcon color="indigo" size={24} radius="xl">
            <IconBrandTeams size="1rem" />
          </ThemeIcon>
        }
      >
        Workspaces and team collaboration
      </List.Item>
      <List.Item
        icon={
          <ThemeIcon color="indigo" size={24} radius="xl">
            <IconDimensions size="1rem" />
          </ThemeIcon>
        }
      >
        More new widgets and customisation options
      </List.Item>
      <List.Item
        icon={
          <ThemeIcon color="indigo" size={24} radius="xl">
            <IconSourceCode size="1rem" />
          </ThemeIcon>
        }
      >
        Bring your own css and customise your widgets
      </List.Item>
      <List.Item
        icon={
          <ThemeIcon color="indigo" size={24} radius="xl">
            <IconWorldWww size="1rem" />
          </ThemeIcon>
        }
      >
        Custom domain and white-labeling
      </List.Item>
      <List.Item
        icon={
          <ThemeIcon color="green" size={24} radius="xl">
            🎉
          </ThemeIcon>
        }
      >
        And many more exciting features.
      </List.Item>
    </List>
  );
};

UnreleasedFeatures.Container = ContainerComponent;
